/**
 * Spot Distrib Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddCanalToDistributeurCmd } from '../model/addCanalToDistributeurCmd';
import { AddDistributeurCmd } from '../model/addDistributeurCmd';
import { AddProduitsDiffuseCmd } from '../model/addProduitsDiffuseCmd';
import { DiffuserRatePlanListSurCanalCmd } from '../model/diffuserRatePlanListSurCanalCmd';
import { DistribSession } from '../model/distribSession';
import { ExclureProduitsCmd } from '../model/exclureProduitsCmd';
import { ExpositionHotel } from '../model/expositionHotel';
import { FormConfig } from '../model/formConfig';
import { UpsertRatePlanCmd } from '../model/upsertRatePlanCmd';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExpoService {

    protected basePath = 'https://services-dev.spotpilot.com/distrib';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Ajouter un nouveau canal pour un distributeur
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoAddCanalToDistributeurPost(body: AddCanalToDistributeurCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoAddCanalToDistributeurPost(body: AddCanalToDistributeurCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoAddCanalToDistributeurPost(body: AddCanalToDistributeurCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoAddCanalToDistributeurPost(body: AddCanalToDistributeurCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoAddCanalToDistributeurPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/addCanalToDistributeur`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter un nouveau distributeur
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoAddDistributeurPost(body: AddDistributeurCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoAddDistributeurPost(body: AddDistributeurCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoAddDistributeurPost(body: AddDistributeurCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoAddDistributeurPost(body: AddDistributeurCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoAddDistributeurPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/addDistributeur`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * infos Expostions
     * 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoAllExpoGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<ExpositionHotel>;
    public apiExpoAllExpoGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpositionHotel>>;
    public apiExpoAllExpoGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpositionHotel>>;
    public apiExpoAllExpoGet(hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoAllExpoGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExpositionHotel>('get',`${this.basePath}/api/expo/allExpo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exclure les produits de la matrice d&#x27;expo
     * 
     * @param hotCode 
     * @param disCode 
     * @param canCode 
     * @param rtpCode 
     * @param prdvuCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoDelProduitExcluDelete(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, prdvuCode?: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoDelProduitExcluDelete(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, prdvuCode?: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoDelProduitExcluDelete(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, prdvuCode?: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoDelProduitExcluDelete(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, prdvuCode?: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoDelProduitExcluDelete.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (disCode !== undefined && disCode !== null) {
            queryParameters = queryParameters.set('DisCode', <any>disCode);
        }
        if (canCode !== undefined && canCode !== null) {
            queryParameters = queryParameters.set('CanCode', <any>canCode);
        }
        if (rtpCode !== undefined && rtpCode !== null) {
            queryParameters = queryParameters.set('RtpCode', <any>rtpCode);
        }
        if (prdvuCode !== undefined && prdvuCode !== null) {
            queryParameters = queryParameters.set('PrdvuCode', <any>prdvuCode);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/expo/delProduitExclu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ratePlanCode 
     * @param pricerCode 
     * @param produitVenduUnitCode 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoDeleteProduitDiffuseDelete(ratePlanCode: string, pricerCode: string, produitVenduUnitCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoDeleteProduitDiffuseDelete(ratePlanCode: string, pricerCode: string, produitVenduUnitCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoDeleteProduitDiffuseDelete(ratePlanCode: string, pricerCode: string, produitVenduUnitCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoDeleteProduitDiffuseDelete(ratePlanCode: string, pricerCode: string, produitVenduUnitCode: string, hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ratePlanCode === null || ratePlanCode === undefined) {
            throw new Error('Required parameter ratePlanCode was null or undefined when calling apiExpoDeleteProduitDiffuseDelete.');
        }

        if (pricerCode === null || pricerCode === undefined) {
            throw new Error('Required parameter pricerCode was null or undefined when calling apiExpoDeleteProduitDiffuseDelete.');
        }

        if (produitVenduUnitCode === null || produitVenduUnitCode === undefined) {
            throw new Error('Required parameter produitVenduUnitCode was null or undefined when calling apiExpoDeleteProduitDiffuseDelete.');
        }

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoDeleteProduitDiffuseDelete.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ratePlanCode !== undefined && ratePlanCode !== null) {
            queryParameters = queryParameters.set('RatePlanCode', <any>ratePlanCode);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('PricerCode', <any>pricerCode);
        }
        if (produitVenduUnitCode !== undefined && produitVenduUnitCode !== null) {
            queryParameters = queryParameters.set('ProduitVenduUnitCode', <any>produitVenduUnitCode);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/expo/deleteProduitDiffuse`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Diffuser une liste de ratePlans sur un canal
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoDiffuserRatePlanListSurCanalPost(body: DiffuserRatePlanListSurCanalCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoDiffuserRatePlanListSurCanalPost(body: DiffuserRatePlanListSurCanalCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoDiffuserRatePlanListSurCanalPost(body: DiffuserRatePlanListSurCanalCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoDiffuserRatePlanListSurCanalPost(body: DiffuserRatePlanListSurCanalCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoDiffuserRatePlanListSurCanalPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/diffuserRatePlanListSurCanal`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exclure les produits de la matrice d&#x27;expo
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoExclureProduitsPost(body: ExclureProduitsCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoExclureProduitsPost(body: ExclureProduitsCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoExclureProduitsPost(body: ExclureProduitsCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoExclureProduitsPost(body: ExclureProduitsCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoExclureProduitsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/exclureProduits`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoNewCanalFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiExpoNewCanalFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiExpoNewCanalFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiExpoNewCanalFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoNewCanalFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/expo/newCanalForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoNewDistributeurFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiExpoNewDistributeurFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiExpoNewDistributeurFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiExpoNewDistributeurFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoNewDistributeurFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/expo/newDistributeurForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param ratePlan 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoRatePlanFormGet(hotCode: string, ratePlan?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiExpoRatePlanFormGet(hotCode: string, ratePlan?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiExpoRatePlanFormGet(hotCode: string, ratePlan?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiExpoRatePlanFormGet(hotCode: string, ratePlan?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoRatePlanFormGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (ratePlan !== undefined && ratePlan !== null) {
            queryParameters = queryParameters.set('ratePlan', <any>ratePlan);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/expo/ratePlanForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param disCode 
     * @param canCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoSelectRatePlansPourDiffusionFormGet(hotCode: string, disCode?: string, canCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiExpoSelectRatePlansPourDiffusionFormGet(hotCode: string, disCode?: string, canCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiExpoSelectRatePlansPourDiffusionFormGet(hotCode: string, disCode?: string, canCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiExpoSelectRatePlansPourDiffusionFormGet(hotCode: string, disCode?: string, canCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoSelectRatePlansPourDiffusionFormGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (disCode !== undefined && disCode !== null) {
            queryParameters = queryParameters.set('disCode', <any>disCode);
        }
        if (canCode !== undefined && canCode !== null) {
            queryParameters = queryParameters.set('canCode', <any>canCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/expo/SelectRatePlansPourDiffusionForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param disCode 
     * @param canCode 
     * @param rtpCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoSelectprdvuExclusionFormGet(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiExpoSelectprdvuExclusionFormGet(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiExpoSelectprdvuExclusionFormGet(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiExpoSelectprdvuExclusionFormGet(hotCode: string, disCode?: string, canCode?: string, rtpCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiExpoSelectprdvuExclusionFormGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (disCode !== undefined && disCode !== null) {
            queryParameters = queryParameters.set('disCode', <any>disCode);
        }
        if (canCode !== undefined && canCode !== null) {
            queryParameters = queryParameters.set('canCode', <any>canCode);
        }
        if (rtpCode !== undefined && rtpCode !== null) {
            queryParameters = queryParameters.set('rtpCode', <any>rtpCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/expo/SelectprdvuExclusionForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour de la liste des produits diffusés pour un tarif
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoUpdateProduistDiffusePost(body: AddProduitsDiffuseCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoUpdateProduistDiffusePost(body: AddProduitsDiffuseCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoUpdateProduistDiffusePost(body: AddProduitsDiffuseCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoUpdateProduistDiffusePost(body: AddProduitsDiffuseCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoUpdateProduistDiffusePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/updateProduistDiffuse`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creation ou Mise à jour d&#x27;un plan tarifaire
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiExpoUpsertRatePlanPost(body: UpsertRatePlanCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiExpoUpsertRatePlanPost(body: UpsertRatePlanCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiExpoUpsertRatePlanPost(body: UpsertRatePlanCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiExpoUpsertRatePlanPost(body: UpsertRatePlanCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiExpoUpsertRatePlanPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/expo/UpsertRatePlan`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
