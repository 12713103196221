/**
 * Spot Distrib Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllPricersHotel } from '../model/allPricersHotel';
import { CreateGrilleCmd } from '../model/createGrilleCmd';
import { CreatePricerNiveauCmd } from '../model/createPricerNiveauCmd';
import { CreatePricerRootCmd } from '../model/createPricerRootCmd';
import { DistribSession } from '../model/distribSession';
import { FixerPrixPricerRackCmd } from '../model/fixerPrixPricerRackCmd';
import { FormConfig } from '../model/formConfig';
import { MovPrdvuToPricerCmd } from '../model/movPrdvuToPricerCmd';
import { RenameGrilleCmd } from '../model/renameGrilleCmd';
import { RenamePricerNiveauCmd } from '../model/renamePricerNiveauCmd';
import { SetDatesValidationForGrilleCmd } from '../model/setDatesValidationForGrilleCmd';
import { SetDatesValidationForNiveauCmd } from '../model/setDatesValidationForNiveauCmd';
import { SetPrdvuRefCmd } from '../model/setPrdvuRefCmd';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PricerService {

    protected basePath = 'https://services-dev.spotpilot.com/distrib';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Liste des pricers d&#x27;un hôtel
     * 
     * @param fixSeulement 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<AllPricersHotel>;
    public apiPricerAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllPricersHotel>>;
    public apiPricerAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllPricersHotel>>;
    public apiPricerAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fixSeulement === null || fixSeulement === undefined) {
            throw new Error('Required parameter fixSeulement was null or undefined when calling apiPricerAllPricersGet.');
        }

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerAllPricersGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fixSeulement !== undefined && fixSeulement !== null) {
            queryParameters = queryParameters.set('FixSeulement', <any>fixSeulement);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AllPricersHotel>('get',`${this.basePath}/api/pricer/allPricers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pricerCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreateGrilleFormGet(hotCode: string, pricerCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerCreateGrilleFormGet(hotCode: string, pricerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerCreateGrilleFormGet(hotCode: string, pricerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerCreateGrilleFormGet(hotCode: string, pricerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerCreateGrilleFormGet.');
        }

        if (pricerCode === null || pricerCode === undefined) {
            throw new Error('Required parameter pricerCode was null or undefined when calling apiPricerCreateGrilleFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('pricerCode', <any>pricerCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/createGrilleForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreateGrillePost(body?: CreateGrilleCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerCreateGrillePost(body?: CreateGrilleCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerCreateGrillePost(body?: CreateGrilleCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerCreateGrillePost(body?: CreateGrilleCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricer/createGrille`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreatePricerNiveauFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerCreatePricerNiveauFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerCreatePricerNiveauFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerCreatePricerNiveauFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerCreatePricerNiveauFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/createPricerNiveauForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreatePricerNiveauPut(body?: CreatePricerNiveauCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerCreatePricerNiveauPut(body?: CreatePricerNiveauCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerCreatePricerNiveauPut(body?: CreatePricerNiveauCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerCreatePricerNiveauPut(body?: CreatePricerNiveauCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/pricer/createPricerNiveau`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreatePricerRootFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerCreatePricerRootFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerCreatePricerRootFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerCreatePricerRootFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerCreatePricerRootFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/createPricerRootForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerCreatePricerRootPut(body?: CreatePricerRootCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerCreatePricerRootPut(body?: CreatePricerRootCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerCreatePricerRootPut(body?: CreatePricerRootCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerCreatePricerRootPut(body?: CreatePricerRootCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/pricer/createPricerRoot`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDatesValidationFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerDatesValidationFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerDatesValidationFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerDatesValidationFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDatesValidationFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/DatesValidationForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDatesValidationGrillePost(body?: SetDatesValidationForGrilleCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDatesValidationGrillePost(body?: SetDatesValidationForGrilleCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDatesValidationGrillePost(body?: SetDatesValidationForGrilleCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDatesValidationGrillePost(body?: SetDatesValidationForGrilleCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricer/datesValidationGrille`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDatesValidationNiveauPost(body?: SetDatesValidationForNiveauCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDatesValidationNiveauPost(body?: SetDatesValidationForNiveauCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDatesValidationNiveauPost(body?: SetDatesValidationForNiveauCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDatesValidationNiveauPost(body?: SetDatesValidationForNiveauCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricer/datesValidationNiveau`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pricerCode 
     * @param grilleCode 
     * @param niveauCode 
     * @param prdvuCode 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeleteFixePrixPricerRackDelete(pricerCode: string, grilleCode: string, niveauCode: string, prdvuCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeleteFixePrixPricerRackDelete(pricerCode: string, grilleCode: string, niveauCode: string, prdvuCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeleteFixePrixPricerRackDelete(pricerCode: string, grilleCode: string, niveauCode: string, prdvuCode: string, hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeleteFixePrixPricerRackDelete(pricerCode: string, grilleCode: string, niveauCode: string, prdvuCode: string, hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pricerCode === null || pricerCode === undefined) {
            throw new Error('Required parameter pricerCode was null or undefined when calling apiPricerDeleteFixePrixPricerRackDelete.');
        }

        if (grilleCode === null || grilleCode === undefined) {
            throw new Error('Required parameter grilleCode was null or undefined when calling apiPricerDeleteFixePrixPricerRackDelete.');
        }

        if (niveauCode === null || niveauCode === undefined) {
            throw new Error('Required parameter niveauCode was null or undefined when calling apiPricerDeleteFixePrixPricerRackDelete.');
        }

        if (prdvuCode === null || prdvuCode === undefined) {
            throw new Error('Required parameter prdvuCode was null or undefined when calling apiPricerDeleteFixePrixPricerRackDelete.');
        }

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeleteFixePrixPricerRackDelete.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('PricerCode', <any>pricerCode);
        }
        if (grilleCode !== undefined && grilleCode !== null) {
            queryParameters = queryParameters.set('GrilleCode', <any>grilleCode);
        }
        if (niveauCode !== undefined && niveauCode !== null) {
            queryParameters = queryParameters.set('NiveauCode', <any>niveauCode);
        }
        if (prdvuCode !== undefined && prdvuCode !== null) {
            queryParameters = queryParameters.set('PrdvuCode', <any>prdvuCode);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/pricer/DeleteFixePrixPricerRack`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pricerCode 
     * @param grilleCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeleteGrilleDelete(hotCode: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeleteGrilleDelete(hotCode: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeleteGrilleDelete(hotCode: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeleteGrilleDelete(hotCode: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeleteGrilleDelete.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('PricerCode', <any>pricerCode);
        }
        if (grilleCode !== undefined && grilleCode !== null) {
            queryParameters = queryParameters.set('GrilleCode', <any>grilleCode);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/pricer/DeleteGrille`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param code 
     * @param pricerCode 
     * @param grilleCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeletePricerNiveauDelete(hotCode: string, code?: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeletePricerNiveauDelete(hotCode: string, code?: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeletePricerNiveauDelete(hotCode: string, code?: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeletePricerNiveauDelete(hotCode: string, code?: string, pricerCode?: string, grilleCode?: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeletePricerNiveauDelete.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('Code', <any>code);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('PricerCode', <any>pricerCode);
        }
        if (grilleCode !== undefined && grilleCode !== null) {
            queryParameters = queryParameters.set('GrilleCode', <any>grilleCode);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/pricer/DeletePricerNiveau`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerFixPricerRackFormGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerFixPricerRackFormGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerFixPricerRackFormGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerFixPricerRackFormGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/fixPricerRackForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerFixerPrixPricerRackPost(body?: FixerPrixPricerRackCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerFixerPrixPricerRackPost(body?: FixerPrixPricerRackCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerFixerPrixPricerRackPost(body?: FixerPrixPricerRackCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerFixerPrixPricerRackPost(body?: FixerPrixPricerRackCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricer/fixerPrixPricerRack`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param prdvuCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerMovPrdvuToPricerFormGet(hotCode: string, prdvuCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerMovPrdvuToPricerFormGet(hotCode: string, prdvuCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerMovPrdvuToPricerFormGet(hotCode: string, prdvuCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerMovPrdvuToPricerFormGet(hotCode: string, prdvuCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerMovPrdvuToPricerFormGet.');
        }

        if (prdvuCode === null || prdvuCode === undefined) {
            throw new Error('Required parameter prdvuCode was null or undefined when calling apiPricerMovPrdvuToPricerFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (prdvuCode !== undefined && prdvuCode !== null) {
            queryParameters = queryParameters.set('prdvuCode', <any>prdvuCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/movPrdvuToPricerForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerMovPrdvuToPricerPut(body?: MovPrdvuToPricerCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerMovPrdvuToPricerPut(body?: MovPrdvuToPricerCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerMovPrdvuToPricerPut(body?: MovPrdvuToPricerCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerMovPrdvuToPricerPut(body?: MovPrdvuToPricerCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/pricer/movPrdvuToPricer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerRenameGrillePut(body?: RenameGrilleCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerRenameGrillePut(body?: RenameGrilleCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerRenameGrillePut(body?: RenameGrilleCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerRenameGrillePut(body?: RenameGrilleCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/pricer/renameGrille`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerRenameObjectFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerRenameObjectFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerRenameObjectFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerRenameObjectFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerRenameObjectFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricer/renameObjectForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerRenamePricerNiveauPost(body?: RenamePricerNiveauCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerRenamePricerNiveauPost(body?: RenamePricerNiveauCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerRenamePricerNiveauPost(body?: RenamePricerNiveauCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerRenamePricerNiveauPost(body?: RenamePricerNiveauCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricer/RenamePricerNiveau`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerSetPrdvuRefPut(body?: SetPrdvuRefCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerSetPrdvuRefPut(body?: SetPrdvuRefCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerSetPrdvuRefPut(body?: SetPrdvuRefCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerSetPrdvuRefPut(body?: SetPrdvuRefCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/pricer/setPrdvuRef`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
