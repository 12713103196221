<div class="cellulemois">
    <div class="mois" #tailleMois>
        <span *ngIf="tailleMois.offsetWidth >= 90">{{ moisLibelle }}</span>
        <span *ngIf="tailleMois.offsetWidth < 90">{{moisLibelleCourt}} </span>
        {{ Annee.toString().slice(-2) }}
    </div>

    <div class="ligne">
        <div *ngFor="let jour of dateMois">
            <div [class.fin-de-semaine]="jour.sunday">
                <div class="day">
                    <div class="jour" [ngClass]="{ dateDuJour: jour.currentDate }" [class.week]="jour.week"
                        [class.weekend]="jour.weekend" [class.dimanche]="jour.sunday">
                        <span *ngIf="jour.moisCourant === Mois"
                            [pTooltip]="jour.dateJour.toLocaleString('fr', { weekday: 'long' })" tooltipPosition="left"
                            pTooltipClass="custom-tooltip">
                            {{ jour.dateNum }}
                        </span>

                        <span *ngIf="jour.moisCourant != Mois" style=" display: inline-block;">

                        </span>
                    </div>
                    <div class="evenements" #cellule>
                        <span *ngFor="let event of events">
                            <ng-container *ngIf="jour.moisCourant === Mois">

                                <span *ngIf="eventDetail(jour.dateJour, event)" class="evenement"
                                    [ngStyle]="{'background-color': event.color}" (click)="setDetailEvent(event)"
                                    pTooltip="{{ event.label }}" tooltipPosition="bottom"
                                    pTooltipClass="custom-tooltip"></span>

                                <span *ngIf="!eventDetail(jour.dateJour, event)" class="evenement"
                                    [ngStyle]="{ 'background-color': 'transparent' }"></span>
                            </ng-container>
                        </span>
                        <span *ngIf="jeudi(jour)" class="valeusemaine">{{getWeekNumber(jour.dateJour)}} </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
