<p-card *ngIf="hotelNotIntialized">
    <div class="mb-3">
        L'hôtel <span class="font-semibold text-pink-900">{{currentHotel.name }} </span> n'est pas initialisé pour Mews
    </div>
    <p-button label="Initialiser" icon="pi pi-chevron-circle-right" (onClick)="initHotel($event) ">
    </p-button>
</p-card>
        <div *ngIf="!hotelNotIntialized" class="mr-1">
            <p-toolbar [style]="{'padding': '2px'}">
                <div class="p-toolbar-group-left">
                    <p-button label="Activer" icon="pi pi-check-circle" class="mr-1" styleClass="p-button-success" (click)="start()" [disabled]="!hotelNotIntialized"></p-button>
                    <p-button label="Désactiver" icon="pi pi-times" class="mr-1" styleClass="p-button-danger"
                      (click)="stop()"  [disabled]="!hotelNotIntialized"></p-button>
                    <p-button label="Initialiser " icon="pi pi-chevron-circle-right" class=" mr-1 ml-5"
                    (onClick)="initializ($event)" [disabled]=""></p-button>
                    <!-- <p-button label="RoomType" icon="pi pi-arrow-up" class="mr-1" styleClass="p-button-success"
                        (onClick)="saveComfirmation()" [disabled]="!hotelNotIntialized">
                    </p-button> -->
                    <p-button label="Renvoyer les resas" icon="pi pi-arrow-up" class="mr-1" styleClass="p-button-info"
                       (click)="loadResas()"  [disabled]="!hotelNotIntialized"></p-button>
                   
                </div>
                <div class="p-toolbar-group-right">
                    <!-- <p-button  *ngIf="hotelCredentials.clientToken == ''" label="Sauvegarder" icon="pi pi-save" class="mr-1" styleClass="p-button-success" ></p-button> -->
                </div>
            </p-toolbar>
            <div class="grid ml-2 mt-1">
                <div class="col grid " *ngIf="hotelCredentials != null">
                    <p-panel  class="col grid" [style]="{width: '100%', height:'calc(100vh - 290px)', 'margin-top' : '5px'}">
                        <ng-template pTemplate="header">
                            <span style="color: #f8f9fa;">cofig</span>
                        </ng-template>
                        <div [style]="{width: '100%', height:'calc(100vh - 290px)'}">
                            <div class="col grid ">
                                <div class="col-11" style=" margin-block: -12px;">
                                    <span class=" mr-4 ">Activé</span>
                                </div>
                                <div class="col-1">
                                    <p-inputSwitch  aria-label="" [(ngModel)]="hotelCredentials.enabled" disabled>
                                    </p-inputSwitch>
                                </div>
                                
                            </div>
                            <div class="col grid">
                                <div class="col-11" style="margin-block: -9px;">
                                    <span class=" mr-4 ">WebHook</span>
                                </div>
                                <div class="col-1">
                                    <p-inputSwitch aria-label="" [(ngModel)]="hotelCredentials.webSocket" disabled>
                                    </p-inputSwitch>
                                </div>
                            </div>
    
                            <div>
                                <div class="grid">
                                    <label class="col-2 ">Client Token</label>
                                    <input class="col" type="text" pInputText
                                        [(ngModel)]="hotelCredentials.clientToken" />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <label class="col-2">Access Token</label>
                                    <input class="col" type="text" pInputText pInputText
                                        [(ngModel)]="hotelCredentials.accessToken" />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2">LoginSpecA</span>
                                    <input class="col-10" type="text" pInputText  inputStyleClass="w-full"
                                        [(ngModel)]="hotelCredentials.loginSpecA" />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2" >PwdSpecA</span>
                                    <p-password autocomplete="new-password"  [feedback]="false"  id="newPassword" pInputText [style]="{'margin': '-7.4px', padding:'0px'}"   class="col" inputStyleClass="w-full" styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right" [(ngModel)]="hotelCredentials.pwdSpecA" [toggleMask]="true" />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2">HotelCodeSpecA</span>
                                    <input class="col-10" type="text" pInputText
                                        [(ngModel)]="hotelCredentials.hotelCodeSpecA" />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2">CBC</span>
                                    <input class="col-10" type="text" pInputText [(ngModel)]="hotelCredentials.cbc" readonly />
                                </div>
                            </div>
    
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2">EnterpriseId</span>
                                    <input class="col-10" type="text" pInputText [(ngModel)]="hotelCredentials.enterpriseId"
                                        readonly />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-2">
                                    <span class="col-2">HotelName</span>
                                    <input class="col-10" type="text" pInputText [(ngModel)]="hotelCredentials.hotelName"
                                        readonly />
                                </div>
                            </div>
                            <div>
                                <div class="grid mt-1 mb-1">
                                    <span class="col-2">CodeLanguage
                                    </span>
                                    <!-- <p-dropdown [options]="codeLanguages" [style]="{'width': '160px', 'min-height': '28px'}"
                                        [(ngModel)]="selectedLanguage" optionLabel="code" [showClear]="true"
                                        placeholder="-">
                                    </p-dropdown> -->
                                    <p-dropdown [options]="codeLanguages" (onChange)="getRoomtypes($event)" [(ngModel)]="selectedLanguage" optionLabel="name" [showClear]="true" placeholder="-"
                            [style]="{'width': '223px', 'min-height': '28px', 'margin-top':'3px'}" [ngClass]="{'p-error': submitted && f.displayName.errors }">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2" *ngIf="selectedLanguage">
                                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedLanguage.code.toLowerCase()" style="width: 18px"/>
                                        <div>{{ selectedLanguage.name }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-country pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/>
                                        <div>{{ country.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                                </div>
                            </div>
                            <div class="col grid">
                                <div class="col-11">
                                    <span class=" mr-4 ">Utiliser un code spot pour les types de chambre </span>
                                </div>
                                <div class="col-1">
                                    <p-inputSwitch  aria-label="" [(ngModel)]="hotelCredentials.useSpotRoomTypeCode"
                                    disabled>
                                </p-inputSwitch>
                                </div>
                            </div>
                            <div class="col grid">
                                <div class="col-11">
                                    <span >Stock uniquement sur les chambres de type Room </span>
                                </div>
                                <div class="col-1">
                                    <p-inputSwitch  aria-label="" (onChange)="getRoomtypes($event)" [(ngModel)]="hotelCredentials.stockRoomOnly">
                                    </p-inputSwitch>
                                </div>
                            </div>
                        </div>
                        
                    </p-panel>
                </div>
                <div class="col grid" >
                    <!-- Services -->
                    
                        <p-panel header="Services" class="col-12" >
                            <p-table [value]="mewsServices" selectionMode="multiple" [(selection)]="selectedServiceMews" dataKey="id"  (onRowSelect)="getRoomtypes($event)" (onRowUnselect)="getRoomtypes($event)" 
                            [scrollable]="true" scrollHeight=" 210px" styleClass="p-datatable-striped p-datatable-sm">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th pSortableColumn="type" style="width:15%">Type <p-sortIcon field="type"></p-sortIcon></th>
                                    <th pSortableColumn="name" style="width:20%">Name <p-sortIcon field="name"></p-sortIcon></th>
                                    <th pSortableColumn="startTime" style="width:15%">Start Time <p-sortIcon field="startTime"></p-sortIcon></th>
                                    <th pSortableColumn="endTime" style="width:15%">End Time <p-sortIcon field="endTime"></p-sortIcon></th>
                                    <th pSortableColumn="id" style="text-align: center;">Id<p-sortIcon field="id"></p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-service>
                                <tr >
                                    <td>
                                        <p-tableCheckbox [value]="service" ></p-tableCheckbox>
                                    </td>
                                    <td>{{service.type}}</td>
                                    <td>{{service.name}}</td>
                                    <td>{{service.startTime}} </td>
                                    <td>{{service.endTime}}</td>
                                    <td>{{service.id}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </p-panel>
                        <p-panel class="col-12" [style]="{width: '100%', 'margin-top': '-2px'}">

                            <ng-template pTemplate="header">
                                <div class="col grid" style="padding: 0px; margin: 0px;">
                                    <span class="col-9"><b>
                                        Types de chambres</b></span>
                                        <span class="col-3" style="text-align: end;"><b>Total chambres: </b>
                                            <p-badge  [value]="nbrooms" styleClass="mr-2"></p-badge>
                                        </span>
                                </div>
                            </ng-template>
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 590px)'}">
                                <p-table [value]="roomTypes"  dataKey="code"
                                [scrollable]="true" scrollHeight="calc(100vh - 530px)" styleClass="p-datatable-striped p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="code" style="width:10%">Code <p-sortIcon field="code"></p-sortIcon></th>
                                                <th pSortableColumn="label" style="width:20%">Libelle <p-sortIcon field="label"></p-sortIcon></th>
                                                <th pSortableColumn="description" style="max-width:50%;">Description <p-sortIcon field="description"></p-sortIcon></th>
                                                <th pSortableColumn="nbRooms" style="width:13%">Chambres <p-sortIcon field="nbRooms"></p-sortIcon></th>
        
                                            </tr>
                                        </ng-template>
                                <ng-template pTemplate="body" let-roomType>
                                    <tr>
                                        <ng-container [ngSwitch]="true" [style.font-style]>
                                            <ng-container *ngSwitchCase="roomType?.description?.length > 80">
                                                <td>{{roomType.code}}</td>
                                            <td>{{roomType.label}}</td>
                                                <td style="max-height: 5px !important;">{{roomType.description?.slice(0,80)}}<span >...</span></td>
                                           <td style="text-align: center;">{{roomType.nbRooms}}</td>

                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                            <td>{{roomType.code}}</td>
                                            <td>{{roomType.label}}</td>
                                           <td >{{roomType.description}}</td>
                                           <td style="text-align: center;">{{roomType.nbRooms}}</td>
                                            </ng-container>
                                            
                                        </ng-container>
                                        
                                    </tr>
                                </ng-template>
                            </p-table>
                            </p-scrollPanel>
                           
                        </p-panel>
                   
                    

                </div>
               
               
            </div>
        </div>
<p-dialog *ngIf="mewsInitDialog" [(visible)]="mewsInitDialog" [modal]="true" [style]="{ height:'350px', width:'340px' }">
    <div class="grid">
        <div class="col-6">
            <span>Debut</span>
            <p-calendar [(ngModel)]="startDate"   [showIcon]="true" [readonlyInput]="true"
            [style]="{'width':'100%'}" appendTo="body" dateFormat="dd/mm/yy">
           </p-calendar>
        </div>
        <div class="col-6">
            <span>Fin</span>
            <p-calendar [(ngModel)]="endDate" [minDate]="startDate"  [showIcon]="true" [readonlyInput]="true"
              [style]="{'width':'100%'}" appendTo="body" dateFormat="dd/mm/yy">
            </p-calendar>
        </div>
    </div>
    <div class="card flex justify-content-start">
        <div class="flex flex-column gap-3">
            <div *ngFor="let button of initButtons" class="field-checkbox">
                <p-radioButton [inputId]="button.name" name="button" [value]="button" [(ngModel)]="selectedInitButton"></p-radioButton>
                <label [for]="button.name" class="ml-2">{{ button.name }}</label>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button [disabled]="startDate == null || endDate == null || selectedInitButton == null" icon="pi pi-check" label="Valider"
            styleClass="p-button-success" (onClick)="saveInit($event)"></p-button>

    </ng-template>
</p-dialog>

<p-dialog *ngIf="mewsSaveDialog" [(visible)]="mewsSaveDialog" [modal]="true" [style]="{ height:'240px' }">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Confirmation de sauvegarde</span>
    </ng-template>
    <p><b>Voulez-vous sauvegarder les modifications ?</b>
    </p>
    <div class="mt-2">
        <div class="mt-2 ">
            <span style="display: block;">Veuillez entrer le code de l'Hôtel <b>{{currentHotel.name}}</b> :</span>
            <input type="text" pInputText [style]="{'width': '200px', 'margin-top': '5px'}" pInputText
                [(ngModel)]="hotCode" />
            <div *ngIf="hotCode != null && hotCode != currentHotel.hotCode ">
                <small id="" class="p-error">Code Hôtel incorrecte</small>
            </div>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button [disabled]="hotCode == null || hotCode != currentHotel.hotCode" icon="pi pi-check" label="Valider"
        (onClick)="saveComfirmation()" styleClass="p-button-success"></p-button>

    </ng-template>
</p-dialog>