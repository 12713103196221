<div [class]="'form-component-' + field.code">

  <div [formGroup]="formGroup">

    <label [for]="field.code">{{ field.label }}
      <span *ngIf="field.required" class="fd-required">*</span>
    </label>

    <div style="margin-bottom: 1em;position: relative">
      <p-dropdown #selectbox1 [options]="field.possibleValues" [formControlName]="field.code" optionValue="code"
        optionLabel="label" (onChange)="onChange($event)" [styleClass]="getStyleClass()" [maxlength]="100"
        [placeholder]="field.placeholder || '-'" [required]="field.required" [pTooltip]="field.toolTip"
        tooltipPosition="top" [tooltipZIndex]="tooltipzindex" appendTo="body">
      </p-dropdown>

      <div class="fd-errorText">
        <small>{{ formControl?.getErrorsMessage() }}
        </small>
      </div>

    </div>
  </div>
</div>