<p-card *ngIf="hotelNotIntialized">
    <div>
        l'hôtel <span class="font-semibold text-pink-900">{{currentHotel.name }} </span> n'est pas initialisé
    </div>
    <p-button label="Initialiser" icon="pi pi-chevron-circle-right" (onClick)="InitHotel($event)">
    </p-button>
</p-card>

<p-tabView styleClass="tabview-custom" *ngIf="displayAlltables">
    <!-- Inventaires -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-calendar mr-1"></i>
            <span>Inventaires</span>
            <i class="pi pi-sync ml-3 btnSync"></i>

        </ng-template>

        <app-inventaires [allProduits]="allProduits"></app-inventaires>

    </p-tabPanel>

    <!-- Produits vendues -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-box  mr-1"></i>
            <span>Produits vendues</span>
        </ng-template>
        <app-produit-vendus [allProduits]="allProduits" (OnRealodData)="loadData()"></app-produit-vendus>
    </p-tabPanel>

    <!-- prestation -->
    <p-tabPanel header="Header III">
        <ng-template pTemplate="header">
            <i class="pi pi-star  mr-1"></i>
            <span>Prestations</span>
        </ng-template>
        <app-prestations [allProduits]="allProduits" (OnRealodData)="loadData()"></app-prestations>
    </p-tabPanel>

    <!-- Packages -->
    <p-tabPanel header="Packages">
        <ng-template pTemplate="header">
            <i class="pi pi-gift  mr-1"></i>
            <span>Packages</span>
        </ng-template>
        <app-packages [allProduits]="allProduits" (OnRealodData)="loadData()"></app-packages>
    </p-tabPanel>

    <!-- Matrice d'attributions/Surclassements-->
    <p-tabPanel header="Header III">
        <ng-template pTemplate="header">
            <i class="pi pi-table  mr-1"></i>
            <span>Matrice d'attributions/Surclassements</span>
        </ng-template>
        <app-attribution-surclassement
            [allProduits]="allProduits" (OnRealodData)="loadData()">
        </app-attribution-surclassement>
    </p-tabPanel>

</p-tabView>
<p-confirmPopup></p-confirmPopup>

