<div class="main">

    <div class="containerMain" [style.width.px]="rowDescriptions.width +titleWidth"
        [style.height.px]="rowDescriptions.ligne_Date_height + rowDescriptions.ligne_Mois_height">
        <div class="container" (mousemove)="scroll($event)" (mousedown)="mouseDown($event)" (mouseup)="mouseUp($event)"
            (mouseleave)="mouseUp($event)" [style.width.px]="rowDescriptions.width"
            [style.height.px]="rowDescriptions.ligne_Date_height + rowDescriptions.ligne_Mois_height"
            [style.left.px]="titleWidth">

            <div id="divTotranslate" style="position: absolute;">
                <div>
                    <div class="cell" *ngFor="let cell of rowDescriptions.rowSciMonth.cells;" [style.left.px]="cell.x"
                        [style.top.px]="rowDescriptions.rowSciMonth.y"
                        [style.background-color]="cell.index ==0?'red':'rgb(241, 241, 240)'">
                        <app-cell-month [rowSci]="rowDescriptions.rowSciMonth" [cellid]="cell.cellId"></app-cell-month>
                    </div>

                    <div class="cell" *ngFor="let cell of rowDescriptions.rowSciDate.cells;" [style.left.px]="cell.x"
                        [style.top.px]="rowDescriptions.rowSciDate.y"
                        [style.background-color]="cell.index ==0?'red':'rgb(241, 241, 240)'">
                        <app-cell-date [rowSci]="rowDescriptions.rowSciDate" [cellid]="cell.cellId"></app-cell-date>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="main-data">
    <div class="containerMain-data" [style.width.px]="rowDescriptions.width +titleWidth" [style.height.px]="0">

        <div class="containerTitle-data" [style.width.px]="titleWidth" [style.left.px]="5">
            <div class="containerTitleItem" [ngClass]="getRowTitleClass(row)"
                *ngFor="let row of rowDescriptions.getRowSciListVisible()" [style.top.px]="row.y"
                (click)="toggleChildren(row)" [style.width.px]="titleWidth" [style.height.px]="row.height">

                    <i *ngIf="isIconPlusVisible(row)" class="pi pi-plus-circle" style="font-size: 0.8rem"></i>
                    <i *ngIf="isIconMinusVisible(row)" class="pi pi-minus-circle" style="font-size: 0.8rem"></i>

                {{row.title}}

            </div>
        </div>

        <div class="container-data"  [style.width.px]="rowDescriptions.width"
            [style.height.px]="heightCompoment" [style.left.px]="titleWidth">

            <div id="divTotranslateData" style="position: absolute;">
                <div *ngFor="let row of rowDescriptions.getRowSciListVisible()">
                    <div class="cell" *ngFor="let cell of row.cells;" [style.left.px]="cell.x" [style.top.px]="row.y">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="row.rowType==='indicateur'">
                                <app-cell-indicateur [rowSci]="row" [cellid]="cell.cellId"></app-cell-indicateur>
                            </ng-container>
                            <ng-container *ngSwitchCase="row.rowType==='pricerRack'">
                                <app-cell-pricerrack [rowSci]="row" [cellid]="cell.cellId"></app-cell-pricerrack>
                            </ng-container>
                            <ng-container *ngSwitchCase="row.rowType==='prdvuPricer'">
                                <app-cell-prdvupricer [rowSci]="row" [cellid]="cell.cellId"></app-cell-prdvupricer>
                            </ng-container>

                            <ng-container *ngSwitchCase="row.rowType==='pricer'">
                                <app-cell-pricer [rowSci]="row" [cellid]="cell.cellId"></app-cell-pricer>
                            </ng-container>
                            <ng-container *ngSwitchCase="row.rowType==='prixRef'">
                                <app-cell-prixref [rowSci]="row" [cellid]="cell.cellId"></app-cell-prixref>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
