import {
  Component,
  OnInit,
  ApplicationRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  User,
  GroupForUpdate,
  Group,
  Role,
  HotelAuth,
  RoleForUpdate,
} from '../../services/model/models';
import {
  GroupService,
  RoleService,
  HotelService,
} from '../../services/api/api';
import { Hotel } from 'src/app/rateShopper/services/model/hotel';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Listbox } from 'primeng/listbox';
import { NgModel } from '@angular/forms';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
})
export class GroupsComponent implements OnInit {
  groups: Group[];
  groupToUpdate: GroupForUpdate = {
    id: '',
    name: '',
    description: '',
    roles: [],
    hotels: [],
  };
  roles: Role[] = [];
  roleList: Role[];
  idGroupList: string[] = [];
  userNameList: string[] = [];
  hotels: HotelAuth[];
  selectedRoles: any;
  selectedHotels: any;
  selectedGroup: Group = {
    id: '',
    name: '',
    description: '',
    roles: [],
    hotels: [],
  };
  isId1Desabled: boolean = true;
  isId1New: boolean = false;
  isUserNameNew: boolean = false;
  isRolesLoaded: boolean = false;
  isHotelsLoaded: boolean = false;
  isFormValidateGroup: boolean = false;
  isGroupsLoaded: boolean;
  isNewGroup: boolean;
  roleToCreation: RoleForUpdate = {
    id: '',
    name: '',
    description: '',
  };
  infosUserGroup: User;
  cols: any[] = [];
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  saveGroupSelection: Group;
  cacheData: any;
  filterValue: string;
  displayListeRoles: boolean = false;
  colsRole: any[] = [];

  constructor(
    private groupService: GroupService,
    private roleService: RoleService,
    private hotelService: HotelService,
    private appRef: ApplicationRef,
    private sanitizer: DomSanitizer,
    private breadcrumbService: BreadcrumbService,
    private notifService : NotificationsService
  ) {
    this.breadcrumbService.setItems([{ label: 'adminusers/groups' }]);
  }

  ngOnInit(): void {
    this.cacheData= {hotels:[], groups: [], roles: []};
    this.cols = [
      { field: 'avatarProfile', header: 'Avatar', width: '15px' },
      { field: 'username', header: 'User', width: '32px' },
      { field: 'displayName', header: "Nom d'affichage", width: '40px' },
    ];
    this.colsRole = [
      { field: 'name', header: 'Nom', width: '15px' },
      { field: 'description', header: 'Description', width: '32px' }
    ];
    this.isNewGroup = false;
    this.getGroups(false);
    this.getHotels();
    this.getRoles();
    // this.getUsersForGroup();
  }
  getGroups(isNewGroup?: boolean, selectedGroup?: Group) {
    this.isGroupsLoaded = false;
    this.sub1 = this.groupService.apiGroupsGet().subscribe((groups) => {
      this.groups = groups;
      this.idGroupList = this.groups.map((e) => e.id);
      this.isGroupsLoaded = true;
      if (selectedGroup && !isNewGroup) {
        this.selectedGroup = groups.find((g) => g.id == selectedGroup.id);
      } else if(!isNewGroup) {
        this.selectedGroup = this.groups[0];
      }
      if (isNewGroup) {
        this.checkIfExistGroup();
      } else {
        this.checkIfAllLoaded(
          this.isRolesLoaded,
          this.isHotelsLoaded,
          this.isGroupsLoaded
        );
      }
      console.log(this.groups);
    });
  }
  checkIfExistGroup() {
    console.log(this.idGroupList);
    if (this.idGroupList?.indexOf(this.selectedGroup?.id) == -1) {
      this.setNewGroup();
    }
  }
  getRoles() {
    this.isRolesLoaded = false;
    this.sub2 = this.roleService.apiRolesGet().subscribe((roles) => {
      this.roles = roles;
      this.isRolesLoaded = true;
      this.checkIfAllLoaded(
        this.isRolesLoaded,
        this.isHotelsLoaded,
        this.isGroupsLoaded
      );
      console.log(this.roles);
    });
  }
  getHotels() {
    this.isHotelsLoaded = false;
    this.sub3 = this.hotelService
      .apiHotelsGet()
      .subscribe((hotels: HotelAuth[]) => {
        this.hotels = hotels;
        this.cacheData.hotels = [];
        this.hotels?.forEach(element => {
          this.cacheData.hotels.push(element);
        });
        this.isHotelsLoaded = true;
        this.checkIfAllLoaded(
          this.isRolesLoaded,
          this.isHotelsLoaded,
          this.isGroupsLoaded
        );
        console.log(this.hotels);
      });
  }
  getUsersForGroup() {
    if (this.selectedGroup) {
      let idGroup = this.selectedGroup.id;
      if (idGroup && idGroup != "") {
        this.sub5 = this.groupService
        .getUsersForGroup(idGroup)
        .subscribe((infosUserGroup: any) => {
          this.infosUserGroup = infosUserGroup;
        }
      );
      }
    }
  }
  checkIfAllLoaded(
    isRolesLoaded: boolean,
    isHotelsLoaded: boolean,
    isGroupsLoaded: boolean
  ) {
    let isRolesHotelsLoaded = isRolesLoaded && isHotelsLoaded && isGroupsLoaded;
    if (isRolesHotelsLoaded) {
      let selectedGroup = Object.assign({}, this.selectedGroup);
      this.showGroupInfos(selectedGroup);
    }
  }
  transform(avatarProfile) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(avatarProfile);
  }
  close(isGroupManage?) {
    if (isGroupManage) {
      this.selectedGroup = {
        id: '',
        name: '',
        description: '',
        roles: [],
        hotels: [],
      };
      this.infosUserGroup = null;
      this.showGroupInfos(this.selectedGroup);
    }
    this.isId1Desabled = true;
    this.isId1New = false;
    this.isUserNameNew = false;
    this.isNewGroup = false;
    this.isFormValidateGroup = false;
    this.hotels = [];
    this.cacheData.hotels?.forEach(element => {
        this.hotels.push(element);
      }
    );
  }
  resetGroups() {
    this.selectedGroup = {};
    this.selectedHotels = [];
    this.selectedRoles = [];
  }
  cancel(){
    this.displayListeRoles = false;
  }

  editSelectedGroup(group?: Group){
    this.isNewGroup = false;
    this.isId1New = false;
    this.isId1Desabled = true;
    this.showGroupInfos(group);
  }

  showGroupInfos(group?: Group) {
    if (this.selectedGroup || group) {
      //this.model.control.setValue(group);
      this.filterValue = '';
      const selectedRoles: string[] = group.roles || [];
      const selectedHotels: string[] = group.hotels || [];
      let selectedRolesToput = this.roles?.filter((role: Role) => {
        return (
          (selectedRoles?.filter((r: string) => {
            return role.id == r;
          })).length != 0
        );
      });
      let otherRoles = this.roles?.filter((role: Role) => {
        return (
          (selectedRoles?.filter((r: string) => {
            return role.id == r;
          })).length == 0
        );
      });
      this.roles.length = 0;
      this.roles = selectedRolesToput?.concat(otherRoles);
      this.selectedRoles = selectedRolesToput;
      let selectedHotelsToput = this.cacheData.hotels?.filter((hotel?: Hotel) => {
        return (
          (selectedHotels?.filter((h: string) => {
            return hotel.hotCode == h;
          })).length != 0
        );
      });
      let otherHotels = this.cacheData.hotels?.filter((hotel?: Hotel) => {
        return (
          (selectedHotels?.filter((h: string) => {
            return hotel.hotCode == h;
          })).length == 0
        );
      });
      this.hotels.length = 0;
      this.hotels = selectedHotelsToput?.concat(otherHotels);
      this.selectedHotels = selectedHotelsToput;
      this.getUsersForGroup();
    }
  }
  onCustomFilter(value: string, objectName: string, prop1: string, prop2?: string, selectionName?: string) {
    let filtredArray = this.cacheData[objectName].filter(
      (elem) =>
        elem[prop1]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0 ||
        elem[prop2]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
    );
    let sortedData = filtredArray?.sort((a, b) => {
      let isSelected = this[selectionName]?.some((sel) => sel[prop1] == a[prop1]);
      if (isSelected) {
        return -1;
      } else{
        return 1;
      }
      return 0;
    });
    this[objectName] = sortedData;
  }
  createNewGroup() {
    this.isNewGroup = true;
    this.isId1New = true;
    this.isId1Desabled = false;
    this.selectedGroup = {
      id: '',
      name: '',
      description: '',
      roles: [],
      hotels: [],
      isObsolete: false,
    };
    this.infosUserGroup = null;
    this.showGroupInfos(this.selectedGroup);
  }

  saveNewGroup() {
    this.isFormValidateGroup = true;
    let isFormValidateGroup =
      this.selectedGroup.id &&
      this.selectedGroup.id != '' &&
      this.selectedGroup.description &&
      this.selectedGroup.description != '' &&
      this.selectedGroup.name &&
      this.selectedGroup.name != '';
    if (this.isNewGroup && isFormValidateGroup) {
      this.getGroups(true, this.selectedGroup);
    } else if (isFormValidateGroup) {
      this.setNewGroup();
    }
  }
  setNewGroup() {
    //let selectedGroup = this.selectedGroup;
    this.isId1Desabled = true;
    this.isId1New = false;
    this.isNewGroup = false;
    console.log(this.selectedGroup);
    this.groupToUpdate.hotels = this.selectedHotels?.map((hotel: Hotel) => {
      return hotel.hotCode;
    });
    this.groupToUpdate.roles = this.selectedRoles?.map((role: Role) => {
      return role.id;
    });
    this.groupToUpdate.name = this.selectedGroup.name;
    this.groupToUpdate.id = this.selectedGroup.id;
    this.groupToUpdate.description = this.selectedGroup.description;
    this.groupToUpdate.isObsolete = this.selectedGroup.isObsolete;
    this.saveGroupSelection = Object.assign({}, this.selectedGroup);
    this.sub4 = this.groupService.apiGroupsPost(this.groupToUpdate).subscribe({
      next: (data) => {
        this.notifService.setMessage(`Enregistrement avec succès de groupe ${this.groupToUpdate.name}`,'Groupe', false);
        this.isFormValidateGroup = false;
        this.getGroups(false, this.selectedGroup);
      },
      error: (error) => {

        this.notifService.setMessage(error?.error,'Groupe', true);
        this.isFormValidateGroup = false;
        this.isId1Desabled = false;
      }
  });
  }
  ngOnDestroy(): void {
    if (this.sub1 != undefined) {
      this.sub1?.unsubscribe();
    }
    if (this.sub2 != undefined) {
      this.sub2?.unsubscribe();
    }
    if (this.sub3 != undefined) {
      this.sub3?.unsubscribe();
    }
    if (this.sub4 != undefined) {
      this.sub4?.unsubscribe();
    }
    if (this.sub5 != undefined) {
      this.sub5?.unsubscribe();
    }
  }
}
