import { Component, Input, SimpleChanges } from '@angular/core';
import { AbstractDataService } from '../../models/AbstractDataService';
import { RowSci } from '../../models/RowSci';
import { CellSci } from '../../models/CellSci';
import { CellBaseComponent } from '../cell-base/cell-base.component';

@Component({
    selector: 'app-cell-pricerrack',
    templateUrl: './cell-pricerrack.component.html',
    styleUrls: ['./cell-pricerrack.component.css']
})
export class CellPricerrackComponent extends CellBaseComponent {

    getValue(): any {

        if (this.cell && this.rowSci) {
            let key = this.cell.cellKeyForDataService;
            key = `${this.cell.cellKeyForDataService}-${this.rowSci.rowId}`;
            return super.getValue(key);

        }
        return "";
    }

    getClassJour(): string {
        const index = this.rowSci.cells[this.cellId].index;
        let day = this.rowSci.cells[this.cellId].cellDate.getDay();

        if (day == 1) return "cellLun";
        if (day == 5) return "cellVen";
        if (day == 6) return "cellSam";
        return "";

    }
}
