import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CellSci } from '../../models/CellSci';
import { RowSci } from '../../models/RowSci';
import { AbstractDataService } from '../../models/AbstractDataService';

@Component({
  selector: 'app-cell-base',
  templateUrl: './cell-base.component.html',
  styleUrls: ['./cell-base.component.css']
})
export class CellBaseComponent {

    @Input("rowSci")
    rowSci: RowSci

    @Input("cellid")
    cellId: number

    cellwidth: number;
    cellHeight: number;

    cell: CellSci;
    value: any;

    constructor(private dataService: AbstractDataService) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.rowSci) {
            this.cell = this.rowSci.cells[this.cellId];
            this.cellwidth = this.rowSci.tableParent.cellWith;
            this.cellHeight = this.rowSci.height;
        }
    }

    getValue(key:string): any {

        return this.dataService.getCellValue(key);

    }

}
