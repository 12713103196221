import { Component, Input } from '@angular/core';
import { FormGroup2 } from '../../models/formGroup2';
import { FormArray2 } from '../../models/FormArray2';
import { FormParameter } from '../../models/form-parameter';

import {
    AbstractControl,
    FormArray,
    FormGroup,
    Validators,
} from '@angular/forms'

@Component({
    selector: 'app-tabview-group',
    templateUrl: './tabview-group.component.html',
    styleUrls: ['./tabview-group.component.css']
})
export class TabviewGroupComponent {
    @Input() formGroup: FormGroup2
    @Input() formArray: FormArray2
    @Input() field: FormParameter

    tabViewItems: string[];

    constructor() { }

    ngOnInit(): void {
        this.tabViewItems = [...new Set(this.formGroup.formParam.childParameters.map(x => x.tabViewItem))];
    }

    isLineSeparatorVisible(): boolean {

        if (this.field.lineSeparator === false) {
            return false
        }
        return true;
    }
}
