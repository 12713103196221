import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, throwError } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AuthorizationService_Azure } from "src/app/azureAdB2C/services/api/api";
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { UserSpotAuthorization } from "src/app/azureAdB2C/services/model/models";


@Injectable({
    providedIn: "root",
})
export class UserInfoService {
    subjectNewHotel: BehaviorSubject<HotelAuth>;
    subjectUserSpotAuthorization: ReplaySubject<UserSpotAuthorization>;

    private _currentHotel: HotelAuth;
    sub: Subscription;
    userSpotAuthorization: UserSpotAuthorization;
    private _isAuthenticate: boolean;

    constructor(private authorizationService: AuthorizationService_Azure, private msalBroadcastService: MsalBroadcastService,) {

        var currentHotelJson = localStorage.getItem("currentHotel");
        if (currentHotelJson) {
            this._currentHotel = JSON.parse(
                currentHotelJson
            ) as HotelAuth;
        }

        if (!this.subjectNewHotel) this.subjectNewHotel = new BehaviorSubject<HotelAuth>(this._currentHotel);

        if (!this.subjectUserSpotAuthorization) this.subjectUserSpotAuthorization = new ReplaySubject<UserSpotAuthorization>(1);  // la deniere valeur


        this.msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
        ).subscribe(() => {
            authorizationService.apiAuthorizationGet().subscribe((userSpotAuthorization) => {
                this.userSpotAuthorization = userSpotAuthorization;
                this.subjectUserSpotAuthorization.next(userSpotAuthorization);
                this._isAuthenticate = true

                if (!this.currentHotel && this.userSpotAuthorization?.hotels?.length > 0) {
                    this._currentHotel = this.userSpotAuthorization.hotels[0];
                }
            });
        });
    }

    get currentHotel(): HotelAuth {
        return this._currentHotel;
    }

    getListHotel$(): Observable<Array<HotelAuth>> {
        return this.subjectUserSpotAuthorization.pipe(map(auth => auth.hotels))
    }

    getUserRoles$(): Observable<Array<string>> {
        return this.subjectUserSpotAuthorization.pipe(map(auth => auth.roles))
    }

    IsAuthenticate(): boolean {
        return this._isAuthenticate;
    }

    newSelectedHotel(hotel: HotelAuth) {
        localStorage.setItem("currentHotel", JSON.stringify(hotel));
        this._currentHotel = hotel;
        this.subjectNewHotel.next(hotel);
    }

    getNewHotel$(): Observable<HotelAuth> {

        return this.subjectNewHotel.asObservable();
    }

    isUserAdmin(): boolean {
        if (
            this.userSpotAuthorization &&
            this.userSpotAuthorization?.roles?.find((r) => r == "Administrator")
        ) {
            return true;
        }
        return false;
    }
}
