import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    AfterViewInit,
} from '@angular/core'
import { FormParameter } from '../models/form-parameter'
import {
    FormGroup,
    FormControl,
    Validators,
    AbstractControl,
    FormArray,
} from '@angular/forms'
import { Subscription } from 'rxjs'

import { debounceTime } from 'rxjs/operators'
import { ErrorDictionary, FormErrors } from '../models/form-errors'
import { FormControl2 } from '../models/formControl2'
import { FormGroup2 } from '../models/formGroup2'
import { IControl2 } from '../models/IControl2'
import { Location } from '@angular/common'
import { FormArray2 } from '../models/FormArray2'
import { FormConfig } from '../models/FormConfig'

@Component({
    selector: 'app-form-dynamic',
    templateUrl: './form-dynamic.component.html',
    styleUrls: ['./form-dynamic.component.css'],
})
export class FormDynamicComponent
    implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() formModel: FormConfig
    @Output() OnValueChange = new EventEmitter()
    @Output() OnValidate = new EventEmitter()
    @Output() OnCancel = new EventEmitter()

    @Input() formValue: any
    @Input() debug: boolean

    isFormVisible = false

    formErrors: FormErrors
    showForm = false
    formGroup2: AbstractControl
    subsciption: Subscription
    that = this
    submitedForm: boolean = false

    controlsWithVisibilityCondition: IControl2[] //list of controls with visibility contraints
    controlsWithDesactivationCondition: IControl2[] //list of controls with desactivate contraints
    globalError: string

    constructor(private _location: Location) { }

    afterViewInit: boolean
    ngAfterViewInit(): void {
        this.afterViewInit = true
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formModel) {
            this.formModel = changes.formModel.currentValue
        }
    }

    ngOnInit(): void {
        this.GenerateForm()
    }

    UpdateValue(value: any): void {
        console.log(this.formGroup2);
        this.formGroup2.setValue(value);
        this.formGroup2?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
    GenerateForm() {
        this.isFormVisible = false
        if (this.formModel.parameters.length) {
            this.formGroup2 = this.buildFormGroup(
                { childParameters: this.formModel.parameters },
                this.formValue
            )

            //liste des d'abstractControl qui possede une visibility condition
            this.controlsWithVisibilityCondition =
                this.getControlsWithVisibilityCondition(
                    this.formGroup2 as FormGroup2
                )

            // get abstractControls with desactivation condition
            this.controlsWithDesactivationCondition =
                this.getControlsWithDesactivationCondition(
                    this.formGroup2 as FormGroup2
                )

            this.UpdateVisibility()
            this.UpdateActivation()

            this.showForm = true
            this.subsciption = this.formGroup2.valueChanges
                .pipe(debounceTime(100))
                .subscribe((val) => {
                    if (this.formGroup2.value != undefined) {
                        this.OnValidateEmited = false
                        this.OnValueChange.emit(this.formGroup2.value)
                        this.UpdateVisibility()
                        this.UpdateActivation()
                    }
                })
        }
        this.isFormVisible = true
    }

    UpdateVisibility(): void {
        this.controlsWithVisibilityCondition = this.getControlsWithVisibilityCondition(this.formGroup2 as FormGroup2)

        for (let index = 0; index < this.controlsWithVisibilityCondition.length; index++) {
            const element = this.controlsWithVisibilityCondition[index]
            const conditions = element.formParam.visibilityCondition.split('==')

            let value: any
            if (conditions[0]) {
                let ctrl = element as FormControl2
                if (ctrl) {
                    value = ctrl.parent.get(conditions[0])?.value
                }

                if (!ctrl || !value) {
                    value = this.formGroup2.get(conditions[0])?.value
                }

                element.visibilityConditionValue = value == conditions[1]

                if (typeof value === 'boolean') {
                    element.visibilityConditionValue =
                        value.toString() == conditions[1]
                }

                if (element instanceof AbstractControl) {
                    let absCtrl = element as AbstractControl
                    if (element.visibilityConditionValue) {
                        if (!absCtrl.enabled) {
                            absCtrl.enable();
                            absCtrl.markAsDirty();
                            absCtrl.markAsTouched();
                        }
                    } else {
                        if (absCtrl.enabled) {
                            absCtrl.disable();
                        }
                    }
                }
            }
        }
    }

    UpdateActivation(): void {
        for (let index = 0; index < this.controlsWithDesactivationCondition.length; index++) {

            const element = this.controlsWithDesactivationCondition[index]
            const conditions = element.formParam.activationCondition.split('==')

            if (conditions[0]) {

                const frmg = this.formGroup2.get(conditions[0]);

                const value = this.formGroup2.get(conditions[0])?.value?.toString();
                element.activationConditionValue = value == conditions[1];

                if (element instanceof AbstractControl) {
                    let absCtrl = element as AbstractControl
                    if (element.activationConditionValue) {
                        if (!absCtrl.enabled) {
                            setTimeout(() => {
                                element.enable()
                                element.markAsDirty()
                                element.markAsTouched()
                            }, 1);

                        }
                    } else {
                        if (absCtrl.enabled) {
                            setTimeout(() => {
                                element.disable();
                            }, 1);


                        }
                    }
                }
            }
        }
    }

    getControlsWithDesactivationCondition(frmg2: FormGroup2): IControl2[] {
        let result = []
        for (const key in frmg2.controls) {
            const ctrl = frmg2.controls[key]

            if (ctrl instanceof FormControl2) {
                if ((<FormControl2>ctrl).formParam.activationCondition) {
                    result.push(ctrl)
                }
            }
            if (ctrl instanceof FormGroup2) {
                let frmgItem = ctrl as FormGroup2
                if (frmgItem.formParam.activationCondition) {
                    result.push(ctrl)
                }
                if (frmgItem.controls) {
                    let children =
                        this.getControlsWithDesactivationCondition(frmgItem)
                    if (children.length > 0) result = result.concat(children)
                }
            }
        }

        return result
    }

    getControlsWithVisibilityCondition(icontro2: IControl2): IControl2[] {
        let result = []
        if (icontro2 instanceof FormGroup2) {
            let frmg2 = icontro2 as FormGroup2
            for (const key in frmg2.controls) {
                const ctrl = frmg2.controls[key]

                if (ctrl instanceof FormControl2) {
                    if ((<FormControl2>ctrl).formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                }
                if (ctrl instanceof FormGroup2) {
                    let frmgItem = ctrl as FormGroup2
                    if (frmgItem.formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                    if (frmgItem.controls) {
                        let children =
                            this.getControlsWithVisibilityCondition(frmgItem)
                        if (children.length > 0)
                            result = result.concat(children)
                    }
                }
                if (ctrl instanceof FormArray2) {
                    let frmgItem = ctrl as FormArray2
                    if (frmgItem.formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                    if (frmgItem.controls) {
                        let children =
                            this.getControlsWithVisibilityCondition(frmgItem)
                        if (children.length > 0)
                            result = result.concat(children)
                    }
                }
            }
        }

        if (icontro2 instanceof FormArray2) {
            let frarray2 = icontro2 as FormArray2
            for (const key in frarray2.controls) {
                const ctrl = frarray2.controls[key]

                if (ctrl instanceof FormControl2) {
                    if ((<FormControl2>ctrl).formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                }
                if (ctrl instanceof FormGroup2) {
                    let frmgItem = ctrl as FormGroup2
                    if (frmgItem.formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                    if (frmgItem.controls) {
                        let children =
                            this.getControlsWithVisibilityCondition(frmgItem)
                        if (children.length > 0)
                            result = result.concat(children)
                    }
                }
                if (ctrl instanceof FormArray2) {
                    let frmgItem = ctrl as FormArray2
                    if (frmgItem.formParam.visibilityCondition) {
                        result.push(ctrl)
                    }
                    if (frmgItem.controls) {
                        let children =
                            this.getControlsWithVisibilityCondition(frmgItem)
                        if (children.length > 0)
                            result = result.concat(children)
                    }
                }
            }
        }

        return result
    }

    buildFormGroup(formParam: FormParameter, formValue: any): AbstractControl {
        let group: any = {}

        if (formParam.formArray) {
            let controls: Array<AbstractControl> = []

            if (Array.isArray(formValue) && formValue.length > 0) {
                for (let index = 0; index < formValue.length; index++) {
                    const elementValue = formValue[index]

                    // clone de formParam avec le champ formArray à false
                    let formParamForGroup = JSON.parse(
                        JSON.stringify(formParam)
                    ) as FormParameter
                    formParamForGroup.formArray = false
                    formParamForGroup.formArrayOrigin = true

                    let frmg = this.buildFormGroup(
                        formParamForGroup,
                        elementValue
                    )
                    controls.push(frmg)
                }

                let frmArry2 = new FormArray2(controls, formParam.code)
                frmArry2.formParam = formParam
                return frmArry2
            } else {
                // un seul element
                // clone de formParam avec le champ formArray à false
                let formParamForGroup = JSON.parse(
                    JSON.stringify(formParam)
                ) as FormParameter
                formParamForGroup.formArray = false
                formParamForGroup.formArrayOrigin = true

                let frmg = this.buildFormGroup(formParamForGroup, '')
                controls.push(frmg)
                let frmArry2 = new FormArray2(controls, formParam.code)
                frmArry2.formParam = formParam
                return frmArry2
            }
        } else {
            for (
                let index = 0;
                index < formParam.childParameters.length;
                index++
            ) {
                const formParamItem = formParam.childParameters[index]

                if (formParamItem.type == 'Label') {
                    continue
                }
                let value: any

                if (formValue == null) {
                    value = null
                } else {
                    value =
                        formValue[formParamItem.code] === undefined
                            ? null
                            : formValue[formParamItem.code]

                }

                if (formParamItem.childParameters != null) {
                    group[formParamItem.code] = this.buildFormGroup(
                        formParamItem,
                        value
                    )
                } else {
                    let frmControl: FormControl2

                    if (value == null || value == undefined) value = formParamItem.defaultValue

                    frmControl = new FormControl2(formParamItem.code, value)
                    frmControl.formParam = formParamItem

                    //--Validation
                    let validateurs = [this.externalValidation] //par defaut

                    if (formParamItem.required) {
                        validateurs.push(Validators.required)
                    }

                    frmControl.setValidators(validateurs)

                    group[formParamItem.code] = frmControl
                }
            }
            var frmg2 = new FormGroup2(group, formParam.code)
            frmg2.formParam = formParam
            frmg2.setValidators(this.externalValidation)
            return frmg2
        }
    }

    setGlobalError(globalError: string) {
        this.globalError = globalError;
    }

    setExternalErrors(errs: FormErrors) {
        this.formErrors = errs
        this.resetExternalErrorMsgs(this.formGroup2 as FormGroup2) //supprimer tous les anciens messages

        let keys = Object.keys(errs.errors)

        for (let index = 0; index < keys.length; index++) {
            const key = keys[index]
            const ctrl = this.formGroup2.get(key)

            if (ctrl instanceof FormControl2) {
                let frm2 = ctrl as FormControl2
                frm2.externalErrorMsg = errs.errors[key].join(' ')
                frm2.markAsDirty()
                frm2.updateValueAndValidity()
            } else if (ctrl instanceof FormGroup2) {
                let grp2 = ctrl as FormGroup2
                grp2.externalErrorMsg = errs.errors[key].join(' ')
                grp2.markAsDirty()
                // grp2.setValue(grp2.value);
                grp2.updateValueAndValidity()
            }
        }
    }

    externalValidation(c: AbstractControl): { [key: string]: boolean } {
        let erroMsg = ''
        if (c instanceof FormGroup2) {
            const frm2 = c as FormGroup2
            erroMsg = frm2.externalErrorMsg
        } else if (c instanceof FormControl2) {
            const grp2 = c as FormControl2
            erroMsg = grp2.externalErrorMsg
        }

        if (erroMsg) return { externalValidation: true }
        else return null
    }

    //mettre me message d'erreur sur le control ( FormGroup ou FormControl)
    resetExternalErrorMsgs(formtoUpdate: FormGroup) {
        this.globalError = "";
        if (formtoUpdate instanceof FormGroup2) {
            let grp2 = formtoUpdate as FormGroup2
            grp2.externalErrorMsg = null
        }

        for (let propertyName in formtoUpdate.controls) {
            let ctrl = formtoUpdate.controls[propertyName]

            if (ctrl instanceof FormControl2) {
                let frm2 = ctrl as FormControl2
                frm2.externalErrorMsg = null
            } else if (ctrl instanceof FormGroup2) {
                this.resetExternalErrorMsgs(ctrl)
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subsciption) {
            this.subsciption.unsubscribe()
        }
    }

    OnValidateEmited: boolean = false // eviter le double click  passe à false  sur onchange

    validate(event): void {
        if (this.submitedForm) return
        if (this.formGroup2 instanceof FormGroup) {
            let frm2 = this.formGroup2 as FormGroup
            frm2.markAsDirty()
            frm2.markAllAsTouched()
            Object.keys(frm2.controls).forEach((key) => {
                let ctrl = frm2.get(key)
                ctrl.markAsDirty()
                ctrl.updateValueAndValidity()
            })
        }

        this.formGroup2.updateValueAndValidity()
        if (this.getStatus()) {
            if (!this.OnValidateEmited) {
                this.OnValidateEmited = true
                this.OnValidate.emit(this.formGroup2.value)
                this.submitedForm = true
                let that = this
                setTimeout(() => {
                    that.submitedForm = false
                }, 500)
            }
        }
    }

    cancel(event): void {
        this.OnCancel.emit(event)

    }

    //utilisation du AfterViewInit pour déactiver le message d'erreur d'angular : ExpressionChangedAfterItHasBeenCheckedError
    getStatus(): boolean {
        if (this.afterViewInit) return this.formGroup2.status == 'VALID'
        else return true
    }
}
