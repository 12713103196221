import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appAdjustFontSize]',
})
export class AdjustFontSizeDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  private adjustFontSize() {
    const element = this.el.nativeElement;
    let fontSize = 13; // Taille de police de départ
    element.style.fontSize = `${fontSize}px`;

    // Ajuste la taille de la police jusqu'à ce que le texte tienne dans le conteneur
    while (element.scrollWidth > element.clientWidth ) {
      fontSize -= 1;
      element.style.fontSize = `${fontSize}px`;
    }
  }

  ngAfterViewInit() {
    this.adjustFontSize(); // Ajuste la taille de la police après le rendu de la vue
  }

  @HostListener('window:resize') // Ré-ajuster lors du redimensionnement de la fenêtre
  onWindowResize() {
    this.adjustFontSize();
  }
}
