import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { MewsAnalyseService } from '../../services/api/mewsAnalyse.service';
import { HotelCredentials } from '../../services/model/hotelCredentials';
import { Service } from '../../services/model/service';
import { forkJoin } from 'rxjs';
import { InitHotelModel } from '../../services/model/initHotelModel';
import { Loadreservations } from '../../services/model/loadreservations';
import { EndModel } from '../../services/model/endModel';
import { StartModel } from '../../services/model/startModel';
import { Rate } from '../../services/model/rate';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { PushRoomTypesRoomType } from '../../services/model/pushRoomTypesRoomType';
import { Password } from 'primeng/password';

@Component({
    selector: "app-mews",
    templateUrl: "./mews.component.html",
    styleUrls: ["./mews.component.css"],
})

export class MewsComponent implements OnInit, OnDestroy {
   // @ViewChild('newPwdInput') newPwdInput: Password;
    currentHotel: HotelAuth;
    private subs = new SubSink();
    hotelNotIntialized: boolean = false;
    hotelCredentials: HotelCredentials;
    mewsServices: Service[] =  [];
    selectedServiceMews: Service[] = [];
    hotCode: string = null;
    mewsSaveDialog: boolean = false;
    codeLanguages: {name:string, code: string, codeLanguage: string }[];
    selectedLanguage: {name:string, code: string, codeLanguage: string };
    mewsInitDialog: boolean = false;
    selectedInitButton: any = null;
    initButtons: any[] = [
        { name: "Reservation et regule" },
        { name: "Regule" },
        { name: "Reservation" },
    ];
    minDate: Date;
    maxDate: Date;
    startDate: Date;
    endDate: Date;
    loadreservations : Loadreservations;
    dataCredentials: HotelCredentials;
    roomTypes: PushRoomTypesRoomType[] = [];
    nbrooms: number = 0;
    isLoading: boolean = false;
    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private breadcrumbService: BreadcrumbService,
        private userInfoService: UserInfoService,
        private notifService: NotificationsService,
        private datePipe: DatePipe,
        private mewsAnalyseService: MewsAnalyseService,
        private _el: Password
    ) {
        this.breadcrumbService.setItems([{ label: "Mews" }]);
        //this.isRowSelectable = this.isRowSelectable.bind(this);
    }
    // ngAfterViewInit(): void {
    //     this._el.input.nativeElement.autocomplete = this.autocomplete&& this.autocomplete.length !== 0 ? this.autocomplete: 'new-password';
    // }
    ngOnInit(): void {
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => { 
                this.currentHotel = hotCode;
                setTimeout(() => {
                    this.initHotel(null);
                    this.loadData();
                }, 100);
            });
        this.hotelNotIntialized = false;
        this.codeLanguages = [{name: "Français", code:"FR", codeLanguage: 'fr-FR' }, {name: 'Espanõl', code:'ES', codeLanguage: "es-ES" }];
       
    }
    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe(); 
        }
    }
    async loadData() {
        this.hotelNotIntialized = false;
        let hotCode = this.currentHotel.hotCode;

        const obs1$ =
            this.mewsAnalyseService.apiMewsAnalyseCredentialsGet(hotCode);
        const obs2$ =
            this.mewsAnalyseService.apiMewsAnalyseHotCodeServicesGet(hotCode);

        this.subs.sink = forkJoin([obs1$, obs2$]).subscribe({
            next: (result) => {
                this.isLoading = true;
                this.hotelCredentials = result[0];
                this.mewsServices = Object.values(result[1]).shift() as Service[];
                this.hotelNotIntialized = false;
                setTimeout(() => {
                    this.selectedServiceMews = this.mewsServices?.filter((x) =>
                        this.hotelCredentials.serviceIds?.find((s) => s == x.id)
                    );
                    this.mewsServices = this.mewsServices?.sort((a: Service, b: Service) => {
                        let isSelected = this.selectedServiceMews.some(
                            (sel: Service) => sel.id == a.id
                        );
                        if (isSelected) {
                            return -1;
                        } else {
                            return 1;
                        }
                        return 0;
                    });
                    this.selectedLanguage = this.codeLanguages.find(
                        (x) => x.codeLanguage == this.hotelCredentials.codeLanguage
                    );
                    this.dataCredentials = this.hotelCredentials;
                }, 1);
                
                if(result){this.getRoomtypes(result[0])} 

            },
            error: (error) => {
                if (error.status == "404" || error.status == "500") {
                    this.hotelNotIntialized = true;
                }
                console.log(error?.error);
            },
        });
    }
    
    getRoomtypes(result? :HotelCredentials, event?){
     let hotCode = this.currentHotel.hotCode;
     let selectedServiceMews: string[] = [];
     let selectedLanguage = this.selectedLanguage ? this.selectedLanguage.codeLanguage : result?.codeLanguage;
     let stockRoomOnly = this.hotelCredentials.stockRoomOnly ? this.hotelCredentials.stockRoomOnly: result?.stockRoomOnly;
     let useSpotRoomTypeCode = this.hotelCredentials.useSpotRoomTypeCode ? this.hotelCredentials.useSpotRoomTypeCode : result?.useSpotRoomTypeCode;
     if(this.selectedServiceMews){
        this.selectedServiceMews.forEach(x=> selectedServiceMews.push(x.id))
    }else selectedServiceMews = result?.serviceIds;
     this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseRoomtypesGet(hotCode, useSpotRoomTypeCode, selectedServiceMews, selectedLanguage, stockRoomOnly)
     .subscribe({
        next: (result) => {
            setTimeout(() => {
                this.nbrooms = 0;
                this.roomTypes = result;
                this.roomTypes.map(x=>{ this.nbrooms = this.nbrooms + x.nbRooms})

            }, 100);
        },
        error: (error) => { 
            console.log(error?.error);
            this.notifService.setMessage(error?.error,"Mews", true);
        },
    });
}
    initializ($event) {
       this.startDate = null;
       this.endDate = null;
       this.selectedInitButton = null;
       this.mewsInitDialog = true;
       this.mewsServices =  null;
       this.selectedServiceMews = null;
       this.roomTypes = null;

    }
    async saveHotelCredentials(){
        let servicesIds: string[] =[];
        if(this.selectedServiceMews){
            this.selectedServiceMews.forEach(x =>{ servicesIds.push(x.id)});
            this.hotelCredentials.serviceIds = servicesIds; 
        }
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseCredentialsPost(this.hotelCredentials)
        .subscribe({
            next: (result) => {
                setTimeout(() => {
                    this.initHotel(null);
                    this.loadData();
                }, 100);
              this.notifService.setMessage(`initialisation avec succès pour l'hotel ${this.currentHotel.name}`,
              "Mews", false);
            },
            error: (error) => { 
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }

    initHotel($event): void { 
        this.hotelCredentials = {
            hotCode: this.currentHotel.hotCode,
            clientToken: "",
            accessToken: "",
            enabled: false,
            webSocket: false,
            loginSpecA: "",
            pwdSpecA: "",
            hotelCodeSpecA: "",
            cbc: "",
            reservationPollingMinute: 0,
            useSpotRoomTypeCode: true,
            serviceIds: [],
            codeLanguage: "",
            stockRoomOnly: false,
            enterpriseId: "",
            hotelName: "",
        };
        this.selectedLanguage = null;
        this.selectedServiceMews = null;
        this.hotelNotIntialized = false;
        this.mewsServices =  null;
        this.selectedServiceMews = null;
        this.roomTypes = null;
        this.nbrooms = 0;
    }
    async start() {
        this.hotelNotIntialized = false;
        let startModel: StartModel ={ hotCode :this.currentHotel.hotCode}; 
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseStartPost(startModel).subscribe({
            next: (result) => {
                setTimeout(() => {
                    this.initHotel(null);
                    this.loadData();
                }, 100);
              this.notifService.setMessage(`activation avec succès pour l'hotel ${this.currentHotel.name}`,
              "Mews", false);
            },
            error: (error) => { 
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async stop() {
        this.hotelNotIntialized = false;
        let endModel : EndModel = { hotCode : this.currentHotel.hotCode};
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseStopPost(endModel).subscribe({
            next: (result) => {
                setTimeout(() => {
                    this.initHotel(null);
                    this.loadData();
                }, 100);
                this.notifService.setMessage(`desactivation avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async loadResas() {
       this.loadreservations = 
       {
        hotCode: this.currentHotel.hotCode,
        start: this.datePipe.transform(this.startDate,"yyyy-MM-ddT00:00:00.000Z"),
        end: this.datePipe.transform(this.endDate,"yyyy-MM-ddT00:00:00.000Z"),
        forceUpdate : true
       }
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseLoadreservationsPost(this.loadreservations).subscribe({
            next: (result) => {
                let resas = result;
                //this.initHotel();
                //this.loadData();
                this.notifService.setMessage(`chargement des résas avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async loadResasById(){
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseLoadreservationByIdsPost().subscribe({
            next: (result) => {
                //this.loadData();
                this.notifService.setMessage(`importation d'historique des résas avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async initReguls(initModel :InitHotelModel) {
       
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseInitRegulsPost(initModel).subscribe({
            next: (result) => {
                //this.loadData();
                this.mewsSaveDialog = false
                this.notifService.setMessage(`importation d'historique des régules avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async initResa(initModel :InitHotelModel) {
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseInitResaPost(initModel).subscribe({
            next: (result) => {
                //this.loadData();
                this.mewsSaveDialog = false
                this.notifService.setMessage(`importation d'historique des résas avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    async initResasAndReguls(initModel :InitHotelModel) {
       
        this.subs.sink = this.mewsAnalyseService.apiMewsAnalyseInitHotelPost(initModel).subscribe({
            next: (result) => {
                //this.loadData();
                this.mewsSaveDialog = false
                this.notifService.setMessage(`importation d'historique des résas et des régules avec succès pour l'hotel ${this.currentHotel.name}`,
                "Mews", false);
            },
            error: (error) => {
                console.log(error?.error);
                this.notifService.setMessage(error?.error,"Mews", true);
            },
        });
    }
    saveInit($event) {
        this.hotCode = null; 
        this.mewsSaveDialog = true;
        this.selectedInitButton = null;
        this.mewsInitDialog = false;
        this.saveComfirmation();
        
    }
    saveComfirmation() {
        let initModel :InitHotelModel ={
            hotcode: this.currentHotel.hotCode,
            forceUpdate : true,
            startDate : this.datePipe.transform(this.startDate, "yyyy-MM-ddT00:00:00.000Z"),
            endDate: this.datePipe.transform(this.endDate,"yyyy-MM-ddT00:00:00.000Z"),
            delaySecondes : 1,
        }
        if(this.selectedInitButton.name == "Regule")
        {
            console.log(initModel);
        //this.initReguls(initModel);
        //this.mewsSaveDialog = false; 
        }
        if(this.selectedInitButton.name == "Reservation")
        {
        //this.initResa(initModel);
          //this.mewsSaveDialog = false;
        }
        if(this.selectedInitButton.name = "Reservation et regule")
        {
            //this.initResasAndReguls(initModel);
            //this.mewsSaveDialog = false;
        }
    }
}

