import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormArray, FormBuilder } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-duplicate-button',
    templateUrl: './duplicate-button.component.html',
    styleUrls: ['./duplicate-button.component.css'],
})
export class DuplicateButtonComponent implements OnInit {
    @Input() form: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2;

    tooltipzindex=TOOL_TIP_Z_INDEX;

    //formArray : controls to be duplicated
    frmArray = new FormArray([])
    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.formControl = this.form.get(this.field.code) as FormControl2
    }

    addFrm() {
        this.formControl?.update_Value_And_Validity();
        let frm = this.form
        this.frmArray.push(frm)
    }
}
