<div class="p-grid" class="p-m-1">
    <div class="p-col">
        <p-panel >
            <!-- <ng-template pTemplate="header">
                <header class="mr-2 font-semibold">Taux de TVA : </header>
                <span class="mr-2 ml-2 font-bold" style="color: blueviolet;" for="switch1">TTC</span>
                <p-inputSwitch [disabled]="GetDisbaledBudget()" class="mr-3"  aria-label=""
                 pTooltip="Basculement TTC/HT désactivé : un budget existe déjà" tooltipStyleClass="customtooltip"
                 [tooltipDisabled]="!GetDisbaledBudget()" [(ngModel)]="isHt" (onChange)="handleChange($event, 'left')" >
                 </p-inputSwitch>
                <span class="mr-2 font-bold" style="color: blueviolet;" for="switch1">HT</span>
            </ng-template> -->
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px'}" (click)="newTVA()" styleClass="p-button-info"
                    icon="pi pi-plus"></p-button>
            </ng-template>
            <div>
                <p-table [columns]="tvaCols" [value]="tva" selectionMode="single" [(selection)]="selectedTva" 
                dataKey="dateFrom" (onRowSelect)="onRowSelect($event)">
                    <ng-template pTemplate="colgroup" let-columns >
                        <colgroup>
                            <col *ngFor="let col of columns"
                                [ngStyle]="{'width': col.width != undefined ? col.width + 'px' : '50px'}">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" style="text-align: center; width: auto;">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" style="text-align: center;">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="col.field=='dateFrom'">
                                        {{rowData[col.field] | date : 'yyyy-MM-dd'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="col.field=='dateEnd'">
                                        {{rowData[col.field] | date : 'yyyy-MM-dd'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="col.field==''">
                                        <td>
                                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                             class="p-button-rounded p-button-success p-button-text" (click)="editTva(rowData)">
                                            </button>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                             class="p-button-rounded p-button-danger p-button-text" (click)="deleteTva($event, rowData)">
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{rowData[col.field]}}%
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-panel>
    </div>
</div>
<p-dialog  *ngIf="displayFormTva" [(visible)]="displayFormTva">
    <p-header></p-header>
    <app-form-budget-tva [tvaFormValue]="tvaFormValue" [isBlank]="isBlank" [tvaFormValue]="tvaFormValue"
      [isNewTva]="isNewTva" [isValideDate]="isValideDate" (OnValidate)="validate($event)" 
      (OnSelecteDate)="selecteDate($event)" (OnCancel)="cancel($event)" >
    </app-form-budget-tva>
</p-dialog>
<p-confirmDialog [style]="{width: '30vw'}"  [position]="position"></p-confirmDialog>
