import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AllProduitstHotel } from '../../services/model/allProduitstHotel';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { Inventaire, UpdateInventaireCmd } from '../../services/model/models';
import { ProduitService } from '../../services/api/produit.service';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';

@Component({
  selector: 'app-inventaires',
  templateUrl: './inventaires.component.html',
  styleUrls: ['./inventaires.component.scss']
})
export class InventairesComponent implements OnInit, OnDestroy {

    inventaireFormConfig: FormConfig;
    displayInvForm: boolean;

    private readonly destroy$: Subject<void>;
    @Input() allProduits: AllProduitstHotel;

    @ViewChild('formInv') formDynamicInv: FormDynamicComponent;
    formInvValue: any;

    selectedInv: Inventaire;

    constructor(private userInfoService: UserInfoService,private produitService: ProduitService,
        private messageService: MessageService, private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit(): void {

    }

    UpdateInv(selectedInv: Inventaire) {
        this.selectedInv = selectedInv;
        this.produitService.apiProduitInvFormGet(this.userInfoService.currentHotel.hotCode)
        .pipe(takeUntil(this.destroy$))
            .subscribe(frm => {
                this.inventaireFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
                this.formInvValue = {
                    inventaire: selectedInv
                };
                this.displayInvForm = true;
            });
    }

    cancel($event): void {
        this.displayInvForm = false;
    }

    validateFormInv(value: any): void {
        const newInv: Inventaire = value.inventaire;

        let cmd: UpdateInventaireCmd = {
            hotCode: this.allProduits.hotCode,
            inventaire: newInv,
        };
        let sub = this.produitService.apiProduitInventairesPut(cmd)
            .subscribe({
                next: session => {
                    this.displayInvForm = false;
                    let oldInv = this.allProduits.inventaireList.find(x => x.code == newInv.code);
                    if (oldInv) {
                        Object.assign(oldInv, { ...newInv });
                    }
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                },
                error: session => {
                    let error = new FormErrors();
                    error.errors = new ErrorDictionary();
                    error.errors["inventaire"] = [session.error.error];  // inventaire c'est le vertical groupe racine
                    this.formDynamicInv.setExternalErrors(error);
                }
            })
    }

}
