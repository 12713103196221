<p-toolbar>
    <div class="p-toolbar-group-left">
        <div *ngIf="!hotelWithRoomtypes && dataLoaded">
            <span class="textWithButton">Initialiser les données du rate shopper pour
                l'hôtel:<b>{{currentHotel.HotelName}}</b></span>
            <p-button label="Initialiser" icon="pi pi-chevron-circle-right" [disabled]=" !dataLoaded"
                (click)="InitHotel($event)">
            </p-button>
        </div>
        <div *ngIf="hotelWithRoomtypes">
            <p-button label="Ajouter" icon="pi pi-plus" [disabled]=" !dataLoaded" (click)="OpenAddCompetitorsDialog()">
            </p-button>
            <p-button label="Supprimer" icon="pi pi-times" styleClass="p-button-danger" (click)="delCompetitors($event)"
                [disabled]="!selectedHotelWithRoomtypes || !dataLoaded"></p-button>
        </div>
    </div>
</p-toolbar>

<div *ngIf="hotelWithRoomtypes">
    <p-panel header="Liste des concurrents pour l'hôtel :  {{currentHotel.HotelName}}">
        <p-table [columns]="cols" [value]="hotelWithRoomtypes" selectionMode="single"
            styleClass="p-datatable-sm p-datatable-striped" (onRowSelect)="onRowSelect($event)"
            [(selection)]="selectedHotelWithRoomtypes">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" class="ui-resizable-column">
                        <ng-container [ngSwitch]="true">

                            <ng-container *ngSwitchCase="col.field=='bookingUrl'">
                                <a [href]="'https://'+rowData.hotel[col.field]" target="_blank">
                                    <button pButton type="button" [label]="rowData.hotel[col.field]"
                                        class="p-button-link">
                                    </button>
                                </a>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                {{rowData.hotel[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
</div>

<!-- type de chmbres -->
<p-panel header="Room Types" *ngIf="selectedHotelWithRoomtypes">
    <p-table [columns]="colsRoomtype" [value]="selectedHotelWithRoomtypes.roomTypes"
        styleClass="p-datatable-sm p-datatable-striped">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width">
            </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" class="ui-resizable-column">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>


<!-- Ecran  d'ajout d'un nouvau concurrent-->
<p-dialog [(visible)]="displayAddCompForm" [modal]="true" [style]="{width: '860px', height:'675px'}">
    <p-header>
        Nouveau concurrent
    </p-header>
    <div style="height: 520px;overflow: hidden; ">
        <p-table #dt2 [columns]="colsHotels" [value]="hotels" [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped" selectionMode="single" [(selection)]="selectedHotelToAdd"
            dataKey="rateShopperId">
            <ng-template pTemplate="caption">
                <div style="text-align: left">
                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="dt2.filterGlobal($event.target.value, 'contains')" style="width:auto">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                </div>
            </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" class="ui-resizable-column">
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <p-button label="Ajouter" icon="pi pi-plus" [disabled]="!selectedHotelToAdd" (click)="addCompetitors()">
        </p-button>
        <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger" (click)="displayAddCompForm=false">
        </p-button>
    </p-footer>

</p-dialog>

<p-toast position="top-right"></p-toast>
<p-confirmPopup></p-confirmPopup>
