import * as moment from "moment";
import { Moment } from "moment";
import { RowSci } from "./RowSci";

export class CellSci {

    constructor(private rowParent: RowSci, public index: number, public cellId: number) {
        this.x = index * this.rowParent.tableParent.cellWith;
        this.width = this.rowParent.tableParent.cellWith;
        this.y = this.rowParent.y;
        this.height = this.rowParent.height

        this.momentCell = moment(this.rowParent.tableParent.dateNow).add(index, 'days')
        this.cellDate = new Date(this.momentCell.toISOString());
        this.setKeyForDataService();

        this.setFirstDayInMonth();
    }

    label: string;
    x: number; //position dans le rouleau
    y: number; //position dans le rouleau
    height: number;
    width: number;
    momentCell: Moment;
    cellKeyForDataService: string;
    isFisrtVisibleInMonth: boolean;

    public cellDate: Date;

    private setKeyForDataService(): void {
        // this.cellDate = new Date(this.momentCell.toISOString());
        this.cellKeyForDataService = `${this.cellDate.getFullYear()}-${this.cellDate.getMonth() + 1}-${this.cellDate.getDate()}`;
    }
    public translate(translationX: number): number | null {

        let result: number | null = null;

        this.x = this.x + translationX

        const newIndex = Math.trunc(this.x / this.rowParent.tableParent.cellWith);

        if (newIndex > this.rowParent.tableParent.cellsCount * 2) {
            const index2 = this.index - this.rowParent.tableParent.cellsCount * 3;
            this.index = index2; result = index2;
            this.x = this.index * this.rowParent.tableParent.cellWith + this.rowParent.currenttranslationX;
            this.momentCell = moment(this.rowParent.tableParent.dateNow).add(this.index, 'days')
            this.cellDate = new Date(this.momentCell.toISOString());

            this.setKeyForDataService();

        }
        else if (newIndex < this.rowParent.tableParent.cellsCount * -1) {
            const index2 = this.index + this.rowParent.tableParent.cellsCount * 3;
            this.index = index2; result = index2;
            this.x = this.index * this.rowParent.tableParent.cellWith + this.rowParent.currenttranslationX;
            this.momentCell = moment(this.rowParent.tableParent.dateNow).add(this.index, 'days')
            this.cellDate = new Date(this.momentCell.toISOString());

            this.setKeyForDataService();

        }
        this.setFirstDayInMonth();
        return result;
    }

    setFirstDayInMonth(): void {
        let day = this.momentCell.format("D");
        let dayValue = Number(day);

        const cellWith = this.rowParent.tableParent.cellWith

        const visible = this.x > -cellWith && this.x < (this.rowParent.tableParent.cellsCount) * cellWith

        this.isFisrtVisibleInMonth = visible && (dayValue == 1 || (this.x > -cellWith && this.x <= 0))
    }
}


