import { Component } from '@angular/core';
import { CellBaseComponent } from '../cell-base/cell-base.component';

@Component({
    selector: 'app-cell-pricer',
    templateUrl: './cell-pricer.component.html',
    styleUrls: ['./cell-pricer.component.css']
})
export class CellPricerComponent extends CellBaseComponent {

    getValue(): any {

        if (this.cell && this.rowSci) {
            let key = this.cell.cellKeyForDataService;
            key = `${this.cell.cellKeyForDataService}-${this.rowSci.rowId}`;
            return super.getValue(key);

        }
        return "";
    }

    getClassJour(): string {
        const index = this.rowSci.cells[this.cellId].index;
        let day = this.rowSci.cells[this.cellId].cellDate.getDay();

        if (day == 1) return "cellLun";
        if (day == 5) return "cellVen";
        if (day == 6) return "cellSam";
        return "";
    }

    getCalssYiledOpenClose(): string {
        let value = this.getValue();

        if (value?.hasYiledOpenClose == true) return "hasYiledOpenClose";
        return "not_hasYiledOpenClose";
    }
}
