import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HotelAction } from '../../services/model/hotelAction';
import { RoleAction } from '../../services/model/roleAction';
import { Group } from '../../services/model/group';
import { User } from '../../services/model/user';
import { HotelsAuthoriz, RoleAuthoriz } from '../../services/model/roleAuthoriz';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  
  @Output() onRoleAuthoriz = new EventEmitter();
  @Output() onColse = new EventEmitter();
  @Input() avatarProfile: string | ArrayBuffer;
  @Input() avatarSrc: string;
  @Input() lineValue: User;
  @Input() selectedRolesAuthoriz: RoleAuthoriz;
  @Input() hotelsAutoriz: HotelsAuthoriz[];
  @Input() groupsAutoriz: Group[];
  @Input() rolesAutoriz: RoleAuthoriz[];
  filterValue: string;
  @Input() cacheData: any;


  onSelectedRoleAuthoriz(selectedRolesAuthoriz: RoleAuthoriz){
      if (selectedRolesAuthoriz) {
          this.onRoleAuthoriz.emit(selectedRolesAuthoriz);
  }
  }
  hotelsAutorizFilter(
    value: string,
    objectName: string,
    prop1: string,
    prop2?: string
) {
    let filtredArray = this.cacheData[objectName].filter(
        (elem) =>
            elem[prop1]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0 ||
            elem[prop2]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
    );
    let sortedData = filtredArray?.sort((a, b) => {
        if (a[prop1] < b[prop1]) {
            return -1;
        }
        if (a[prop1] > b[prop1]) {
            return 1;
        }
        return 0;
    });
    this[objectName] = sortedData;
}
close(){
  this.onColse.emit();
}

}
