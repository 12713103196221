<div *ngIf="visible"  class="exception-body error">
    <div class="logo">
        <img src="assets/layout/images/harmony-logo.png" alt="harmony-layout"/>
        <span class="app-name">Backoffice</span>
    </div>

    <div class="exception-panel">
        <img src="assets/layout/images/template/image-error-mobile.jpg" alt="harmony-layout" class="mobile"/>
        <div class="left-part">
            <span class="exception-panel-header">Error Occured</span>
            <span class="exception-panel-subheader">Something went wrong</span>

            <button type="button" pButton label="Home" class="p-button-raised" [routerLink]="['/']"></button>
        </div>
        <div class="right-part">
            <img src="assets/layout/images/template/image-error.png" alt="harmony-layout"/>
        </div>
    </div>
</div>
