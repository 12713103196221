export * from './authorization.service';
import { AuthorizationService } from './authorization.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './hotel.service';
import { HotelService } from './hotel.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './serverStorage.service';
import { ServerStorageService } from './serverStorage.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AuthorizationService, GroupService, HotelService, RoleService, ServerStorageService, UserService];
