import { FormControl } from '@angular/forms'
import { IControl2 } from './IControl2'
import { FormParameter } from './form-parameter'
import { FormGroup2 } from './formGroup2'

export class FormControl2 extends FormControl implements IControl2 {
    constructor(public code: string, formState?: any) {
        super(formState)
    }
    formParam: FormParameter
    externalErrorMsg?: string
    visibilityConditionValue: boolean = true
    activationConditionValue: boolean = true

    getErrorsMessage(): string {
        if (this.dirty || this.touched) {
            if (this.errors?.required) return 'Champ requis'
            if (this.externalErrorMsg) return this.externalErrorMsg
            return ''
        }
        else {
            return ''
        }
    }

    update_Value_And_Validity(): void {

        this.updateValueAndValidity();
        this.externalErrorMsg = undefined;

        let parentControl = this.parent as FormGroup2;

        if (parentControl) parentControl.update_Value_And_Validity();


    }
}
