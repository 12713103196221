<p-card *ngIf="hotelNotIntialized">
    <div class="mb-3">
        L'hôtel <span class="font-semibold text-pink-900">{{currentHotel.name }} </span> n'est pas initialisé pour Mews Live
    </div>
    <p-button label="Initialiser" icon="pi pi-chevron-circle-right" (click)="initHotel($event)">
    </p-button>
</p-card>
<p-tabView styleClass="tabview-custom" *ngIf="!hotelNotIntialized">
    <p-toolbar [style]="{'padding': '2px'}">
        <div class="p-toolbar-group-left">
        </div>
        <div class="p-toolbar-group-right">
            <p-button label="Sauvegarder" icon="pi pi-save" class="mr-1" styleClass="p-button-success"
                (onClick)="saveMappingComfirmation($event)">
            </p-button>
        </div>
    </p-toolbar>
    <!-- Configuration -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-cog  mr-1"></i>
            <span>Configuration</span>
        </ng-template>

        <div class="mt-3">
            <div>
                <span>Services Mews
                </span>
                <p-dropdown [options]="serviceMews" [style]="{'width': '560px', 'min-height': '28px'}"
                    [(ngModel)]="selectedServiceMews" optionLabel="name" [showClear]="true" placeholder="-">
                </p-dropdown>
            </div>
        </div>
        <div class=" mt-2">
            <div class="mt-2">
                <span>Client Token</span>
                <input type="text" pInputText [style]="{'width': '560px', 'margin': '20px'}"
                    [(ngModel)]="clientToken" />
            </div>
        </div>
        <div class="mt-2">
            <div class="mt-2 ">
                <span>Access Token</span>
                <input type="text" pInputText [style]="{'width': '560px', 'margin': '15px'}" pInputText
                    [(ngModel)]="accessToken" />
            </div>
        </div>
        <div class="mt-4">
            <div style="width: 450px; margin-block: -12px;">
                <span class=" mr-4 ">Prix</span>
            </div>
            <p-inputSwitch class="mr-4" aria-label="" [(ngModel)]="rateEnabled"></p-inputSwitch>
        </div>
        <div class="mt-4">
            <div style="margin-block: -9px;">
                <span class=" mr-4 ">Restriction</span>
            </div>
            <p-inputSwitch aria-label="" [(ngModel)]="restrictionEnabled"></p-inputSwitch>
        </div>

    </p-tabPanel>
    <!-- Rate-plans -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-credit-card mr-1"></i>
            <span>Rate Plans</span>
        </ng-template>
        <div *ngIf="!hotelNotIntialized">
            <app-rate-plans [spotRatePlans]="stpotRatePlans" [mewsRatePlans]="mewsRatePlans"
                [mappingRateInventory]="mappingRateInventory"
                [hotelNotIntialized]="hotelNotIntialized"></app-rate-plans>
        </div>

    </p-tabPanel>
    <!-- Type chambre -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class=" mr-1"></i>
            <span>Type de chambre</span>
            <i class=" ml-3 "></i>
        </ng-template>
        <div *ngIf="!hotelNotIntialized">
            <app-categories [produitVenduUnits]="produitVenduUnits" [resourceCategoryMews]="resourceCategoryMews"
                [mappingRateInventory]="mappingRateInventory" [hotelNotIntialized]="hotelNotIntialized">

            </app-categories>
        </div>
    </p-tabPanel>
</p-tabView>
<p-dialog *ngIf="mewsSaveDialog" [(visible)]="mewsSaveDialog" [modal]="true" [style]="{ height:'240px' }">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Confirmation de sauvegarde</span>
    </ng-template>
    <p><b>Voulez-vous sauvegarder les modifications ?</b>
    </p>
    <div class="mt-2">
        <div class="mt-2 ">
            <span style="display: block;" >Veuillez entrer le code de l'Hôtel <b>{{currentHotel.name}}</b> :</span>
            <input type="text" pInputText [style]="{'width': '200px', 'margin-top': '5px'}" pInputText
                [(ngModel)]="hotCode" />
                <div
                    *ngIf="hotCode != null && hotCode != currentHotel.hotCode ">
                    <small id="" class="p-error">Code Hôtel incorrecte</small>
                </div>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button [disabled]="hotCode == null || hotCode != currentHotel.hotCode" icon="pi pi-check"
          label="Valider" styleClass="p-button-success" (onClick)="saveMapping($event)"></p-button>

    </ng-template>
</p-dialog>
