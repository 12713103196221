import { Component, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AppMainComponent } from './app.main.component';
import { UserInfoService } from './azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from './services/notifications/notifications.service';
import { UserSpotAuthorization } from './azureAdB2C/services/model/userSpotAuthorization';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ["./app.topbar.component.css"],
})
export class AppTopBarComponent {
    @ViewChild('inputText') inputText: ElementRef;
    @ViewChild('inputFile') inputFile: ElementRef;
    items: MenuItem[];
    activeIndex:number = 0;
    environment: {
        production: boolean; version: string; urlRateShopper: string; urlUser: string; urlAnalyse: string;  urlSpotConnect: string; urlDistrib: string; urlBudget: string; urlPowerBi: string; msalConfig: { auth: { clientId: string; }; }; apiConfig: {
            scopes: string[]; //     scopes: ["openid"],
            //     scopes: ["openid"],
            //     authority: b2cPolicies.authorities.editProfile.authority,
            // };
            // this.login(editProfileFlowRequest);
            uri: string; //     authority: b2cPolicies.authorities.editProfile.authority,
        }; //     authority: b2cPolicies.authorities.editProfile.authority,
        apiConfig_dev: { scopes: string[]; uri: string; }; b2cPolicies: { names: { signUpSignIn: string; resetPassword: string; editProfile: string; }; authorities: { signUpSignIn: { authority: string; }; resetPassword: { authority: string; }; editProfile: { authority: string; }; }; authorityDomain: string; };
    };
    avatar: any;
    

    constructor(public app: AppMainComponent,
        protected userInfoService: UserInfoService,
        private authService: MsalService,
        private notifService: NotificationsService,
        private sanitizer: DomSanitizer,
        ) { }
        

        ngOnInit() {
            this.environment = environment
            this.items = [
                {
                    label: 'Profile',
                    icon: 'pi pi-user-edit',
                    command: (event) => {
                        // let editProfileFlowRequest = {
                        //     scopes: ["openid"],
                        //     authority: b2cPolicies.authorities.editProfile.authority,
                        // };

                        // this.login(editProfileFlowRequest);
                    }
                },
                {
                    label: 'Se déconnecter',
                    icon: 'pi pi-power-off',
                    command: (event) => {
                        this.authService.logout();
                    }
                },
            ];
            this.getUserProfile()
        }
        getUserProfile(): UserSpotAuthorization{
            let userSpotAuthorization = this.userInfoService.userSpotAuthorization;
            this.avatar = this.transform(userSpotAuthorization.avatarProfile);
            return userSpotAuthorization;
        }
        transform(avatarProfile) {
            if (
                avatarProfile != undefined &&
                avatarProfile != null &&
                avatarProfile != 'string'
            ) {
                return this.sanitizer.bypassSecurityTrustResourceUrl(avatarProfile);
            }
            return null;
        }
        getCount(){
            return this.notifService.getCount();
        }

        checkAccount(): boolean {
            return this.authService.instance.getAllAccounts().length > 0
        }

        getUserName(): string {
            var allAccounts = this.authService.instance.getAllAccounts();
            if (allAccounts.length > 0) {
                // console.log(allAccounts[0]);
                return allAccounts[0].name
            }
            return "";
        }

        getVersion(): string {
            return environment.version;
        }

}
