import { Component } from '@angular/core';
import { CellBaseComponent } from '../cell-base/cell-base.component';

@Component({
  selector: 'app-cell-prixref',
  templateUrl: './cell-prixref.component.html',
  styleUrls: ['./cell-prixref.component.css']
})
export class CellPrixrefComponent extends CellBaseComponent {

    getValue(): any {

        if (this.cell && this.rowSci) {
            let key = this.cell.cellKeyForDataService;

            if (this.rowSci.parentRowSci) {
                key = `${this.cell.cellKeyForDataService}-${this.rowSci.parentRowSci.rowId}-${this.rowSci.rowId}`;
            }
            else {
                key = `${this.cell.cellKeyForDataService}-${this.rowSci.rowId}`;
            }

            return super.getValue(key);
        }
        return "";
    }
    getClassJour(): string {
        const index = this.rowSci.cells[this.cellId].index;
        let day = this.rowSci.cells[this.cellId].cellDate.getDay();

        if (day == 1) return "cellLun";
        if (day == 5) return "cellVen";
        if (day == 6) return "cellSam";
        return "";

    }
}
