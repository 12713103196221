<div [formGroup]="newFormGroup" class="p-fluid">

  <label [for]="field.code">{{ field.label }}
    <span *ngIf="field.required && field.label" class="fd-required">*</span>
  </label>
  <div style="margin-bottom: 1em;position: relative;">
    <p-selectButton [options]="field.possibleValues" [formControlName]="field.code" optionLabel="label"
      [multiple]="true" [pTooltip]="field.toolTip" tooltipPosition="top" [tooltipZIndex]="tooltipzindex">
    </p-selectButton>

    <div class="fd-errorText">
      <small>{{ formControlIn?.getErrorsMessage() }}
      </small>
    </div>
  </div>
</div>