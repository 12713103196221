/**
 * Spot RateShopper Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FormConfig } from '../model/formConfig';
import { Hotel } from '../model/hotel';
import { HotelConfigState } from '../model/hotelConfigState';
import { HotelForCreation } from '../model/hotelForCreation';
import { HotelForUpdate } from '../model/hotelForUpdate';
import { HotelWithRoomtypes } from '../model/hotelWithRoomtypes';
import { RateShopperMappings } from '../model/rateShopperMappings';
import { RateShopperMappingsForUpdate } from '../model/rateShopperMappingsForUpdate';
import { RateShopperRoomType } from '../model/rateShopperRoomType';
import { SpotServiceResponse } from '../model/spotServiceResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RateShopperConfigService {

    protected basePath = 'https://services-dev.spotpilot.com/rateshopper';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Liste de tous les hôtels  du rateShopper
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Hotel>>;
    public apiHotelsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Hotel>>>;
    public apiHotelsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Hotel>>>;
    public apiHotelsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Hotel>>('get',`${this.basePath}/api/hotels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter un concurent pour un hôtel
     * 
     * @param hotCode 
     * @param rateShopperId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeAddcompetitorPost(hotCode: string, rateShopperId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiHotelsHotCodeAddcompetitorPost(hotCode: string, rateShopperId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiHotelsHotCodeAddcompetitorPost(hotCode: string, rateShopperId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiHotelsHotCodeAddcompetitorPost(hotCode: string, rateShopperId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeAddcompetitorPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (rateShopperId !== undefined && rateShopperId !== null) {
            queryParameters = queryParameters.set('rateShopperId', <any>rateShopperId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/addcompetitor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liste des concurrents d&#x27;un hôtel
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeCompetitorsGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<HotelWithRoomtypes>>;
    public apiHotelsHotCodeCompetitorsGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HotelWithRoomtypes>>>;
    public apiHotelsHotCodeCompetitorsGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HotelWithRoomtypes>>>;
    public apiHotelsHotCodeCompetitorsGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeCompetitorsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HotelWithRoomtypes>>('get',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/competitors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Etat de la config rateShopper pour un hotel (pour RMS)
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeHotelConfigStateGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<HotelConfigState>;
    public apiHotelsHotCodeHotelConfigStateGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelConfigState>>;
    public apiHotelsHotCodeHotelConfigStateGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelConfigState>>;
    public apiHotelsHotCodeHotelConfigStateGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeHotelConfigStateGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HotelConfigState>('get',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/hotelConfigState`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initailiser du mappings rateshopper s&#x27;il est vide
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeInitRateShopperHotelPost(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiHotelsHotCodeInitRateShopperHotelPost(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiHotelsHotCodeInitRateShopperHotelPost(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiHotelsHotCodeInitRateShopperHotelPost(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeInitRateShopperHotelPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/initRateShopperHotel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mappings complet d&#x27;un hôtel
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeMappingsGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<RateShopperMappings>;
    public apiHotelsHotCodeMappingsGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RateShopperMappings>>;
    public apiHotelsHotCodeMappingsGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RateShopperMappings>>;
    public apiHotelsHotCodeMappingsGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeMappingsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RateShopperMappings>('get',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/mappings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour du mappings RateShopper
     * 
     * @param hotCode 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeMappingsPut(hotCode: string, body?: RateShopperMappingsForUpdate, observe?: 'body', reportProgress?: boolean): Observable<SpotServiceResponse>;
    public apiHotelsHotCodeMappingsPut(hotCode: string, body?: RateShopperMappingsForUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SpotServiceResponse>>;
    public apiHotelsHotCodeMappingsPut(hotCode: string, body?: RateShopperMappingsForUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SpotServiceResponse>>;
    public apiHotelsHotCodeMappingsPut(hotCode: string, body?: RateShopperMappingsForUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeMappingsPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SpotServiceResponse>('put',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/mappings`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un concurent
     * 
     * @param hotCode 
     * @param rateShopperId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotCodeRemovecompetitorDelete(hotCode: string, rateShopperId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiHotelsHotCodeRemovecompetitorDelete(hotCode: string, rateShopperId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiHotelsHotCodeRemovecompetitorDelete(hotCode: string, rateShopperId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiHotelsHotCodeRemovecompetitorDelete(hotCode: string, rateShopperId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotelsHotCodeRemovecompetitorDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (rateShopperId !== undefined && rateShopperId !== null) {
            queryParameters = queryParameters.set('rateShopperId', <any>rateShopperId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('delete',`${this.basePath}/api/hotels/${encodeURIComponent(String(hotCode))}/removecompetitor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Formulaire de saisie d&#x27;un hotel RateShopper
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsHotelformGet(observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiHotelsHotelformGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiHotelsHotelformGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiHotelsHotelformGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/hotels/hotelform`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creation d&#x27;un hôtel dans le RateShopper
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsPost(body?: HotelForCreation, observe?: 'body', reportProgress?: boolean): Observable<Hotel>;
    public apiHotelsPost(body?: HotelForCreation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hotel>>;
    public apiHotelsPost(body?: HotelForCreation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hotel>>;
    public apiHotelsPost(body?: HotelForCreation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Hotel>('post',`${this.basePath}/api/hotels`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour d&#x27;un hôtel dans le RateShopper
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsPut(body?: HotelForUpdate, observe?: 'body', reportProgress?: boolean): Observable<Hotel>;
    public apiHotelsPut(body?: HotelForUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hotel>>;
    public apiHotelsPut(body?: HotelForUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hotel>>;
    public apiHotelsPut(body?: HotelForUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Hotel>('put',`${this.basePath}/api/hotels`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Type chambres d&#x27;un hotel dans le rateShopper
     * 
     * @param rateShopperId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsRateShopperIdRoomTypesGet(rateShopperId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RateShopperRoomType>>;
    public apiHotelsRateShopperIdRoomTypesGet(rateShopperId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateShopperRoomType>>>;
    public apiHotelsRateShopperIdRoomTypesGet(rateShopperId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateShopperRoomType>>>;
    public apiHotelsRateShopperIdRoomTypesGet(rateShopperId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rateShopperId === null || rateShopperId === undefined) {
            throw new Error('Required parameter rateShopperId was null or undefined when calling apiHotelsRateShopperIdRoomTypesGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateShopperRoomType>>('get',`${this.basePath}/api/hotels/${encodeURIComponent(String(rateShopperId))}/roomTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activer/Déactiver un hôtel dans le RateShopper (basculer le flag isDisabled)
     * 
     * @param rateShopperId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelsToggleStatePut(rateShopperId?: string, observe?: 'body', reportProgress?: boolean): Observable<Hotel>;
    public apiHotelsToggleStatePut(rateShopperId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hotel>>;
    public apiHotelsToggleStatePut(rateShopperId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hotel>>;
    public apiHotelsToggleStatePut(rateShopperId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (rateShopperId !== undefined && rateShopperId !== null) {
            queryParameters = queryParameters.set('rateShopperId', <any>rateShopperId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Hotel>('put',`${this.basePath}/api/hotels/toggleState`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Hotel rateShopper byId
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRateShopperHotel(id: string, observe?: 'body', reportProgress?: boolean): Observable<Hotel>;
    public getRateShopperHotel(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hotel>>;
    public getRateShopperHotel(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hotel>>;
    public getRateShopperHotel(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getRateShopperHotel.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Hotel>('get',`${this.basePath}/api/hotels/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
