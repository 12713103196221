<div [ngClass]="'form-component-' + field.code">
  <div [formGroup]="formGroup">

    <div>
      <label [for]="field.code">{{ field.label }}
        <span *ngIf="field.required" class="fd-required">*</span>
      </label>
    </div>

    <div style="margin-bottom: 1em;position: relative">
      <input type="text" pInputText [formControlName]="field.code" [id]="field.code" (keydown)="onKeydown($event)"
        autocomplete="off" [pTooltip]="field.toolTip" tooltipPosition="top" [tooltipZIndex]="tooltipzindex"
        [ngClass]="getStyleClass()" [readOnly]="field.readOnly" />

      <div class="fd-errorText">
        <small>{{ formControl?.getErrorsMessage() }}
        </small>
      </div>
    </div>
  </div>
</div>