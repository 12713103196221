<div [class]="'mt-3 mb-3 form-component-' + field.code"
    [style]="{ width: field.type == 'ShortSelect' ? '95px' : '177px' }" *ngIf="typeParrentGroup != 'VGroup'">
    <div class="p-fluid">
        <div class="p-field" style="position: relative" [formGroup]="newFormGroup">
            <label [for]="field.code">{{ field.label }}
                <span *ngIf="field.required" class="p-invalid">*</span>
            </label>
            <p-multiSelect #selectbox1 [options]="field.possibleValues" [formControlName]="field.code"
                optionValue="code" optionLabel="label" [showClear]="true" (onChange)="onChange($event)"
                [placeholder]="field.placeholder || '-'" [required]="field.required" [pTooltip]="field.toolTip"
                tooltipPosition="top" [tooltipZIndex]="tooltipzindex" appendTo="body">
            </p-multiSelect>
            <div class="errorText">
                <small class="p-invalid errorText">{{
                    formControl?.getErrorsMessage()
                    }}</small>
            </div>
        </div>
    </div>
</div>

<div [class]="'form-component-' + field.code" [style]="{ width: field.type == 'ShortSelect' ? '95px' : '177px' }"
    *ngIf="typeParrentGroup == 'VGroup'">
    <div class="p-field p-grid" [formGroup]="formGroup">
        <label [for]="field.code" class="p-col-fixed">{{ field.label }}
            <span *ngIf="field.required" class="text-danger">*</span>
        </label>
        <div class="p-col" style="position: relative">
            <p-multiSelect #selectbox1 [options]="field.possibleValues" [formControlName]="field.code"
                optionValue="code" optionLabel="label" [showClear]="true" (onChange)="onChange($event)"
                [placeholder]="field.placeholder || '-'" [required]="field.required" [pTooltip]="field.toolTip"
                tooltipPosition="top" [tooltipZIndex]="tooltipzindex" appendTo="body">
            </p-multiSelect>

            <div class="errorText">
                <small class="p-invalid errorText">{{ formControl?.getErrorsMessage() }}
                </small>
            </div>
        </div>
    </div>
</div>