import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { UserInfoService } from "./UserInfoService/user-InfoService";

@Injectable()
export class SupportRoleRequiredGuard {
    constructor(private router: Router, private userinfoservice: UserInfoService, private authService: MsalService,) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return new Promise<boolean>((resolve) => {
            this.userinfoservice.getUserRoles$().subscribe(roles => {
                if (roles && roles.find(r => r == "Support") == "Support") {
                    resolve(true);
                }
                else {
                    this.authService.logout();
                    resolve(false);
                }
            })
        });
    }
}
