import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { AdminService as OperaCloudPmsLinkService } from '../../services/pmslink/api/admin.service';
import { AdminService as OperaCloudComptaService } from '../../services/compta/api/admin.service';
import { firstValueFrom, throwError, forkJoin, delay } from 'rxjs';
import { Hotel as HotelCredentialsPmsLink } from '../../services/pmslink/model/hotel';
import { HotelCredentials as HotelCredentialsCompta } from '../../services/compta/model/hotelCredentials';
import { promises } from 'dns';
import {Clipboard} from '@angular/cdk/clipboard';
import * as moment from 'moment';
import { RoomType } from '../../services/pmslink/model/roomType';


@Component({
  selector: 'app-operacloud',
  templateUrl: './operacloud.component.html',
  styleUrls: ['./operacloud.component.css']
})

export class OperacloudComponent implements OnInit, OnDestroy {  
  currentHotel: HotelAuth;
  hotelCredentialsPmsLink: HotelCredentialsPmsLink;
  hotelRoomTypes: RoomType[];
  nbrooms: number = 0;
  nbroomsReal: number = 0;
  hotelCredentialsCompta: HotelCredentialsCompta;
  hotelCredentialsPmsLinkIsSaved: boolean = false;
  hotelCredentialsComptaIsSaved: boolean = false;
  private subs = new SubSink();
  hotelPmsLinkNotInitialized: boolean = true;
  hotelComptaNotInitialized: boolean = true;
  hotelComptaNotPmsLinkInitialized: boolean = true;
  loading: boolean = false;
  histoForm: FormGroup;
  histoComptaForm: FormGroup;
  reservationIdToLoad: string = null;
  reservationIdToRead: string = null;
  dialogJsonData: string = null;
  savingComptaFromPmsLink: boolean = false;
  startingCompta: boolean = false;
  stoppingCompta: boolean = false;
  startingPmsLink: boolean = false;
  stoppingPmsLink: boolean = false;
  reloadingReservation: boolean = false;
  gettingReservation: boolean = false;
  savingCredentialsCompta : boolean = false;
  savingCredentials : boolean = false;
  loadingHisto : boolean = false;
  hasReservations : boolean = true;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private userInfoService: UserInfoService,
    private notifService: NotificationsService,
    private datePipe: DatePipe,
    private operaCloudPmsLinkService: OperaCloudPmsLinkService,
    private operaCloudComptaService: OperaCloudComptaService,
    private fb: FormBuilder,
    private clipboard: Clipboard
  ) {
    this.breadcrumbService.setItems([{ label: "OperaCloud" }]);
    this.createForm();
  }

  createForm() : void {
    this.histoForm = this.fb.group({
       histoStart: [null, Validators.required ],
       histoEnd: [null],
       histoResas: [true, Validators.required],
       histoOOs: [true, Validators.required],
       histoBlocks: [true, Validators.required],
    });
    this.histoComptaForm = this.fb.group({
      histoComptaStart: [null, Validators.required ]
   });
  }

  ngOnInit(): void {
    this.subs.sink = this.userInfoService
        .getNewHotel$()
        .subscribe((hotCode) => {
            this.loading = true;
            this.savingComptaFromPmsLink = false;
            this.startingCompta = false;
            this.stoppingCompta = false;
            this.startingPmsLink = false;
            this.stoppingPmsLink = false;
            this.reloadingReservation = false;
            this.gettingReservation = false;
            this.savingCredentials = false;
            this.savingCredentialsCompta = false;
            this.loadingHisto = false;
            this.currentHotel = hotCode;
            this.hotelPmsLinkNotInitialized = true;
            this.hotelComptaNotInitialized = true;
            this.hotelComptaNotPmsLinkInitialized = true;
            this.hotelCredentialsPmsLinkIsSaved = false;
            this.hotelCredentialsComptaIsSaved = false;
            this.loadData();
        });
  }
  ngOnDestroy(): void {
    if (this.subs != undefined) {
        this.subs.unsubscribe(); 
    }
  }

  isUserAdmin(): boolean {
      return this.userInfoService.isUserAdmin();
  }

  hasNoReservationOrIsAdmin(): boolean {
      return this.isUserAdmin() || !this.hasReservations; 
  }

  initHotel($event): void { 
    this.hotelCredentialsPmsLink = {
        hotCode: this.currentHotel.hotCode,
        operaCloudAppKey: "58bcaae7-6579-4529-b26f-40c9e95f6b1c",
        operaCloudBaseUrl: "",
        operaCloudClientId: "eaxess_Client",
        operaCloudClientSecret: "qxXV71F3NQ-PSY-2Vwu0Eu5K",
        operaCloudExternalSystemCode: "ExtSystemCode",
        operaCloudHotelId: "",
        operaCloudPassword: "",
        operaCloudTenant: "",
        operaCloudUserName: "",
        hotelCodeSpecA: "",
        loginSpecA: "",
        pwdSpecA: "",
        getNewResasFrequencyInHours: 0,
    };
    this.hotelPmsLinkNotInitialized = false;
    this.hotelCredentialsPmsLinkIsSaved = false;
  }

  initHotelCompta($event): void {
    this.hotelCredentialsCompta = {
      hotCode: this.currentHotel.hotCode,
      operaCloudAppKey: "58bcaae7-6579-4529-b26f-40c9e95f6b1c",
      operaCloudBaseUrl: "",
      operaCloudClientId: "eaxess_Client",
      operaCloudClientSecret: "qxXV71F3NQ-PSY-2Vwu0Eu5K",
      operaCloudExternalSystemCode: "ExtSystemCode",
      operaCloudHotelId: "",
      operaCloudPassword: "",
      operaCloudUserName: "",
    };
    this.hotelComptaNotInitialized = false;
    this.hotelComptaNotPmsLinkInitialized = true;
    this.hotelCredentialsComptaIsSaved = false;
  }

  async initFromPmsLinkHotel($event): Promise<void> {
    if(this.hotelComptaNotPmsLinkInitialized) {
      return;
    }

    this.savingComptaFromPmsLink = true;
    try
    {
      var result = await firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeCredentialsInitFromPmsLinkPost(this.currentHotel.hotCode));
      console.log(result);
      if(!result) {
        throw result;
      }

      this.savingComptaFromPmsLink = false;
      await this.loadData();
      this.notifService.setMessage(`InitCredentials de l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud Compta", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de InitCredentials : ${firstProp}`, "OperaCloud Compta", true);
            break;
        }
      }
    }
    finally
    {
      this.savingComptaFromPmsLink = false;
    }
  }

  async loadData() : Promise<void> {
    try {
      const result = await Promise.all([
        firstValueFrom(this.operaCloudPmsLinkService.apiAdminCredentialsHotCodeGet(this.currentHotel.hotCode)),
        firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeCredentialsGet(this.currentHotel.hotCode)),
        firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeCredentialsPmslinkGet(this.currentHotel.hotCode)),
        firstValueFrom(this.operaCloudPmsLinkService.apiAdminHasreservationsHotCodeGet(this.currentHotel.hotCode)),
        firstValueFrom(this.operaCloudPmsLinkService.apiAdminRoomtypesHotCodeGet(this.currentHotel.hotCode))
      ]);

      this.hotelCredentialsPmsLink = result[0];
      this.hotelCredentialsCompta = result[1];
      const hotelCredentialsComptaPmsLink = result[2];
      this.hotelRoomTypes = result[4];
      this.nbrooms = 0;
      this.nbroomsReal = 0;
      this.hotelRoomTypes.map(x => { 
        this.nbrooms = this.nbrooms + x.stock; 
        if(x.isReal == true) {
          this.nbroomsReal = this.nbroomsReal + x.stock;
        }
      });
      this.hasReservations = result[3];
      this.hotelPmsLinkNotInitialized = this.hotelCredentialsPmsLink == null || this.hotelCredentialsPmsLink.hotCode.trim() == '';
      this.hotelComptaNotInitialized = this.hotelCredentialsCompta == null || this.hotelCredentialsCompta.hotCode.trim() == '';
      this.hotelComptaNotPmsLinkInitialized = hotelCredentialsComptaPmsLink == null || hotelCredentialsComptaPmsLink.hotCode.trim() == ''; 
      this.hotelCredentialsPmsLinkIsSaved = !this.hotelPmsLinkNotInitialized;
      this.hotelCredentialsComptaIsSaved = !this.hotelComptaNotInitialized;
    }
    catch {
      this.hasReservations = false;
      this.hotelPmsLinkNotInitialized = true;
      this.hotelComptaNotInitialized = true;
      this.hotelComptaNotPmsLinkInitialized = true;
      this.hotelCredentialsPmsLinkIsSaved = !this.hotelPmsLinkNotInitialized;
      this.hotelCredentialsComptaIsSaved = !this.hotelComptaNotInitialized;
    }
    finally {
      this.reservationIdToLoad = null;
      this.reservationIdToRead = null;
      this.dialogJsonData = null;
      this.loading = false;
      this.savingComptaFromPmsLink = false;
      this.startingCompta = false;
      this.stoppingCompta = false;
      this.startingPmsLink = false;
      this.stoppingPmsLink = false;
      this.reloadingReservation = false;
      this.gettingReservation = false;
      this.savingCredentials = false;
      this.savingCredentialsCompta = false;
      this.loadingHisto = false;
    }
  }

  async saveCredentials() : Promise<void> {
    if(!this.hotelCredentialsPmsLink?.hotCode){
      return;
    }

    this.savingCredentials = true;
    try
    {
      var result = await firstValueFrom(this.operaCloudPmsLinkService.apiAdminCredentialsPost(
        {
          hotCode: this.hotelCredentialsPmsLink.hotCode,
          operaCloudAppKey: this.hotelCredentialsPmsLink.operaCloudAppKey,
          operaCloudBaseUrl: this.hotelCredentialsPmsLink.operaCloudBaseUrl,
          operaCloudClientId: this.hotelCredentialsPmsLink.operaCloudClientId,
          operaCloudClientSecret: this.hotelCredentialsPmsLink.operaCloudClientSecret,
          operaCloudExternalSystemCode: this.hotelCredentialsPmsLink.operaCloudExternalSystemCode,
          operaCloudHotelId: this.hotelCredentialsPmsLink.operaCloudHotelId,
          operaCloudPassword: this.hotelCredentialsPmsLink.operaCloudPassword,
          operaCloudTenant: this.hotelCredentialsPmsLink.operaCloudTenant,
          operaCloudUserName: this.hotelCredentialsPmsLink.operaCloudUserName,
          hotelCodeSpecA: this.hotelCredentialsPmsLink.hotelCodeSpecA,
          loginSpecA: this.hotelCredentialsPmsLink.loginSpecA,
          pwdSpecA: this.hotelCredentialsPmsLink.pwdSpecA,
          getNewResasFrequencyInHours: this.hotelCredentialsPmsLink.getNewResasFrequencyInHours
        }
      ));
      console.log(result);
      if(!result) {
        throw result;
      }

      await this.loadData();
      this.notifService.setMessage(`SetCredentials de l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud PmsLink", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de SetCredentials : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.savingCredentials = false;
    }
  }

  async saveCredentialsCompta() : Promise<void> {
    if(!this.hotelCredentialsCompta?.hotCode){
      return;
    }

    this.savingCredentialsCompta = true;
    try
    {
      var result = await firstValueFrom(this.operaCloudComptaService.apiAdminCredentialsPost(
        {
          hotCode: this.hotelCredentialsCompta.hotCode,
          operaCloudAppKey: this.hotelCredentialsCompta.operaCloudAppKey,
          operaCloudBaseUrl: this.hotelCredentialsCompta.operaCloudBaseUrl,
          operaCloudClientId: this.hotelCredentialsCompta.operaCloudClientId,
          operaCloudClientSecret: this.hotelCredentialsCompta.operaCloudClientSecret,
          operaCloudExternalSystemCode: this.hotelCredentialsCompta.operaCloudExternalSystemCode,
          operaCloudHotelId: this.hotelCredentialsCompta.operaCloudHotelId,
          operaCloudPassword: this.hotelCredentialsCompta.operaCloudPassword,
          operaCloudUserName: this.hotelCredentialsCompta.operaCloudUserName
        }
      ));
      if(!result) {
        throw result;
      }

      await this.loadData();
      this.notifService.setMessage(`SetCredentials de l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud Compta", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de SetCredentials : ${firstProp}`, "OperaCloud Compta", true);
            break;
        }
      }
    }
    finally
    {
      this.savingCredentialsCompta = false;
    }
  }

  async submitHistoForm() : Promise<void> {
    if(!this.histoForm.valid) {
      return;
    }
    if(this.histoForm.get("histoEnd").value && this.histoForm.get("histoEnd").value <= this.histoForm.get("histoStart").value) {
      console.log('end<=start');
      this.notifService.setMessage(`Erreur de LoadHistory : la date de fin doit être supérieur à la date de début`, "OperaCloud PmsLink", true);
      return;
    }
    if(this.histoForm.get("histoEnd").value) {      
      const limit = moment().local().endOf('years').add(2, 'years').toDate();
      const endLocale = this.histoForm.get("histoEnd").value as Date;
      if(limit < endLocale) {
        console.log('end>now+2y');
        this.histoForm.get("histoEnd").setErrors({ invalidValue: true });
        this.notifService.setMessage(`Erreur de LoadHistory : la date de fin ne peut pas être ultérieure à la fin de la deuxième année après celle du jour.`, "OperaCloud PmsLink", true);
        return;
      }
    }
    if(this.histoForm.get("histoStart").value) {
      const limit = moment().local().startOf('years').add(-2, 'years').toDate();
      const startLocale = this.histoForm.get("histoStart").value as Date;
      if(limit > startLocale) {
        console.log('start<now-2y');
        this.histoForm.get("histoStart").setErrors({ invalidValue: true });
        this.notifService.setMessage(`Erreur de LoadHistory : la date de début ne peut pas être inférieure au début de la deuxième année avant celle du jour.`, "OperaCloud PmsLink", true);
        return;
      }
    }

    this.loadingHisto = true;
    try
    {
      const fromLocale = (this.histoForm.get("histoStart").value as Date);
      const fromUTC = new Date(Date.UTC(fromLocale.getFullYear(), fromLocale.getMonth(), fromLocale.getDate(), 0, 0, 0));
      const endLocale = this.histoForm.get("histoEnd").value ? (this.histoForm.get("histoEnd").value as Date) : null;
      const endUTC = endLocale ? new Date(Date.UTC(endLocale.getFullYear(), endLocale.getMonth(), endLocale.getDate(), 0, 0, 0)) : null;
      await firstValueFrom(this.operaCloudPmsLinkService.apiAdminLoadhistoryHotCodePost(
        this.currentHotel.hotCode,
        this.histoForm.get('histoResas').value,
        this.histoForm.get('histoOOs').value,
        this.histoForm.get('histoBlocks').value,
        fromUTC,
        endUTC
      ));
      this.notifService.setMessage(`LoadHistory demandé pour l'hôtel ${this.currentHotel.name}`, "OperaCloud PmsLink", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de LoadHistory : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.loadingHisto = false;
    }
  }

  async submitHistoComptaForm() : Promise<void> {
    if(!this.histoComptaForm.valid) {
      return;
    }

    this.loadingHisto = true;
    try
    {
      const fromLocale = (this.histoComptaForm.get("histoComptaStart").value as Date);
      const fromUTC = new Date(Date.UTC(fromLocale.getFullYear(), fromLocale.getMonth(), fromLocale.getDate(), 0, 0, 0));
      await firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeLoadhistoryPut(
        this.currentHotel.hotCode,
        fromUTC
      ));
      this.notifService.setMessage(`LoadHistory demandé pour l'hôtel ${this.currentHotel.name}`, "OperaCloud Compta", false);
    }
    catch(ex) {
      console.log(ex);
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de LoadHistory : ${firstProp}`, "OperaCloud Compta", true);
            break;
        }
      }
    }
    finally
    {
      this.loadingHisto = false;
    }
  }

  validate(hotelCreds : HotelCredentialsPmsLink) : boolean
  {
    return !!(hotelCreds != null
      && hotelCreds.hotCode
      && hotelCreds.hotelCodeSpecA
      && hotelCreds.loginSpecA
      && hotelCreds.operaCloudAppKey
      && hotelCreds.operaCloudBaseUrl
      && hotelCreds.operaCloudClientId
      && hotelCreds.operaCloudClientSecret
      && hotelCreds.operaCloudExternalSystemCode
      && hotelCreds.operaCloudHotelId
      && hotelCreds.operaCloudPassword
      && hotelCreds.operaCloudTenant
      && hotelCreds.operaCloudUserName
      && hotelCreds.pwdSpecA);
  }

  validateCompta(hotelCreds : HotelCredentialsCompta) : boolean
  {
    return !!(hotelCreds != null
      && hotelCreds.hotCode
      && hotelCreds.operaCloudAppKey
      && hotelCreds.operaCloudBaseUrl
      && hotelCreds.operaCloudClientId
      && hotelCreds.operaCloudClientSecret
      && hotelCreds.operaCloudExternalSystemCode
      && hotelCreds.operaCloudHotelId
      && hotelCreds.operaCloudPassword
      && hotelCreds.operaCloudUserName);
  }
  
  async start() : Promise<void> {
    if(!this.hotelCredentialsPmsLinkIsSaved || this.hotelCredentialsPmsLink.enabled) {
      return;
    }

    this.startingPmsLink = true;
    try
    {
      await firstValueFrom(this.operaCloudPmsLinkService.apiAdminStartHotCodePut(this.currentHotel.hotCode));
      await this.loadData();
      this.notifService.setMessage(`Activation de l'hôtel ${this.currentHotel.name} réussie`, "OperaCloud PmsLink", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de Start : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.startingPmsLink = false;
    }
  }

  async stop() : Promise<void> {
    if(!this.hotelCredentialsPmsLinkIsSaved || !this.hotelCredentialsPmsLink.enabled) {
        return;
      }

    this.stoppingPmsLink = true;
    try
    {
      await firstValueFrom(this.operaCloudPmsLinkService.apiAdminStopHotCodePut(this.currentHotel.hotCode));
      await this.loadData();
      this.notifService.setMessage(`Arrêt de l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud PmsLink", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de Stop : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.stoppingPmsLink = false;
    }
  }
  
  async startCompta() : Promise<void> {
    if(!this.hotelCredentialsComptaIsSaved || this.hotelCredentialsCompta.enabled) {
      return;
    }

    this.startingCompta = true;
    try
    {
      await firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeStartPut(this.currentHotel.hotCode));
      await this.loadData();
      this.notifService.setMessage(`Activation de l'hôtel ${this.currentHotel.name} réussie`, "OperaCloud Compta", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de Start : ${firstProp}`, "OperaCloud Compta", true);
            break;
        }
      }
    }
    finally
    {
      this.startingCompta = false;
    }
  }

  async stopCompta() : Promise<void> {
    if(!this.hotelCredentialsComptaIsSaved || !this.hotelCredentialsCompta.enabled) {
      return;
    }

    this.stoppingCompta = true;
    try
    {
      await firstValueFrom(this.operaCloudComptaService.apiAdminHotCodeStopPut(this.currentHotel.hotCode));
      await this.loadData();
      this.notifService.setMessage(`Arrêt de l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud Compta", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de Stop : ${firstProp}`, "OperaCloud Compta", true);
            break;
        }
      }
    }
    finally
    {
      this.stoppingCompta = false;
    }
  }

  async reloadReservation(resaId: string) : Promise<void> {
    if(!this.hotelCredentialsPmsLinkIsSaved || !resaId) {
      return;
    }

    this.reloadingReservation = true;
    try
    {
      await firstValueFrom(this.operaCloudPmsLinkService.apiAdminLoadreservationHotCodePost(this.currentHotel.hotCode, resaId));
      this.notifService.setMessage(`LoadReservation de la résa ${resaId} pour l'hôtel ${this.currentHotel.name} réussi`, "OperaCloud PmsLink", false);
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de LoadReservation : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.reloadingReservation = false;
    }
  }

  async getReservation(resaId: string) : Promise<void> {
    if(!this.hotelCredentialsPmsLinkIsSaved || !resaId) {
      return;
    }

    this.gettingReservation = true;
    try
    {
      var json = await firstValueFrom(this.operaCloudPmsLinkService.apiAdminReservationHotCodePmsReservationIdGet(this.currentHotel.hotCode, resaId));
      this.dialogJsonData = json;
    }
    catch(ex) {
      var firstProp;
      for(var key in ex.error.errors) {
        if(ex.error.errors.hasOwnProperty(key)) {
            firstProp = ex.error.errors[key];
            console.log(firstProp);
            this.notifService.setMessage(`Erreur de ReadReservation : ${firstProp}`, "OperaCloud PmsLink", true);
            break;
        }
      }
    }
    finally
    {
      this.gettingReservation = false;
    }
  }

  copyToClipboard(data) : void {
    this.clipboard.copy(data);
  }

}
