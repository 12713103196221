<div class="colonne">
  <div class="scroll" #scroll>
    <div class="celluleannee" [style.width.px]="tailleDivScroll * 2">
      <ng-container *ngFor="let moisAnnee of monthRange">
        <app-calendrier-mensuel
          [events]="events"
          [Mois]="moisAnnee.month"
          [Annee]="moisAnnee.year"
          [ngClass]="{
            moisimpaire: moisAnnee.month % 2 === 1,
            moispaire: moisAnnee.month % 2 === 0
          }"
        ></app-calendrier-mensuel>
      </ng-container>
    </div>
  </div>
  <div class="casedescription">
    <div *ngFor="let event of evenementTrier" class="intitule">
      <div
        class="couleur"
        [ngStyle]="{ 'background-color': event.color }"
      ></div>
      <div class="label">{{ event.label }}</div>
    </div>
  </div>
</div>
