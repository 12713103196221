<p-card *ngIf="hotelNotIntialized">
    <div>
        Il faut initialisé l'hôtel <span class="font-semibold text-pink-900">{{currentHotel.name }} </span> et créer ses
        produits
    </div>
</p-card>

<p-tabView styleClass="tabview-custom" *ngIf="!hotelNotIntialized && !!allPricers">


    <!-- Grille BAR -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-table mr-1"></i>
            <span>Grilles Tarifaires BAR</span>
        </ng-template>

        <app-grille-bar [allPricers]="allPricers" (OnRealodData)="RealodData($event)"></app-grille-bar>
    </p-tabPanel>

    <!-- GRILLE SPECIALES -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-table  mr-1"></i>
            <span>Griles Tarifaires Spéciales</span>
        </ng-template>
        <app-grille-bar [allPricers]="allPricers" (OnRealodData)="RealodData($event)"
            [isGrilleSpeciale]="true"></app-grille-bar>
    </p-tabPanel>

    <!-- PRICERS -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-sliders-v  mr-1"></i>
            <span>Pricers</span>
        </ng-template>
        <app-pricers-avances [allPricers]="allPricers" (OnRealodData)="RealodData($event)"></app-pricers-avances>
    </p-tabPanel>

</p-tabView>

<p-card *ngIf="!hotelNotIntialized && !allPricers && dataError">
    <div style="display: grid; grid-template-columns: auto auto 1fr; align-items: center; column-gap: 10px;">
        <span class="material-icons-outlined" style="font-size: 2.1em;">
            sentiment_dissatisfied
        </span>
        <span style="font-size: 1.2em;">
            Erreur de chargement des données
        </span>
    </div>
</p-card>

<p-confirmDialog [style]="{width: '30vw'}" key="pricerConfirmation"></p-confirmDialog>
