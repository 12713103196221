import { DatesValidation, ModelDate, Periode, PricerGrille } from "../../services/model/models";

// Modele de donnée pour le formulaire dynmic
export class PeriodeDtvForm {
    plageFix: {
        debut: Date | null;
        fin: Date | null;
    };
    plageGlis: {
        jourPlus: number | null;
        jourGlissant: number | null;
    };
    jourSemaine: {
        mon: boolean;
        tue: boolean;
        weds: boolean;
        thur: boolean;
        fri: boolean;
        sat: boolean;
        sun: boolean;
    };
    datesExclus: Date[];
}

export class PeriodeDtvFormList {
    periodes: Array<PeriodeDtvForm>
}



function GetdatesExclus(datesExclus: ModelDate[]): Date[] {

    let result: Date[] = [];
    if (!datesExclus) return result;

    for (let mDt of datesExclus) {
        result.push(new Date(mDt.year, mDt.month - 1, mDt.day));
    }
    return result;

}

export function GetDateValidationFormValue(periodes: Array<Periode>): PeriodeDtvFormList {

    let value: PeriodeDtvFormList = {
        periodes: []
    };
    for (let dtv of periodes) {
        if (!dtv) continue;

        let vl: PeriodeDtvForm = {
            plageFix: {
                debut: dtv.debut ? new Date(dtv.debut.year, dtv.debut.month - 1, dtv.debut.day) : null,
                fin: dtv.fin ? new Date(dtv.fin.year, dtv.fin.month - 1, dtv.fin.day) : null,
            },
            plageGlis: {
                jourPlus: dtv.jourPlus ? dtv?.jourPlus : null,
                jourGlissant: dtv.jourGlissant ? dtv.jourGlissant : null
            },
            jourSemaine: {
                mon: dtv.mon,
                tue: dtv.tue,
                weds: dtv.weds,
                thur: dtv.thur,
                fri: dtv.fri,
                sat: dtv.sat,
                sun: dtv.sun
            },
            datesExclus: GetdatesExclus(dtv.datesExclues)
        }

        value.periodes.push(vl);
    }
    return value;
}


function toArrayModelDate(dtList: Array<Date> | null): Array<ModelDate> | null {
    if (!dtList) return null;

    let result: Array<ModelDate> = [];
    for (const dt of dtList) {
        result.push(toModelDate(dt))
    }

    return result;
}

function toModelDate(dt: Date | null): ModelDate | null {
    if (!dt) return null;

    let md: ModelDate = {
        day: dt.getDate(), month: dt.getMonth() + 1, year: dt.getFullYear()
    }
    return md;
}

export function GetDateValidationCmdValue(periodeForm: PeriodeDtvFormList): DatesValidation {
    let periodes: Array<Periode> = []; // ne pas confondre  periode de dates   et pricerPeriode

    for (let prdf of periodeForm.periodes) {
        let newprd: Periode = {
            debut: prdf.plageFix?.debut ? toModelDate(prdf.plageFix.debut) : null,

            fin: prdf.plageFix?.fin ? toModelDate(prdf.plageFix.fin) : null,
            datesExclues: toArrayModelDate(prdf.datesExclus),
            jourGlissant: prdf.plageGlis.jourGlissant,
            jourPlus: prdf.plageGlis.jourPlus,
            fri: prdf.jourSemaine?.fri,
            mon: prdf.jourSemaine?.mon,
            sat: prdf.jourSemaine?.sat,
            sun: prdf.jourSemaine?.sun,
            thur: prdf.jourSemaine?.thur,
            tue: prdf.jourSemaine?.tue,
            weds: prdf.jourSemaine?.weds
        }
        periodes.push(newprd)
    }
    let result: DatesValidation = {
        periodes: periodes
    };
    return result;
}
