<div *ngIf="field.label" class="title-group mb-1">
    {{ field.label }}
</div>

<ng-container *ngIf="!formArray">
    <p-tabView>
        <p-tabPanel *ngFor=" let item of tabViewItems" [header]="item">
            <app-form-renderer [formGroup2]="formGroup" [tabviewItem]="item"> </app-form-renderer>
        </p-tabPanel>
    </p-tabView>

    <div class="mb-2">
        <span class="p-invalid">{{ formGroup?.getErrorsMessage() }} </span>
    </div>

    <hr *ngIf="isLineSeparatorVisible()" />
</ng-container>
