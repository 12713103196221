<div *ngIf="dataLoaded">
  <div>
    <span>Date du scrape : {{prices.scrapDate| date:'dd MMMM yyyy HH:mm' }}</span>
  </div>
    <div *ngFor="let pricesByStd of prices.rateShopperPriceForTypeStd">
        <h3> {{pricesByStd.roomTypeStd}} </h3>

        <table class="p-datatable" *ngIf="dataLoaded">
            <thead class="p-datatable-thead">
                <tr>
                    <th style="width: 300px;" class="p-text-center">Hotels</th>
                    <th *ngFor="let date of dateRanges">
                        {{getFormatTh(date)}}
                    </th>
                </tr>

            </thead>

            <tbody class="p-datatable-tbody">
                <tr>
                    <td style="width: 300px;">
                        {{pricesByStd.roomTypePrices.hotelName}}
                    </td>
                    <td *ngFor="let price of pricesByStd.roomTypePrices.rateShopperPrices"
                        [class.isPromo]="isPromo(price)" [class.isFull]="isFull(price)">
                        {{getPrice(price)}}
                    </td>
                </tr>
                <tr *ngFor="let priceComp of pricesByStd.compRoomTypePrices">
                    <td style="width: 300px;">
                        {{priceComp.hotelName}}
                    </td>
                    <td *ngFor="let price of priceComp.rateShopperPrices" [class.isPromo]="isPromo(price)"
                        [class.isFull]="isFull(price)">
                        {{getPrice(price)}}
                    </td>
                </tr>
            </tbody>


        </table>


    </div>
</div>
