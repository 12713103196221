<div class="grid-container">
    <div class="pricerTree">
        <p-tree [value]="pricerTreeNodeList" selectionMode="single" [(selection)]="selectedTreeNodePricer"
            (selectionChange)="selectionChanged()"></p-tree>
    </div>
    <div class="pricerContent">

        <p-toolbar>
            <div class="p-toolbar-group-start">
                <p-splitButton [label]="selectedPricer?.libelle" icon="pi pi-pencil" [model]="menuItemPrc"
                    class="mr-3 ml-3" [style]="{'width':'300px'}" styleClass="p-button-success"
                    [menuStyle]="{'min-width':'400px'}"
                    [disabled]="selectedPricer?.isRack || selectedPricer?.isSpecial "
                    (onClick)="displayViewUpdateCurrentPricer()">
                </p-splitButton>
            </div>

        </p-toolbar>

        <div>

            <div class="infosPricer mt-3 ml-3">
                <ng-container *ngIf="niveau">
                    <div class=" mr-3" (click)="displaydatesValidationForm=true" style="cursor: pointer;">
                        Période de validité
                        <i class="pi pi-calendar ml-2" style="font-size: 1rem"></i>
                    </div>
                    <div>
                        <span *ngFor=" let desc of niveau?.datesValidation?.description; let i = index">
                            [ {{desc}} ] <span *ngIf=" i < niveau?.datesValidation?.description.length-1">-</span>
                        </span>

                    </div>
                </ng-container>
                <div class="mr-3">Règle d'arrondi
                </div>
                <div *ngIf="!selectedPricer?.isRack && !selectedPricer?.isSpecial">
                    <div class="mt-3">
                        <div>
                            <span class="mr-3">Formule:</span> <span>{{selectedPricer?.formule?.description}}</span>
                        </div>

                        <div class="mt-2" *ngIf="this.selectedPricer?.formule?.levierNumerique">
                            <span class="mr-3">Levier numerique:</span>
                            <span>{{selectedPricer?.formule?.valeursAutoriseesCalculees}}</span>
                            <span class="deleteLevier ml-3 pi pi-times" (click)="confirmDeleteLevierNum()"></span>
                            <p-confirmPopup key="delLevierNum"></p-confirmPopup>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3 ml-3" style="height: calc(100vh - 400px);">
                <div class="title1">Produits concernés</div>
                <p-scrollPanel *ngIf="displayProduitScrollPanel"
                    [style]="{ width: '100%', height: '100%' }">
                    <div *ngFor="let prdvu of selectedPricer?.pricerProduits; let i = index" class="item-produit"
                        [ngStyle]="{'background-color': i % 2 !== 0 ? 'var(--surface-100)' : 'transparent'}">
                        <div [ngClass]="prdvu?.appliquerLaFormuleProduit?'appliquerLaFormuleProduit':''">
                            {{prdvu?.produitVenduUnit?.libelle}}
                            <!-- <span class="ml-4">{{prdvu?.appliquerLaFormuleProduit}}</span> -->
                        </div>
                    </div>
                </p-scrollPanel>

            </div>
        </div>
    </div>
</div>

<div *ngIf="displaydatesValidationForm">
    <p-dialog [(visible)]="displaydatesValidationForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Config de la période de validité du pricer {{ selectedPricer.libelle}}
        </p-header>
        <app-form-dynamic [formModel]="datesValidationForm" [formValue]="dateValidationFormValue"
            (OnCancel)="displaydatesValidationForm=false" (OnValidate)="SetDatesValidationGrille($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayUpsertPricerDeriveForm">
    <p-dialog [(visible)]="displayUpsertPricerDeriveForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            <span *ngIf="pricerModeUpdate">Modifier le pricer dérivé {{selectedPricer.libelle}} </span>
            <span *ngIf="!pricerModeUpdate">Créer un pricer dérivé</span>
        </p-header>
        <app-form-dynamic [formModel]="upsertPricerDeriveForm" [formValue]="upsertPricerDeriveFormInitValue" #formPricer
            (OnCancel)="displayUpsertPricerDeriveForm=false" (OnValidate)="upserPricerDerive($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayLevierNumerique">
    <p-dialog [(visible)]="displayLevierNumerique" [modal]="true" [style]="{width: '600px', minheight:'400px' }">
        <p-header>
            Levier numérique pour le pricer {{selectedPricer.libelle}}
        </p-header>
        <app-form-dynamic [formModel]="levierNumForm" [formValue]="levierNumFormValue"
            (OnCancel)="displayLevierNumerique=false" (OnValidate)="upserLevierNumerique($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayProduitPropreFormule">
    <p-dialog [(visible)]="displayProduitPropreFormule" [modal]="true" [style]="{width: '600px', minheight:'300px' }">
        <p-header>
            Produits appliquant leur propre formule pour le pricer
            <div> {{selectedPricer.libelle}}</div>
        </p-header>
        <app-form-dynamic [formModel]="produitPropreFormuleForm" [formValue]="produitPropreFormuleFormValue"
            (OnCancel)="displayProduitPropreFormule=false" (OnValidate)="updatePricerPrdvuFormulePropre($event)">
        </app-form-dynamic>
    </p-dialog>
</div>
