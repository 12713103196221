/**
 * Spot Analyse Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AnalyseIndicateurs } from '../model/analyseIndicateurs';
import { ImecResponse } from '../model/imecResponse';
import { IndicateursByRefTempsResponse } from '../model/indicateursByRefTempsResponse';
import { NotifIndicators } from '../model/notifIndicators';
import { TrappesDerniersJoursIndicateurs } from '../model/trappesDerniersJoursIndicateurs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IndicatorsService {

    protected basePath = 'https://services-lx.spotpilot.com/analyse-v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Indicateurs TO,PM, RevPar, H...
     * 
     * @param hotCode 
     * @param from Date de début
     * @param to Date de fin
     * @param month Si [From] et [To] sont vides , alors la plage de calcul est le mois [Month]  (un seule mois)
     * @param year Si [From] , [To]  et [Month] sont vides , alors  la plage de calcul est le l&#x27;année [Year]
     * @param cumulDates Si false résultat détaillé par date (true par défaut)
     * @param detailMois Si true et ( year!&#x3D;null et  from&#x3D;null,to&#x3D;null, Month&#x3D;null et cumulDate&#x3D;true) alors résultat sera détaillé par mois
     * @param ht Afficher les prix en HT , par defaut TTC
     * @param obsDateTime Date d&#x27;observation: Il faut une date pas une dateTime  Exp: pour RefDateTime&#x3D;01/10/2020 , etat des indicateurs avant 02/10/2020
     * @param detailCanaux Si true résultat détaillé par canal (false par défaut)
     * @param detailRoomtypes Si true résultat détaillé par type de chambre
     * @param reftTempsList Liste des refTemps séparés par ; : an_x,  f_x,  pickupJx,  pickupJx_an_y,  pickupForJx,  pickupForJx_an_y,  PickupDateRange,  pickupDateRange_An_x , budgetAn_
     * @param pickupDateRefStart Début de date reference pour le PickupDateRange
     * @param pickupDateRefEnd Fin de date reference pour le PickupDateRange
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsHotCodeByRefTempsGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, detailMois?: boolean, ht?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, reftTempsList?: string, pickupDateRefStart?: Date, pickupDateRefEnd?: Date, observe?: 'body', reportProgress?: boolean): Observable<IndicateursByRefTempsResponse>;
    public apiIndicatorsHotCodeByRefTempsGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, detailMois?: boolean, ht?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, reftTempsList?: string, pickupDateRefStart?: Date, pickupDateRefEnd?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IndicateursByRefTempsResponse>>;
    public apiIndicatorsHotCodeByRefTempsGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, detailMois?: boolean, ht?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, reftTempsList?: string, pickupDateRefStart?: Date, pickupDateRefEnd?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IndicateursByRefTempsResponse>>;
    public apiIndicatorsHotCodeByRefTempsGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, detailMois?: boolean, ht?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, reftTempsList?: string, pickupDateRefStart?: Date, pickupDateRefEnd?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsHotCodeByRefTempsGet.');
        }














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('Month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (cumulDates !== undefined && cumulDates !== null) {
            queryParameters = queryParameters.set('CumulDates', <any>cumulDates);
        }
        if (detailMois !== undefined && detailMois !== null) {
            queryParameters = queryParameters.set('DetailMois', <any>detailMois);
        }
        if (ht !== undefined && ht !== null) {
            queryParameters = queryParameters.set('Ht', <any>ht);
        }
        if (obsDateTime !== undefined && obsDateTime !== null) {
            queryParameters = queryParameters.set('ObsDateTime', <any>obsDateTime.toISOString());
        }
        if (detailCanaux !== undefined && detailCanaux !== null) {
            queryParameters = queryParameters.set('DetailCanaux', <any>detailCanaux);
        }
        if (detailRoomtypes !== undefined && detailRoomtypes !== null) {
            queryParameters = queryParameters.set('DetailRoomtypes', <any>detailRoomtypes);
        }
        if (reftTempsList !== undefined && reftTempsList !== null) {
            queryParameters = queryParameters.set('ReftTempsList', <any>reftTempsList);
        }
        if (pickupDateRefStart !== undefined && pickupDateRefStart !== null) {
            queryParameters = queryParameters.set('PickupDateRefStart', <any>pickupDateRefStart.toISOString());
        }
        if (pickupDateRefEnd !== undefined && pickupDateRefEnd !== null) {
            queryParameters = queryParameters.set('PickupDateRefEnd', <any>pickupDateRefEnd.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IndicateursByRefTempsResponse>('get',`${this.basePath}/api/indicators/${encodeURIComponent(String(hotCode))}/byRefTemps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Vide le cache des données stockées en mémoire par l&#x27;Api (obsolète)
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsHotCodeClearcachePost(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiIndicatorsHotCodeClearcachePost(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiIndicatorsHotCodeClearcachePost(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiIndicatorsHotCodeClearcachePost(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsHotCodeClearcachePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/indicators/${encodeURIComponent(String(hotCode))}/clearcache`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Indicateurs TO,PM, RevPar, H...
     * 
     * @param hotCode 
     * @param from Date de début
     * @param to Date de fin
     * @param month Si [From] et [To] sont vides , alors la plage de calcul est le mois [Month]  (un seule mois)
     * @param year Si [From] , [To]  et [Month] sont vides , alors  la plage de calcul est le l&#x27;année [Year]
     * @param cumulDates Si false résultat détaillé par date (true par défaut)
     * @param obsDateTime Date d&#x27;observation: Il faut une date pas une dateTime  Exp: pour RefDateTime&#x3D;01/10/2020 , etat des indicateurs avant 02/10/2020
     * @param detailCanaux Si true résultat détaillé par canal (false par défaut)
     * @param detailRoomtypes Si true résultat détaillé par type de chambre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsHotCodeGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AnalyseIndicateurs>;
    public apiIndicatorsHotCodeGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnalyseIndicateurs>>;
    public apiIndicatorsHotCodeGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnalyseIndicateurs>>;
    public apiIndicatorsHotCodeGet(hotCode: string, from?: Date, to?: Date, month?: number, year?: number, cumulDates?: boolean, obsDateTime?: Date, detailCanaux?: boolean, detailRoomtypes?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsHotCodeGet.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('Month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (cumulDates !== undefined && cumulDates !== null) {
            queryParameters = queryParameters.set('CumulDates', <any>cumulDates);
        }
        if (obsDateTime !== undefined && obsDateTime !== null) {
            queryParameters = queryParameters.set('ObsDateTime', <any>obsDateTime.toISOString());
        }
        if (detailCanaux !== undefined && detailCanaux !== null) {
            queryParameters = queryParameters.set('DetailCanaux', <any>detailCanaux);
        }
        if (detailRoomtypes !== undefined && detailRoomtypes !== null) {
            queryParameters = queryParameters.set('DetailRoomtypes', <any>detailRoomtypes);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AnalyseIndicateurs>('get',`${this.basePath}/api/indicators/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * indicateurs Mentée en charge :  Historique des indicateurs sur une periode (dateFrom,DateTo) depuis dateStart et DateEnd
     * 
     * @param hotCode 
     * @param dateFrom Date debut séjour
     * @param dateTo Date fin séjour
     * @param dateStart Début de date reference
     * @param dateEnd Fin de date reference
     * @param ht Afficher les prix en HT , par defaut TTC
     * @param detailCanaux Si true résultat détaillé par canal (false par défaut)
     * @param detailRoomtypes Si true résultat détaillé par type de chambre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsHotCodeImecGet(hotCode: string, dateFrom: Date, dateTo: Date, dateStart: Date, dateEnd: Date, ht?: boolean, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ImecResponse>;
    public apiIndicatorsHotCodeImecGet(hotCode: string, dateFrom: Date, dateTo: Date, dateStart: Date, dateEnd: Date, ht?: boolean, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImecResponse>>;
    public apiIndicatorsHotCodeImecGet(hotCode: string, dateFrom: Date, dateTo: Date, dateStart: Date, dateEnd: Date, ht?: boolean, detailCanaux?: boolean, detailRoomtypes?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImecResponse>>;
    public apiIndicatorsHotCodeImecGet(hotCode: string, dateFrom: Date, dateTo: Date, dateStart: Date, dateEnd: Date, ht?: boolean, detailCanaux?: boolean, detailRoomtypes?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsHotCodeImecGet.');
        }

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling apiIndicatorsHotCodeImecGet.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling apiIndicatorsHotCodeImecGet.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling apiIndicatorsHotCodeImecGet.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling apiIndicatorsHotCodeImecGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('DateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('DateTo', <any>dateTo.toISOString());
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('DateStart', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('DateEnd', <any>dateEnd.toISOString());
        }
        if (ht !== undefined && ht !== null) {
            queryParameters = queryParameters.set('Ht', <any>ht);
        }
        if (detailCanaux !== undefined && detailCanaux !== null) {
            queryParameters = queryParameters.set('DetailCanaux', <any>detailCanaux);
        }
        if (detailRoomtypes !== undefined && detailRoomtypes !== null) {
            queryParameters = queryParameters.set('DetailRoomtypes', <any>detailRoomtypes);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ImecResponse>('get',`${this.basePath}/api/indicators/${encodeURIComponent(String(hotCode))}/imec`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pickups des derniers jours pour une date donnée
     * 
     * @param hotCode 
     * @param date Date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsHotCodeLastpickupsGet(hotCode: string, date?: Date, observe?: 'body', reportProgress?: boolean): Observable<TrappesDerniersJoursIndicateurs>;
    public apiIndicatorsHotCodeLastpickupsGet(hotCode: string, date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrappesDerniersJoursIndicateurs>>;
    public apiIndicatorsHotCodeLastpickupsGet(hotCode: string, date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrappesDerniersJoursIndicateurs>>;
    public apiIndicatorsHotCodeLastpickupsGet(hotCode: string, date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsHotCodeLastpickupsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('Date', <any>date.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TrappesDerniersJoursIndicateurs>('get',`${this.basePath}/api/indicators/${encodeURIComponent(String(hotCode))}/lastpickups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Calcul sur les reserveation recues (annulation et création)   Pour calculer les resas recues aujourd&#x27;hui pour une date de séjour : date &#x3D;date de sejour  -- RftdateFrom &#x3D; RftdateTo &#x3D; date aujourdhui  Pour calculer les resas recues depuis une semaine jour par jour  pour une date de séjour : date &#x3D;date de sejour  --  RftdateFrom &#x3D; date aujourdhui -7 et  RftdateTo &#x3D; date aujourdhui   Pour calculer les resas recues aujourd&#x27;hui (indicateurLive)  date &#x3D;null  -- RftdateFrom &#x3D; RftdateTo &#x3D; date aujourdhui  todo: implementer le cumulDate
     * 
     * @param hotCode CodeHotel
     * @param rftdateFrom debut :date de réception
     * @param rftdateTo fin :date de réception
     * @param date Date de séjour
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiIndicatorsNotifHotCodeGet(hotCode: string, rftdateFrom: Date, rftdateTo: Date, date?: Date, observe?: 'body', reportProgress?: boolean): Observable<NotifIndicators>;
    public apiIndicatorsNotifHotCodeGet(hotCode: string, rftdateFrom: Date, rftdateTo: Date, date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotifIndicators>>;
    public apiIndicatorsNotifHotCodeGet(hotCode: string, rftdateFrom: Date, rftdateTo: Date, date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotifIndicators>>;
    public apiIndicatorsNotifHotCodeGet(hotCode: string, rftdateFrom: Date, rftdateTo: Date, date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsNotifHotCodeGet.');
        }

        if (rftdateFrom === null || rftdateFrom === undefined) {
            throw new Error('Required parameter rftdateFrom was null or undefined when calling apiIndicatorsNotifHotCodeGet.');
        }

        if (rftdateTo === null || rftdateTo === undefined) {
            throw new Error('Required parameter rftdateTo was null or undefined when calling apiIndicatorsNotifHotCodeGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('Date', <any>date.toISOString());
        }
        if (rftdateFrom !== undefined && rftdateFrom !== null) {
            queryParameters = queryParameters.set('RftdateFrom', <any>rftdateFrom.toISOString());
        }
        if (rftdateTo !== undefined && rftdateTo !== null) {
            queryParameters = queryParameters.set('RftdateTo', <any>rftdateTo.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<NotifIndicators>('get',`${this.basePath}/api/indicators/notif/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
