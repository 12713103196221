import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Observable, lastValueFrom } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import {
    User,
    UserForUpdate,
    UserInfos,
    Role,
    UserSpotAuthorization,
    RoleAction,
    HotelAction,
    HotelAuth,
    UserForCreation,
    RoleForUpdate,
    Group,
    RoleByHotel,
} from '../services/model/models';
import { UserService, GroupService, RoleService, HotelService } from '../services/api/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import * as moment from 'moment';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { RoleAuthoriz, HotelsAuthoriz } from '../services/model/roleAuthoriz';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
    providers: [MessageService],
})
export class UsersComponent implements OnInit {
    @ViewChild('inputText') inputText: ElementRef;
    @ViewChild('inputFile') inputFile: ElementRef;

    users: Array<User>;
    selectedUsers: User;
    cols: any[] = [];
    searchedUser: string;
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    lineValue: User = {};
    userToUpdate: UserForUpdate = {
        id: '',
        azureADB2CId: '',
        displayName: '',
        username: '',
        givenName: '',
        email: '',
        mobilePhone: null,
        avatarProfile: '',
        familyName: '',
        expirationDate: null,
        accessDate: null,
    };
    userForCreation: UserForCreation = {
        displayName: '',
        username: '',
        givenName: '',
        email: '',
        mobilePhone: null,
        avatarProfile: '',
        familyName: '',
        roles: [],
        hotels: [],
        groups: [],
        expirationDate: null,
        accessDate: null,
    };
    avatarProfile: string | ArrayBuffer;
    avatarSrc: any;
    data: UserInfos[];
    infosUser: User = {};
    userAuthoriz: UserSpotAuthorization = {};
    displayUserForm: boolean;
    displayUser: boolean;
    displayGroup: boolean;
    displayRole: boolean;
    displayResetPwd: boolean;
    displayPwd: boolean;
    displayResetPwdResult: boolean;
    resettingPwd: boolean;
    displayEnabledDisableUser: boolean;
    newTemporaryPassword: any;
    newPassword: any;
    password: any;
    groups: Group[];
    roles: Role[] = [];
    roleList: Role[];
    userNameList: string[] = [];
    emailList: string[] = [];
    hotels: HotelAuth[];
    selectedRoles: any;
    selectedHotels: any;
    selectedGroup: Group = {
        id: '',
        name: '',
        description: '',
        roles: [],
        hotels: [],
    };
    isMainUser: boolean = true;
    isUserNameNew: boolean = false;
    isFormValidateRole: boolean = false;
    isEditLoad: boolean = false;
    isCopied: boolean = false;
    selectedUserGroup: Group[];
    selectedUserHotels: HotelAction[];
    selectedUserRoles: RoleAction[];
    roleToCreation: RoleForUpdate = {
        id: '',
        name: '',
        description: '',
    };
    newRole: RoleForUpdate;
    userGroups: Group[];
    userHotels: HotelAction[];
    userRoles: RoleAction[];
    hotelsAutoriz: HotelsAuthoriz[];
    groupsAutoriz: Group[];
    rolesAutoriz: RoleAuthoriz[];
    isDefautAvatarProfile: boolean = true;
    rolesFormInfoService: string[];
    filterValue: string;
    private subs = new SubSink();
    isUserAutoriz: any;
    cacheData: any;
    isEmailError: boolean;
    selectedRolesAuthoriz: RoleAuthoriz;
    isUserPasswordLoaded: boolean;
    displayListeRoles: boolean = false;
    colsRole: { field: string; header: string; width: string }[];
    initUsers: boolean;
    saveLoading: boolean = false;

    constructor(
        private userService: UserService,
        private groupService: GroupService,
        private roleService: RoleService,
        private hotelService: HotelService,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private userInfoService: UserInfoService,
        private notifService: NotificationsService,
        private clipboard: Clipboard
    ) {
        this.breadcrumbService.setItems([{ label: 'adminusers/users' }]);
    }

    ngOnInit(): void {
        this.initModels();
        this.setViewBool('isMainUser');
        this.loading = true;
        this.getUsers('', false, {}, false, true);
        this.loading = false;
        this.userInfoService.getUserRoles$().subscribe((roles) => {
            this.rolesFormInfoService = roles;
            console.log(roles);
        });
    }
    async initModels() {
        this.initUsers = true;
        this.selectedRolesAuthoriz = {};
        this.isEmailError = false;
        this.filterValue = '';
        this.searchedUser = '';
        this.cacheData = {
            hotelsAutoriz: [],
            userHotels: [],
            groups: [],
            roles: [],
        };
        this.cols = [
            { field: 'avatarProfile', header: 'Avatar', width: '10px' },
            { field: 'familyName', header: 'Nom', width: '40px' },
            { field: 'givenName', header: 'Prénom', width: '40px' },
            { field: 'username', header: 'Login', width: '40px' },
            { field: 'email', header: 'Email', width: '40px' },
            { field: 'displayName', header: "Nom D'affichage", width: '40px' },
        ];
        this.newRole = {
            id: '',
            name: '',
            description: '',
        };
        const initData = await this.getRoleHotelGroups();
    }
    async getRoleHotelGroups() {
        this.roles = await lastValueFrom(this.getRoles());
        this.hotels = await lastValueFrom(this.getHotels());
        this.groups = await lastValueFrom(this.getGroups());
        this.selectedGroup = this.groups[0];
    }

    isAdmin(): boolean {
        if (
            this.rolesFormInfoService &&
            this.rolesFormInfoService.find((r) => r == 'Administrator')
        ) {
            return true;
        }
        return false;
    }
    getUsers(
        user: string,
        isNewUser: boolean,
        formResult,
        isCheckUserName?: boolean,
        isInitUser?: boolean
    ) {
        //this.loading = true;
        try {
            this.subs.sink = this.userService.apiUsersGet(user).subscribe((users) => {
                this.userNameList = users?.map((e) => e.username);
                if (isInitUser) {
                    this.emailList = users?.map((e) => e.email);
                    console.log('email list: ' + this.emailList);
                }
                if (isNewUser) {
                    this.checkIfExistUser(formResult);
                } else if (Object.entries(formResult).length == 0 && !isCheckUserName) {
                    this.users = users;
                }
                //this.loading = false;
                console.log(this.users);
            });
        } catch (error) {
            this.saveLoading = false;
        }

    }
    getInfosUsers(idUser): Observable<User> {
        return this.userService.getUser(idUser);
    }
    getAuthorization(idUser): Observable<UserSpotAuthorization> {
        return this.userService.apiUsersAuthorizationUserIdGet(idUser);
    }
    hotelsAutorizFilter(
        value: string,
        objectName: string,
        prop1: string,
        prop2?: string
    ) {
        let filtredArray = this.cacheData[objectName].filter(
            (elem) =>
                elem[prop1]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0 ||
                elem[prop2]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
        );
        let sortedData = filtredArray?.sort((a, b) => {
            if (a[prop1] < b[prop1]) {
                return -1;
            }
            if (a[prop1] > b[prop1]) {
                return 1;
            }
            return 0;
        });
        this[objectName] = sortedData;
    }
    //Rafraichissement d’un utilisateur après avoir les détails des infos de l’utilisateur en question
    refreshUserInfos() {
        const selectedRoles: RoleAction[] = this.infosUser.roles || [];
        const selectedHotels: HotelAction[] = this.infosUser.hotels || [];
        const selectedGroup: string[] = this.infosUser.groups || [];
        let userHotels: HotelAction[] = [];
        this.selectedUserHotels = [];
        this.hotels?.forEach((hotel: HotelAuth) => {
            let userHotel: HotelAction = hotel;
            let isSelected = selectedHotels?.filter(
                (sel: HotelAction) => sel.hotCode == hotel.hotCode
            );
            if (isSelected && isSelected.length > 0) {
                userHotel.deny = isSelected[0].deny;
                this.selectedUserHotels.push(userHotel);
            } else {
                userHotel.deny = false;
            }
            userHotels.push(userHotel);
        });
        this.userHotels = userHotels?.sort((a: HotelAction, b: HotelAction) => {
            let isSelected = selectedHotels.some(
                (sel: HotelAction) => sel.hotCode == a.hotCode
            );
            if (isSelected) {
                return -1;
            } else {
                return 1;
            }
            return 0;
        });
        this.cacheData.userHotels = this.userHotels;
        let userRoles: RoleAction[] = [];
        this.selectedUserRoles = [];
        this.roles?.forEach((role: Role) => {
            let userRole: RoleAction = role;
            let isSelected = selectedRoles?.filter(
                (sel: RoleAction) => sel.id == role.id
            );
            if (isSelected && isSelected.length > 0) {
                userRole.deny = isSelected[0].deny;
                this.selectedUserRoles.push(userRole);
            } else {
                userRole.deny = false;
            }
            userRoles.push(userRole);
        });
        this.userRoles = userRoles?.sort((a: RoleAction, b: RoleAction) => {
            let isSelected = selectedRoles.some((sel: RoleAction) => sel.id == a.id);
            if (isSelected) {
                return -1;
            }
            return 1;
        });
        this.selectedUserGroup = this.groups?.filter((group?: Group) => {
            return (
                (selectedGroup?.filter((g) => {
                    return group.id == g;
                })).length != 0
            );
        });
        let otherGroups = this.groups?.filter((group?: Group) => {
            return (
                (selectedGroup?.filter((g) => {
                    return group.id == g;
                })).length == 0
            );
        });
        this.userGroups = this.selectedUserGroup?.concat(otherGroups);
    }
    getGroups(): Observable<Group[]> {
        return this.groupService.apiGroupsGet();
    }
    checkIfExistUser(formResult) {
        let username = formResult?.username.value || '';
        let email = formResult?.email.value || '';
        if (
            (this.userNameList?.indexOf(username) == -1 ||
                username == this.lineValue.username) &&
            (this.emailList?.indexOf(email) == -1 || email == this.lineValue.email)
        ) {
            if (this.isEditLoad) {
                this.updateUser(formResult);
            } else {
                this.setNewUser(formResult);
            }
        } else this.saveLoading = false;

    }
    getRoles(): Observable<Role[]> {
        return this.roleService.apiRolesGet();
    }
    getHotels(): Observable<HotelAuth[]> {
        return this.hotelService.apiHotelsGet();
    }
    getRoleName = (id, prop: string, objectName: string): string => {
        let filtred = this[objectName].filter((role) => role[prop] == id);
        let name = filtred && filtred.length > 0 ? filtred[0].name : '';
        return name || '';
    };
    setUserAutorizData() {
        if (
            this.userAuthoriz.rolesByHotel &&
            this.userAuthoriz.rolesByHotel.length > 0
        ) {
            let hotelsByRole: RoleAuthoriz[] = [];
            let reduce: any = this.userAuthoriz.rolesByHotel?.reduce(
                (acc: RoleByHotel, obj: RoleByHotel) => {
                    let prop = obj.role;
                    if ((!acc[prop] && prop) || obj.allHotels) {
                        acc[prop] = [];
                    }
                    if (acc[prop] || acc[prop]?.length == 0) {
                        let hotelName = this.getRoleName(obj.hotCode, 'hotCode', 'hotels');
                        acc[prop].push({
                            hotCode: obj.hotCode,
                            name: hotelName,
                            allHotels: obj.allHotels,
                        });
                    }
                    return acc;
                },
                {}
            );
            Object.keys(reduce).forEach((prop) => {
                if (prop != 'role' && prop != 'hotCode' && prop != 'allHotels') {
                    let roleName = this.getRoleName(prop, 'id', 'roles');
                    hotelsByRole.push({ id: prop, name: roleName, hotels: reduce[prop] });
                }
            });
            this.rolesAutoriz = hotelsByRole;
            this.selectedRolesAuthoriz = this.rolesAutoriz[0];
            this.onSelectedRoleAuthoriz(this.selectedRolesAuthoriz);
        } else {
            this.rolesAutoriz = [];
        }

        /**
         * Groups Auth
         */
        let userAutGroup: Group[] = [];
        this.groups?.forEach((g: Group) => {
            if (this.userAuthoriz.groups.indexOf(g.id) >= 0) {
                userAutGroup.push({ id: g.id, name: g.name });
            }
        });
        this.groupsAutoriz = userAutGroup;
    }
    onSelectedRoleAuthoriz(selectedRolesAuthoriz: RoleAuthoriz) {
        if (selectedRolesAuthoriz) {
            if (selectedRolesAuthoriz.hotels[0]?.allHotels) {
                this.hotelsAutoriz = this.hotels;
            } else {
                this.hotelsAutoriz = selectedRolesAuthoriz.hotels;
            }
            this.filterValue = '';
        }
    }
    setUserDatas() {
        this.userRoles = this.roles;
        this.userRoles.map((user) => {
            let obj = user;
            obj.deny = false;
            return obj;
        });
        this.userHotels = this.hotels;
        this.cacheData.userHotels = [];
        this.userHotels?.forEach((element) => {
            this.cacheData.userHotels.push(element);
        });
        this.userHotels.map((user) => {
            let obj = user;
            obj.deny = false;
            return obj;
        });
        this.userGroups = this.groups;
        this.selectedUserGroup = [];
        this.selectedUserHotels = [];
        this.selectedUserRoles = [];
        this.setViewBool('displayUserForm');
    }
    transform(avatarProfile) {
        if (
            avatarProfile != undefined &&
            avatarProfile != null &&
            avatarProfile != 'string'
        ) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(avatarProfile);
        }
        return null;
    }
    get f() {
        return this.registerForm.controls;
    }
    onRowSelect(event) {
        console.log(this.selectedUsers);
    }
    async newUser() {
        this.isEditLoad = false;
        this.isUserNameNew = true;
        this.avatarSrc = null;
        this.avatarProfile = null;
        this.submitted = false;
        this.selectedUsers = {};
        this.lineValue = {};
        this.userForCreation = {
            displayName: '',
            username: '',
            givenName: '',
            email: '',
            mobilePhone: null,
            avatarProfile: '',
            familyName: '',
            roles: [],
            hotels: [],
            groups: [],
            expirationDate: null,
            accessDate: null,
        };
        this.selectedUsers = null;
        const getDatas = await this.getRoleHotelGroups();
        this.setUserDatas();
    }
    async editUser(rowData?: User, isClickLine?: boolean) {
        if (this.selectedUsers || rowData) {
            if (rowData) {
                this.selectedUsers = rowData;
            }
            let selectedUser = isClickLine ? rowData : this.selectedUsers;
            this.isEditLoad = true;
            this.isUserNameNew = false;
            this.submitted = false;
            this.lineValue = {};
            this.lineValue = selectedUser;
            if (this.lineValue) {
                let idUser = this.lineValue.id;
                this.infosUser = await lastValueFrom(this.getInfosUsers(idUser));
                this.isDefautAvatarProfile = this.infosUser.isDefautAvatarProfile;
                this.refreshUserInfos();
                this.setViewBool('displayUserForm');
            }
            this.avatarProfile = selectedUser.avatarProfile;
            this.avatarSrc = this.transform(this.lineValue.avatarProfile);
        }
    }
    async showUser() {
        this.isUserAutoriz = true;
        this.lineValue = this.selectedUsers;
        this.avatarSrc = this.transform(this.lineValue.avatarProfile);
        if (this.lineValue) {
            let idUser = this.lineValue.id;
            this.userAuthoriz = await lastValueFrom(this.getAuthorization(idUser));
            this.cacheData.hotelsAutoriz = [];
            this.userAuthoriz.hotels?.forEach((element) => {
                this.cacheData.hotelsAutoriz.push(element);
            });
            const initData = await this.getRoleHotelGroups();
            this.setUserAutorizData();
            this.setViewBool('displayUser');
        }
    }
    cancel(event) {
        this.submitted = false;
        this.avatarSrc = '';
        //this.userPassword = '';
        this.newPassword = '';
        this.isUserPasswordLoaded = false;
        // this.registerForm.reset();
        this.setViewBool('isMainUser');
        this.displayRole = false;
        this.displayPwd = false;
        this.displayResetPwd = false;
        this.displayResetPwdResult = false;
        this.resettingPwd = false;
        this.displayListeRoles = false;
        this.displayEnabledDisableUser = false;
        this.newTemporaryPassword = '';
        this.password = '';
        this.isFormValidateRole = false;
        this.isCopied = false;
        console.log(event);
    }
    close() {
        this.selectedGroup = {
            id: '',
            name: '',
            description: '',
            roles: [],
            hotels: [],
        };
        this.isCopied = false;
        this.isUserPasswordLoaded = false;
        this.isEditLoad = false;
        this.isUserNameNew = false;
        this.isEmailError = false;
        this.setViewBool('isMainUser');
        this.isUserNameNew = false;
        this.avatarSrc = '';
        this.isUserAutoriz = false;
        this.hotelsAutoriz = this.cacheData.hotelsAutoriz;
        this.userHotels = this.cacheData.userHotels;
        this.selectedRolesAuthoriz = {};
    }

    checkUserIfExist(username: string, isEmailCheck?: boolean) {
        if (username && username != '' && username != ' ' && !isEmailCheck) {
            this.getUsers(username, false, {}, true);
        }
        if (isEmailCheck) {
            this.getUsers('', false, {}, true);
        }
    }
    validate(validateData) {
        this.saveLoading = true;
        this.selectedUserHotels = validateData.selectedUserHotels;
        this.selectedUserRoles = validateData.selectedUserRoles;
        this.selectedUserGroup = validateData.selectedUserGroup;
        this.avatarProfile = validateData.avatarProfile;
        this.isDefautAvatarProfile = validateData.isDefautAvatarProfile;
        this.userForCreation.language = validateData.language;
        this.userToUpdate.language = validateData.language;
        this.userForCreation.codeCountry = validateData.codeCountry;
        this.userToUpdate.codeCountry = validateData.codeCountry;
        this.submitted = true;
        // if (this.registerForm.invalid) {
        //   return;
        // }
        let username = validateData.formResult.username.value || '';
        this.getUsers(username, true, validateData.formResult);
    }
    /* mise a jour d'un utilisateur*/
    updateUser(newValues: any) {
        let expirationDate = newValues.expirationDate.value
            ? new Date(newValues.expirationDate.value).toISOString()
            : null;
        let accessDate = newValues.accessDate.value
            ? new Date(newValues.accessDate.value).toISOString()
            : null;
        this.isEmailError = false;
        this.userToUpdate.familyName = newValues.familyName.value;
        this.userToUpdate.id = newValues.id.value;
        this.userToUpdate.givenName = newValues.givenName.value;
        this.userToUpdate.username = newValues.username.value;
        this.userToUpdate.email = newValues.email.value;
        this.userToUpdate.mobilePhone = newValues.mobilePhone.value ? newValues.mobilePhone.value : null;
        this.userToUpdate.displayName = newValues.displayName.value;
        this.userToUpdate.avatarProfile = this.avatarProfile?.toString();
        this.userToUpdate.azureADB2CId = this.infosUser.azureADB2CId;
        //this.userToUpdate.language = this.infosUser.language;
        this.userToUpdate.accessDate = accessDate;
        this.userToUpdate.expirationDate = expirationDate;
        this.userToUpdate.isDefautAvatarProfile = this.isDefautAvatarProfile;
        this.userToUpdate.hotels = this.selectedUserHotels;
        this.userToUpdate.roles = this.selectedUserRoles;
        this.userToUpdate.groups = this.selectedUserGroup?.map((group: Group) => {
            return group.id;
        });
        this.subs.sink = this.userService.apiUsersPut(this.userToUpdate).subscribe({
            next: (data) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'User',
                    detail: `Mise à jour avec succès de l'utilisateur ${this.userToUpdate.username}`,
                });

                this.isEmailError = false;
                this.getUsers(this.searchedUser, false, {});
                this.notifService.setMessage(
                    `Mise à jour avec succès de l'utilisateur ${this.userToUpdate.username}`, 'Utilisateur',
                    false
                );
                this.saveLoading = false;
                this.close();
            },
            error: (error) => {
                this.isEditLoad = true;
                this.saveLoading = false;

                if (error?.error && typeof (error?.error) == "string" &&
                    error?.error?.indexOf('Un utilisateur existe avec le même Email') >= 0
                ) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'User',
                        detail: error?.error,
                        life: 5000,
                    });

                    this.isEmailError = true;
                }

                this.notifService.setMessage("Erreur de mise à jour", 'Utilisateur', true);

            }
        });
    }
    findUsers(searchedUser: string, event?) {
        this.searchedUser = searchedUser;
        if (searchedUser && searchedUser != '') {
            this.getUsers(searchedUser, false, {});
        } else {
            this.getUsers('', false, {});
        }
        this.selectedUsers = null;
    }
    /* Création d'un nouveau utilisateur */
    setNewUser(newValues: any) {
        this.isEditLoad = false;
        this.isUserNameNew = false;
        this.isEmailError = false;
        console.log(this.userForCreation);
        this.userForCreation.givenName = newValues?.givenName.value;
        this.userForCreation.familyName = newValues?.familyName.value;
        this.userForCreation.username = newValues?.username.value;
        this.userForCreation.email = newValues?.email.value;
        this.userForCreation.mobilePhone = newValues?.mobilePhone.value?newValues?.mobilePhone.value:null;
        this.userForCreation.displayName = newValues?.displayName.value;
        this.userForCreation.expirationDate = newValues?.expirationDate.value;
        this.userForCreation.accessDate = newValues?.accessDate.value;
        this.userForCreation.avatarProfile = this.avatarProfile?.toString();
        this.userForCreation.hotels = this.selectedUserHotels;
        this.userForCreation.isDefautAvatarProfile = this.isDefautAvatarProfile;
        this.userForCreation.roles = this.selectedUserRoles;
        this.userForCreation.groups = this.selectedUserGroup?.map(
            (group: Group) => {
                return group.id;
            }
        );
        this.subs.sink = this.userService.apiUsersPost(this.userForCreation).subscribe({
            next: (respose) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'User',
                    detail: `Enregistrement avec succès de l'utilisateur ${this.userForCreation.username}`,
                });
                this.getUsers('', false, {});
                this.close();
                this.isEmailError = false;
                this.password = respose.password;
                this.notifService.setMessage(
                    `Enregistrement avec succès de l'utilisateur ${this.userForCreation.username}`, 'Utilisateur',
                    false
                );
                this.displayPwd = true;
                this.saveLoading = false;
            },
            error: (error) => {
                this.isUserNameNew = true;
                this.messageService.add({
                    severity: 'error',
                    summary: 'User',
                    detail: error?.error,
                    life: 5000,
                });
                if (
                    error?.error?.indexOf('Un utilisateur existe avec le même Email') >= 0
                ) {
                    this.isEmailError = true;
                }
                this.notifService.setMessage(error?.error, 'Utilisateur', true);
                this.displayPwd = false;
                this.password = '';
                this.saveLoading = false;
            }
        });
    }
    setViewBool(boolName: string) {
        this.isMainUser = false;
        this.displayUserForm = false;
        this.displayUser = false;
        this[boolName] = true;
    }
    newRoles() {
        this.displayRole = true;
        this.newRole = {
            id: '',
            name: '',
            description: '',
        };
    }
    /* Création des roles*/
    createRole() {
        this.isFormValidateRole = true;
        let isFormValidateRole =
            this.newRole.id &&
            this.newRole.id != '' &&
            this.newRole.description &&
            this.newRole.description != '' &&
            this.newRole.name &&
            this.newRole.name != '';
        if (isFormValidateRole) {
            console.log(this.newRole);
            this.displayRole = false;
            this.roleToCreation.id = this.newRole.id;
            this.roleToCreation.name = this.newRole.name;
            this.roleToCreation.description = this.newRole.description;
            this.subs.sink = this.roleService.apiRolesPost(this.roleToCreation).subscribe({
                next: (data) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Role',
                        detail: `Enregistrement avec succès de role ${this.roleToCreation.name}`,
                    });
                    this.notifService.setMessage(
                        `Enregistrement avec succès de role ${this.roleToCreation.name}`, 'Utilisateur',
                        false
                    );
                },
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Role',
                        detail: error?.error,
                        life: 5000,
                    });
                    this.notifService.setMessage(error?.error, 'Utilisateur', true);
                }
            });
        }
    }
    openResetUserPasswordDialog() {
        this.displayResetPwd = true;
        this.displayResetPwdResult = false;
        this.resettingPwd = false;
        this.newTemporaryPassword = '';
        this.newPassword = '';
    }
    closeResetUserPasswordDialog() {
        this.displayResetPwd = false;
        this.displayResetPwdResult = false;
        this.resettingPwd = false;
        this.newTemporaryPassword = '';
        this.newPassword = '';
    }
    resetUserPassword() {
        this.isCopied = false;
        if (this.selectedUsers && this.selectedUsers.id) {
            this.resettingPwd = true;
            this.newPassword = this.newPassword;
            this.subs.sink = this.userService
                .resetPassword(this.selectedUsers.id, this.newPassword)
                .subscribe({
                    next: (data) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: '',
                            detail: `Enregistrement avec succès du nouveau mot de passe pour ${this.selectedUsers.username}`,
                        });
                        this.notifService.setMessage(
                            `Enregistrement avec succès du nouveau mot de passe pour ${this.selectedUsers.username}`,
                            'Utilisateur', false
                        );
                        this.newTemporaryPassword = data;
                        this.resettingPwd = false;
                        this.displayResetPwdResult = true;
                    },
                    error: (error) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: '',
                            detail: error?.error,
                            life: 5000,
                        });
                        this.notifService.setMessage(error?.error, 'Utilisateur', true);
                        this.newTemporaryPassword = '';
                        this.resettingPwd = false;
                        this.displayResetPwd = false;
                        this.displayResetPwdResult = false;
                    }
                });
        }
        //this.displayRole = false;
    }
    openEnableDisableUserDialog() {
        this.displayEnabledDisableUser = true;
    }
    closeEnableDisableUserDialog() {
        this.displayEnabledDisableUser = false;
    }
    switchUserActivation() {
        if (this.selectedUsers && this.selectedUsers.id) {
            if (this.isUserDisabled(this.selectedUsers)) {
                this.subs.sink = this.userService
                    .enableUser(this.selectedUsers.id)
                    .subscribe({
                        next: (data) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Utilisateur',
                                detail: `L'activation de ${this.selectedUsers.username} a réussi`,
                            });
                            this.notifService.setMessage(
                                `L'activation de ${this.selectedUsers.username} a réussi`,
                                'Utilisateur', false
                            );
                            this.selectedUsers.expirationDate = null;
                            this.displayEnabledDisableUser = false;
                        },
                        error: (error) => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Utilisateur',
                                detail: 'Echec d enregistrement des données!',
                                life: 5000,
                            });
                            this.notifService.setMessage(error?.error, 'Utilisateur', true);
                            this.displayEnabledDisableUser = false;
                        }
                    });
            } else {
                this.subs.sink = this.userService
                    .disableUser(this.selectedUsers.id)
                    .subscribe({
                        next: (data) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Utilisateur',
                                detail: `La désactivation de ${this.selectedUsers.username} a réussi`,
                            });
                            this.notifService.setMessage(
                                `La désactivation de ${this.selectedUsers.username} a réussi`,
                                'Utilisateur', false
                            );
                            this.selectedUsers.expirationDate = new Date();
                            this.displayEnabledDisableUser = false;
                        },
                        error: (error) => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Utilisateur',
                                detail: error?.error,
                                life: 5000,
                            });
                            this.notifService.setMessage(error?.error, 'Utilisateur', true);
                            this.displayEnabledDisableUser = false;
                        }
                    });
            }
        }
    }
    isUserDisabled(user: User) {
        return (
            user &&
            user.expirationDate &&
            moment(user.expirationDate).utc() < moment().utc()
        );
    }
    /* To copy any Text */
    copyText(value: string): void {
        const text: string = value || '';
        console.log(text);
        const successful = this.clipboard.copy(text);
        this.isCopied = true;
    }
    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs?.unsubscribe();
        }
    }
}
