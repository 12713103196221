<div class="container" style="user-select: none;">
    <div>
        <p-table #dt1 [value]="allExpo.ratePlanList" selectionMode="single" [(selection)]="selectedRatePlan"
            dataKey="code" styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
            [globalFilterFields]="['code','libelle']" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="tarifListTitle">
                    Plans tarifaires
                </div>
                <div class="flex">
                    <button pButton label="" class="p-button-primary ml-3" icon="pi pi-plus"
                        (click)="ratePlanShowdialog(true)" pTooltip="Nouveau plan tarifaire"></button>

                    <button [disabled]="!selectedRatePlan" pButton label="" class="p-button-success ml-1"
                        icon="pi pi-pencil" (click)="ratePlanShowdialog(false)"
                        [pTooltip]="true?'Modifier le plan tarifaire ' + selectedRatePlan?.libelle :''"></button>

                    <button [disabled]="!selectedRatePlan" pButton label="" class="p-button-danger ml-1"
                        icon="pi pi-times"
                        [pTooltip]="true?'Supprimer le plan tarifaire ' + selectedRatePlan?.libelle :''"></button>

                    <button pButton label="" class="p-button-success ml-1" icon="pi pi-sync "
                        pTooltip="Synchrosier avec la liste des Pricers"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="libelle">Libellé <p-sortIcon field="libelle"></p-sortIcon></th>
                    <th pSortableColumn="produitExposeList"><p-sortIcon field="produitExposeList"></p-sortIcon></th>
                    <th pSortableColumn="minlos">MinLos<p-sortIcon field="Min Los"></p-sortIcon></th>
                    <th pSortableColumn="maxlos">MaxLos<p-sortIcon field="Max Los"></p-sortIcon></th>
                    <th pSortableColumn="isPassif">Passif<p-sortIcon field="isPassif"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ratePlan>
                <tr [pSelectableRow]="ratePlan" [ngClass]="ratePlan.isObsolete? 'text-pink-500':''">
                    <td>{{ ratePlan.code }}</td>
                    <td>
                        <span>{{ ratePlan.libelle }}</span>
                        <span *ngIf="ratePlan.isObsolete" class="ml-2">
                            <i class="pi pi-times" style="color: red"></i>
                        </span>
                    </td>
                    <td>
                        <i *ngIf="ratePlan?.produitExposeList?.length==0" pTooltip="Aucun produit diffusé"
                            class="pi pi-exclamation-triangle triangleExclamation"></i>
                    </td>
                    <td>
                        <span *ngIf="ratePlan.minlos">{{ratePlan.minlos}}</span>
                    </td>
                    <td>
                        <span *ngIf="ratePlan.maxlos">{{ratePlan.maxlos}}</span>
                    </td>
                    <td>
                        <span *ngIf="ratePlan.isPassif">
                            <i class="pi pi-check" style="color: var(--green-500); font-weight: bold;"></i></span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div>
        <p-table #dt2 [value]="selectedRatePlan?.produitExposeList" styleClass="p-datatable-sm p-datatable-striped"
            [scrollable]="true" [globalFilterFields]="['libelle','pricerLibelle']"
            [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="tarifListTitle">
                    Produits diffusés
                </div>
                <div class="flex">
                    <button pButton label="Diffuser les produits" class="p-button-success" icon="pi pi-plus"
                        (click)="displayProduitDiffuses=true;" [disabled]="!selectedRatePlan || selectedRatePlan?.isPassif" > </button>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Produits vendus</th>
                    <th>Pricers</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-prdExpose>
                <tr [pSelectableRow]="ratePlan">
                    <td>{{ prdExpose.produitVenduUnitLibelle }}</td>
                    <td>{{ prdExpose.pricerLibelle }}</td>
                    <td (click)="deletePrdExpose(prdExpose)"> <i class="pi pi-times delProduit">
                        </i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div>
        <p-table #dt3 [value]="canalDiffuseList" styleClass="p-datatable-sm p-datatable-striped"
            [scrollable]="true" [globalFilterFields]="['canalLibelle','canalCode']"
            [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="tarifListTitle">
                    Cannaux de diffusions
                </div>
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt3.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Code</th>
                    <th>Libellé</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-can>
                <tr [pSelectableRow]="can">
                    <td>{{ can.code }}</td>
                    <td>{{ can.libelle }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>



<!-- Boites de dialogue -->

<div *ngIf="displayRatePlan">
    <p-dialog [(visible)]="displayRatePlan" [modal]="true" [style]="{minwidth: '600px', minheight:'300px' }">
        <p-header *ngIf="!newRatePlan">
            Modification du plan tarifaire {{selectedRatePlan.libelle}}
        </p-header>
        <p-header *ngIf="newRatePlan">
            Nouveau plan tarifaire
        </p-header>
        <app-form-dynamic #formRatePlan [formModel]="ratePlanForm" [formValue]="ratePlanFormValue"
            (OnCancel)="displayRatePlan=false" (OnValidate)="upsertRatePlan($event)">
        </app-form-dynamic>
    </p-dialog>
</div>


<div *ngIf="displayProduitDiffuses">
    <p-dialog [(visible)]="displayProduitDiffuses" [modal]="true" [style]="{minwidth: '800px', minheight:'300px' }">
        <p-header>
            Produits diffusés
        </p-header>
        <div class="prdDiffGrid">
            <div>
                <span class="title-group mb-1">
                    Pricers
                </span>
                <p-listbox [options]="pricerList" [(ngModel)]="selectedPricer" optionLabel="libelle" [filter]="true"
                    [listStyle]="{'height': '310px', 'min-width':'350px'}">
                </p-listbox>
            </div>

            <div>
                <span class="title-group mb-1">
                    Produits vendus
                </span>
                <p-listbox [(ngModel)]="selectedPricerPrdvuList" [options]="pricerPrdvuList" optionLabel="libelle"
                    optionValue="code" [filter]="true" checkbox="true" [multiple]="true"
                    [listStyle]="{'height': '310px', 'min-width':'350px'}">
                </p-listbox>
            </div>
        </div>
        <hr />

        <div class=" mt-3 toolbar-grid">
            <div></div>
            <div class="mr3">
                <p-button label="Valider" icon="pi pi-check" (click)="updatePrdvuDiffuse($event)" class="formValidate"
                    [style]="{width:'100%'}"></p-button>
            </div>
            <div>
                <p-button label="Annuler" icon="pi pi-times" (click)="displayProduitDiffuses=false"
                    class="p-button-secondary formCancel" [style]="{width:'100%'}"></p-button>
            </div>
        </div>
    </p-dialog>
</div>
<p-confirmDialog [style]="{minwidth: '45vw'}" key="deleteProduitDiffuse"></p-confirmDialog>
