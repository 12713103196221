import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'

import { TOOL_TIP_Z_INDEX } from '../componentConfig'


@Component({
    selector: 'app-check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.css'],
})
export class CheckBoxComponent implements OnInit {
    @Input() formGroup: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2;
    tooltipzindex = TOOL_TIP_Z_INDEX;
    constructor() { }

    ngOnInit() {


        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (!this.formControl.value) {
            this.formControl.patchValue(false)
        }

        if (this.field.readOnly) {
            this.formControl.disable();
        }
    }

    click($event) {
        this.formControl.update_Value_And_Validity();
    }
}
