<p-table [value]="allProduits.prestationList" styleClass="p-datatable-sm p-datatable-striped"
[scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'">
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Libellé </th>
            <th>Prix Ref</th>
            <th>Prix Min</th>
            <th>Prix Max</th>

            <th>Peti déj</th>
            <th>Déjeuner</th>
            <th>Dîner</th>
            <th style="text-align: center;">
                <p-button icon="pi pi-plus" pTooltip="Nouveau" (onClick)="NewPrs()"
                    styleClass="p-button-rounded p-button-success">
                </p-button>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-prs>
        <tr [pSelectableRow]="prs" [ngClass]="prs.isObsolete? 'text-pink-500':''">
            <td>{{ prs.code }}</td>
            <td>
                <span>{{ prs.libelle }}</span>
                <span *ngIf="prs.isObsolete" class="ml-2">
                    <i class="pi pi-times" style="color: red"></i>
                </span>
            </td>
            <td>{{ prs.prixRef | currency: 'EUR' }}</td>
            <td>{{ prs.prixMin | currency: 'EUR' }}</td>
            <td>{{ prs.prixMax | currency: 'EUR' }}</td>

            <td><p-checkbox [(ngModel)]="prs.isBreakfast" [binary]="true" [readonly]="true"></p-checkbox></td>
            <td><p-checkbox [(ngModel)]="prs.isLunch" [binary]="true" [readonly]="true"></p-checkbox></td>
            <td><p-checkbox [(ngModel)]="prs.isDinner" [binary]="true" [readonly]="true"></p-checkbox></td>
            <td style="text-align: center;">
                <p-button icon="pi pi-pencil" (onClick)="UpdatePrs(prs)" pTooltip="Modifier"
                    styleClass="p-button-rounded p-button-success p-button-raised p-button-text">
                </p-button>
                <p-button *ngIf="!prs.isObsolete" icon="pi pi-times" pTooltip="Désactiver" (onClick)="DelPrs(prs)"
                    class="ml-1" styleClass="p-button-rounded p-button-danger p-button-raised p-button-text ">
                </p-button>
                <p-button *ngIf="prs.isObsolete" icon="pi pi-check" pTooltip="Activer" (onClick)="DelPrs(prs)"
                    class="ml-1" styleClass="p-button-rounded p-button-success p-button-raised p-button-text ">
                </p-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="displayPrsForm">
    <p-dialog [(visible)]="displayPrsForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Prestation
        </p-header>
        <app-form-dynamic #formprs [formModel]="prsFormConfig" [formValue]="formPrsValue"
            (OnValidate)="validateFormPrs($event)" (OnCancel)="cancel($event)" [debug]="false">
        </app-form-dynamic>

    </p-dialog>
</div>
