<div>
    <p-panel header="Profile utilisateur" >
        <div class="grid">
            <div class="col-2">
                <img style="border-radius: 50px; margin-top: 20px; margin-left: 2%;" [src]="avatarSrc" alt=""
                    srcset="" height="100" width="100" />
            </div>
            <div class="col-4">
                <div class="field grid " style=" margin-top: 28px;">
                    <label for="familyName" class="col-12 md:col-3 md:col-0 "><b>Nom :</b></label>
                    <div class=" col-12  md:col-5">
                        {{lineValue.familyName}}
                    </div>
                </div>
                <div class="field grid" style=" margin-top: 28px;">
                    <label for="givenName" class="col-12 md:col-3 md:col-2 mb-md:col-0"><b>Prénom :</b></label>
                    <div class=" col-12  md:col-5">
                        {{lineValue.givenName}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="field grid" style=" margin-top: 28px;">
                    <p for="username" class="col-12 md:col-3 md:col-2 mb-md:col-0 "><b>Login</b></p>
                    <div class="col-12  md:col-6">
                        {{lineValue.username}}
                    </div>
                </div>
                <div class="field grid" style=" margin-top: 28px;">
                    <label for="givenName" class="col-12 md:col-3 md:col-2 mb-md:col-0"><b>Nom D'affichage :</b></label>
                    <div class=" col-12  md:col-6">
                        {{lineValue.displayName}}
                    </div>
                </div>
            </div>
        </div>
        <div class="fluid " style="margin-left: 16%; margin-right: 5%;">
            <div class="grid">
                <div class="col m-1">
                    <p-panel>
                        <ng-template pTemplate="header" class="grid">
                            <span class="col-10"><b>Roles </b></span>
                            <p-badge [value]="rolesAutoriz?.length" style="margin: 0; padding: 0;" styleClass="mr-2"></p-badge>
                        </ng-template>
                        <p-listbox [options]="rolesAutoriz" optionLabel="name" [(ngModel)]="selectedRolesAuthoriz"
                            [listStyle]="{'max-height':'248px'}" [style]="{'height':'250px'}"
                            (onClick)="onSelectedRoleAuthoriz(selectedRolesAuthoriz)">
                        </p-listbox>
                    </p-panel>
                </div>
                <div class="col m-1">
                    <p-panel>
                        <ng-template pTemplate="header" class="grid">
                            <span class="col-10"><b>Hotels </b></span>
                            <p-badge [value]="hotelsAutoriz?.length" styleClass="mr-2"></p-badge>
                        </ng-template>
                        <p-listbox [options]="hotelsAutoriz" optionLabel="name" [listStyle]="{'max-height':'200px'}"
                            [multiple]="true" [style]="{'height':'250px'}">
                            <ng-template pTemplate="header">
                                <div class="p-listbox-filter-container">
                                    <input type="text" [value]="filterValue||''" [(ngModel)]="filterValue"
                                        (input)="hotelsAutorizFilter(filterValue, 'hotelsAutoriz', 'hotCode', 'name')"
                                        class="p-listbox-filter p-inputtext p-component">
                                    <span class="p-listbox-filter-icon pi pi-search"></span>
                                </div>
                            </ng-template>
                            <ng-template let-hotel pTemplate="item">
                                <span class="">{{hotel.hotCode}}
                                    {{hotel.name}}
                                </span>
                            </ng-template>
                        </p-listbox>
                    </p-panel>
                </div>
                <div class="col m-1">
                    <p-panel>
                        <ng-template pTemplate="header" class="grid">
                            <span class="col-10"><b>Groupes </b></span>
                            <p-badge [value]="groupsAutoriz?.length" styleClass="mr-2"></p-badge>
                        </ng-template>
                        <p-listbox [options]="groupsAutoriz" optionLabel="name" [listStyle]="{'max-height':'200px'}"
                            [style]="{'height':'250px'}">
                        </p-listbox>
                    </p-panel>
                </div>
            </div>
        </div>
    </p-panel>
</div>
