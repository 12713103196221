/**
 * Spot Distrib Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivatePackageCmd } from '../model/activatePackageCmd';
import { ActivatePrestationCmd } from '../model/activatePrestationCmd';
import { ActivateProduitVenduCmd } from '../model/activateProduitVenduCmd';
import { AllProduitstHotel } from '../model/allProduitstHotel';
import { CreatePackageCmd } from '../model/createPackageCmd';
import { CreatePackageTypeCmd } from '../model/createPackageTypeCmd';
import { DistribSession } from '../model/distribSession';
import { FormConfig } from '../model/formConfig';
import { NewPrestationCmd } from '../model/newPrestationCmd';
import { NewProduitVenduCmd } from '../model/newProduitVenduCmd';
import { SetAttributionCmd } from '../model/setAttributionCmd';
import { UpdateInventaireCmd } from '../model/updateInventaireCmd';
import { UpdatePackageCmd } from '../model/updatePackageCmd';
import { UpdatePrestationCmd } from '../model/updatePrestationCmd';
import { UpdateProduitVenduCmd } from '../model/updateProduitVenduCmd';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProduitService {

    protected basePath = 'https://services-dev.spotpilot.com/distrib';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Liste des produits d&#x27;un hôtel ( produitVendus , packages, presations... )
     * 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitAllProduitsGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<AllProduitstHotel>;
    public apiProduitAllProduitsGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllProduitstHotel>>;
    public apiProduitAllProduitsGet(hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllProduitstHotel>>;
    public apiProduitAllProduitsGet(hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiProduitAllProduitsGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AllProduitstHotel>('get',`${this.basePath}/api/produit/allProduits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mise à jour attribution / surclassement
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitAttributionPost(body?: SetAttributionCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitAttributionPost(body?: SetAttributionCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitAttributionPost(body?: SetAttributionCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitAttributionPost(body?: SetAttributionCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/produit/attribution`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitInvFormGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitInvFormGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitInvFormGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitInvFormGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/invForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modification d&#x27;un inventaire
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitInventairesPut(body?: UpdateInventaireCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitInventairesPut(body?: UpdateInventaireCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitInventairesPut(body?: UpdateInventaireCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitInventairesPut(body?: UpdateInventaireCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/inventaires`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pkgType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitNewPkgFormGet(hotCode?: string, pkgType?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitNewPkgFormGet(hotCode?: string, pkgType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitNewPkgFormGet(hotCode?: string, pkgType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitNewPkgFormGet(hotCode?: string, pkgType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (pkgType !== undefined && pkgType !== null) {
            queryParameters = queryParameters.set('pkgType', <any>pkgType);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/newPkgForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitNewPkgtypeFormGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitNewPkgtypeFormGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitNewPkgtypeFormGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitNewPkgtypeFormGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/newPkgtypeForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nouveau Package
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPackagePost(body?: CreatePackageCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPackagePost(body?: CreatePackageCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPackagePost(body?: CreatePackageCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPackagePost(body?: CreatePackageCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/produit/package`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour d&#x27;un Package
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPackagePut(body?: UpdatePackageCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPackagePut(body?: UpdatePackageCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPackagePut(body?: UpdatePackageCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPackagePut(body?: UpdatePackageCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/package`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nouveau type de Package avec les produits
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPackageTypePost(body?: CreatePackageTypeCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPackageTypePost(body?: CreatePackageTypeCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPackageTypePost(body?: CreatePackageTypeCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPackageTypePost(body?: CreatePackageTypeCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/produit/packageType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activer - déactiver un produit Vendu
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPackagesActivatePut(body?: ActivatePackageCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPackagesActivatePut(body?: ActivatePackageCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPackagesActivatePut(body?: ActivatePackageCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPackagesActivatePut(body?: ActivatePackageCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/packages/activate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param forNewPkg 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPkgFormGet(hotCode?: string, forNewPkg?: boolean, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitPkgFormGet(hotCode?: string, forNewPkg?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitPkgFormGet(hotCode?: string, forNewPkg?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitPkgFormGet(hotCode?: string, forNewPkg?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (forNewPkg !== undefined && forNewPkg !== null) {
            queryParameters = queryParameters.set('forNewPkg', <any>forNewPkg);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/pkgForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPrdvFormGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitPrdvFormGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitPrdvFormGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitPrdvFormGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/prdvForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activer - déactiver une prestation
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPrestationActivatePut(body?: ActivatePrestationCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPrestationActivatePut(body?: ActivatePrestationCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPrestationActivatePut(body?: ActivatePrestationCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPrestationActivatePut(body?: ActivatePrestationCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/prestation/activate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nouvelle prestation
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPrestationPost(body?: NewPrestationCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPrestationPost(body?: NewPrestationCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPrestationPost(body?: NewPrestationCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPrestationPost(body?: NewPrestationCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/produit/prestation`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour d&#x27;une prestation
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPrestationPut(body?: UpdatePrestationCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitPrestationPut(body?: UpdatePrestationCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitPrestationPut(body?: UpdatePrestationCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitPrestationPut(body?: UpdatePrestationCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/prestation`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activer - déactiver un produit Vendu
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitProduitVendusActivatePut(body?: ActivateProduitVenduCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitProduitVendusActivatePut(body?: ActivateProduitVenduCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitProduitVendusActivatePut(body?: ActivateProduitVenduCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitProduitVendusActivatePut(body?: ActivateProduitVenduCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/produitVendus/activate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nouveau d&#x27;un produit Vendu
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitProduitVendusPost(body?: NewProduitVenduCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitProduitVendusPost(body?: NewProduitVenduCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitProduitVendusPost(body?: NewProduitVenduCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitProduitVendusPost(body?: NewProduitVenduCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/produit/produitVendus`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour d&#x27;un produit Vendu
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitProduitVendusPut(body?: UpdateProduitVenduCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiProduitProduitVendusPut(body?: UpdateProduitVenduCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiProduitProduitVendusPut(body?: UpdateProduitVenduCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiProduitProduitVendusPut(body?: UpdateProduitVenduCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('put',`${this.basePath}/api/produit/produitVendus`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProduitPrsFormGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiProduitPrsFormGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiProduitPrsFormGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiProduitPrsFormGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/produit/prsForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
