<p-toolbar #upload_Export_button>
    <div  style="width: 100%; margin: -10px;">
        <p-fileUpload name="file" #fileUpload  accept=".xls, .xlsx"
            chooseLabel="Importer" uploadLabel="Charger" customUpload="true" cancelLabel="Annuler" multiple="false"
            (onSelect)="onSelectedFile($event)" (onClear)="onClearSelection()" headers="uploadHeaders"
             (uploadHandler)="uploadBudgetDays($event, fileUpload)">
            <ng-template pTemplate="toolbar">
                <i style="margin:10px ;">{{selectedFile}}</i>
                <span class="font-bold" style="color: blueviolet; margin: 10px; float: right;" for="switch1">HT</span>
                <a [href]="" tooltipStyleClass="customtooltip" tooltipPosition="left" pTooltip="Basculement TTC/HT désactivé : un budget existe déjà" [tooltipDisabled]="!GetDisbaledBudget()"  style="float: right;" >
                    <p-inputSwitch   [disabled]="GetDisbaledBudget()"   [(ngModel)]="isHt" (onChange)="handleChange($event,'right')" >
                    </p-inputSwitch>
                </a>
                <span  class="font-bold" style="color: blueviolet; margin:10px; float: right;" for="switch1">TTC</span>

                <a [href]="GetExportUrl()" download="nom-du-fichier" style="float: right;">
                    <p-button icon="pi pi-file-excel" iconPos="left" styleClass="p-button-success" label="Exporter"
                        class="ui-button-success">
                    </p-button>
                </a>
            </ng-template>
        </p-fileUpload>
    </div>
</p-toolbar>
<div class="card" #getBudgetYear_button>

    <div class="flex justify-content-center flex-wrap card-container mr-4">
        <p-button class="flex-1 flex align-items-center justify-content-center   border-round" (onClick)="getBudgetYear(year -3, true)"
            styleClass="{{ btnClass[year-3]}}">
            {{year - 3}}
        </p-button>
        <p-button class="flex-1 flex align-items-center justify-content-center   border-round" (onClick)="getBudgetYear(year -2, true)"
            styleClass="{{ btnClass[year-2]}}">
            {{year - 2}}
        </p-button>
        <p-button class="flex-1 flex align-items-center justify-content-center   border-round" (onClick)="getBudgetYear(year -1, true)"
            styleClass="{{ btnClass[year-1]}}">
            {{year - 1}}
        </p-button>
        <p-button class="flex-1 flex align-items-center justify-content-center   border-round" (onClick)="getBudgetYear(year, true)"
            styleClass="{{ btnClass[year]}}">
            {{year}}
        </p-button>
        <p-button class="flex-1 flex align-items-center justify-content-center   border-round" (onClick)="getBudgetYear(year + 1, true)"
            styleClass="{{ btnClass[year + 1]}}">
            {{year + 1}}
        </p-button>
    </div>
</div>
<p-table class="m-1" [columns]="cols" [value]="bugdetYear" [(selection)]="selectedBudget"
    (onRowSelect)="onRowSelect($event)" selectionMode="single" dataKey="ca">
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [ngStyle]="{'width': col == 'Total' ? '110px' : '95px'}">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns; let i = index;" [ngClass]="{'isSelectedMonth' : i == monthIdex}">
                <span (click)="loadData(col, i)" style="cursor: pointer;">
                    {{col == "ca" ? "" :  col}}</span>
                    <i [ngClass]="{'pi pi-pencil' : col != 'ca' && col != 'Total'}"
                    style="font-size: 0.8rem; color: blueviolet; margin:7px; cursor:pointer ;"
                    (click)="loadData(col, i)">
                </i>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [ngClass]="{'row-total' : rowData['ca'] == 'Total Ca'}">
            <td *ngFor="let col of columns"
                [hidden]="rowData['ca'] == 'year' || ptotal.indexOf(rowData['ca']?.toLowerCase()) >= 0
                || rowData['ca'] == 'offerts' || rowData['ca'] == 'occup' || rowData['ca'] == 'nuitees'
                 || rowData['ca'] == 'hs' || rowData['ca'] == 'nbCh' || rowData['ca'] == 'prixPdj'|| rowData['ca'] == 'tauxRemplissage' "
                [ngClass]="{'row-total' : col == 'Total'}">
                {{(col != 'ca' && rowData['ca'] != 'chVendu' && rowData['ca'] != 'chTravaux' && rowData['ca'] != 'to' ) ?
                (rowData[col] | currency : 'EUR') :

                (rowData['ca'] == 'to' && col != 'ca' ? (( rowData[col] * 100 | number : '0.0-2') + ' %') :
                 col == 'ca' ? rowData[col]?.charAt(0).toUpperCase() + rowData[col]?.substring(1).toLowerCase() : rowData[col])}}
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog *ngIf="displayBudget" [(visible)]="displayBudget" [modal]="true" [style]="{'min-width': '500px', margin:'5px'}">
    <p-header></p-header>
    <app-form-budget [newBudgetMonth]="newBudgetMonth" [isError]="isError" [budgetDay]="budgetDay" [budgetCalcul]="budgetCalcul"
        [displayBudgetDays]="displayBudgetDays" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear"
        (OnValidate)="budgetValidate($event)" (OnProcess)="processPost($event)">
    </app-form-budget>
</p-dialog>
<p-confirmDialog [style]="{width: '30vw'}"  [position]="position"></p-confirmDialog>

