<p-table [value]="allProduits.inventaireList" styleClass="p-datatable-sm p-datatable-striped"
[scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'">
    <ng-template pTemplate="header">
        <tr>
            <th>N° surclassement</th>
            <th>Code</th>
            <th>Libellé </th>
            <th>Nb construites</th>
            <th>Prix Ref</th>
            <th>Prix Min</th>
            <th>Prix Max</th>
            <th>Pax Std</th>
            <th>Pax Max</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
        <tr [pSelectableRow]="product">
            <td>
                <div style="display: grid; grid-template-columns: 1fr 20px">
                    <span >{{product.numeroSurClass}}</span>
                    <span *ngIf="product.invDeReference">
                        <i class="pi pi-star-fill" style="color: red"></i>
                    </span>
                </div>
            </td>
            <td>{{ product.code }}</td>
            <td>{{ product.libelle }}</td>
            <td>{{ product.stock }}</td>

            <td>{{ product.prixRef | currency: 'EUR' }}</td>
            <td>{{ product.prixMin | currency: 'EUR' }}</td>
            <td>{{ product.prixMax | currency: 'EUR' }}</td>
            <td>{{ product.stdPax }}</td>
            <td>{{ product.maxPax }}</td>
            <td>
                <p-button icon="pi pi-pencil" (onClick)="UpdateInv(product)"
                    styleClass="p-button-rounded p-button-success p-button-text p-button-raised">
                </p-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="displayInvForm">
    <p-dialog [(visible)]="displayInvForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Inventaire
        </p-header>
        <app-form-dynamic #formInv [formModel]="inventaireFormConfig" [formValue]="formInvValue"
            (OnValidate)="validateFormInv($event)" (OnCancel)="cancel($event)" [debug]="false">
        </app-form-dynamic>

    </p-dialog>
</div>
