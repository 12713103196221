export * from './admin.service';
import { AdminService } from './admin.service';
export * from './hotels.service';
import { HotelsService } from './hotels.service';
export * from './live.service';
import { LiveService } from './live.service';
export * from './rateShopper.service';
import { RateShopperService } from './rateShopper.service';
export * from './rateShopperConfig.service';
import { RateShopperConfigService } from './rateShopperConfig.service';
export const APIS = [AdminService, HotelsService, LiveService, RateShopperService, RateShopperConfigService];
