import { ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TableSci } from './models/TableSci';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { CellSci } from './models/CellSci';
import { AbstractDataService } from './models/AbstractDataService';
import { RowSci } from './models/RowSci';

@Component({
    selector: 'app-horizontal-scroll',
    templateUrl: './horizontal-scroll.component.html',
    styleUrls: ['./horizontal-scroll.component.css']
})
export class HorizontalScrollComponent implements OnInit, OnChanges {

    douwn: boolean = false;
    pointerX: number;
    translation: number;

    translation_y: number;
    currentTranslation: number;
    indexTranslation: number;

    @Input() hotCode: string;

    @Input() rowDescriptions: TableSci;
    heightCompoment: number = 1000;

    titleWidth: number = 230;  // largeur de la premiere colonne

    divtostranslate: HTMLElement;
    divtostranslateData: HTMLElement;

    constructor(private detector: ChangeDetectorRef, private dataService: AbstractDataService) {
    }
    ngOnChanges(changes: SimpleChanges): void {

        if (this.rowDescriptions && this.hotCode) {
            this.heightCompoment = this.rowDescriptions.current_Y;

            const dateMin = moment().add(this.rowDescriptions.cellsCount * -1, "day");
            const dateMax = moment().add(this.rowDescriptions.cellsCount * 2, "day");

            this.dataService.loadData(this.hotCode, dateMin.toDate(), dateMax.toDate());
        }
    }
    ngOnInit(): void {
        moment.locale('fr');

        this.divtostranslate = document.getElementById("divTotranslate");
        this.divtostranslateData = document.getElementById("divTotranslateData");

        this.translation = 0;
        this.translation_y = 0;
        this.currentTranslation = 0;
    }

    scroll(e: MouseEvent): void {
        if (this.douwn && Math.abs(e.movementX) > 1) {
            this.translation = e.clientX - this.pointerX;

            this.divtostranslate.style["left"] = this.translation.toString() + "px";
            this.divtostranslateData.style["left"] = this.translation.toString() + "px";


        }
    }

    // à utiliser  plus tard pour determiner le nombre de cellules et / ou  la lageur de chaque cellule
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        let width = window.innerWidth;
        let height = window.innerHeight;
    }


    getTranslation() {
        return this.translation;
    }
    mouseDown(e: MouseEvent): void {
        this.douwn = true;
        this.pointerX = e.clientX;
        this.detector.detach()
    }

    trackUsingId(index, cell: CellSci) {
        return cell.cellId;
    }

    mouseUp(e): void {
        if (this.douwn) {
            this.douwn = false;

            this.detector.reattach();

            this.divtostranslate.style["left"] = "0";
            this.divtostranslateData.style["left"] = "0";
            const newIndexs = this.rowDescriptions.translate(this.translation);

            if (newIndexs.length > 0) {
                const min = Math.min(...newIndexs);
                const max = Math.max(...newIndexs);

                const dateMin = moment().add(min, "day");
                const dateMax = moment().add(max, "day");

                this.dataService.loadData(this.hotCode, dateMin.toDate(), dateMax.toDate());

                this.currentTranslation = this.currentTranslation + this.translation;
                this.translation = 0;
                this.indexTranslation = Math.trunc(this.currentTranslation / this.rowDescriptions.cellWith);
            }
        }
    }

    getRowTitleClass(row: RowSci): string {
        if (row.parentRowSci) {
            return "containerTitleItem-data-n1";
        }
        else return "containerTitleItem-data";
    }

    toggleChildren(row: RowSci): void {

        this.rowDescriptions.toggleChildren(row);
        this.heightCompoment = this.rowDescriptions.current_Y; // actualiser la hauteur du composant
    }

    isIconPlusVisible(row: RowSci): boolean {
        if (!row) return false;
        if (row.childrenRows.length == 0) return false;
        if (row.childrenRows[0].visible) return false;
        return true;
    }
    isIconMinusVisible(row: RowSci): boolean {
        if (!row) return false;
        if (row.childrenRows.length == 0) return false;
        if (row.childrenRows[0].visible) return true;
        return false;
    }
}
