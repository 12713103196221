export * from './expo.service';
import { ExpoService } from './expo.service';
export * from './hotel.service';
import { HotelService } from './hotel.service';
export * from './pricer.service';
import { PricerService } from './pricer.service';
export * from './pricerDerive.service';
import { PricerDeriveService } from './pricerDerive.service';
export * from './produit.service';
import { ProduitService } from './produit.service';
export * from './session.service';
import { SessionService } from './session.service';
export * from './views.service';
import { ViewsService } from './views.service';
export const APIS = [ExpoService, HotelService, PricerService, PricerDeriveService, ProduitService, SessionService, ViewsService];
