import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { HotelService as DistribHotelService } from '../../services/api/hotel.service';
import { AbstractDataService } from 'src/app/horizontal-scroll/models/AbstractDataService';
import { YieldDataService } from '../Services/dataService';
import { TableSci } from 'src/app/horizontal-scroll/models/TableSci';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/models';
import * as moment from 'moment';
import { PricerService } from '../../services/api/pricer.service';
import { AllPricersHotel, Pricer } from '../../services/model/models';

@Component({
    selector: 'app-spot-yield',
    templateUrl: './spot-yield.component.html',
    styleUrls: ['./spot-yield.component.css'],
    providers: [{ provide: AbstractDataService, useClass: YieldDataService }] // Utilise ServiceB
})
export class SpotYieldComponent implements OnInit, OnDestroy {
    destroy$: Subject<unknown>;
    currentHotel: HotelAuth;
    rowDescriptions: TableSci;
    dataLoaded: boolean;
    hotelNotIntialized: boolean;
    dataError: boolean;
    allPricers: AllPricersHotel;
    heightRow = 25;

    constructor(private hotelService: DistribHotelService, private pricerService: PricerService,
        private userInfoService: UserInfoService,
        private confirmationService: ConfirmationService,
        private notifService: NotificationsService,
        private breadcrumbService: BreadcrumbService,
        private distribSessionService: DistribSessionService) {
        this.breadcrumbService.setItems([
            {
                label: 'distrib/yield'
            }
        ]);
        this.destroy$ = new Subject();
    }
    ngOnDestroy(): void {

    }
    ngOnInit(): void {
        moment.locale('fr');
        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    this.currentHotel = hotel;
                    this.loadData();
                }, 100);
            });
    }


    loadData() {
        this.dataLoaded = false;
        this.hotelNotIntialized = false;
        this.dataError = false;
        this.pricerService.apiPricerAllPricersGet(true, this.currentHotel.hotCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                {
                    next: data => {
                        this.allPricers = data;
                        this.buildTableDescription();
                    },
                    error: err => {

                        if (err.status == '404') {
                            this.hotelNotIntialized = true;
                        }
                        else {
                            this.dataError = true;
                        }
                        this.dataLoaded = false;
                        this.allPricers = null;

                    }
                }
            );
    }

    buildTableDescription() {
        let cellWidth = 50; // la largeur de chaque cellule du tableau
        let titleWidth = 230;   // à transmettre dans le constructeur de tableSci
        let cellCount = Math.trunc((window.innerWidth - 230) / cellWidth);  // le nombre de cellules  par lignes

        this.rowDescriptions = new TableSci(cellWidth, cellCount);

        //Prix Ref
        for (let pricer of this.allPricers.pricerList.filter(x => x.isMainRack == true)) {

            let pricer_rowSci = this.rowDescriptions.addRow("Prix Ref", this.heightRow, pricer.code, "prixRef");
        }


        for (let pricer of this.allPricers.pricerList.filter(x => !x.pricerParentCode && !x.isVirtual)) {

            let pricer_rowSci = this.rowDescriptions.addRow(pricer.libelle, this.heightRow, pricer.code, "pricerRack");

            for (let prdvu of pricer.pricerProduits) {
                this.rowDescriptions.addRow(prdvu.produitVenduUnit.libelle, this.heightRow, prdvu.produitVenduUnit.code,
                    "prdvuPricer", pricer_rowSci, false);
            }
            this.addPricerDerive(pricer)
        }


        this.dataLoaded = true;
    }

    addPricerDerive(pricerParent: Pricer): void {
        for (let pricer of this.allPricers.pricerList.filter(x => x.pricerParentCode == pricerParent.code)) {

            let pricer_rowSci = this.rowDescriptions.addRow(pricer.libelle, this.heightRow, pricer.code, "pricer");

            for (let prdvu of pricer.pricerProduits) {
                this.rowDescriptions.addRow(prdvu.produitVenduUnit.libelle, this.heightRow, prdvu.produitVenduUnit.code,
                    "prdvuPricer", pricer_rowSci, false);
            }

            this.addPricerDerive(pricer)
        }
    }
}
