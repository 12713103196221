/**
 * Spot RateShopper Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EReputation } from '../model/eReputation';
import { HistoEReputation } from '../model/histoEReputation';
import { RateComparisonHotel } from '../model/rateComparisonHotel';
import { RateComparisonRoom } from '../model/rateComparisonRoom';
import { RateShopperFilters } from '../model/rateShopperFilters';
import { RateShopperHotelAllPrices } from '../model/rateShopperHotelAllPrices';
import { RateShopperHotelDetailHistoPrices } from '../model/rateShopperHotelDetailHistoPrices';
import { RateShopperHotelDetailPrices } from '../model/rateShopperHotelDetailPrices';
import { RateShopperHotelPrices } from '../model/rateShopperHotelPrices';
import { ViewRate } from '../model/viewRate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RateShopperService {

    protected basePath = 'https://services-dev.spotpilot.com/rateshopper';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getHotels: Simulation de PartityRate
     * 
     * @param key 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGetHotelsGet(key?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RateComparisonHotel>>;
    public apiGetHotelsGet(key?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateComparisonHotel>>>;
    public apiGetHotelsGet(key?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateComparisonHotel>>>;
    public apiGetHotelsGet(key?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateComparisonHotel>>('get',`${this.basePath}/api/getHotels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Tous les prix des concurents par type chambre
     * 
     * @param hotCode 
     * @param from 
     * @param to 
     * @param filterPax 
     * @param filterSource 
     * @param filterRoomTypeStd 
     * @param filterNumbersOfNight 
     * @param filterRatePlan 
     * @param filterPensionCode 
     * @param filterAllPrices 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeAllpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'body', reportProgress?: boolean): Observable<RateShopperHotelAllPrices>;
    public apiHotCodeAllpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RateShopperHotelAllPrices>>;
    public apiHotCodeAllpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RateShopperHotelAllPrices>>;
    public apiHotCodeAllpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeAllpricesGet.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling apiHotCodeAllpricesGet.');
        }

        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling apiHotCodeAllpricesGet.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }
        if (filterPax !== undefined && filterPax !== null) {
            queryParameters = queryParameters.set('filter.Pax', <any>filterPax);
        }
        if (filterSource !== undefined && filterSource !== null) {
            queryParameters = queryParameters.set('filter.Source', <any>filterSource);
        }
        if (filterRoomTypeStd !== undefined && filterRoomTypeStd !== null) {
            queryParameters = queryParameters.set('filter.RoomTypeStd', <any>filterRoomTypeStd);
        }
        if (filterNumbersOfNight !== undefined && filterNumbersOfNight !== null) {
            queryParameters = queryParameters.set('filter.NumbersOfNight', <any>filterNumbersOfNight);
        }
        if (filterRatePlan !== undefined && filterRatePlan !== null) {
            queryParameters = queryParameters.set('filter.RatePlan', <any>filterRatePlan);
        }
        if (filterPensionCode !== undefined && filterPensionCode !== null) {
            queryParameters = queryParameters.set('filter.PensionCode', <any>filterPensionCode);
        }
        if (filterAllPrices !== undefined && filterAllPrices !== null) {
            queryParameters = queryParameters.set('filter.AllPrices', <any>filterAllPrices);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RateShopperHotelAllPrices>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/allprices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Historique pour une date du Prix Bar(min entre bar et promo) avec le detail des concurents et la mediane
     * 
     * @param hotCode 
     * @param importFrom 
     * @param importTo 
     * @param date 
     * @param filterPax 
     * @param filterSource 
     * @param filterRoomTypeStd 
     * @param filterNumbersOfNight 
     * @param filterRatePlan 
     * @param filterPensionCode 
     * @param filterAllPrices 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeDetailhistopricesGet(hotCode: string, importFrom: Date, importTo: Date, date?: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<RateShopperHotelDetailHistoPrices>>;
    public apiHotCodeDetailhistopricesGet(hotCode: string, importFrom: Date, importTo: Date, date?: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateShopperHotelDetailHistoPrices>>>;
    public apiHotCodeDetailhistopricesGet(hotCode: string, importFrom: Date, importTo: Date, date?: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateShopperHotelDetailHistoPrices>>>;
    public apiHotCodeDetailhistopricesGet(hotCode: string, importFrom: Date, importTo: Date, date?: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeDetailhistopricesGet.');
        }

        if (importFrom === null || importFrom === undefined) {
            throw new Error('Required parameter importFrom was null or undefined when calling apiHotCodeDetailhistopricesGet.');
        }

        if (importTo === null || importTo === undefined) {
            throw new Error('Required parameter importTo was null or undefined when calling apiHotCodeDetailhistopricesGet.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('Date', <any>date.toISOString());
        }
        if (importFrom !== undefined && importFrom !== null) {
            queryParameters = queryParameters.set('ImportFrom', <any>importFrom.toISOString());
        }
        if (importTo !== undefined && importTo !== null) {
            queryParameters = queryParameters.set('ImportTo', <any>importTo.toISOString());
        }
        if (filterPax !== undefined && filterPax !== null) {
            queryParameters = queryParameters.set('filter.Pax', <any>filterPax);
        }
        if (filterSource !== undefined && filterSource !== null) {
            queryParameters = queryParameters.set('filter.Source', <any>filterSource);
        }
        if (filterRoomTypeStd !== undefined && filterRoomTypeStd !== null) {
            queryParameters = queryParameters.set('filter.RoomTypeStd', <any>filterRoomTypeStd);
        }
        if (filterNumbersOfNight !== undefined && filterNumbersOfNight !== null) {
            queryParameters = queryParameters.set('filter.NumbersOfNight', <any>filterNumbersOfNight);
        }
        if (filterRatePlan !== undefined && filterRatePlan !== null) {
            queryParameters = queryParameters.set('filter.RatePlan', <any>filterRatePlan);
        }
        if (filterPensionCode !== undefined && filterPensionCode !== null) {
            queryParameters = queryParameters.set('filter.PensionCode', <any>filterPensionCode);
        }
        if (filterAllPrices !== undefined && filterAllPrices !== null) {
            queryParameters = queryParameters.set('filter.AllPrices', <any>filterAllPrices);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateShopperHotelDetailHistoPrices>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/detailhistoprices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prix (min entre bar et promo par defaut) de l&#x27;hôtel avec le detail des concurents et la mediane
     * 
     * @param hotCode 
     * @param from 
     * @param to 
     * @param filterPax 
     * @param filterSource 
     * @param filterRoomTypeStd 
     * @param filterNumbersOfNight 
     * @param filterRatePlan 
     * @param filterPensionCode 
     * @param filterAllPrices 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeDetailpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<RateShopperHotelDetailPrices>>;
    public apiHotCodeDetailpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateShopperHotelDetailPrices>>>;
    public apiHotCodeDetailpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateShopperHotelDetailPrices>>>;
    public apiHotCodeDetailpricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeDetailpricesGet.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling apiHotCodeDetailpricesGet.');
        }

        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling apiHotCodeDetailpricesGet.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }
        if (filterPax !== undefined && filterPax !== null) {
            queryParameters = queryParameters.set('filter.Pax', <any>filterPax);
        }
        if (filterSource !== undefined && filterSource !== null) {
            queryParameters = queryParameters.set('filter.Source', <any>filterSource);
        }
        if (filterRoomTypeStd !== undefined && filterRoomTypeStd !== null) {
            queryParameters = queryParameters.set('filter.RoomTypeStd', <any>filterRoomTypeStd);
        }
        if (filterNumbersOfNight !== undefined && filterNumbersOfNight !== null) {
            queryParameters = queryParameters.set('filter.NumbersOfNight', <any>filterNumbersOfNight);
        }
        if (filterRatePlan !== undefined && filterRatePlan !== null) {
            queryParameters = queryParameters.set('filter.RatePlan', <any>filterRatePlan);
        }
        if (filterPensionCode !== undefined && filterPensionCode !== null) {
            queryParameters = queryParameters.set('filter.PensionCode', <any>filterPensionCode);
        }
        if (filterAllPrices !== undefined && filterAllPrices !== null) {
            queryParameters = queryParameters.set('filter.AllPrices', <any>filterAllPrices);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateShopperHotelDetailPrices>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/detailprices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Les informations de e-reputation
     * 
     * @param hotCode 
     * @param from 
     * @param to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeEreputationGet(hotCode: string, from: Date, to: Date, observe?: 'body', reportProgress?: boolean): Observable<EReputation>;
    public apiHotCodeEreputationGet(hotCode: string, from: Date, to: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EReputation>>;
    public apiHotCodeEreputationGet(hotCode: string, from: Date, to: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EReputation>>;
    public apiHotCodeEreputationGet(hotCode: string, from: Date, to: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeEreputationGet.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling apiHotCodeEreputationGet.');
        }

        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling apiHotCodeEreputationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EReputation>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/ereputation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Filtres RateShopper
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeFiltersGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<RateShopperFilters>;
    public apiHotCodeFiltersGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RateShopperFilters>>;
    public apiHotCodeFiltersGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RateShopperFilters>>;
    public apiHotCodeFiltersGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeFiltersGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RateShopperFilters>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/filters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Historique par mois des eReputations
     * 
     * @param hotCode 
     * @param fromMonthHisto fromMonthHisto &#x3D;-12    toMonthHisto&#x3D;0   de -12 à 0  nous somme
     * @param toMonthHisto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeHistoEreputationGet(hotCode: string, fromMonthHisto: number, toMonthHisto: number, observe?: 'body', reportProgress?: boolean): Observable<Array<HistoEReputation>>;
    public apiHotCodeHistoEreputationGet(hotCode: string, fromMonthHisto: number, toMonthHisto: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HistoEReputation>>>;
    public apiHotCodeHistoEreputationGet(hotCode: string, fromMonthHisto: number, toMonthHisto: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HistoEReputation>>>;
    public apiHotCodeHistoEreputationGet(hotCode: string, fromMonthHisto: number, toMonthHisto: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeHistoEreputationGet.');
        }

        if (fromMonthHisto === null || fromMonthHisto === undefined) {
            throw new Error('Required parameter fromMonthHisto was null or undefined when calling apiHotCodeHistoEreputationGet.');
        }

        if (toMonthHisto === null || toMonthHisto === undefined) {
            throw new Error('Required parameter toMonthHisto was null or undefined when calling apiHotCodeHistoEreputationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromMonthHisto !== undefined && fromMonthHisto !== null) {
            queryParameters = queryParameters.set('fromMonthHisto', <any>fromMonthHisto);
        }
        if (toMonthHisto !== undefined && toMonthHisto !== null) {
            queryParameters = queryParameters.set('toMonthHisto', <any>toMonthHisto);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HistoEReputation>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/histo/ereputation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prix Bar de l&#x27;hôtel et  min, max et mediane des concurents
     * 
     * @param hotCode 
     * @param from 
     * @param to 
     * @param filterPax 
     * @param filterSource 
     * @param filterRoomTypeStd 
     * @param filterNumbersOfNight 
     * @param filterRatePlan 
     * @param filterPensionCode 
     * @param filterAllPrices 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodePricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<RateShopperHotelPrices>>;
    public apiHotCodePricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateShopperHotelPrices>>>;
    public apiHotCodePricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateShopperHotelPrices>>>;
    public apiHotCodePricesGet(hotCode: string, from: Date, to: Date, filterPax?: number, filterSource?: string, filterRoomTypeStd?: string, filterNumbersOfNight?: number, filterRatePlan?: string, filterPensionCode?: string, filterAllPrices?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodePricesGet.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling apiHotCodePricesGet.');
        }

        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling apiHotCodePricesGet.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('From', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('To', <any>to.toISOString());
        }
        if (filterPax !== undefined && filterPax !== null) {
            queryParameters = queryParameters.set('filter.Pax', <any>filterPax);
        }
        if (filterSource !== undefined && filterSource !== null) {
            queryParameters = queryParameters.set('filter.Source', <any>filterSource);
        }
        if (filterRoomTypeStd !== undefined && filterRoomTypeStd !== null) {
            queryParameters = queryParameters.set('filter.RoomTypeStd', <any>filterRoomTypeStd);
        }
        if (filterNumbersOfNight !== undefined && filterNumbersOfNight !== null) {
            queryParameters = queryParameters.set('filter.NumbersOfNight', <any>filterNumbersOfNight);
        }
        if (filterRatePlan !== undefined && filterRatePlan !== null) {
            queryParameters = queryParameters.set('filter.RatePlan', <any>filterRatePlan);
        }
        if (filterPensionCode !== undefined && filterPensionCode !== null) {
            queryParameters = queryParameters.set('filter.PensionCode', <any>filterPensionCode);
        }
        if (filterAllPrices !== undefined && filterAllPrices !== null) {
            queryParameters = queryParameters.set('filter.AllPrices', <any>filterAllPrices);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateShopperHotelPrices>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/prices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Savoir si le RateShopper est initialisé
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotCodeRateShopperInitializedGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<RateShopperHotelAllPrices>;
    public apiHotCodeRateShopperInitializedGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RateShopperHotelAllPrices>>;
    public apiHotCodeRateShopperInitializedGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RateShopperHotelAllPrices>>;
    public apiHotCodeRateShopperInitializedGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiHotCodeRateShopperInitializedGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RateShopperHotelAllPrices>('get',`${this.basePath}/api/${encodeURIComponent(String(hotCode))}/rateShopperInitialized`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRateComparisonHotels: Simulation de PartityRate
     * 
     * @param hotelId 
     * @param key 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelIdGetRateComparisonHotelsGet(hotelId: string, key?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RateComparisonHotel>>;
    public apiHotelIdGetRateComparisonHotelsGet(hotelId: string, key?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateComparisonHotel>>>;
    public apiHotelIdGetRateComparisonHotelsGet(hotelId: string, key?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateComparisonHotel>>>;
    public apiHotelIdGetRateComparisonHotelsGet(hotelId: string, key?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelId === null || hotelId === undefined) {
            throw new Error('Required parameter hotelId was null or undefined when calling apiHotelIdGetRateComparisonHotelsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateComparisonHotel>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotelId))}/getRateComparisonHotels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRateComparisonRooms: Simulation de PartityRate
     * 
     * @param hotelId 
     * @param key 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiHotelIdGetRateComparisonRoomsGet(hotelId: string, key?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RateComparisonRoom>>;
    public apiHotelIdGetRateComparisonRoomsGet(hotelId: string, key?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RateComparisonRoom>>>;
    public apiHotelIdGetRateComparisonRoomsGet(hotelId: string, key?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RateComparisonRoom>>>;
    public apiHotelIdGetRateComparisonRoomsGet(hotelId: string, key?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelId === null || hotelId === undefined) {
            throw new Error('Required parameter hotelId was null or undefined when calling apiHotelIdGetRateComparisonRoomsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RateComparisonRoom>>('get',`${this.basePath}/api/${encodeURIComponent(String(hotelId))}/getRateComparisonRooms`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * viewRates: Simulation de PartityRate
     * 
     * @param key 
     * @param hotelId 
     * @param rateHotelId 
     * @param startDate Format : yyyy-MM-dd
     * @param endDate Format : yyyy-MM-dd
     * @param prestationCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiViewRatesGet(key: string, hotelId: string, rateHotelId: string, startDate: string, endDate: string, prestationCode?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ViewRate>>;
    public apiViewRatesGet(key: string, hotelId: string, rateHotelId: string, startDate: string, endDate: string, prestationCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ViewRate>>>;
    public apiViewRatesGet(key: string, hotelId: string, rateHotelId: string, startDate: string, endDate: string, prestationCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ViewRate>>>;
    public apiViewRatesGet(key: string, hotelId: string, rateHotelId: string, startDate: string, endDate: string, prestationCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling apiViewRatesGet.');
        }

        if (hotelId === null || hotelId === undefined) {
            throw new Error('Required parameter hotelId was null or undefined when calling apiViewRatesGet.');
        }

        if (rateHotelId === null || rateHotelId === undefined) {
            throw new Error('Required parameter rateHotelId was null or undefined when calling apiViewRatesGet.');
        }

        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling apiViewRatesGet.');
        }

        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling apiViewRatesGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }
        if (hotelId !== undefined && hotelId !== null) {
            queryParameters = queryParameters.set('hotelId', <any>hotelId);
        }
        if (rateHotelId !== undefined && rateHotelId !== null) {
            queryParameters = queryParameters.set('rateHotelId', <any>rateHotelId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (prestationCode !== undefined && prestationCode !== null) {
            queryParameters = queryParameters.set('prestationCode', <any>prestationCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ViewRate>>('get',`${this.basePath}/api/viewRates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
