import { DatePipe } from "@angular/common";
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { UserInfoService } from "src/app/azureAdB2C/UserInfoService/user-InfoService";
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { SubSink } from "src/app/dynamicForms/tools/subsink";
import { MappingConfig } from "../../services/misterbooking/model/mappingConfig";
import { AdminService } from "../../services/misterbooking/api/admin.service";
import { options } from "@fullcalendar/core/preact";
import { ICols } from "src/app/analyse/services/model/cols-table";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
    selector: "app-mapping",
    templateUrl: "./mapping.component.html",
    styleUrls: ["./mapping.component.css"],
})
export class MappingComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    currentHotel: HotelAuth;
    dataLoaded: boolean;
    hotelNotIntialized: boolean;
    mapping: any[];
    mappingListeAdd: any[];
    listeSpot: MappingConfig[];
    listeMisterBooking: MappingConfig[];
    listemappe: MappingConfig;
    listeGlobale: MappingConfig[] = [];
    selectedMisterBooking: MappingConfig;
    selectedSpot: MappingConfig;
    selectedMapping: MappingConfig;
    showMappingAdd: boolean;
    mappingCols: ICols[];
    mappingSpot: ICols[];
    mappingMisterbooking: ICols[];
    donneeCharge: boolean;
    rolesFormInfoService: string[];
    position: string = 'center';
    saveDialog : boolean = false;
    hotCode: string = null;

    constructor(
        private userInfoService: UserInfoService,
        private liveMisterBookingService: AdminService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.currentHotel = this.userInfoService.currentHotel;
        this.loadData();
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
                this.selectedSpot = undefined;
                this.selectedMapping = undefined;
                this.selectedMisterBooking = undefined;
                this.loadData();
            });
        this.mappingCols = [
            { field: "spotRatePlanCode", width: 250 },
            { field: "misterBookingRateId", width: 250 },
            { field: "", header: "", width: 50 },
        ];
        this.mappingSpot = [
            { field: "spotRatePlanCode", width: 125 },
            { field: "spotProduitVenduUnitCode", width: 125 },
        ];
        this.mappingMisterbooking = [
            { field: "misterBookingRateId", width: 125 },
            { field: "misterBookingRoomTypeId", width: 125 },
        ];

        this.userInfoService.getUserRoles$().subscribe((roles) => {
            this.rolesFormInfoService = roles;
        });
    }
    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }

    isAdmin(): boolean {
        if (
            this.rolesFormInfoService &&
            this.rolesFormInfoService.find((r) => r == "Administrator")
        ) {
            return true;
        }
        return false;
    }
    loadData() {
        this.donneeCharge = true;
        this.subs.sink = this.liveMisterBookingService
            .adminMappingsHotCodeGet(this.currentHotel.hotCode)
            .subscribe({
                next: (info) => {
                    this.listeGlobale = info;
                    this.updateData(info);
                    this.donneeCharge = false;

                },
                error: (error) => {
                    console.error(error);
                }
            });
    }
    sortingLists() {
        if (this.listeMisterBooking) {
           this.listeMisterBooking = this.listeMisterBooking.sort((a, b)=>{
            if(a.misterBookingRateHuman < b.misterBookingRateHuman) return -1;
            return 1;
        })
        }
        if (this.listeSpot) {
            this.listeSpot = this.listeSpot.sort((a, b)=>{
             if(a.spotProduitVenduUnitLabel < b.spotProduitVenduUnitLabel) return -1;
             return 1;
         })
         }
    }
    fusionsMapping() {
        this.showMappingAdd = true;
        this.selectedMisterBooking = null;
        this.selectedSpot = null;
    }
    cancel() {
        this.showMappingAdd = false;
        this.selectedMisterBooking = null;
        this.selectedSpot = null;
    }
    validerMapping() {
        let objInv: MappingConfig = {
            spotRatePlanCode: this.selectedSpot.spotRatePlanCode,
            spotProduitVenduUnitCode:
                this.selectedSpot.spotProduitVenduUnitCode,
                spotRatePlanLabel: this.selectedSpot.spotRatePlanLabel,
            spotProduitVenduUnitLabel:
                this.selectedSpot.spotProduitVenduUnitLabel,
            misterBookingRateId: this.selectedMisterBooking.misterBookingRateId,
            misterBookingRoomTypeId:
                this.selectedMisterBooking.misterBookingRoomTypeId,
                misterBookingRateHuman : this.selectedMisterBooking.misterBookingRateHuman,
        };
        this.listeGlobale = this.listeGlobale.concat(objInv);
        this.listeGlobale = this.listeGlobale.filter((option) => {
            return (
                option !== this.selectedSpot &&
                option !== this.selectedMisterBooking
            );
        });
        this.updateData(this.listeGlobale);
        this.showMappingAdd = false;
    }
    updateData(info){
        this.listeSpot = info.filter(
            (option) =>
                option.spotRatePlanCode &&
                option.spotProduitVenduUnitCode &&
                !option.misterBookingRateId &&
                !option.misterBookingRoomTypeId
        );
        this.listemappe = info.filter(
            (option) =>
                option.spotRatePlanCode &&
                option.spotProduitVenduUnitCode &&
                option.misterBookingRateId &&
                option.misterBookingRoomTypeId
        );
        this.listeMisterBooking = info.filter(
            (option) =>
                option.misterBookingRateId &&
                option.misterBookingRoomTypeId &&
                !option.spotRatePlanCode &&
                !option.spotProduitVenduUnitCode
        ); 
        this.sortingLists();
    }
    saveMappingComfirmation(){
        this.hotCode = null;
        this.saveDialog = true;
    }
   saveMapping(){
    this.saveDialog = false;
    this.donneeCharge = true;
            this.subs.sink = this.liveMisterBookingService
            .adminMappingsHotCodePut(
                this.listeGlobale,
                this.currentHotel.hotCode
            )
            .subscribe({
                next: (info) => {this.loadData();},
                error: (error) => {
                    console.error(error);
                },
            });
    
}
    deleteFromMapping(rowData) {
                let objSpot: MappingConfig = {
                    spotRatePlanCode: rowData.spotRatePlanCode,
                    spotProduitVenduUnitCode: rowData.spotProduitVenduUnitCode,
                    spotRatePlanLabel: rowData.spotRatePlanLabel,
                    spotProduitVenduUnitLabel: rowData.spotProduitVenduUnitLabel,
                    misterBookingRateId: null,
                    misterBookingRoomTypeId: null,
                    misterBookingRateHuman : null,
                };
                let objMisterBooking: MappingConfig = {
                    spotRatePlanCode: null,
                    spotProduitVenduUnitCode: null,
                    spotRatePlanLabel: null,
                    spotProduitVenduUnitLabel: null,
                    misterBookingRateId: rowData.misterBookingRateId,
                    misterBookingRoomTypeId: rowData.misterBookingRoomTypeId,
                    misterBookingRateHuman : rowData.misterBookingRateHuman,
                };
                this.listeGlobale = this.listeGlobale.concat(
                    objSpot,
                    objMisterBooking
                );
                this.listeGlobale = this.listeGlobale
                    .filter((substract) => substract !== rowData)
                    .map(
                        ({
                            spotRatePlanCode,
                            spotProduitVenduUnitCode,
                            misterBookingRateId,
                            misterBookingRoomTypeId,
                            spotProduitVenduUnitLabel,
                            spotRatePlanLabel,
                            misterBookingRateHuman
                        }) => ({
                            spotRatePlanCode,
                            spotProduitVenduUnitCode,
                            misterBookingRateId,
                            misterBookingRoomTypeId,
                            spotProduitVenduUnitLabel,
                            spotRatePlanLabel,
                            misterBookingRateHuman
                        })
                    );
                    this.updateData(this.listeGlobale);
    }
}
