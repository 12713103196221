<div class="cell" [ngClass]="getClassJour()"
    [ngStyle]="{'font-weight':getValue()?.hasYiledLevierNumerique==true?'bold':'' }" [style.width.px]="cellwidth"
    [style.height.px]="cellHeight" [style.line-height.px]="cellHeight">

    <div *ngIf="getValue()?.levierNumerique !=null">
        <i *ngIf="getValue()?.isOpen===false" class="pi pi-times" [ngClass]="getCalssYiledOpenClose()"
            style="color: black; font-size: 1rem ">
        </i>
        <span *ngIf="getValue()?.isOpen===true">
            {{getValue()?.levierNumeriqueValue }}
        </span>

    </div>

    <div *ngIf="getValue()?.levierNumerique ==null">
        <i *ngIf="getValue()?.isOpen===true" class="pi pi-check" [ngClass]="getCalssYiledOpenClose()"
            style="color: green; font-size: 1rem ">
        </i>
        <i *ngIf="getValue()?.isOpen===false" class="pi pi-times"  [ngClass]="getCalssYiledOpenClose()"
            style="color: black; font-size: 1rem ">
        </i>
    </div>

</div>
