/**
 * Spot PBIEmbedded-SF Api V2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiWorkSpaces } from '../model/apiWorkSpaces';
import { CreateWorkSpace } from '../model/createWorkSpace';
import { Group } from '../model/group';
import { UpdateRole } from '../model/updateRole';
import { UserInfos } from '../model/userInfos';
import { WorkSpaceInfos } from '../model/workSpaceInfos';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminWorkspaceService {

    protected basePath = 'https://services-dev.spotpilot.com/pbiembedded-v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Liste de tous les Workspaces
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesAllGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ApiWorkSpaces>>;
    public apiAdminWorkSpacesAllGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiWorkSpaces>>>;
    public apiAdminWorkSpacesAllGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiWorkSpaces>>>;
    public apiAdminWorkSpacesAllGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiWorkSpaces>>('get',`${this.basePath}/api/admin/workSpaces/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un WorkSpace
     * 
     * @param workSpaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesDelete(workSpaceId?: string, observe?: 'body', reportProgress?: boolean): Observable<Group>;
    public apiAdminWorkSpacesDelete(workSpaceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Group>>;
    public apiAdminWorkSpacesDelete(workSpaceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Group>>;
    public apiAdminWorkSpacesDelete(workSpaceId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workSpaceId !== undefined && workSpaceId !== null) {
            queryParameters = queryParameters.set('workSpaceId', <any>workSpaceId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Group>('delete',`${this.basePath}/api/admin/workSpaces`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Workspace infos by id
     * 
     * @param workSpaceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesGet(workSpaceId?: string, observe?: 'body', reportProgress?: boolean): Observable<WorkSpaceInfos>;
    public apiAdminWorkSpacesGet(workSpaceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkSpaceInfos>>;
    public apiAdminWorkSpacesGet(workSpaceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkSpaceInfos>>;
    public apiAdminWorkSpacesGet(workSpaceId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workSpaceId !== undefined && workSpaceId !== null) {
            queryParameters = queryParameters.set('workSpaceId', <any>workSpaceId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WorkSpaceInfos>('get',`${this.basePath}/api/admin/workSpaces`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un WorkSpace pour un groupe d&#x27;hotels
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesPost(body?: CreateWorkSpace, observe?: 'body', reportProgress?: boolean): Observable<Group>;
    public apiAdminWorkSpacesPost(body?: CreateWorkSpace, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Group>>;
    public apiAdminWorkSpacesPost(body?: CreateWorkSpace, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Group>>;
    public apiAdminWorkSpacesPost(body?: CreateWorkSpace, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Group>('post',`${this.basePath}/api/admin/workSpaces`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesRolePost(body?: UpdateRole, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminWorkSpacesRolePost(body?: UpdateRole, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminWorkSpacesRolePost(body?: UpdateRole, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminWorkSpacesRolePost(body?: UpdateRole, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/admin/workSpaces/role`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprimer un utilisateur dans un workspace
     * 
     * @param workSpaceId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesUsersDelete(workSpaceId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminWorkSpacesUsersDelete(workSpaceId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminWorkSpacesUsersDelete(workSpaceId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminWorkSpacesUsersDelete(workSpaceId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workSpaceId !== undefined && workSpaceId !== null) {
            queryParameters = queryParameters.set('workSpaceId', <any>workSpaceId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('delete',`${this.basePath}/api/admin/workSpaces/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liste des utilisateurs
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesUsersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserInfos>>;
    public apiAdminWorkSpacesUsersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserInfos>>>;
    public apiAdminWorkSpacesUsersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserInfos>>>;
    public apiAdminWorkSpacesUsersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserInfos>>('get',`${this.basePath}/api/admin/workSpaces/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ajouter un utilisateur  dans un workspace
     * 
     * @param workSpaceId 
     * @param identifier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminWorkSpacesUsersPost(workSpaceId?: string, identifier?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminWorkSpacesUsersPost(workSpaceId?: string, identifier?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminWorkSpacesUsersPost(workSpaceId?: string, identifier?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminWorkSpacesUsersPost(workSpaceId?: string, identifier?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workSpaceId !== undefined && workSpaceId !== null) {
            queryParameters = queryParameters.set('workSpaceId', <any>workSpaceId);
        }
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/admin/workSpaces/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
