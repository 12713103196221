<p-confirmPopup></p-confirmPopup>
<p-toolbar>
    <div class="p-toolbar-group-left">

        <div *ngIf="dataError">
            <div class="textWithButton">
                L'hôtel:<b>{{currentHotel.name}}</b> pas encore initialisé
            </div>
            <p-button label="Initialiser" icon="pi pi-chevron-circle-right" (onClick)="InitHotel($event)">
            </p-button>
        </div>


        <div *ngIf="!dataError">
            <div class="hotelTitle mr-1 font-bold">
                Concurrent
            </div>
            <p-button icon="pi pi-plus" [disabled]=" !dataLoaded" styleClass="p-button-primary"
                (onClick)="OpenAddCompetitorsDialog()" class="mr-1"> </p-button>
            <p-button icon="pi pi-trash" [disabled]=" !selectedCompetitor" (onClick)="removeSelectedCompetitor($event)"
                styleClass="p-button-danger" class="mr-5">
            </p-button>

            <div class="hotelTitle mr-1 font-bold">
                Type
            </div>
            <p-button icon="pi pi-plus" [disabled]=" !dataLoaded" styleClass="p-button-primary"
                (onClick)="addTypeStdDialog($event)" class="mr-1"></p-button>
            <p-button icon="pi pi-pencil" styleClass="p-button-success" [disabled]="!selectedTypeStd"
                (onClick)="updateTypeStdDialog($event)" class="mr-1">
            </p-button>
            <p-button icon="pi pi-trash" [disabled]=" !selectedTypeStd" styleClass="p-button-danger"
                (onClick)="removeSelectedTypeStd($event)" class="mr-4"></p-button>

            <p-button icon="pi pi-save" [disabled]=" !dataLoaded || !dataUpdated" (onClick)="updateMappings()">
            </p-button>
        </div>
    </div>
</p-toolbar>

<!-- ajouter un type -->
<p-overlayPanel [showTransitionOptions]="'2ms'" [hideTransitionOptions]="'2ms'" #op [dismissable]="true">
    <ng-template pTemplate>
        <div class="card">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="roomTypeNumber">Numéro</label>

                    <p-inputNumber inputId="roomTypeNumber" [(ngModel)]="typStdToAddorUpdate.roomTypeNumber"
                        autocomplete="off">
                    </p-inputNumber>


                </div>
                <div class="p-field">
                    <label for="roomTypeStd">Nom</label>
                    <input id="roomTypeStd" type="text" pInputText [(ngModel)]="typStdToAddorUpdate.roomTypeStd"
                        autocomplete="off">
                </div>
            </div>
            <div style="text-align: center;">
                <p-button icon="pi pi-check" (onClick)="addOrUpdateTypeStd()" label="Valider"
                    styleClass="p-button-text">
                </p-button>
                <p-button icon="pi pi-times" (onClick)="op.hide()" label="Annuller" styleClass="p-button-text">
                </p-button>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<table class="p-datatable" *ngIf="dataLoaded">
    <thead class="p-datatable-thead">
        <tr>
            <th style="width: 200px;" class="p-text-center">Hotels</th>
            <th class="p-text-center" style="width: 250px;">Types non mappés</th>
            <th *ngFor="let typStd of ratShopperMappings.roomTypeStandards" (click)="selectTypeStandard(typStd)"
                [class.selectedTypeStd]="typStd.roomTypeNumber== selectedTypeStd?.roomTypeNumber" class="p-text-center"
                style="width: 250px;">
                {{typStd.roomTypeNumber}} - {{typStd.roomTypeStd}}
            </th>
        </tr>

    </thead>
    <tbody class="p-datatable-tbody">
        <tr *ngFor="let mapObj of ratShopperMappings.mappings">
            <td (click)="selectCompetitors($event, mapObj)" class="p-text-center font-bold"
                [class.principal]="mapObj.isPrincipal"
                [class.selectedCompetitor]="selectedCompetitor!=null && selectedCompetitor.rateShopperId== mapObj.rateShopperId">
                <a [href]="'https://'+mapObj.bookingUrl" target="_blank" class="linkBooking">
                    <span
                        [class.selectedCompetitor]="selectedCompetitor!=null && selectedCompetitor.rateShopperId== mapObj.rateShopperId">
                        {{mapObj.hotelName}}
                        <span *ngIf="mapObj.isDisabled==true">
                            <i class="pi pi-times" style="color: red;"></i>
                        </span>
                    </span>
                </a>
            </td>

            <td pDroppable="Mapped" (onDrop)="dropToNotMapped(mapObj)">
                <div *ngFor="let notMap of mapObj.notMappedRoomTypes">

                    <div pDraggable="Mapped" (onDragStart)="dragNotMappedStart(mapObj,notMap)"
                        (onDragEnd)="dragNotMappedEnd($event)" class="notMapTypeName">
                        {{notMap.roomTypeName}}
                    </div>
                </div>
            </td>

            <td *ngFor="let typStd of ratShopperMappings.roomTypeStandards" pDroppable="Mapped"
                (onDrop)="dropToMapped(mapObj,typStd)">

                <div pDraggable="Mapped" *ngFor="let mapRoomtype of getMappedRoomType(mapObj,typStd)"
                    (onDragStart)="dragMappedStart(mapObj,mapRoomtype)" (onDragEnd)="dragMappedEnd($event)"
                    class="mapTypeName">
                    {{mapRoomtype.roomTypeName}}
                </div>

            </td>
        </tr>

    </tbody>
</table>


<!-- Ecran  d'ajout d'un nouvau concurrent-->
<p-dialog [(visible)]="displayAddCompForm" [modal]="true" [style]="{width: '860px', height:'675px'}">
    <p-header>
        Nouveau concurrent
    </p-header>
    <div style="height: 520px;overflow: hidden; ">
        <p-table #dt2 [columns]="colsHotels" [value]="hotels" [rows]="10"
            styleClass="p-datatable-sm p-datatable-striped" selectionMode="single"
            [(selection)]="selectedCompetitorToAdd" dataKey="rateShopperId">
            <ng-template pTemplate="caption">
                <div style="text-align: left">
                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="dt2.filterGlobal($event.target.value, 'contains')" style="width:auto">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                </div>
            </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">

                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" class="ui-resizable-column">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="col.field=='eaxessId'">
                                {{rowData[col.field]}}
                                <span *ngIf="rowData['isDisabled']==true">
                                    <i class="pi pi-times" style="color: red;"></i>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>

            </ng-template>

        </p-table>
    </div>
    <p-footer>
        <p-button label="Ajouter" icon="pi pi-plus" [disabled]="addCompetitorsDesabled()" (onClick)="addCompetitors()">
        </p-button>
        <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger" (onClick)="displayAddCompForm=false">
        </p-button>
    </p-footer>

</p-dialog>
