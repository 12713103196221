import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { BudgetService } from '../services/api/budget.service';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { TauxTva } from '../services/model/tauxTva';
import * as moment from 'moment';
import { formatDate } from '@fullcalendar/core';
import { DatePipe } from '@angular/common';
import { forkJoin, lastValueFrom } from 'rxjs';
import { BudgetInfo } from '../services/model/budgetInfo';

@Component({
  selector: "app-budget-tva",
  templateUrl: "./budget-tva.component.html",
  styleUrls: ["./budget-tva.component.css"],
})
export class BudgetTvaComponent implements OnInit, OnDestroy {
  currentHotel: HotelAuth;
  displayFormTva: boolean;
  tva: TauxTva[] = [];
  tvaFormValue: TauxTva = {};
  tvaCols: ICols[];
  private subs = new SubSink();
  dataLoaded: boolean;
  selectedTva :TauxTva ={};
  isNewTva: boolean;
  minDate: Date;
  maxDate : Date;
  isValideDate: boolean = true;
  tvaLastElem: TauxTva;
  dateEnd: string;
  isHt: boolean = false;
  isBlank: boolean = false;
  tvaExist: boolean[];
  rolesFormInfoService: string[];
  budgetExist: boolean = false;
  position: string = 'center';

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private userInfoService: UserInfoService,
    private notifService: NotificationsService,
    private budgetService: BudgetService,
    private datePipe: DatePipe
  ) {
    this.breadcrumbService.setItems([{ label: "analyse/budget-tva" }]);
  }

  ngOnInit(): void {
    this.subs.sink = this.userInfoService.getNewHotel$().subscribe((hotCode) => {
      this.currentHotel = hotCode;
      setTimeout(() => {
        this.loadData();
      }, 100);
    });
    this.userInfoService.getUserRoles$().subscribe((roles) => {
      this.rolesFormInfoService = roles;
  });
    this.tvaCols = [
      { field: "dateFrom", header: "Date Début", width: 100 },
      { field: "dateEnd", header: "Date fin", width: 100 },
      { field: "cah", header: "Cah" },
      { field: "caPdj", header: "Ca Pdj" },
      { field: "p3", header: "P3" },
      { field: "p4", header: "P4" },
      { field: "p5", header: "P5" },
      { field: "p6", header: "P6" },
      { field: "p7", header: "P7" },
      { field: "p8", header: "P8" },
      { field: "p9", header: "P9" },
      { field: "", header: "", width: 100 },
    ]; 
  }
  // GetDisbaledBudget():boolean
  //   {
  //       if(this.isAdmin()) return false;
  //       return this.budgetExist;
  //   }
  isAdmin(): boolean {
      if (
          this.rolesFormInfoService &&
          this.rolesFormInfoService.find((r) => r == "Administrator")
      ) {
          return true;
      }
      return false;
  }
  async loadData() {
    let hotCode = this.currentHotel.hotCode;
    
      const obs1$ = this.budgetService.apiBudgetHotCodeTvaGet(hotCode);
      const obs2$ = this.budgetService.apiBudgetHotCodeBudgetInfoGet(hotCode);

      this.subs.sink = forkJoin([obs1$, obs2$]).subscribe({
        next: result => {this.tva = result[0];
          this.isHt = result[1].isHT;
          this.budgetExist = result[1].budgetExist;
          if (this.tva?.length > 0) {
            this.dataLoaded = true;
          } else this.dataLoaded = false;},
          error:(error) =>{
            this.dataLoaded = false;
            console.log(error?.error);
          }});
      
  }

   
  handleChange(e, position: string) {
    this.position = position;
    this.confirmationService.confirm({
      target: event.target,
      message:`Confirmation de Basculer le budget tva de TTC en HT ?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Oui",
      rejectLabel: "Non",
      accept: () => {
       this.isHt = e.checked;
        
            this.notifService.setMessage(`basculement avec succès de TTC en HT `,"Budget tva", false);
         
          },
      reject: () => {
        this.isHt = !e.checked;
        this.messageService.add({ severity: 'error', summary: 'BudgetTVA', detail: 'Opération annulée' });
      }
    });
    let budgetInfo : BudgetInfo = {};
    budgetInfo.hotCode = this.currentHotel.hotCode;
    budgetInfo.isHT = this.isHt;
    this.subs.sink = this.budgetService.apiBudgetHotCodeBudgetInfoPut(this.currentHotel.hotCode, budgetInfo).subscribe({
      next: (data) => {
        //this.loadData();
      },
      error: (error) => {
      }
  });

  }

  newTVA() {
    this.isNewTva = true;
    this.displayFormTva = true;
    this.tvaFormValue = {cah:0, caPdj:0, p3:0, p4:0, p5:0, p6:0, p7:0, p8:0, p9:0};
    this.isValideDate =true;
    this.isBlank = false;

  }
  editTva(selectedTva : TauxTva){
    this.isNewTva = false;
    this.isBlank = false;
    this.selectedTva = selectedTva;
    this.tvaFormValue = selectedTva;
    this.dateEnd = selectedTva.dateEnd.toString();
    this.displayFormTva = true;
    this.isValideDate =true;
    this.tvaFormValue.dateFrom = new Date(selectedTva.dateFrom);
    this.tvaFormValue.dateEnd = selectedTva.dateEnd ? new Date(selectedTva.dateEnd) : '';
  }
  deleteTva(event, selectedTva : TauxTva){
   let hotCode = this.currentHotel.hotCode;
   let dateFrom = this.datePipe.transform(selectedTva.dateFrom, "yyyy-MM-dd");
  this.confirmationService.confirm({
    target: event.target,
    message:`Confirmation de suppression de budget tva du ${dateFrom}?`,
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: "Oui",
    rejectLabel: "Non",
    accept: () => {
      this.subs.sink = this.budgetService.apiBudgetHotCodeTvaDelete(hotCode, dateFrom).subscribe({
        next:(data) => {
          this.loadData();
          this.notifService.setMessage(`suppression avec succès de budget tva du ${dateFrom}`,"Budget tva", false);
        },
        error:(error) => {
          this.notifService.setMessage(error?.error,"Budget tva", true);
        }
    });
    },
    reject: () => {
      this.messageService.add({ severity: 'error', summary: 'BudgetTVA', detail: 'Opération annulée' });
    }
  });

  }
  cancel() {
    this.displayFormTva = false;
    this.tvaFormValue = {};
    this.isNewTva = false;
    this.isValideDate = true;
  }
  onRowSelect($event){
  }
  selecteDate(event){
    this.tvaFormValue.dateFrom = event;
  }
  validate() {
    let tvaFormValue = Object.assign({}, this.tvaFormValue);
    tvaFormValue.dateFrom = this.datePipe.transform(tvaFormValue.dateFrom, "yyyy-MM-dd");
    tvaFormValue.dateEnd = tvaFormValue.dateEnd
    ? this.datePipe.transform(tvaFormValue.dateEnd, "yyyy-MM-dd") : "";
    tvaFormValue.hotCode = this.currentHotel.hotCode;
    tvaFormValue.isHt = this.isHt;
    this.isBlank = !tvaFormValue.dateFrom || tvaFormValue.dateFrom == "";
     let isFormValidateTva = tvaFormValue.dateFrom  && tvaFormValue.caPdj >= 0 && tvaFormValue.cah >= 0
     && tvaFormValue.hotCode && tvaFormValue.p3 >= 0 && tvaFormValue.p4 >= 0 && tvaFormValue.p5 >= 0
     && tvaFormValue.p6 >= 0 && tvaFormValue.p7 >= 0 && tvaFormValue.p8 >= 0 && tvaFormValue.p9 >= 0;
    if (isFormValidateTva) {
      if (this.isNewTva) {
        this.subs.sink = this.budgetService.apiBudgetHotCodeTvaPost(this.currentHotel.hotCode, tvaFormValue).subscribe({
          next:(data) => {
            this.loadData();
            this.cancel();
            this.notifService.setMessage(`Enregistrement avec succès du budget tva`,"Budget tva", false);
          },
          error:(error) => {
            this.notifService.setMessage(error?.error,"Budget tva", true);
          }
      });
      }else {
          this.subs.sink = this.budgetService.apiBudgetHotCodeTvaPut(this.currentHotel.hotCode, tvaFormValue).subscribe({
            next:(data) => {
              this.loadData();
              this.cancel();
              this.notifService.setMessage(`Enregistrement avec succès du budget tva`,"Budget", false);
            },
            error:(error) => {
              this.tvaFormValue.dateEnd = this.dateEnd;
              this.notifService.setMessage(error?.error,"Budget", true);
            }
      });

      }

    }
  }

  ngOnDestroy(): void {
    if (this.subs != undefined) {
      this.subs.unsubscribe();
    }
  }
}

