/**
 * Spot BackOffice Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CredentialsAsterio } from '../model/credentialsAsterio';
import { PipelineStatus } from '../model/pipelineStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ComptaService {

    protected basePath = 'https://services.spotpilot.com/backoffice-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<CredentialsAsterio>;
    public apiComptaAsterioGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CredentialsAsterio>>;
    public apiComptaAsterioGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CredentialsAsterio>>;
    public apiComptaAsterioGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CredentialsAsterio>('get',`${this.basePath}/api/Compta/asterio`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param nbDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioPipelineStatusGet(hotCode: string, nbDays: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PipelineStatus>>;
    public apiComptaAsterioPipelineStatusGet(hotCode: string, nbDays: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PipelineStatus>>>;
    public apiComptaAsterioPipelineStatusGet(hotCode: string, nbDays: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PipelineStatus>>>;
    public apiComptaAsterioPipelineStatusGet(hotCode: string, nbDays: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioPipelineStatusGet.');
        }

        if (nbDays === null || nbDays === undefined) {
            throw new Error('Required parameter nbDays was null or undefined when calling apiComptaAsterioPipelineStatusGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (nbDays !== undefined && nbDays !== null) {
            queryParameters = queryParameters.set('nbDays', <any>nbDays);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PipelineStatus>>('get',`${this.basePath}/api/Compta/asterio/pipeline-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param kmlCode 
     * @param login 
     * @param password 
     * @param siteCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioSaveCredentialsPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiComptaAsterioSaveCredentialsPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiComptaAsterioSaveCredentialsPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiComptaAsterioSaveCredentialsPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioSaveCredentialsPost.');
        }

        if (kmlCode === null || kmlCode === undefined) {
            throw new Error('Required parameter kmlCode was null or undefined when calling apiComptaAsterioSaveCredentialsPost.');
        }

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling apiComptaAsterioSaveCredentialsPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling apiComptaAsterioSaveCredentialsPost.');
        }

        if (siteCode === null || siteCode === undefined) {
            throw new Error('Required parameter siteCode was null or undefined when calling apiComptaAsterioSaveCredentialsPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (kmlCode !== undefined && kmlCode !== null) {
            queryParameters = queryParameters.set('KmlCode', <any>kmlCode);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('Login', <any>login);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('Password', <any>password);
        }
        if (siteCode !== undefined && siteCode !== null) {
            queryParameters = queryParameters.set('SiteCode', <any>siteCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Compta/asterio/save-credentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioStartPipelineInitPost(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiComptaAsterioStartPipelineInitPost(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiComptaAsterioStartPipelineInitPost(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiComptaAsterioStartPipelineInitPost(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioStartPipelineInitPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Compta/asterio/start-pipeline-init`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param kmlCode 
     * @param login 
     * @param password 
     * @param siteCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioStartPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiComptaAsterioStartPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiComptaAsterioStartPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiComptaAsterioStartPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioStartPost.');
        }

        if (kmlCode === null || kmlCode === undefined) {
            throw new Error('Required parameter kmlCode was null or undefined when calling apiComptaAsterioStartPost.');
        }

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling apiComptaAsterioStartPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling apiComptaAsterioStartPost.');
        }

        if (siteCode === null || siteCode === undefined) {
            throw new Error('Required parameter siteCode was null or undefined when calling apiComptaAsterioStartPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (kmlCode !== undefined && kmlCode !== null) {
            queryParameters = queryParameters.set('KmlCode', <any>kmlCode);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('Login', <any>login);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('Password', <any>password);
        }
        if (siteCode !== undefined && siteCode !== null) {
            queryParameters = queryParameters.set('SiteCode', <any>siteCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Compta/asterio/start`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param kmlCode 
     * @param login 
     * @param password 
     * @param siteCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaAsterioTestConnectionPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiComptaAsterioTestConnectionPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiComptaAsterioTestConnectionPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiComptaAsterioTestConnectionPost(hotCode: string, kmlCode: string, login: string, password: string, siteCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiComptaAsterioTestConnectionPost.');
        }

        if (kmlCode === null || kmlCode === undefined) {
            throw new Error('Required parameter kmlCode was null or undefined when calling apiComptaAsterioTestConnectionPost.');
        }

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling apiComptaAsterioTestConnectionPost.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling apiComptaAsterioTestConnectionPost.');
        }

        if (siteCode === null || siteCode === undefined) {
            throw new Error('Required parameter siteCode was null or undefined when calling apiComptaAsterioTestConnectionPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (kmlCode !== undefined && kmlCode !== null) {
            queryParameters = queryParameters.set('KmlCode', <any>kmlCode);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('Login', <any>login);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('Password', <any>password);
        }
        if (siteCode !== undefined && siteCode !== null) {
            queryParameters = queryParameters.set('SiteCode', <any>siteCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Compta/asterio/test-connection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiComptaTestGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiComptaTestGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiComptaTestGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiComptaTestGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Compta/test`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
