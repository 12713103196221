import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProduitVendu } from '../../services/model/produitVendu';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { ActivatePackageCmd, ActivateProduitVenduCmd, AllProduitstHotel, CreatePackageCmd, CreatePackageTypeCmd, DistribSession, ModelPackage, NewProduitVenduCmd, PackageType, UpdatePackageCmd, UpdateProduitVenduCmd } from '../../services/model/models';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { ProduitService } from '../../services/api/api';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { aN } from '@fullcalendar/core/internal-common';

@Component({
    selector: 'app-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit, OnDestroy, AfterViewInit {

    private packageTypeList: Array<PackageType>;

    private readonly destroy$: Subject<void>;
    @Input() allProduits: AllProduitstHotel;
    @Output() OnRealodData = new EventEmitter()

    displayPkgForm: boolean;
    pkgFormConfig: FormConfig;

    @ViewChild('formpkg') formDynamicPkg: FormDynamicComponent;

    @ViewChild('newFormpkg') formDynamicNewPkg: FormDynamicComponent;


    //
    formPkgValue: any;

    selectedPkg: ModelPackage;

    readOnlyPkgFormConfig: FormConfig;
    displayNewPkgForm: boolean;
    newPkgFormConfig: FormConfig;
    displayNewPkgdiag: boolean;

    isNewPackageType: boolean = false;

    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService, private produitService: ProduitService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
    }
    ngAfterViewInit(): void {
    }

    private buildPackageTypeList() {
        let newtype: PackageType = {
            pkgTypeCode: "--new--",
            libelle: "Nouveau type"
        }

        this.packageTypeList = [newtype, ...this.allProduits.packageTypeList];
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    this.loadForm();
                }, 100);
            });
    }

    private relaodData():void {
        this.OnRealodData.emit();
        this.loadForm();
    }
    private loadForm(): void {
        this.produitService.apiProduitPkgFormGet(this.userInfoService.currentHotel.hotCode, false)
            .pipe(takeUntil(this.destroy$))
            .subscribe(frm => {
                this.readOnlyPkgFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
            });
        this.newPkgFormConfig = null;
        this._selectedPkgType = null;
    }

    cancel($event): void {
        this.displayPkgForm = false;
        this.displayNewPkgdiag=false;
    }


    UpdatePkg(selectedPkg: ModelPackage): void {
        this.SetParamCode(true);  // rendre le champ code non editable et  requis
        this.selectedPkg = selectedPkg;
        this.formPkgValue = {
            type: selectedPkg.pkgTypeCode,
            package: {
                code: selectedPkg.code,
                libelle: selectedPkg.libelle,
                prdv1: selectedPkg.items[0].produitVenduCode,
                prdv2: selectedPkg.items.length > 1 ? selectedPkg.items[1].produitVenduCode : null,
                countPrdv1: selectedPkg.items.length > 0 ? selectedPkg.items[0].count : null,
                countPrdv2: selectedPkg.items.length > 1 ? selectedPkg.items[1].count : null

            }
        };
        this.pkgFormConfig = this.readOnlyPkgFormConfig;
        this.displayPkgForm = true;
    }


    private _selectedPkgType: string;

    get selectedPkgType(): string {
        return this._selectedPkgType;
    }

    set selectedPkgType(value: string) {
        this.displayNewPkgForm = false;
        this._selectedPkgType = value;

        if (this._selectedPkgType == "--new--") {
            this.isNewPackageType = true;
            this.produitService.apiProduitNewPkgtypeFormGet(this.userInfoService.currentHotel.hotCode)
                .pipe(takeUntil(this.destroy$))
                .subscribe(frm => {
                    this.newPkgFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
                    this.displayNewPkgForm = true;
                })
        }
        else {
            this.isNewPackageType = false;
            this.produitService.apiProduitNewPkgFormGet(this.userInfoService.currentHotel.hotCode, this._selectedPkgType)
                .pipe(takeUntil(this.destroy$))
                .subscribe(frm => {
                    this.newPkgFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
                    this.displayNewPkgForm = true;
                })
        }
    }

    NewPkg(): void {
        this.buildPackageTypeList();
        this.displayNewPkgdiag = true;
        this._selectedPkgType = null;
        this.displayNewPkgForm = false;
        this.newPkgFormConfig = undefined;
    }

    DelPkg(selectedPgk: ModelPackage): void {

        let cmd: ActivatePackageCmd = {
            hotCode: this.allProduits.hotCode,
            activate: !selectedPgk.isObsolete,
            code: selectedPgk.code
        };

        this.produitService.apiProduitPackagesActivatePut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                   this.relaodData();
                },
                error: err => {
                    //todo : boit de dialoggue ou toast
                }
            })
    }



    UpdatePkgInDB(value: any): void {
        const newPkg: PakageFormModel = value.package;
        let existingPkg = this.allProduits.packageList.find(x => x.code == newPkg.code)

        existingPkg = { ...existingPkg }; //cloner l'objet

        existingPkg.libelle = newPkg.libelle;

        let cmd: UpdatePackageCmd = {
            hotCode: this.allProduits.hotCode,
            modelPackage: existingPkg,
        };

        this.produitService.apiProduitPackagePut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.UpdateCompoment(session, existingPkg);
                },
                error: err => {
                    this.SetError(err, "package", this.formDynamicPkg);
                }
            })
    }

    CreatePackage(value: any): void {

        console.log(JSON.stringify(value));;

        if (this.isNewPackageType) { //création d'un nouveau type de package avec les produits
            const pkgTyp = value.newPackageType;
            const cmd :CreatePackageTypeCmd = {
                hotCode:this.userInfoService.currentHotel.hotCode,
                packageTypeCode:pkgTyp.packageTypeCode,
                packageTypeLibelle:pkgTyp.packageTypeLibelle,
                prdvBase:pkgTyp.prdvBase,
                prdvlist:pkgTyp.prdvlist
            }
            this.produitService.apiProduitPackageTypePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    this.relaodData();
                    this.displayNewPkgdiag = false;
                },
                error: err => {
                    this.SetError(err, "newPackageType", this.formDynamicNewPkg);
                }
            })
        }
        else {
            const cmd: CreatePackageCmd = {
                hotCode: this.userInfoService.currentHotel.hotCode,
                packageTypeCode: this.selectedPkgType,
                code: value.newPackage.code,
                libelle: value.newPackage.libelle,
                prdv1: value.newPackage.prdv1,
                countPrdv1: value.newPackage.countPrdv1,
                prdv2: value.newPackage.prdv2,
                countPrdv2: value.newPackage.countPrdv2
            }
            this.produitService.apiProduitPackagePost(cmd)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: session => {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.relaodData();
                        this.displayNewPkgdiag = false;
                    },
                    error: err => {
                        this.SetError(err, "newPackage", this.formDynamicNewPkg);
                    }
                })
        }
    }

    UpdateCompoment(session: DistribSession, newPkg: ModelPackage): void {
        this.displayPkgForm = false;
        let oldpkg = this.allProduits.packageList.find(x => x.code == newPkg.code);
        if (oldpkg) {
            Object.assign(oldpkg, { ...newPkg });
        }
        else {
            this.allProduits.produitVenduList.push(newPkg);
        }
        this.distribSessionService.newSession(session.hotCode, session.sessionId);
    }

    SetError(session: any, rootField: string, form: FormDynamicComponent): void {
        let error = new FormErrors();
        error.errors = new ErrorDictionary();
        error.errors[rootField] = [session.error.error];  // inventaire c'est le vertical groupe racine

        form.setExternalErrors(error);
    }
    private SetParamCode(update: boolean) {
        let paramCode = this.pkgFormConfig?.parameters[0]?.childParameters?.find(x => x.code == "code");
        if (paramCode) {
            paramCode.readOnly = update;
            paramCode.required = update;
        }
    }

}

class PakageFormModel {
    code: string;
    libelle: string;
    prdv1: string;
    prdv2: string;
    type: string;
    nbChb: number;
}
