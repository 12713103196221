<div [class]="getGridCalss()">
    <div *ngFor="let field of formParams" [ngSwitch]="true">

        <ng-container *ngSwitchCase="field.type === 'HGroup'">
            <ng-container *ngIf="!field.formArray">
                <app-hgroup-dynamic [formGroup]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-hgroup-dynamic>
            </ng-container>

            <ng-container *ngIf="field.formArray">
                <app-hgroup-dynamic [formArray]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-hgroup-dynamic>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'VGroup'">
            <ng-container *ngIf="!field.formArray">
                <app-vgroup-dynamic [formGroup]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-vgroup-dynamic>
            </ng-container>

            <ng-container *ngIf="field.formArray">
                <app-vgroup-dynamic [formArray]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-vgroup-dynamic>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'ColumnGroup'">
            <ng-container *ngIf="!field.formArray">
                <app-column-group [formGroup]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-column-group>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'TabViewGroup'">
            <ng-container *ngIf="!field.formArray">
                <app-tabview-group [formGroup]="formGroup2.get(field.code)" [field]="field"
                    *ngIf="getvisibilityForField(field)">
                </app-tabview-group>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'Select' || field.type === 'ShortSelect' ">
            <app-select [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-select>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'MultiCheckBox'">
            <app-multi-check-box [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-multi-check-box>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'MultiSelect'">
            <app-multi-select [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-multi-select>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'ListBox'">
            <app-list-box [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-list-box >
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <div *ngSwitchCase=" field.type === 'Number' || field.type === 'ShortNumber'">
            <app-input-number [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-input-number>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <div *ngSwitchCase="field.type === 'ColorSelector'">
            <app-color-selector [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-color-selector>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <div *ngSwitchCase="field.type === 'ScenariosSelector'">
            <app-scenarios-selector [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-scenarios-selector>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <div *ngSwitchCase="field.type === 'Price'">
            <app-price [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-price>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <div *ngSwitchCase="field.type === 'Text'">
            <app-input-text [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-input-text>

            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <ng-container *ngSwitchCase="field.type === 'Switch'">
            <app-switch [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)"></app-switch>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="
                    field.type === 'Date' ||
                    field.type === 'DateTime' ||
                    field.type === 'Time' ||
                    field.type === 'DateMultiple'
                ">
            <app-datetime [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-datetime>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'CheckBoxButtonGroup'">
            <ng-container *ngIf="getvisibilityForField(field)">
                <app-checkbox-button-group [formGroup2]="formGroup2" [field]="field">
                </app-checkbox-button-group>
            </ng-container>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <div *ngSwitchCase="field.type === 'ColoredRadioButtonGroup'">
            <app-colored-radio-button-group [formGroup2]="formGroup2" [field]="field"
                *ngIf="getvisibilityForField(field)">
            </app-colored-radio-button-group>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </div>

        <ng-container *ngSwitchCase="field.type === 'RadioGroup'  || field.type === 'RadioGroupHorizontal'">
            <app-radio-group [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-radio-group>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'CheckBoxGroup'  || field.type === 'CheckBoxGroupHorizontal'">
            <app-check-box-group [formGroup]="formGroup2" [field]="field" *ngIf="getvisibilityForField(field)">
            </app-check-box-group>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'Button'">
            <app-duplicate-button [form]="formGroup2" [field]="field"></app-duplicate-button>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'CheckBox'">
            <app-check-box [formGroup]="formGroup2" [field]="field"></app-check-box>
            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>

        <ng-container *ngSwitchCase="field.type === 'Label'">
            <div *ngIf="isTitle(field)" class="ml2">
                <h5>
                    {{ field.label }}
                </h5>
            </div>
            <div style="display: grid; height: 100%;">
                <div *ngIf="!isTitle(field)" style="align-self: center;">
                    {{ field.label }}
                </div>
            </div>

            <ng-container *ngSwitchCase="field.type === 'HiddenText'">
                <app-hidden-text [formGroup]="formGroup2" [field]="field"></app-hidden-text>
            </ng-container>


            <hr *ngIf="isLineSepartorVisible(field)" />
        </ng-container>
    </div>
</div>
