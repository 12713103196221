<div #form class="p-grid">
    <div class="p-col">
        <p-panel header="Budget {{selectedMonth}} {{selectedYear}}">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px'}" label="Calculer" (click)="processPost()"
                 [disabled]="!budgetCalcul.isbudgetValidate" styleClass="p-button-info"></p-button>
                <p-button [style]="{'margin-right':'5px'}" icon="pi pi-check" label="Sauvegarder"
                    (click)="budgetValidate()" [disabled]="!budgetCalcul.isbudgetValidate || isError" styleClass="p-button-success">
                </p-button>
            </ng-template>
            <div class="p-m-0">
                <div class="grid m-2 ">
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">cah</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.cah" mode="currency" currency="EUR"
                                 >
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:80px">Ca Pdj</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.caPdj" mode="currency" currency="EUR"
                                ></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:130px">Ch Vendues</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.chv"
                                 [maxFractionDigits]="0" >
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">Ch Construites</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.nbCh"> </p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2 ">
                        <div class=" p-inputgroup ">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:100px">Ch Travaux</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.chTrv"
                                 [maxFractionDigits]="0" >
                            </p-inputNumber>
                        </div>

                        <div *ngIf="(newBudgetMonth.chTravaux != 0 && newBudgetMonth.chTravaux > nbChambres )">
                            <small id="" class="p-error"> Ch Travaux doit être < à Nb Chambres </small>
                        </div>
                    </div>
                    <div class="col-12 md:col-1">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:30px">Pm</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.pm"
                                 mode="currency" currency="EUR"
                                ></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-1">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:30px">To</span>
                            <p-inputNumber [(ngModel)]="budgetCalcul.to" mode="decimal" suffix="%" [maxFractionDigits]="0"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="grid m-2">
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">Prix Pdj</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.prixPdj" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:80px">P3</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p3" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:130px">P4</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p4" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">P5</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p5" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:100px">P6</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p6" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-2">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">P7</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p7" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="grid m-2 ">
                    <div class="col-12 md:col-3 ">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:120px">P8</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p8" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:80px">P9</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.p9" mode="currency" currency="EUR"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon p-col-fixed" style="width:130px">Taux remplissage</span>
                            <p-inputNumber [(ngModel)]="newBudgetMonth.tauxRemplissage" mode="decimal"
                                [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
    </div>
</div>
<div *ngIf="displayBudgetDays">
    <p-table [columns]="dayCols" [value]="budgetDay" [scrollable]="true" scrollHeight="280px">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns"
                    [ngStyle]="{'width': col.width != undefined ? col.width + 'px' : '70px'}">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" style="text-align: center;">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [ngClass]="{'row-isError': rowData.isError}">
                <td *ngFor="let col of columns" style="text-align: center;"
                [ngClass]="{'row-week-end': rowData['dayOfWeek'] == 'Dim' || rowData['dayOfWeek'] == 'Sam' }">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.field=='date'">
                        {{rowData[col.field] | date : 'dd'}}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{(col.field != 'chVendu' && col.field != 'hs' && col.field != 'occup' && col.field != 'date' &&
                        col.field != 'offerts' && col.field != 'tauxRemplissage' && col.field != 'nuitees' &&
                        col.field != 'nbCh' && col.field != 'chTravaux'  && col.field != 'to' && col.field != 'dayOfWeek') ?
                        (rowData[col.field] | currency : 'EUR') : (rowData[col.field]) }}
                    </ng-container>
                </ng-container>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

