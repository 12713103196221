<p-table [value]="allProduits.produitVenduList" styleClass="p-datatable-sm p-datatable-striped"
[scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'">
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Libellé </th>
            <th>Prix Ref</th>
            <th>Prix Min</th>
            <th>Prix Max</th>
            <th>Pax Std</th>
            <th>Pax Min</th>
            <th>Pax Max</th>
            <th>Inventaire</th>
            <th style="text-align: center;">
                <p-button icon="pi pi-plus" pTooltip="Nouveau" (onClick)="NewPrdv()"
                    styleClass="p-button-rounded p-button-success">
                </p-button>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-prdv>
        <tr [pSelectableRow]="prdv" [ngClass]="prdv.isObsolete? 'text-pink-500':''">
            <td>{{ prdv.code }}</td>
            <td>
                <span>{{ prdv.libelle }}</span>
                <span *ngIf="prdv.isObsolete" class="ml-2">
                    <i class="pi pi-times" style="color: red"></i>
                </span>
            </td>
            <td>{{ prdv.prixRef | currency: 'EUR' }}</td>
            <td>{{ prdv.prixMin | currency: 'EUR' }}</td>
            <td>{{ prdv.prixMax | currency: 'EUR' }}</td>

            <td>{{ prdv.stdPax }}</td>
            <td>{{ prdv.minPax }}</td>
            <td>{{ prdv.maxPax }}</td>
            <td>{{ prdv.invCode }}</td>
            <td style="text-align: center;">
                <p-button icon="pi pi-pencil" (onClick)="UpdatePrdv(prdv)" pTooltip="Modifier"
                    styleClass="p-button-rounded p-button-success p-button-raised p-button-text">
                </p-button>
                <p-button *ngIf="!prdv.isObsolete" icon="pi pi-times" pTooltip="Désactiver" (onClick)="DelPrdv(prdv)"
                    class="ml-1" styleClass="p-button-rounded p-button-danger p-button-raised p-button-text ">
                </p-button>
                <p-button *ngIf="prdv.isObsolete" icon="pi pi-check" pTooltip="Activer" (onClick)="DelPrdv(prdv)"
                    class="ml-1" styleClass="p-button-rounded p-button-success p-button-raised p-button-text ">
                </p-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="displayPrdvForm">
    <p-dialog [(visible)]="displayPrdvForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Produit Vendu
        </p-header>
        <app-form-dynamic #formprdv [formModel]="prdvFormConfig" [formValue]="formPrdvValue"
            (OnValidate)="validateFormPrdv($event)" (OnCancel)="cancel($event)" [debug]="false">
        </app-form-dynamic>

    </p-dialog>
</div>
