import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-colored-radio-button-group',
    templateUrl: './colored-radio-button-group.component.html',
    styleUrls: ['./colored-radio-button-group.component.css'],
})
export class ColoredRadioButtonGroupComponent implements OnInit {
    @Input() formGroup2: FormGroup2
    @Input() field: FormParameter
    formControl2: FormControl2
    tooltipzindex=TOOL_TIP_Z_INDEX;

    constructor() {}

    ngOnInit(): void {
        this.formControl2 = this.formGroup2.get(this.field.code) as FormControl2
    }

    getColorClass(code: string): Object {
        return {
            shadow: code != this.formControl2.value,
            'p-button-success': code === 'C0',
            'p-button-warning': code === 'C1',
            'p-button-danger': code === 'C2',
        }
    }

    onClick(code: string): void {
        this.formControl2.update_Value_And_Validity();
    }
}
