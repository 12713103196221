import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProduitVendu } from '../../services/model/produitVendu';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { ActivateProduitVenduCmd, AllProduitstHotel, DistribSession, NewProduitVenduCmd, UpdateProduitVenduCmd } from '../../services/model/models';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { ProduitService } from '../../services/api/api';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';

@Component({
    selector: 'app-produit-vendus',
    templateUrl: './produit-vendus.component.html',
    styleUrls: ['./produit-vendus.component.scss']
})
export class ProduitVendusComponent implements OnInit, OnDestroy {

    private readonly destroy$: Subject<void>;
    @Input() allProduits: AllProduitstHotel;
    @Output() OnRealodData = new EventEmitter()

    displayPrdvForm: boolean;
    prdvFormConfig: FormConfig;

    @ViewChild('formprdv') formDynamicPrdv: FormDynamicComponent;
    formPrdvValue: any;

    selectedPrdv: ProduitVendu;
    isNewPrdv: boolean;

    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService, private produitService: ProduitService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {

        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    this.loadForm();
                }, 10);
            });
    }

    private loadForm(): void {
        this.produitService.apiProduitPrdvFormGet(this.userInfoService.currentHotel.hotCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe(frm => {
                this.prdvFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
                console.log(this.prdvFormConfig);
            });
    }
    cancel($event): void {
        this.displayPrdvForm = false;
    }

    UpdatePrdv(selectedPrdv: ProduitVendu): void {
        this.SetParamCode(true);  // rendre le champ code non editable et  requis
        this.isNewPrdv = false;
        this.selectedPrdv = selectedPrdv;
        this.formPrdvValue = {
            produitVendu: selectedPrdv
        };
        this.displayPrdvForm = true;
    }

    NewPrdv(): void {

        this.SetParamCode(false);  // rendre le champ code editable et non requis
        this.isNewPrdv = true;
        this.formPrdvValue = {
            produitVendu: {}
        };
        this.displayPrdvForm = true;
    }

    DelPrdv(selectedPrdv: ProduitVendu): void {

        let cmd: ActivateProduitVenduCmd = {
            hotCode: this.allProduits.hotCode,
            activate: !selectedPrdv.isObsolete,
            code: selectedPrdv.code
        };

        this.produitService.apiProduitProduitVendusActivatePut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    this.OnRealodData.emit();
                },
                error: err => {
                    this.notifService.setMessage(err.error.error, "ProduitVenues", true);
                }
            })

    }
    validateFormPrdv(value: any): void {
        if (this.isNewPrdv) {
            this.NewPrdvInDB(value)
        }
        else {
            this.UpdatePrdvInDB(value);
        }
    }

    UpdatePrdvInDB(value: any): void {
        const newPrdv: ProduitVendu = value.produitVendu;

        let cmd: UpdateProduitVenduCmd = {
            hotCode: this.allProduits.hotCode,
            produitVendu: newPrdv,
        };

        this.produitService.apiProduitProduitVendusPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        this.SetError(session.error);
                    }
                    else
                        this.UpdateCompoment(session, newPrdv);
                },
                error: err => {
                    this.SetError(err);
                }
            })
    }

    NewPrdvInDB(value: any): void {
        const newPrdv: ProduitVendu = value.produitVendu;

        let cmd: NewProduitVenduCmd = {
            hotCode: this.allProduits.hotCode,
            produitVendu: newPrdv,
        };

        this.produitService.apiProduitProduitVendusPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        this.SetError(session.error);
                    }
                    else {
                        this.UpdateCompoment(session, newPrdv);
                        this.OnRealodData.emit();
                    }

                },
                error: err => {
                    this.SetError(err.error.error);
                }
            })
    }

    UpdateCompoment(session: DistribSession, newPrdv: ProduitVendu): void {
        this.displayPrdvForm = false;
        let oldInv = this.allProduits.produitVenduList.find(x => x.code == newPrdv.code);
        if (oldInv) {
            Object.assign(oldInv, { ...newPrdv });
        }
        else {
            this.allProduits.produitVenduList.push(newPrdv);
        }
        this.distribSessionService.newSession(session.hotCode, session.sessionId);
    }

    SetError(msg: string): void {
        let error = new FormErrors();
        error.errors = new ErrorDictionary();
        error.errors["produitVendu"] = [msg];  // inventaire c'est le vertical groupe racine
        this.formDynamicPrdv.setExternalErrors(error);
    }


    private SetParamCode(update: boolean) {
        let paramCode = this.prdvFormConfig?.parameters[0]?.childParameters?.find(x => x.code == "code");
        if (paramCode) {
            paramCode.readOnly = update;
            paramCode.required = update;
        }
    }
}
