import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { promises } from 'dns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ExpoService } from 'src/app/distrib/services/api/api';
import { AddCanalToDistributeurCmd, AddDistributeurCmd, Canal, DiffuserRatePlanListSurCanalCmd, Distributeur, ExclureProduitsCmd, ExpositionHotel, MatriceExpoItemsExclu, ProduitVenduUnit, RatePlan, RatePlanCanal } from 'src/app/distrib/services/model/models';
import { DistribSessionService } from 'src/app/distrib/session/distrib-session.service';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
    selector: 'app-routage-cannaux',
    templateUrl: './routage-cannaux.component.html',
    styleUrls: ['./routage-cannaux.component.css']
})
export class RoutageCannauxComponent implements OnInit, OnDestroy, OnChanges {

    private readonly destroy$: Subject<void>;
    @Input() allExpo: ExpositionHotel;
    @Output() OnReLoadData = new EventEmitter()



    currentHotCode: string;
    dataLoaded: boolean;
    _selectedDistributeur: Distributeur;
    _selectedRatePlan: RatePlanCanal;

    _selectedCanal: Canal


    @ViewChild('formNewDis') formNewDis: FormDynamicComponent;
    @ViewChild('formNewCan') formNewCan: FormDynamicComponent;
    @ViewChild('formaddRatePlans') formaddRatePlans: FormDynamicComponent;



    newCanalModel: FormConfig;
    NewDistributeurModel: FormConfig;

    displayNewDistributeur: boolean;
    displayNewCanal: boolean;



    displayAddRatePlans: boolean;
    addRatePlansModel: FormConfig;

    matriceExpoItemsExcluList: Array<MatriceExpoItemsExclu>;
    AddPrdvuExclusModel: FormConfig;
    displayAddPrdvuExclusDialog: boolean;

    get selectedDistributeur(): Distributeur {
        return this._selectedDistributeur;
    }

    set selectedDistributeur(value: Distributeur) {
        this._selectedDistributeur = value;

        if (this._selectedDistributeur?.canalList?.length > 0) {
            if (this.selectedCanal) {
                const existingCanal = this._selectedDistributeur.canalList.find(x => x.code == this.selectedCanal.code);
                if (existingCanal)
                    this.selectedCanal = existingCanal;
                else
                    this.selectedCanal = this._selectedDistributeur.canalList[0];
            }
            else
                this.selectedCanal = this._selectedDistributeur.canalList[0];
        }
        else {
            this.selectedCanal = null;
        }
    }

    get selectedCanal(): Canal {
        return this._selectedCanal;
    }
    set selectedCanal(value: Canal) {
        this._selectedCanal = value;

        if (this._selectedCanal?.ratePlansList.length > 0) {
            if (this.selectedRatePlan) {
                const existingRatePlan = this._selectedCanal.ratePlansList.find(x => x.code == this.selectedRatePlan.code);
                if (existingRatePlan)
                    this.selectedRatePlan = existingRatePlan;
                else
                    this.selectedRatePlan = this._selectedCanal?.ratePlansList[0];
            }
            else
                this.selectedRatePlan = this._selectedCanal?.ratePlansList[0];
        }
        else {
            this.selectedRatePlan = null;
            this.matriceExpoItemsExcluList = [];
        }
    }


    get selectedRatePlan(): RatePlanCanal {
        return this._selectedRatePlan;
    }

    set selectedRatePlan(value: RatePlanCanal) {
        this._selectedRatePlan = value;

        if (value) {
            this.matriceExpoItemsExcluList = this.allExpo?.matriceExpoItemsExcluList
                .filter(x => x.canCode == this.selectedCanal?.code && x.discode == this.selectedDistributeur?.code
                    && x.rtpCode == this._selectedRatePlan?.code);
        }
        else this.matriceExpoItemsExcluList = [];
    }

    constructor(private expoService: ExpoService, private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {

    }



    ngOnChanges(changes: SimpleChanges): void {

        const newHotel = this.currentHotCode != this.userInfoService.currentHotel.hotCode;
        this.currentHotCode = this.userInfoService.currentHotel.hotCode;

        if (this.allExpo) {
            this.dataLoaded = true;

            if (this.selectedDistributeur) {
                const exitingDistributeur = this.allExpo?.distributeurList.find(x => x.code == this.selectedDistributeur.code);
                if (!exitingDistributeur) {
                    this.selectedDistributeur = this.allExpo.distributeurList[0];
                }
                else {
                    this.selectedDistributeur = exitingDistributeur;
                }
            }
            else if (this.allExpo?.distributeurList?.length > 0) {
                this.selectedDistributeur = this.allExpo.distributeurList[0];
            }
            else {
                if (newHotel) this.selectedDistributeur = null;
            }
        }
        else {
            if (newHotel) this.selectedDistributeur = null;
        }
    }

    async displayDialogNewDistributeur(): Promise<void> {
        const frm1 = await lastValueFrom(this.expoService.apiExpoNewDistributeurFormGet(this.currentHotCode));
        this.NewDistributeurModel = JSON.parse(JSON.stringify(frm1)) as FormConfig;

        this.displayNewDistributeur = true;

        this.selectedDistributeur.libelle

    }

    addNewDistributeur(value): void {
        let cmd: AddDistributeurCmd = {
            hotCode: this.currentHotCode,
            disCode: value.disCode,

        }
        this.expoService.apiExpoAddDistributeurPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        let error = new FormErrors();
                        error.errors = new ErrorDictionary();
                        error.errors["disCode"] = [session.error];  // ratePlan c'est le vertical groupe racine
                        this.formNewDis.setExternalErrors(error);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayNewDistributeur = false;
                        this.notifService.setMessage(`Ajout avec succès du distributeur: ${cmd.disCode}`, "Exposition", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de d'ajout du distributeur ${cmd.disCode}`, "Exposition", true);
                }
            });
    }


    async displayDialogNewCanal(): Promise<void> {
        const frm1 = await lastValueFrom(this.expoService.apiExpoNewCanalFormGet(this.currentHotCode));
        this.newCanalModel = JSON.parse(JSON.stringify(frm1)) as FormConfig;

        this.displayNewCanal = true;
    }


    addNewCanal(value: any): void {
        let cmd: AddCanalToDistributeurCmd = {
            hotCode: this.currentHotCode,
            disCode: this.selectedDistributeur.code,
            canCode: value.canCode
        }

        this.expoService.apiExpoAddCanalToDistributeurPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        let error = new FormErrors();
                        error.errors = new ErrorDictionary();
                        error.errors["canCode"] = [session.error];  // ratePlan c'est le vertical groupe racine
                        this.formNewCan.setExternalErrors(error);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayNewCanal = false;
                        this.notifService.setMessage(`Ajout avec succès du Canal: ${cmd.canCode}`, "Exposition", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de d'ajout du canal ${cmd.canCode}`, "Exposition", true);
                }
            });
    }


    async displayDialogAddRatePlans(): Promise<void> {
        const frm1 = await lastValueFrom(this.expoService.apiExpoSelectRatePlansPourDiffusionFormGet(
            this.currentHotCode, this.selectedDistributeur.code, this.selectedCanal?.code));

        this.addRatePlansModel = JSON.parse(JSON.stringify(frm1)) as FormConfig;
        this.displayAddRatePlans = true;
    }

    addRatePlans(value: any): void {
        let cmd: DiffuserRatePlanListSurCanalCmd = {
            hotCode: this.currentHotCode,
            canCode: this.selectedCanal.code,
            ratePlans: value.ratePlans,
        }
        this.expoService.apiExpoDiffuserRatePlanListSurCanalPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        let error = new FormErrors();
                        error.errors = new ErrorDictionary();
                        error.errors["ratePlans"] = [session.error];  // ratePlan c'est le vertical groupe racine
                        this.formNewCan.setExternalErrors(error);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayAddRatePlans = false;
                        this.notifService.setMessage(`Ajout avec succès des ratePlans`, "Exposition", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de d'ajout des ratePlans`, "Exposition", true);
                }
            });
    }

    async displayDialogAddPrdvUExclus(): Promise<void> {
        const frm1 = await lastValueFrom(this.expoService.apiExpoSelectprdvuExclusionFormGet(
            this.currentHotCode, this.selectedDistributeur.code, this._selectedCanal.code, this.selectedRatePlan.code));

        this.AddPrdvuExclusModel = JSON.parse(JSON.stringify(frm1)) as FormConfig;
        this.displayAddPrdvuExclusDialog = true;
    }

    public addProduitExclus(value: any) {
        let cmd: ExclureProduitsCmd = {
            hotCode: this.currentHotCode,
            canCode: this.selectedCanal.code,
            disCode: this.selectedDistributeur.code,
            rtpCode: this.selectedRatePlan.code,
            prdvuList: value.prdvus
        }

        this.expoService.apiExpoExclureProduitsPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        let error = new FormErrors();
                        error.errors = new ErrorDictionary();
                        error.errors["prdvus"] = [session.error];  // ratePlan c'est le vertical groupe racine
                        this.formNewCan.setExternalErrors(error);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayAddPrdvuExclusDialog = false;
                        this.notifService.setMessage(`Ajout avec succès des produits exclus`, "Exposition", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de d'ajout des produits exclus`, "Exposition", true);
                }
            });

    }

    delProduitExclu(prdvu:MatriceExpoItemsExclu):void {
        this.expoService.apiExpoDelProduitExcluDelete(this.currentHotCode,
            this.selectedDistributeur.code,
            this.selectedCanal.code,
             this.selectedRatePlan.code,
             prdvu.prdvuCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        this.notifService.setMessage(`Erreur: ${session.error}`, "Exposition", true);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.notifService.setMessage(`Supression avec succès du produit ${prdvu.prdvuLibelle} de la liste d'exclusion`, "Exposition", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de supression du produit ${prdvu.prdvuLibelle} de la liste d'exclusion`, "Exposition", true);
                }
            });
    }

}
