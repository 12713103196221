import { Component, OnDestroy, OnInit } from "@angular/core";
import { APIS, AdminWorkspaceService } from "../services/api/api";
import { ApiWorkSpaces } from "../services/model/apiWorkSpaces";
import { UserInfos } from "../services/model/userInfos";
import { WorkSpaceInfos } from "../services/model/workSpaceInfos";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { CreateWorkSpace } from "../services/model/createWorkSpace";
import { Role } from "src/app/adminUsers/services/model/role";
import { RoleService } from "src/app/adminUsers/services/api/role.service";
import { UpdateRole } from "../services/model/updateRole";
import { UserInfoService } from "src/app/azureAdB2C/UserInfoService/user-InfoService";
import { NotificationsService } from "src/app/services/notifications/notifications.service";

@Component({
    selector: "app-admin-work-space",
    templateUrl: "./admin-work-space.component.html",
    styleUrls: ["./admin-work-space.component.css"],
})
export class AdminWorkSpaceComponent implements OnInit, OnDestroy {
    constructor(
        private adminWorkspaceService: AdminWorkspaceService,
        private confirmationService: ConfirmationService,
        private notifService: NotificationsService,
        private roleService: RoleService,
        private userInfoService: UserInfoService
    ) { }
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    sub6: Subscription;
    sub7: Subscription;
    sub8: Subscription;
    sub9: Subscription;
    workspace: Array<ApiWorkSpaces>;
    role: Array<Role>;
    user: Array<UserInfos>;
    adduser: Array<UserInfos>;
    rapport: Array<WorkSpaceInfos>;
    rolesFormInfoService: string[];
    selectedWorkspace: string;
    selectedWorkspaceId: string;
    addUserDialogVisible: boolean = false;
    addWorkspaceVisible: boolean = false;
    isUserSelected: boolean = false;
    workspaceName: string;
    nomUser: string;
    mailUserDelete;
    nomNouveauWorkspace: string;
    addSelectedUsers: UserInfos;
    createWorkspaceWithSelectedUsers: UserInfos;
    inWorkspaceUsers: UserInfos[] = [];
    selectedUser: UserInfos;
    nombreRapportInWorkspace: number;
    suppWorkspaceDialogVisible: boolean = true;
    userSeleced: string;
    loadingAddWorkspace: boolean = false;
    loadingSuppWorkspace: boolean = false;
    loadingAddUser: boolean = false;
    loadingSuppUser: boolean = false;
    loadingInfoWorkspace: boolean = false;
    loadingNameWorkspace: boolean = false;
    isFormInvalid: boolean = false;
    selectedRole: string;
    currentRole: string;
    roleReset: boolean = false;
    descriptionWorkspace: string = "Aucun rôle n'est attribué à ce workpsace ";

    ngOnInit() {
        this.setNomWorkspaces();
        this.setRapportById();
        this.afficherListeAddUser();
        this.listeDesRoles();
        this.userInfoService.getUserRoles$().subscribe((roles) => {
            this.rolesFormInfoService = roles;
        });
    }
    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
        this.sub6?.unsubscribe();
        this.sub7?.unsubscribe();
        this.sub8?.unsubscribe();
        this.sub9?.unsubscribe();
    }

    isAdmin(): boolean {
        if (
            this.rolesFormInfoService &&
            this.rolesFormInfoService.find((r) => r == "Administrator")
        ) {
            return true;
        }
        return false;
    }

    AjouterUnWorkspaceVisible() {
        this.addWorkspaceVisible = true;
    }

    addWorkspace() {
        if (
            !this.nomNouveauWorkspace ||
            !this.createWorkspaceWithSelectedUsers
        ) {
            this.isFormInvalid = true;
        } else {
            this.isFormInvalid = false;
            this.loadingAddWorkspace = true;

            const newWorkspace: CreateWorkSpace = {
                workSpaceName: this.nomNouveauWorkspace,
                idUser: this.createWorkspaceWithSelectedUsers.identifier,
            };
            this.sub3 = this.adminWorkspaceService
                .apiAdminWorkSpacesPost(newWorkspace)
                .subscribe({
                    next: (workSpace) => {
                        this.addWorkspaceVisible = false;

                        this.selectedWorkspace = workSpace.id;
                        this.setNomWorkspaces();
                    },
                    error: (error) => {
                        this.notifService.setMessage("Probleme lors de la création du workspace", "Erreur", true);
                    },
                    complete: () => {
                        this.loadingAddWorkspace = false;
                        this.nomNouveauWorkspace = null;
                        this.createWorkspaceWithSelectedUsers = null;
                        this.notifService.setMessage("Le Workspace a été créé avec succès.", "Confirmation", false)
                    },
                });
        }
    }

    AjouterUnUser() {
        this.addUserDialogVisible = true;
    }
    afficherListeAddUser() {
        this.sub4 = this.adminWorkspaceService
            .apiAdminWorkSpacesUsersGet()
            .subscribe({
                next: (users) => {
                    this.adduser = users;
                },
                error: (error) => {
                    this.notifService.setMessage("Probleme lors du chargement des utilisateur", "Erreur", true);
                },
            });
    }

    addUserInWorkspace(event) {
        this.loadingAddUser = true;

        if (!this.addSelectedUsers || !this.addSelectedUsers.identifier) {
            this.notifService.setMessage("Aucun utilisateur sélectionné", "Erreur", true)
            this.addUserDialogVisible = false;
            return;
        }

        const selectedUserIds = this.addSelectedUsers.identifier;

        this.sub7 = this.adminWorkspaceService
            .apiAdminWorkSpacesUsersPost(
                this.selectedWorkspaceId,
                selectedUserIds
            )
            .subscribe({
                next: (workspace) => {
                    this.setRapportById();
                },
                error: (error) => {
                    console.error(error);
                    this.notifService.setMessage("Probleme lors de l'ajout de l'utilisatuer", "Erreur", true);
                    this.loadingAddUser = false;
                },
                complete: () => {
                    this.loadingAddUser = false;
                },
            });

        this.addUserDialogVisible = false;
    }

    confirmSuppUser(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message:
                "Etes vous sur de vouloir supprimer " +
                this.nomUser +
                " de " +
                this.workspaceName,
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.loadingSuppUser = true;
                this.sub6 = this.adminWorkspaceService
                    .apiAdminWorkSpacesUsersDelete(
                        this.selectedWorkspaceId,
                        this.mailUserDelete
                    )
                    .subscribe({
                        next: (workspace) => {
                            this.setRapportById();
                        },
                        error: (error) => {
                            this.notifService.setMessage("Probleme lors de la suppression de l'utilisateur", "Erreur", true);
                        },
                        complete: () => {
                            this.loadingSuppUser = false;
                            this.notifService.setMessage("L'utilisateur a bien été supprimer", "WorkSpace", false);
                        },
                    });
            },
            reject: () => {
                this.notifService.setMessage("Annulation confirmer", "Erreur", true);
            },
        });
    }


    confirmSuppWorkspace(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message:
                "Etes vous sûr de vouloir supprimer le Workspace " +
                this.workspaceName +
                "?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                // this.loadingSuppWorkspace = true;
                this.sub2 = this.adminWorkspaceService
                    .apiAdminWorkSpacesDelete(this.selectedWorkspaceId)
                    .subscribe({
                        next: (workspace) => {
                            this.setNomWorkspaces();
                        },
                        error: (error) => {
                            console.error(error);
                            this.notifService.setMessage("Probleme lors de la suppression du workspace", "Workspace", true);
                        },
                        complete: () => {
                            // this.loadingSuppWorkspace = false;
                            this.notifService.setMessage("Le Workspace a bien été supprimer", "Workspace", false);
                        },
                    });
            },
            reject: () => {
                this.notifService.setMessage("Annulation confirmer", "Erreur", true);
            },
        });
    }

    setNomWorkspaces() {
        this.loadingNameWorkspace = true;
        this.loadingInfoWorkspace = true;
        this.sub1 = this.adminWorkspaceService
            .apiAdminWorkSpacesAllGet()
            .subscribe({
                next: (workspaces) => {
                    this.workspace = workspaces;

                    const newlyAddedWorkspace = this.workspace.find(
                        (workspace) => workspace.id === this.selectedWorkspace
                    );
                    if (newlyAddedWorkspace) {
                        this.editSelectedWorkspace(newlyAddedWorkspace.id);
                    } else {
                        this.selectedWorkspace = this.workspace[0].id;
                        this.editSelectedWorkspace(this.selectedWorkspace);
                    }
                    this.loadingNameWorkspace = false;
                },
                error: (error) => {
                    console.error("error", error);
                    this.notifService.setMessage("Probleme lors du chargement des workspaces", "Erreur", true);
                    this.loadingNameWorkspace = false;
                },
            });
    }

    setRapportById() {
        this.loadingInfoWorkspace = true;
        if (this.selectedWorkspaceId) {
            this.sub5 = this.adminWorkspaceService
                .apiAdminWorkSpacesGet(this.selectedWorkspaceId)
                .subscribe({
                    next: (rapports) => {
                        this.rapport = rapports?.reports;
                        this.user = rapports?.users;
                        this.inWorkspaceUsers = rapports?.users;
                        this.nombreRapportInWorkspace = this.rapport.length;
                        this.selectedRole = rapports.roleId;
                        this.currentRole = this.selectedRole;

                        if (this.nombreRapportInWorkspace > 0) {
                            this.suppWorkspaceDialogVisible = true;
                        } else {
                            this.suppWorkspaceDialogVisible = false;
                        }
                        this.loadingInfoWorkspace = false;
                    },
                    error: (error) => {
                        console.error(error);
                        this.loadingInfoWorkspace = false;
                        this.notifService.setMessage("Probleme lors du chargement des rapports", "Rapports", true);
                    },
                });
        } else {
            this.rapport = [];
            this.nombreRapportInWorkspace = 0;
        }
    }
    filterUsers() {
        return this.adduser.filter(
            (user) =>
                !this.inWorkspaceUsers.some(
                    (workspaceUser) =>
                        workspaceUser.identifier === user.identifier
                )
        );
    }
    filterUsersInCreationWorkspace() {
        const excludedUsers = [
            "g.chassigneux@e-axess.com",
            "k.saci@e-axess.com",
            "saci.karim@gmail.com",
            "arbam.maalik@gmail.com",
            "m.arban@e-axess.com",
            "l.chevalier@e-axess.com",
            "pbiembeddedservice@spotpilot.com",
            "philippe.guimard@e-axess.com",
            "l.lintignat@e-axess.com",
            "l.lintignat@e-axess.fr",
            "l.lintignat@spotpilot.com",
            "luc_lintignat@hotmail.fr",
        ];

        return this.adduser.filter(
            (user) =>
                !this.inWorkspaceUsers.some(
                    (workspaceUser) =>
                        workspaceUser.emailAddress === user.emailAddress
                ) && !excludedUsers.includes(user.emailAddress)
        );
    }

    editSelectedWorkspace(selectedWorkspace) {
        this.selectedWorkspaceId = selectedWorkspace;
        this.setRapportById();
        this.workspaceName = this.workspace.find(
            (w) => w.id === selectedWorkspace
        )?.name;
        this.isUserSelected = false;
        this.userSeleced = null;
        this.updateIsUsertSelected(null);
    }

    updateIsUsertSelected(event) {
        if (event === null) {
            this.isUserSelected = false;
            this.nomUser = null;
            this.mailUserDelete = null;
            this.selectedUser = null;
        } else {
            this.isUserSelected = true;
            this.nomUser = event.option.displayName;
            this.mailUserDelete = event.option.identifier;
            this.selectedUser = this.adduser.find(
                (user) => user.emailAddress === this.mailUserDelete
            );
            this.mailUserDelete = this.selectedUser?.identifier;
            // console.log("info user selectionne", event);
            // console.log("nom user selectionne", this.nomUser);
            // console.log("identifiant", this.mailUserDelete);
        }
    }

    listeDesRoles() {
        this.sub8 = this.roleService.apiRolesGet().subscribe({
            next: (role) => {
                this.role = role.filter((r) => r.id.startsWith("Fusion"));
            },
            error: (error) => {
                console.error("error", error);
            },
        });
    }

    roleSave() {
        this.loadingInfoWorkspace = true;

        const modifRole: UpdateRole = {
            roleId: this.selectedRole,
            workspaceId: this.selectedWorkspace,
        };
        if (this.selectedRole === this.currentRole && this.roleReset == false) {
            this.notifService.setMessage("Le rôle sélectionné est déjà le rôle actuel du workspace.", "Workspace", false);
            this.loadingInfoWorkspace = false;

            return;
        } else {
            if (this.roleReset == true) {
                modifRole.roleId = null;
            }

            this.sub9 = this.adminWorkspaceService
                .apiAdminWorkSpacesRolePost(modifRole)
                .subscribe({
                    next: (rolemodif) => {
                        this.roleReset = false;
                        this.loadingInfoWorkspace = false;
                    },
                    error: (error) => {
                        console.error(error);
                        this.notifService.setMessage("Probleme lors de la modification du role", "WorkSpace", true);
                        this.roleReset = false;
                        this.loadingInfoWorkspace = false;
                        this.descriptionWorkspace =
                            "Aucun rôle n'est attribué à ce workpsace ";
                    },
                    complete: () => {
                        this.notifService.setMessage("Le role a été attribué avec succès.", "WorkSpace", false);
                        this.currentRole = this.selectedRole;
                        this.descriptionWorkspace =
                            "Aucun rôle n'est attribué à ce workpsace ";
                        this.roleReset = false;
                        this.loadingInfoWorkspace = false;
                    },
                });
        }
    }

    handleClearRole() {
        this.selectedRole = null;
        this.currentRole = null;
        this.roleReset = true;
        this.descriptionWorkspace =
            "Ne selectionné aucun rôle pour ce workspace";
    }
}
