<div [class]="'p-mr-3 form-component-' + field.code" *ngIf="typeParrentGroup != 'VGroup'">
    <div class="p-fluid">
        <div class="p-field" style="position: relative" [formGroup]="newFormGroup">
            <label [for]="field.code">{{ field.label }}
                <span *ngIf="field.required" class="p-invalid">*</span>
            </label>
            <p-dropdown [options]="field.possibleValues" [formControlName]="field.code" optionValue="code"
                optionLabel="label" (onChange)="onChange($event)" [placeholder]="field.placeholder || '-'"
                [required]="field.required" [pTooltip]="field.toolTip" tooltipPosition="top"
                [tooltipZIndex]="tooltipzindex" appendTo="body">
                <ng-template let-item pTemplate="selectedItem">
                    <div class="scenario">
                        <div [ngClass]="{
                                'color-box-mini': true,
                                'color-box-noborder':
                                    item.label.split('|')[1] !== 'transparent'
                            }" [style]="
                                'background-color:' + item.label.split('|')[1]
                            "></div>
                        <div class="label">{{ item.label.split('|')[0] }}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="scenario">
                        <div [ngClass]="{
                                'color-box': true,
                                'color-box-noborder':
                                    item.label.split('|')[1] !== 'transparent'
                            }" [style]="
                                'background-color:' + item.label.split('|')[1]
                            ">
                        </div>
                        <div class="label">{{ item.label.split('|')[0] }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <div class="errorText">
                <small class="p-invalid errorText">{{
                    formControl?.getErrorsMessage()
                    }}</small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="typeParrentGroup == 'VGroup'">
    <div [class]="'p-field p-grid form-component-' + field.code" [formGroup]="formGroup">
        <label [for]="field.code" class="p-col-fixed">{{ field.label }}
            <span *ngIf="field.required" class="text-danger">*</span>
        </label>
        <div class="p-col" style="position: relative">
            <p-dropdown [options]="field.possibleValues" [formControlName]="field.code" optionValue="code"
                optionLabel="label" [showClear]="true" (onChange)="onChange($event)"
                [placeholder]="field.placeholder || '-'" [required]="field.required" [pTooltip]="field.toolTip"
                tooltipPosition="top" [tooltipZIndex]="tooltipzindex" appendTo="body">
                <ng-template let-item pTemplate="selectedItem">
                    <div class="scenario">
                        <div [ngClass]="{
                                'color-box-mini': true,
                                'color-box-noborder':
                                    item.label.split('|')[1] !== 'transparent'
                            }" [style]="
                                'background-color:' + item.label.split('|')[1]
                            "></div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="scenario">
                        <div [ngClass]="{
                                'color-box': true,
                                'color-box-noborder':
                                    item.label.split('|')[1] !== 'transparent'
                            }" [style]="
                                'background-color:' + item.label.split('|')[1]
                            "></div>
                        <div class="label">{{ item.label.split('|')[0] }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <div class="errorText">
                <small class="p-invalid errorText">{{ formControl?.getErrorsMessage() }}
                </small>
            </div>
        </div>
    </div>
</div>