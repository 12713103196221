import { Component, OnDestroy, OnInit} from "@angular/core";
import { Subscription, throwError } from "rxjs";
import { IndicatorsLiveService } from "src/app/analyse/services/api/api";
import { ExportTablesService } from 'src/app/services/exports/export-tables.service';
import { formatDate } from '@fullcalendar/core';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ResnotifService } from 'src/app/spotConnect/services/api/api';
import { ResNotif } from 'src/app/spotConnect/services/model/models';
import { AnalyseResaTimeLine } from "../../services/model/analyseResaTimeLine";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit, OnDestroy {
  cols: ICols[];
  resasList: AnalyseResaTimeLine[];
  rows = 12;
  frozenCols: ICols[];
  hotCode: string;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  rangeDates: Date[];
  currentHotel: HotelAuth;
  loading:boolean;
  displayJson:boolean = false;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  position: string;
  resnotif: ResNotif[]=[];
  currentResnotif:ResNotif;
  selectedResa: AnalyseResaTimeLine;
  index :number;
  constructor(
    private indicatorsLiveService: IndicatorsLiveService,
    private exportTablesService: ExportTablesService,
    private userInfoService: UserInfoService,
    private resnotifService: ResnotifService,
    private breadcrumbService: BreadcrumbService,
    private datePipe: DatePipe
    ) {
      this.breadcrumbService.setItems([
        { label: 'analyse/reservations' }
      ]);
    }
  ngOnInit(): void {
    this.resaColumns();
    this.currentHotel = this.userInfoService.currentHotel;
    this.sub1 = this.userInfoService
      .getNewHotel$()
      .subscribe((hotCode) => {
        this.currentHotel = hotCode;
        setTimeout(() => {
          this.resaColumns();
          this.getLiveTimeLine(this.rangeDates);
        }, 100);
      }
      );
      this.index = 0;
  }
  getLiveTimeLine(rangeDates: Date[], calendar?) {
    if (rangeDates != null && rangeDates.length > 1 && rangeDates[1] != null) {
      if (calendar) {
        calendar.overlayVisible = false;
      }
      this.loading = true;
      let selectedStartDate = rangeDates[0];
      let selectedEndDate= rangeDates[1];
      const startDate = this.datePipe.transform(selectedStartDate, "yyyy-MM-dd"); //formatDate(selectedStartDate, { format: 'yy-MM-dd' });
      const endDate = this.datePipe.transform( selectedEndDate, "yyyy-MM-dd"); // formatDate(selectedEndDate, { format: 'yy-MM-dd' });
      this.sub2 = this.indicatorsLiveService
        .apiIndicatorsliveTimeLineHotCodeGet(this.currentHotel.hotCode, startDate, endDate)
        .subscribe({
          next: (data) => {
            this.resasList = data;
            this.resasList.map((row, key) => {
              let keys = Object.keys(row);
              if (keys.indexOf('arrivalDate') >= 0) {
                row.arrivalDate =  this.datePipe.transform( row.arrivalDate, "yyyy-MM-dd"); //formatDate(row.arrivalDate, { format: 'yy-MM-dd', locale: 'fr' });
              }
              if (keys.indexOf('date') >= 0) {
                row.date = new Date(row.date).toLocaleString('fr');
              }
              return row;
            })
            this.loading = false;
          },
          error :(error: any) => {
            console.log(error?.error);
          }
    });
    }
  }
  resaColumns() {
    this.cols = [
      { field: "id", header: "Id", width: 140 },
      { field: "date", header: "Date",  },
      { field: "arrivalDate", header: "Date d'arrivée", isDate: true, width: 130 },
      { field: "name", header: "Nom", },
      { field: "duration", header: "Durée",width: 70 },
      { field: "pmz", header: "PMZ", width: 90},
      { field: "caz", header: "CAZ",width: 90 },
      { field: "source", header: "Source",width: 90 },
      { field: "status", header: "Status",width: 70  },
      { field: "roomCount", header: "Nbr chambres",width: 80 },
      { field: "json", header: "", width: 50 },
    ];
  }
  displayJsonResnotif(position: string, rowData: AnalyseResaTimeLine) {
    this.position = position;
    this.selectedResa = rowData;
    this.displayJson = true;
    let pmsReservationId = this.selectedResa.id;
    let hotCode = this.currentHotel.hotCode;
    this.sub3 = this.resnotifService
        .apiResnotifResnotifsGet(hotCode, pmsReservationId)
        .subscribe({
          next: (resnotif) => {
            this.resnotif = resnotif;
            this.index = 0;
            this.currentResnotif = this.resnotif[this.index];
          },
          error: (error: any) => {
            console.log(error);
          }});
}
onPrev() {
  if (this.index > 0) {
    this.index--;
    this.currentResnotif = this.resnotif[this.index];
  }
}
onNext() {
  if (this.index < this.resnotif.length -1) {
    this.index = this.index + 1;
    this.currentResnotif = this.resnotif[this.index];
  }
}
  exportExcel() {
    this.exportTablesService.exportExcel(this.resasList, "reservations");
  }
  refreshData(hotCode) {
    if (hotCode && this.currentHotel.hotCode != hotCode) {
      this.currentHotel.hotCode = hotCode;
      this.getLiveTimeLine(this.rangeDates);
    }
  }
  ngOnDestroy(): void {
    if (this.sub1 != undefined) {
      this.sub1.unsubscribe();
    }
    if (this.sub2 != undefined) {
      this.sub2.unsubscribe();
    }
    if (this.sub3 != undefined) {
      this.sub3.unsubscribe();
    }
  }
}
