<div class="container" style="user-select: none;">

    <div>
        <p-table #dt2 selectionMode="single"  [value]="matrice"
        rowGroupMode="subheader" groupRowsBy="groupinfo"
            styleClass="p-datatable-sm p-datatable-gridlines" [scrollable]="true" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Matrice
                </div>

            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Distributeur</th>
                    <th>Canal</th>
                    <th>Plan tarifaire</th>
                    <th>Pricer</th>
                    <th>Produit vendu</th>
                    <th>Inventaire distant</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="groupheader" let-pkg>
                <tr pRowGroupHeader>
                    <td colspan="5">
                        <span class="font-bold text-pink-900">
                            {{pkg.disLibelle}} - {{pkg.canLibelle}} - {{pkg.ratePlanLibelle}} </span>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-mat>
                <tr [pSelectableRow]="mat">
                    <td>{{mat.disLibelle}}</td>
                    <td>{{mat.canLibelle}}</td>
                    <td>{{mat.ratePlanLibelle}}</td>
                    <td>{{mat.pricerLibelle}}</td>
                    <td>{{mat.prdvuLibelle}}</td>
                    <td>{{mat.invDisLibelle}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>




</div>
