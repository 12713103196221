<div [class]="'mr-3 form-component-' + field.code" style="width: 177px" *ngIf="!field.inlineLabel">
    <div class="p-fluid" [formGroup]="formGroup">
        <div style="position: relative">
            <label [for]="field.code">{{ field.label }}
                <span *ngIf="field.required && field.label" class="text-danger">*</span>
            </label>
            <p-inputNumber [formControlName]="field.code" (keydown)="onInput($event)" [showButtons]="true"
                autocomplete="off" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="currency" currency="EUR"
                [pTooltip]="field.toolTip" tooltipPosition="top" [tooltipZIndex]="tooltipzindex">
            </p-inputNumber>

            <div class="errorText">
                <small class="p-invalid errorText">{{
                    formControl?.getErrorsMessage()
                    }}</small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="field.inlineLabel">
    <div [class]="'field grid form-component-' + field.code" [formGroup]="formGroup">
        <label [for]="field.code" class="p-col-fixed">{{ field.label }}
            <span *ngIf="field.required && field.label" class="text-danger">*</span>
        </label>
        <div class="col" style="position: relative">
            <p-inputNumber [formControlName]="field.code" (keydown)="onInput($event)" [showButtons]="true"
                autocomplete="off" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="currency" currency="EUR"
                [pTooltip]="field.toolTip" tooltipPosition="top" tooltipZIndex="100000">
            </p-inputNumber>
            <div class="errorText">
                <small class="p-invalid errorText">{{
                    formControl?.getErrorsMessage()
                    }}</small>
            </div>
        </div>
    </div>
</div>