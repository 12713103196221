import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription, throwError } from "rxjs";
import { DataLiveService } from "src/app/analyse/services/api/dataLive.service";
import { ExportTablesService } from 'src/app/services/exports/export-tables.service';
import { formatDate } from '@fullcalendar/core';
import { SetGetLocalStorageService } from 'src/app/services/local-storage/set-get-local-storage.service';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DataLiveItem } from "../services/model/dataLiveItem";
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-data-live",
  templateUrl: "./data-live.component.html",
  styleUrls: ["./data-live.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DataLiveComponent implements OnInit, OnDestroy {
  cols: ICols[];
  listDataLive: DataLiveItem[];
  rows = 12;
  frozenCols: ICols[];
  hotCode: string;
  selectedDate?: Date;
  currentHotel: HotelAuth;
  loading:boolean;
  sub1: Subscription;
  sub2: Subscription;

  constructor(
    private dataLiveService: DataLiveService,
    private exportTablesService: ExportTablesService,
    private userInfoService: UserInfoService,
    private breadcrumbService: BreadcrumbService,
    private datePipe: DatePipe
    ) {
      this.breadcrumbService.setItems([
        { label: 'analyse/data-live' }
      ]);
    }

  ngOnInit(): void {
    this.buildColumns();
    this.currentHotel = this.userInfoService.currentHotel;
    this.sub1 = this.userInfoService
      .getNewHotel$()
      .subscribe((hotCode) => {
        this.currentHotel = hotCode;
        setTimeout(() => {
          this.buildColumns();
          this.getDataLive(this.selectedDate);
        }, 100);
      }
      );
  }
  getDataLive(selectedDate?: Date) {
    this.selectedDate = selectedDate;
    if (selectedDate != undefined ) {
      this.loading = true;
      const date = this.datePipe.transform(selectedDate, "yyyy-MM-dd");//formatDate(selectedDate, { format: 'yy-MM-dd' });
      this.sub2 = this.dataLiveService
        .apiDataliveHotCodeGet(this.currentHotel.hotCode, date)
        .subscribe({
          next: (response) => {
            this.listDataLive = response;
            this.listDataLive.map((row, key) => {
              let keys = Object.keys(row);
              if (keys.indexOf('arrivalDate') >= 0) {
                row.arrivalDate = this.datePipe.transform(row.arrivalDate, "yyyy-MM-dd"); //formatDate(row.arrivalDate, { format: 'yy-MM-dd', locale: 'fr' });
              }
              if (keys.indexOf('dataLiveItemDate') >= 0) {
                row.dataLiveItemDate = this.datePipe.transform(row.dataLiveItemDate, "yyyy-MM-dd");row.dataLiveItemDate; // formatDate(row.dataLiveItemDate, { format: 'yy-MM-dd', locale: 'fr' });
              }
              if (keys.indexOf('operationDate') >= 0) {
                row.operationDate = new Date(row.operationDate).toLocaleString('fr');
              }
              if (keys.indexOf('creationDate') >= 0) {
                row.creationDate = new Date(row.creationDate).toLocaleString('fr');
              }
              return row;
            })
            this.loading = false;
          },
          error: (error) => {
            console.log(error?.error);
          }
    });
    }
  }
  buildColumns() {
    this.cols = [
      { field: "arrivalDate", header: "arrivalDate", isDate: true, width: 105 },
      { field: "caAutre", header: "caAutre", width: 100 },
      { field: "caAutre_HT", header: "caAutre_HT", width: 100 },
      { field: "caHbgt", header: "caHbgt", width: 100 },
      { field: "caHbgt_HT", header: "caHbgt_HT", width: 100 },
      { field: "caPtDej", header: "caPtDej", width: 100 },
      { field: "caPtDej_HT", header: "caPtDej_HT", width: 100 },
      { field: "canCode", header: "canCode", width: 100 },
      { field: "idResa", header: "idResa", width: 135 },
      { field: "notPricedYet", header: "notPricedYet", width: 105 },
      { field: "operationDate", header: "operationDate", isDate: true },
      { field: "outOfOrder", header: "outOfOrder", width: 100 },
      { field: "outOfService", header: "outOfService", width: 110 },
      { field: "roomType", header: "roomType", width: 100 },
      { field: "status", header: "status", width: 100 },
      { field: "roomOccupancy", header: "roomOccupancy", width: 130 },
      { field: "countryCode", header: "countryCode", width: 100 },
      { field: "companyCode", header: "companyCode", width: 120 },
      { field: "lastName", header: "Nom", width: 350 },
      { field: "firstName", header: "Prénom", width: 220 },
      { field: "paxAdt", header: "paxAdt", width: 100 },
      { field: "paxChd", header: "paxChd", width: 100 },
    ];
    this.frozenCols = [
      { field: "dataLiveItemDate", header: "dataLiveItemDate", isDate: true, width: 105 },
      { field: "creationDate", header: "creationDate", isDate: true, width: 105 }
    ];
  }
  exportExcel() {
    this.exportTablesService.exportExcel(this.listDataLive, "data-live");
  }
  refreshData(hotCode) {
    if (hotCode && this.currentHotel.hotCode != hotCode) {
      this.currentHotel.hotCode = hotCode;
      this.getDataLive(this.selectedDate);
    }
  }
  ngOnDestroy(): void {
    if (this.sub1 != undefined) {
      this.sub1.unsubscribe();
    }
    if (this.sub2 != undefined) {
      this.sub2.unsubscribe();
    }
  }
}
