import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';

import { HotelWithRoomtypes } from '../../services/model/hotelWithRoomtypes';

import { ConfirmationService } from "primeng/api";
import { Hotel } from '../../services/model/hotel';
import { RateShopperConfigService } from '../../services/api/api';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';


@Component({
    selector: 'app-competitors',
    templateUrl: './competitors.component.html',
    styleUrls: ['./competitors.component.css']
})
export class CompetitorsComponent implements OnInit, OnDestroy {

    constructor(private hotelsService: RateShopperConfigService,
        private userInfoService: UserInfoService,
        private appRef: ApplicationRef,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private notifService: NotificationsService,
        private primengConfig: PrimeNGConfig, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([{ label: "rateshopper/concurrents" }]);
    }

    currentHotel: HotelAuth;
    sub3: Subscription;
    sub2: Subscription;
    sub1: Subscription;
    sub4: Subscription;

    cols: any[] = [];
    colsRoomtype: any[] = [];
    selectedHotelWithRoomtypes: HotelWithRoomtypes;
    selectedHotelToAdd: Hotel;

    displayAddCompForm: boolean;

    hotels: Array<Hotel>;
    colsHotels: any[] = [];

    lineValue: Hotel;


    dataLoaded: boolean;

    hotelWithRoomtypes: Array<HotelWithRoomtypes>;

    ngOnInit(): void {
        this.colsHotels = [
            { field: "rateShopperId", header: "Id", width: "10px" },
            { field: "hotCode", header: "HotCode", width: "15px" },
            { field: "name", header: "Nom", width: "45px" },
            { field: "address", header: "Adresse", width: "45px" },
            { field: "city", header: "Ville", width: "45px" },
        ];
        this.cols = [
            { field: "rateShopperId", header: "Id", width: "10px" },
            { field: "name", header: "Nom", width: "50px" },
            { field: "bookingName", header: "Nom booking", width: "50px" },
            { field: "address", header: "adresse", width: "28px" },
            { field: "city", header: "Ville", width: "10px" },
            { field: "bookingUrl", header: "Url booking", width: "60px" },
        ];

        this.colsRoomtype = [
            { field: "roomTypeCode", header: "Code", width: "10px" },
            { field: "roomTypeName", header: "Nom", width: "80px" },
            { field: "pax", header: "Pax", width: "50px" },
        ];


        this.sub1 = this.userInfoService.getNewHotel$().subscribe(hotCode => {
            this.dataLoaded = false;
            this.selectedHotelWithRoomtypes = null;
            this.hotels = null;
            this.hotelWithRoomtypes = null;
            setTimeout(() => {
                this.currentHotel = hotCode;
                this.loadData();
            }, 100);

        });
    }

    LoadCompetitors() {
        this.sub3 = this.hotelsService.apiHotelsHotCodeCompetitorsGet(this.currentHotel.hotCode).subscribe(data => {
            this.hotelWithRoomtypes = data;
        });

    }
    loadData(): void {
        const obs1$ = this.hotelsService.apiHotelsGet();
        const obs2$ = this.hotelsService.apiHotelsHotCodeCompetitorsGet(this.currentHotel.hotCode)
        this.sub3 = forkJoin([obs1$, obs2$]).subscribe({
            next: (result) => {
                this.hotels = result[0];
                this.hotelWithRoomtypes = result[1];
                this.dataLoaded = true
            }, error: err => {
                console.error(err);
                this.dataLoaded = false;
            }
        });
    }

    InitHotel(event): void {

        this.confirmationService.confirm({
            target: event.target,
            message: "Confirmation de l'initialisation de l'hôtel?",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {
                this.hotelsService.apiHotelsHotCodeInitRateShopperHotelPost(this.currentHotel.hotCode)
                    .subscribe({
                        next: (value) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'RateShopper',
                                detail: `Initialisation en cours de l'hôtel ${this.currentHotel.name}`
                            });
                            this.notifService.setMessage(`Initialisation en cours de l'hôtel ${this.currentHotel.name}`, 'Concurents', false);
                        },
                        error: (err => {
                            // console.log(err);
                            this.messageService.add({
                                severity: 'error',
                                summary: 'RateShopper',
                                detail: err.error, life: 5000
                            });
                            this.notifService.setMessage(err?.error, 'Concurents', true);
                        })
                    }
                    );
            },
            reject: () => {
                this.messageService.add({ severity: 'error', summary: 'RateShopper', detail: 'Opération annulée' });
            }
        });

    }
    onRowSelect(event) {
        // console.log(this.selectedHotelWithRoomtypes)
    }

    OpenAddCompetitorsDialog() {
        this.selectedHotelToAdd = null;
        this.displayAddCompForm = true;

    }
    delCompetitors(event): void {
        this.confirmationService.confirm({
            target: event.target,
            message: `Supprimer le concurrent: ${this.selectedHotelWithRoomtypes.hotel.name} ?`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {
                this.sub4 = this.hotelsService.apiHotelsHotCodeRemovecompetitorDelete(this.currentHotel.hotCode, this.selectedHotelWithRoomtypes.hotel.rateShopperId)
                    .subscribe({
                        next: (value) => {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'RateShopper',
                                detail: `concurrent ${this.selectedHotelToAdd.name} Supprimé`
                            });
                            this.notifService.setMessage(`concurrent ${this.selectedHotelToAdd.name} Supprimé`, 'Concurents', false);
                            this.LoadCompetitors();
                            this.selectedHotelWithRoomtypes = null;
                        },
                        error: (err => {
                            // console.log(err);
                            this.messageService.add({
                                severity: 'error',
                                summary: 'RateShopper',
                                detail: `Erreur dans la procédure d'de supression du concurrent  ${this.selectedHotelWithRoomtypes.hotel.rateShopperId}`,
                                life: 5000
                            });
                            this.notifService.setMessage(`Erreur dans la procédure d'de supression du concurrent  ${this.selectedHotelWithRoomtypes.hotel.rateShopperId}`, 'Concurents', true);
                        })
                    }
                    );
            },
            reject: () => {
                this.messageService.add({ severity: 'error', summary: 'RateShopper', detail: 'Opération annulée' });
            }
        });






    }

    addCompetitors(): void {
        this.displayAddCompForm = false;

        this.hotelsService.apiHotelsHotCodeAddcompetitorPost(this.currentHotel.hotCode, this.selectedHotelToAdd.rateShopperId)
            .subscribe((value) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'RateShopper',
                    detail: `concurrent ${this.selectedHotelToAdd.name} ajouté`
                });
                this.notifService.incrementCount();
                this.notifService.setMessage(`concurrent ${this.selectedHotelToAdd.name} ajouté`, 'Concurents', false);
                this.LoadCompetitors();
            },
                (err => {
                    // console.log(err);
                    this.messageService.add({ severity: 'error', summary: 'RateShopper', detail: "Erreur dans la procédure d'jout d'un concurrent", life: 5000 });
                })
            );
        this.notifService.incrementCount();
        this.notifService.setMessage(`Erreur dans la procédure d'jout de concurrent ${this.selectedHotelToAdd.name}`, 'Concurents', true);
    }
    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
    }


}
