import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Events } from '../model/dateCal';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionConfiguration } from '../model/OptionConfiguration';

@Component({
    selector: 'app-calendrier-annuel',
    templateUrl: './calendrier-annuel.component.html',
    styleUrls: ['./calendrier-annuel.component.css'],
})
export class CalendrierAnnuelComponent implements OnInit, OnChanges {

    @Input() events: Array<Events>;
    @Input() optionConfiguration: OptionConfiguration;

    mois: number = 1;
    annee: number = moment().year();
    monthRange: { month: number; year: number }[];


    getMonthRange(): { month: number; year: number }[] {
        const range: { month: number; year: number }[] = [];
        const totalMonths = 24;
        // const totalMonths = 12;
        for (let i = 0; i < totalMonths; i++) {
            let month = this.mois + i;
            let year = this.annee;
            if (month > 12) {
                month = month - 12;
                year = this.annee + 1;
            }
            range.push({ month, year });
        }
        return range;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.monthRange = this.getMonthRange();
        this.trieEvent();
    }


    evenementTrier: Events[];

    ngOnInit() {
    }

    trieEvent() {
        this.evenementTrier = [];
        for (const ev of this.events) {
            if (!this.evenementTrier.find(x => x.color == ev.color && ev.label == x.label)) {
                this.evenementTrier.push(ev);
            }
        }
    }
    tailleDivScroll: number;
    // @ViewChild('scroll') divElement!: ElementRef<HTMLDivElement>;

    ngAfterViewInit() {
        // setTimeout(() => {
        //     this.updateDivWidth();
        // });
    }

    // @HostListener('window:resize')
    // onWindowResize() {
    //     this.updateDivWidth();
    // }

    // updateDivWidth() {
    //     this.tailleDivScroll = this.divElement.nativeElement.offsetWidth;
    //     console.log(this.tailleDivScroll);
    // }
}
