export * from './admin.service';
import { AdminService } from './admin.service';
export * from './budget.service';
import { BudgetService } from './budget.service';
export * from './console.service';
import { ConsoleService } from './console.service';
export * from './dataLive.service';
import { DataLiveService } from './dataLive.service';
export * from './indicators.service';
import { IndicatorsService } from './indicators.service';
export * from './indicatorsLive.service';
import { IndicatorsLiveService } from './indicatorsLive.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './statsMarche.service';
import { StatsMarcheService } from './statsMarche.service';
export const APIS = [AdminService, BudgetService, ConsoleService, DataLiveService, IndicatorsService, IndicatorsLiveService, SettingsService, StatsMarcheService];
