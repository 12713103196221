<h3></h3>
<div >
    <p-toolbar>
        <div class="p-toolbar-group-left">
            <div class="ui-helper-clearfix" style="text-align: left" >
                <p-button icon="pi pi-file-excel" iconPos="left" styleClass="p-button-success" 
                 label="EXCEL" (click)="exportExcel()" class="ui-button-success">
                </p-button>            
                <p-calendar [(ngModel)]="selectedDate" [showIcon]="true" dateFormat="yy-mm-dd"
                 (onSelect)="getDataLive(selectedDate)" showButtonBar="true" 
                 (keyup.enter)="getDataLive(selectedDate)" placeholder="Sélectionner une date">
                </p-calendar>
            </div>
        </div>
    </p-toolbar>
    <div >
    <p-table class="p-m-1" #dt [columns]="cols" [value]="listDataLive" [paginator]="true"  [frozenColumns]="frozenCols" frozenWidth="360px"
            [rows]="rows" [showCurrentPageReport]="true" [loading]="loading" [scrollable]="true" scrollHeight="580px" styleClass="p-datatable-striped">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width != undefined ? col.width + 'px' : '180px'}">
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns">
                    <div *ngIf = "(col.header == 'dataLiveItemDate')">
                        <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" 
                    [ngStyle]="{'width': col.width != undefined ? col.width - 20 + 'px' : '150px'}">
                        <!-- <p-columnFilter type="date" field="name" ></p-columnFilter> -->
                    </div>
                    <div *ngIf = "(col.header == 'creationDate')">
                        <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" 
                    [ngStyle]="{'width': col.width != undefined ? col.width - 20 + 'px' : '150px'}"> 
                    </div>
                    <div *ngIf = "(col.header == 'arrivalDate')">
                        <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" 
                    [ngStyle]="{'width': col.width != undefined ? col.width - 20 + 'px' : '150px'}"> 
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [ngClass]="{'row-status': rowData.status == 'S',
                'row': rowData.status != 'S' && rowData.roomOccupancy == '-1'}">
                <td *ngFor="let col of columns">
                    {{rowData[col.field]}}
                </td>
            </tr>            
        </ng-template>
    </p-table>
    </div>
</div>


