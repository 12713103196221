import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SessionService } from '../services/api/api';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';

import { DistribSession } from '../services/model/distribSession'
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class DistribSessionService {

    SessionsByHotel: Map<string, DistribSession> = new Map<string, DistribSession>();

    subjectNewSession: BehaviorSubject<DistribSession>; // peut-être qu'il faut mettre subject;

    subjectCancelSession: Subject<DistribSession>;

    subsciptionSessionBase: Subscription;

    constructor(private sessionService: SessionService, private userInfoService: UserInfoService, private router:Router) {
        this.subjectNewSession = new BehaviorSubject<DistribSession>(null);
        this.subjectCancelSession = new Subject<DistribSession>();

        this.userInfoService.getNewHotel$()
            .subscribe(hotel => {
                setTimeout(() => {
                console.log(this.router.url)
                    if (hotel?.hotCode && this.router.url.toLowerCase().includes("distrib") )
                        this.loadSessionFoHotel(hotel.hotCode);
                }, 100);
            });
    }

    loadSessionFoHotel(hotCode: string):void {
        let currentSession = this.SessionsByHotel.get(hotCode);
        if (!currentSession) { // regarder en base s'il y a une session

            if (this.subsciptionSessionBase) this.subsciptionSessionBase.unsubscribe();

            this.subsciptionSessionBase = this.sessionService.apiSessionGet(hotCode).subscribe(
                {
                    next: session => {
                        let currentSession: DistribSession = {};
                        currentSession.dateStart = session.dateStart;
                        currentSession.sessionId = session.sessionId;
                        currentSession.hotCode = hotCode;
                        this.SessionsByHotel.set(hotCode, currentSession);
                        this.subjectNewSession.next(currentSession);
                    }
                }
            )
        }
        this.subjectNewSession.next(currentSession);

    }

    newSession(hotCode: string, sessionId: string): void {

        if (!sessionId || !hotCode) return;

        let currentSession = this.SessionsByHotel.get(hotCode);
        if (!currentSession) {
            let currentSession: DistribSession = {};
            currentSession.sessionId = sessionId;
            currentSession.dateStart = new Date();
            currentSession.hotCode = hotCode;
            this.SessionsByHotel.set(hotCode, currentSession);
            this.subjectNewSession.next(currentSession);
        }
        else if (currentSession.sessionId != sessionId) {
            this.SessionsByHotel.delete(hotCode);  //une seule session par hotel;
            let currentSession: DistribSession = {};
            currentSession.sessionId = sessionId;
            currentSession.dateStart = new Date();
            currentSession.hotCode = hotCode;
            this.SessionsByHotel.set(hotCode, currentSession);
            this.subjectNewSession.next(currentSession);
        }
    }

    validateSession(distribSession: DistribSession): void {

        let currentSession = this.SessionsByHotel.get(distribSession.hotCode);
        if (currentSession) {
            let sub1 = this.sessionService.apiSessionValidatePost(distribSession.hotCode, distribSession.sessionId).subscribe(x => {

                this.SessionsByHotel.delete(distribSession.hotCode);
                sub1?.unsubscribe();
                this.loadSessionFoHotel(this.userInfoService.currentHotel.hotCode);
            })
        }
    }

    cancelSession(distribSession: DistribSession): void {

        let currentSession = this.SessionsByHotel.get(distribSession.hotCode);
        if (currentSession) {
            let sub1 = this.sessionService.apiSessionCancelPost(distribSession.hotCode, distribSession.sessionId).subscribe(x => {

                this.SessionsByHotel.delete(distribSession.hotCode);
                sub1?.unsubscribe();
                this.loadSessionFoHotel(this.userInfoService.currentHotel.hotCode);
                this.subjectCancelSession.next(null);
            })
        }
    }

    getNewSession$(): Observable<DistribSession> {

        return this.subjectNewSession.asObservable();
    }

    getCancelSession$(): Observable<DistribSession> {
        return this.subjectCancelSession.asObservable();
    }
}



