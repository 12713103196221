import { Injectable, NgZone } from '@angular/core';
import { Observable, fromEvent, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  private breakpoints = {
    xs: 576,
    sm: 800,
    md: 960,
    lg: 1200
  };

  constructor(private zone: NgZone) { }

  public observeBreakpoint(breakpoint: string): Observable<boolean> {
    const query = `(min-width: ${this.breakpoints[breakpoint]}px)`;
    const mediaQuery = window.matchMedia(query);
    const resize$ = fromEvent(window, 'resize');
    const match$ = merge(resize$, this.zone.onStable.pipe(startWith(null)))
      .pipe(
        map(() => mediaQuery.matches)
      );
    return match$;
  }

}
