<div id="selecthotels">
    <div class="barHotels">
        <ng-container *ngFor="let item of getSelectedHotels()">

            <button pButton type="button" [label]="item.hotCode" [ngClass]="getclassForbutton(item)"
                tooltipPosition="bottom" [pTooltip]="item.name" (click)="newHotel(item)"
                style="border-radius: 0px; box-shadow: none; text-align: center; "></button>
        </ng-container>
    </div>

    <div class="barHotels" (click)="op.toggle($event)">
        <a pButton type="button" icon="pi pi-search" class="ui-button-warn"></a>
    </div>
</div>
<div class="hotelTitle" *ngIf="is_lg" >{{userInfoService?.currentHotel?.name}}</div>


<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="popupListHotel">
            <div class="ui-inputgroup">
                <input type="text" pInputText class="textSearch" [(ngModel)]="textSearch" (keyup)="findHotels()"
                    autofocus #searchText>
                <a pButton type="button" icon="pi pi-search"></a>
            </div>

            <div *ngFor="let hot of filtrerdlisthotel.slice(0,15)" class="lineHotel" (click)="addRemoveHotel(hot)">
                <span class="hotCode">{{hot.hotCode}}</span>
                <span class="hotelName">{{hot.name}}</span>

                <button pRipple type="button" pButton icon="pi pi-plus" *ngIf="!isSelected(hot.hotCode)"
                    style="font-size:0.5em;" class="p-button-info">
                </button>

                <button pRipple pButton type="button" icon="pi pi-minus" *ngIf="isSelected(hot.hotCode)"
                    style="font-size:0.5em;" class="p-button-danger">
                </button>

            </div>
        </div>

    </ng-template>
</p-overlayPanel>
