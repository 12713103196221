<p-toolbar>
    <div class="p-toolbar-group-left">
        <p-button label="Nouveau" icon="pi pi-plus" class="mr-1" (onClick)="newHotel()"
            [disabled]="!dataLoaded"></p-button>
        <p-button label="Edition" icon="pi pi-pencil" class="mr-1" tyleClass="p-button-success" (onClick)="editHotel()"
            [disabled]="!selectedHotels || !dataLoaded"></p-button>

        <p-button label="Activer / Désactiver" icon="pi pi-times" styleClass="p-button-danger" class="mr-1"
            (onClick)="desableHotel()" [disabled]="!selectedHotels || !dataLoaded"></p-button>

        <!-- pTooltip="XLS" -->
        <p-button icon="pi pi-file-excel" iconPos="left" styleClass="p-button-success" [disabled]="!dataLoaded"
          tooltipPosition="bottom" label="Export excel" (click)="exportExcel()" class="ui-button-success">
        </p-button>
    </div>


</p-toolbar>

<p-table #dt [columns]="cols" [value]="hotels" [resizableColumns]="true" [paginator]="true" [rows]="20"
    styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true" selectionMode="single"
    [(selection)]="selectedHotels" dataKey="rateShopperId" (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="caption">
        <div style="text-align: left">
            <input type="text" pInputText size="50" placeholder="Global Filter"
                (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        </div>
    </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns" class="ui-resizable-column">
                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="col.field=='bookingUrl'">
                        <a [href]="'https://'+rowData[col.field]" target="_blank">
                            <button pButton type="button" [label]="rowData[col.field]" class="p-button-link">
                            </button>
                        </a>
                    </ng-container>

                    <ng-container *ngSwitchCase="col.field=='rateShopperId'">
                        {{rowData[col.field]}}
                        <span *ngIf="rowData['isDisabled']==true">
                            <i class="pi pi-times" style="color: red;"></i>
                        </span>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="displayHotelForm" [modal]="true" [style]="{width: '780px'}">
    <p-header>
        Rate shopper hôtel
    </p-header>
    <app-form-dynamic *ngIf="displayHotelForm" [formModel]="hotelFormsModel" (OnValidate)="validate($event)"
        [formValue]="lineValue" (OnCancel)="cancel($event)">
    </app-form-dynamic>
</p-dialog>

