import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserInfoService } from "src/app/azureAdB2C/UserInfoService/user-InfoService";
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { AdminService } from "../services/misterbooking/api/admin.service";
import { Subscription, forkJoin } from "rxjs";
import { SubSink } from "src/app/dynamicForms/tools/subsink";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
    selector: "app-mister-booking",
    templateUrl: "./mister-booking.component.html",
    styleUrls: ["./mister-booking.component.css"],
})
export class MisterBookingComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    currentHotel: HotelAuth;
    hotelNotIntialized: boolean;
    passwordValue: string;
    idHotel: number;
    loginHotel: string;
    prixOn: boolean;
    restrictionOn: boolean;
    simulationOn: boolean;
    hotelDataLoaded: boolean;
    chargementCredentialsFini: boolean = false;
    chargementActivationFini: boolean = false;
    isFormInvalid: boolean = false;
    dataLoad: boolean;
    rolesFormInfoService: string[];
    position: string = 'center';
    hotCode : string = null;
    misterBookingPutDialog : boolean = false;

    constructor(
        private userInfoService: UserInfoService,
        private liveMisterBookingService: AdminService,
        private breadcrumbService: BreadcrumbService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService
    ) {
        this.breadcrumbService.setItems([{ label: "SpotLive/MisterBooking"}]);
    }

    ngOnInit(): void {
        this.currentHotel = this.userInfoService.currentHotel;
        this.affichageDonneHotel();
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
                this.affichageDonneHotel();
            });
        this.userInfoService.getUserRoles$().subscribe((roles) => {
            this.rolesFormInfoService = roles;
        });
    }

    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }

    isAdmin(): boolean {
        if (
            this.rolesFormInfoService &&
            this.rolesFormInfoService.find((r) => r == "Administrator")
        ) {
            return true;
        }
        return false;
    }

    affichageDonneHotel() {
        this.dataLoad = true;
        this.isFormInvalid = false;
        this.hotelNotIntialized = false;
        this.subs.sink = this.liveMisterBookingService
            .adminGetcredentialsGet(this.currentHotel.hotCode)
            .subscribe({
                next: (info) => {
                    if (info == null) {
                        this.hotelNotIntialized = true;
                        this.idHotel = null;
                        this.loginHotel = null;
                        this.passwordValue = null;
                        this.prixOn = false;
                        this.restrictionOn = false;
                        this.simulationOn = false;
                    } else {
                        this.idHotel = info.misterBookingHotelId;
                        this.loginHotel = info.misterBookingLogin;
                        this.passwordValue = info.misterBookingPwd;
                        this.prixOn = info.rateEnabled;
                        this.restrictionOn = info.restrictionEnabled;
                        this.simulationOn = info.simulation;
                    }
                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    this.dataLoad = false;
                },
            });
    }
    putConfirmation(){
        this.hotCode = null;
        this.misterBookingPutDialog = true;
    }
    initialiseHotel() {
        this.misterBookingPutDialog = false;
        this.dataLoad = true;
        this.isFormInvalid = true;
        if (this.idHotel && this.loginHotel && this.passwordValue) {
            this.subs.sink = this.liveMisterBookingService
                .adminSetcredentialsdetailedPost(
                    this.currentHotel.hotCode,
                    this.idHotel,
                    this.loginHotel,
                    this.passwordValue,
                    this.prixOn,
                    this.restrictionOn,
                    this.simulationOn
                )
                .subscribe({
                    next: (modif) => {},
                    error: (error) => {
                        console.error(error);
                    },
                    complete: () => {
                        this.affichageDonneHotel();
                    },
                });
        }
    }
}
