import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    Output,
    EventEmitter,
    OnDestroy,
} from "@angular/core";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { BudgetService } from "src/app/budgets/services/api/api";
import { ICols } from "src/app/analyse/services/model/cols-table";
import { BudgetMonth } from "src/app/budgets/services/model/budgetMonth";
import { forkJoin, takeUntil, Subject, take, lastValueFrom } from "rxjs";
import { HotelAuth } from "src/app/azureAdB2C/services/model/models";
import { UserInfoService } from "src/app/azureAdB2C/UserInfoService/user-InfoService";
import * as moment from "moment";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { BudgetDay } from "src/app/budgets/services/model/budgetDay";
import { FileUpload } from "primeng/fileupload";
import { SettingsService } from "src/app/analyse/services/api/api";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { SubSink } from "src/app/dynamicForms/tools/subsink";
import { BudgetYearCalculate } from "./budgetYearCalculate";
import { Console } from "console";
import { BudgetCalcul } from "./budgetCalcul";
import { BudgetInfo } from "../services/model/budgetInfo";
import { TooltipOptions } from "primeng/tooltip";

@Component({
    selector: "app-budgets",
    templateUrl: "./budgets.component.html",
    styleUrls: ["./budgets.component.css"],
})
export class BudgetsComponent implements OnInit, OnDestroy {
    @ViewChild("inputText") inputText: ElementRef;
    @ViewChild("inputFile") inputFile: ElementRef;
    @ViewChild("fileUpload") fileUpload: FileUpload;

    budgetMonth: BudgetMonth = {};
    selectedBudget: BudgetMonth = {};
    newBudgetMonth: BudgetMonth = {};
    cols: string[];
    private subs = new SubSink();
    currentHotel: HotelAuth;
    bugdetYear: any[] = [];
    bugdetYears: any;
    displayBudget: boolean;
    year: number = 0;
    dataLoaded: boolean;
    btnClass: any = {};
    isFormValidateBudget: boolean = false;
    bugdetExist: any;
    selectedYear: number;
    selectedMonth: string;
    monthIdex: number;
    month: string;
    url: string;
    uploadedFiles: Blob[] = [];
    selectedFile: string;
    uploadHeaders: any;
    ptotal: string[];
    budgetDay: BudgetDay[];
    dayCols: ICols[];
    nbRooms: number;
    displayBudgetDays: boolean;
    nbChambres: number;
    isHt: boolean = false;
    isError: boolean;
    rolesFormInfoService: string[];
    isAdm: boolean = false;
    budgetExist: boolean = false;
    tooltipOptions: TooltipOptions = {
        autoHide: false,
        tooltipEvent: 'hover',
        tooltipPosition: 'left',
        disabled : false,
        appendTo: 'body',
    };
    position: string = 'center';

    constructor(
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private userInfoService: UserInfoService,
        private notifService: NotificationsService,
        private budgetService: BudgetService,
        private settingsService: SettingsService,
        private sanitizer: DomSanitizer,
        private confirmationService: ConfirmationService,
    ) {
        this.breadcrumbService.setItems([{ label: "analyse/budgets" }]);
    }

    ngOnInit(): void {
        this.ptotal = ["p3", "p4", "p5", "p6", "p7", "p8", "p9"];
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
                setTimeout(() => {
                    this.getBudgetYears();
                    this.loadBudgetInfo();
                }, 100);
            });
            this.userInfoService.getUserRoles$().subscribe((roles) => {
                this.rolesFormInfoService = roles;
            });
        this.btnClass = {};
        this.currentHotel = this.userInfoService.currentHotel;
        this.bugdetYears = [];
        this.uploadHeaders = {
            "Content-Type": "multipart/form-data",
        };
        this.dayCols = [
            { field: "dayOfWeek", header: "", width: 30 },
            { field: "date", header: "", width: 30 },
            { field: "cah", header: "cah", width: 90 },
            { field: "caPdj", header: "caPdj" },
            { field: "p3", header: "p3" },
            { field: "p4", header: "p4" },
            { field: "p5", header: "p5" },
            { field: "p6", header: "p6" },
            { field: "p7", header: "p7" },
            { field: "p8", header: "p8" },
            { field: "p9", header: "p9" },
            { field: "occup", header: "occup" },
            { field: "nuitees", header: "nuits" },
            { field: "hs", header: "hs" },
            { field: "offerts", header: "offer" },
            { field: "chVendu", header: "chVend" },
            { field: "chTravaux", header: "chTrav" },
            { field: "nbCh", header: "nbCh" },
        ];
        
    }

    GetDisbaledBudget():boolean
    {
        if(this.isAdmin()) return false;
        return this.budgetExist;
    }
    isAdmin(): boolean {
        if (
            this.rolesFormInfoService &&
            this.rolesFormInfoService.find((r) => r == "Administrator")
        ) {
            return true;
        }
        return false;
    }
    loadBudgetInfo() {
        this.subs.sink = this.budgetService
            .apiBudgetHotCodeBudgetInfoGet(this.currentHotel.hotCode)
            .subscribe({
                next: (data) => {
                    this.isHt = data.isHT;
                    this.budgetExist = data.budgetExist;
                    console.log("api budget exist ", this.budgetExist)
                },
                error: (err) => {
                    this.notifService.setMessage(`Erreur APi`, "Budget", true);
                },
            });
    }

    async getBudgetYears(yearParam?: number) {
        this.bugdetExist = {};
        this.btnClass = {};
        this.year = moment().year();
        this.bugdetYear = [];
        for (let i = 0; i < 5; i++) {
            let y = moment().year() - i;
            try {
                const result$ = this.budgetService.apiBudgetHotCodeYearGet(
                    this.currentHotel.hotCode,
                    y + 1
                );
                const data = await lastValueFrom(result$);
                let dataYear: BudgetMonth[] = data.budgetMonths;
                this.bugdetExist[y + 1] = data.budgetExist;
                if (dataYear && dataYear.length > 0) {
                    let ptotal = ["p3", "p4", "p5", "p6", "p7", "p8", "p9"];
                    let notIncludedArr = ["chvendu", "chtravaux", "offerts", "tauxremplissage", "to", "pm",
                        "occup", "nbch",  "nuitees", "hs"];
                    let budgetYear = new BudgetYearCalculate();

                    let result = budgetYear.budgetCalculate(dataYear, ptotal, notIncludedArr);
                    this.cols = result.cols;
                    this.bugdetYears[y + 1] = result.bugdetYear;
                    if (yearParam) {
                        if (yearParam == y + 1) {
                            this.getBudgetYear(yearParam, true);
                        } else {
                            this.getBudgetYear(y + 1, false);
                        }
                    } else {
                        if (i == 1) {
                            this.getBudgetYear(this.year, true);
                        } else {
                            this.getBudgetYear(y + 1, false);
                        }
                    }
                }
            } catch (error) {}
        }
        this.dataLoaded = true;
    }
    GetExportUrl(): SafeUrl {
        this.url = `${environment.urlBudget}/api/budget/${
            this.currentHotel.hotCode
        }/downloadbudgetdays?year=${this.selectedYear}&parm=${Date.now()}`;
        return this.sanitizer.bypassSecurityTrustUrl(this.url);
    }

    getBudgetYear(year: number, isClicked: boolean) {
        this.monthIdex = null;
        if (isClicked) {
            this.bugdetYear = this.bugdetYears[year];
            Object.keys(this.btnClass).map((elem) => {
                if (this.bugdetExist[elem]) {
                    this.btnClass[elem] = "p-button-success";
                } else {
                    this.btnClass[elem] = "p-button-warning";
                }
            });
            if (this.bugdetExist[year]) {
                this.btnClass[year] = "p-button-success";
            } else {
                this.btnClass[year] = "p-button-warning";
            }
            this.btnClass[year] += " isSelectedYear";

            this.selectedYear = year;

            this.url = `https://services.spotpilot.com/budget/api/budget/${this.currentHotel.hotCode}/downloadbudgetdays?year=${this.selectedYear}`;
        } else {
            if (this.bugdetExist[year]) {
                this.btnClass[year] = "p-button-success";
            } else {
                this.btnClass[year] = "p-button-warning";
            }
        }
    }
    loadData(col: string, month: number): void {
        try {
            let year = this.selectedYear;
            const nbDaysMonth: number = new Date(
                this.selectedYear,
                month,
                0
            ).getDate();
            this.nbRooms = 0;
            let dateFrom = new Date(year, month - 1, 1);
            let dateTo = new Date(year, month - 1, nbDaysMonth);
            const obs1$ = this.settingsService.apiSettingsHotCodeRoomtypesGet(
                this.currentHotel.hotCode,
                dateFrom,
                dateTo
            );
            const obs2$ = this.budgetService.apiBudgetHotCodeDaysGet(
                this.currentHotel.hotCode,
                month,
                year
            );

            this.subs.sink = forkJoin([obs1$, obs2$]).subscribe({
                next: (result) => {
                    let roomTypes = result[0].roomTypes;
                    roomTypes.map((x) => {
                        this.nbRooms += x.isReal ? x.nbRooms : 0;
                    });
                    this.budgetDay = result[1];
                    this.dataLoaded = true;
                    this.displayFormBudgetMonth(col, month);
                    this.displayBudgetDays = this.budgetCalcul.isbudgetValidate;
                },
                error: (err) => {
                    console.error(err);
                    this.dataLoaded = false;
                }
        });
        } catch (error) {
            this.dataLoaded = false;
        }
    }
    handleChange(e, position: string) {
        this.position = position;
      this.confirmationService.confirm({
        target: event.target,
        message:`Confirmation de Basculer le budget tva de TTC en HT ?`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Oui",
        rejectLabel: "Non",
        accept: () => {
         this.isHt = e.checked;
         let budgetInfo: BudgetInfo = {};
         budgetInfo.hotCode = this.currentHotel.hotCode;
         budgetInfo.isHT = this.isHt;
         this.subs.sink = this.budgetService
             .apiBudgetHotCodeBudgetInfoPut(
                 this.currentHotel.hotCode,
                 budgetInfo
             )
             .subscribe({
                 next: (data) => {
              this.notifService.setMessage(`basculement avec succès de TTC - HT `,"Budget tva", false);

                 },
                 error: (error) => {
                    this.notifService.setMessage(error?.error,"Budget", true);
                 }
        });
              //this.notifService.setMessage(`basculement avec succès de TTC - HT `,"Budget tva", false);

            },
        reject: () => {
          this.isHt = !e.checked;
          this.messageService.add({ severity: 'error', summary: 'BudgetTVA', detail: 'Opération annulée' });
        }
      });
        //this.isHt = e.checked;
    }
    uploadBudgetDays(event, fileUpload) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
            let f: Blob = file;
            this.subs.sink = this.budgetService
                .apiBudgetUploadBudgetDaysPostForm(
                    f,
                    this.currentHotel.hotCode,
                    this.selectedYear
                )
                .subscribe({
                    next: () => {
                        fileUpload.clear();
                        this.notifService.setMessage(
                            `chargement avec succès du budget ${this.selectedYear}`,
                            "Import Budget",
                            false
                        );
                    },
                    error: (error) => {
                        if (error.status == 200) {
                            fileUpload.clear();
                            this.notifService.setMessage(
                                `chargement avec succès du budget ${this.selectedYear}`,
                                "Import Budget",
                                false
                            );
                        } else {
                            this.onClearSelection();
                            fileUpload.clear();
                            this.notifService.setMessage(
                                error?.error,
                                "Import Budget",
                                true
                            );
                        }
                        this.getBudgetYears(this.selectedYear);
                    },
                });
        }
    }

    updateBudgetMonth() {
        let m =
            this.budgetMonth.month > 9
                ? moment(`${this.selectedYear}-${this.budgetMonth.month}`)
                : moment(`${this.selectedYear}-0${this.budgetMonth.month}`);
        let month = m.locale("fr").format("MMMM");
        const obs3$ = this.budgetService.apiBudgetHotCodeDaysPost(
            this.currentHotel.hotCode,
            this.budgetDay
        );
        const obs4$ = this.budgetService.apiBudgetHotCodeMonthPost(
            this.currentHotel.hotCode,
            this.budgetMonth
        );

        this.subs.sink = forkJoin([obs3$, obs4$]).subscribe({
            next: data => {

                this.notifService.setMessage(
                    `${this.currentHotel.hotCode}:Enregistrement avec succès du budget
          ${month} ${this.selectedYear}`,
                    "Budget",
                    false
                );
                this.displayBudget = false;
                this.getBudgetYears(this.selectedYear);
            },
            error: err => {
                this.notifService.setMessage(err?.error, "Budget", true);
                this.dataLoaded = false;
        
            }   
        });
        this.subs.sink = this.budgetService
            .apiBudgetHotCodeDaysPost(this.currentHotel.hotCode, this.budgetDay)
            .subscribe((data) => {
                this.getBudgetYears(this.selectedYear);
            });
    }

    budgetCalcul: BudgetCalcul;

    displayFormBudgetMonth(col: string, i: number) {
        if (col != "ca" && col != "Total") {
            this.monthIdex = i;
            this.newBudgetMonth = { month: i };
            let selectedMonth =
                i > 9
                    ? moment(`${this.selectedYear}-${i}`)
                    : moment(`${this.selectedYear}-0${i}`);
            this.selectedMonth = selectedMonth.locale("fr").format("MMMM");
            let data = this.bugdetYear.map((row) => {
                let prop = row.ca;
                if (prop != "Total Ca") {
                    this.newBudgetMonth[prop] = row[col];
                    this.newBudgetMonth = this.newBudgetMonth;
                    this.newBudgetMonth.tauxRemplissage =
                        this.newBudgetMonth.tauxRemplissage != 0
                            ? this.newBudgetMonth.tauxRemplissage
                            : 1.6;
                }
            });
            const nbDaysMonth: number = new Date(
                this.selectedYear,
                i,
                0
            ).getDate();
            this.newBudgetMonth.pm =
                this.newBudgetMonth.chVendu > 0
                    ? this.newBudgetMonth.cah / this.newBudgetMonth.chVendu
                    : 0;
            this.newBudgetMonth.to =
                this.newBudgetMonth.nbCh * nbDaysMonth -
                    this.newBudgetMonth.chTravaux >
                0
                    ? (this.newBudgetMonth.chVendu /
                          (this.newBudgetMonth.nbCh * nbDaysMonth -
                              this.newBudgetMonth.chTravaux)) *
                      100
                    : 0;
            this.newBudgetMonth.nbCh = this.nbRooms > 0 ? this.nbRooms : 50;
            this.newBudgetMonth.prixPdj =
                this.newBudgetMonth.prixPdj != 0
                    ? this.newBudgetMonth.prixPdj
                    : 10;
            this.month = col;

            this.budgetCalcul = new BudgetCalcul(
                nbDaysMonth,
                this.nbRooms,
                this.newBudgetMonth.cah,
                this.newBudgetMonth.chVendu,
                this.newBudgetMonth.chTravaux,
                this.newBudgetMonth.pm,
                this.newBudgetMonth.to,
                this.newBudgetMonth.caPdj,
                this.newBudgetMonth.tauxRemplissage,
                this.newBudgetMonth.prixPdj,
                this.newBudgetMonth.p3,
                this.newBudgetMonth.p4,
                this.newBudgetMonth.p5,
                this.newBudgetMonth.p6,
                this.newBudgetMonth.p7,
                this.newBudgetMonth.p8,
                this.newBudgetMonth.p9
            );
            this.displayBudget = this.dataLoaded;
        }
    }

    async processPost(event) {
        this.budgetDay = [];
        this.formValues();
        this.monthIdex = this.newBudgetMonth.month;
        if (this.budgetCalcul.isbudgetValidate) {
            try {
                const data$: any =
                    await this.budgetService.apiBudgetHotCodeProcessPost(
                        this.budgetMonth.hotCode,
                        this.budgetMonth
                    );
                const data: any = await lastValueFrom(data$);
                this.budgetDay = data;
                let errors = [];
                errors = this.budgetDay.map((x) => x.isError == true);
                this.isError = errors.includes(true);
            } catch (error) {}
            this.displayBudgetDays = true;
        }
    }
    onRowSelect(event) {
        // alert(event);
    }
    formValues() {
        this.budgetMonth.hotCode = this.currentHotel.hotCode;
        this.budgetMonth.hs = 0;
        this.budgetMonth.month = this.newBudgetMonth.month;
        this.budgetMonth.nuitees = 0;
        this.budgetMonth.occup = 0;
        this.budgetMonth.p3 = this.newBudgetMonth.p3;
        this.budgetMonth.p4 = this.newBudgetMonth.p4;
        this.budgetMonth.p5 = this.newBudgetMonth.p5;
        this.budgetMonth.p6 = this.newBudgetMonth.p6;
        this.budgetMonth.p7 = this.newBudgetMonth.p7;
        this.budgetMonth.p8 = this.newBudgetMonth.p8;
        this.budgetMonth.p9 = this.newBudgetMonth.p9;
        this.budgetMonth.prixPdj = this.newBudgetMonth.prixPdj;
        this.budgetMonth.caPdj = this.newBudgetMonth.caPdj;
        this.budgetMonth.tauxRemplissage = this.newBudgetMonth.tauxRemplissage;
        this.budgetMonth.year = this.newBudgetMonth.year;
        this.budgetMonth.pm = this.budgetCalcul.pm;
        this.budgetMonth.nbCh = this.budgetCalcul.nbCh;
        this.budgetMonth.to = this.budgetCalcul.to;
        this.budgetMonth.cah = this.budgetCalcul.cah;
        this.budgetMonth.chTravaux = this.budgetCalcul.chTrv;
        this.budgetMonth.chVendu = this.budgetCalcul.chv;
        this.budgetMonth.offerts = 0;
    }

    budgetValidate(event) {
        this.formValues();
        if (this.budgetCalcul.isbudgetValidate) {
            this.updateBudgetMonth();
        }
    }
    onSelectedFile(event) {
        this.selectedFile = event.files[0]?.name;
    }
    onClearSelection() {
        this.selectedFile = "";
    }
    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }
}
