import { Component, OnInit,Input,OnChanges,SimpleChanges} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.css'],
})
export class SwitchComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() field: FormParameter;

    formControl: FormControl2;
    tooltipzindex=TOOL_TIP_Z_INDEX;


    constructor() {}

    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2
    }
    handleChange(event) {
        this.formControl.update_Value_And_Validity();
    }
}
