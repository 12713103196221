/**
 * Spot PBIEmbedded-SF Api V2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiReport } from '../model/apiReport';
import { ApiWorkSpaces } from '../model/apiWorkSpaces';
import { EmbedConfig } from '../model/embedConfig';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PBIReportListService {

    protected basePath = 'https://services-dev.spotpilot.com/pbiembedded-v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Obtenir le token powerBi pour un rapport  : soit par nom ou par id du rapport ( nom est prioritaire)
     * Si le hotCode est spécifié alors le rapport est sera filtré par code hotel , si non par utilisateur
     * @param hotCode 
     * @param reportName Nom du rapport
     * @param groupId Id du group
     * @param reportId Id du rapport
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPBIGenerateTokenGet(hotCode?: string, reportName?: string, groupId?: string, reportId?: string, observe?: 'body', reportProgress?: boolean): Observable<EmbedConfig>;
    public apiPBIGenerateTokenGet(hotCode?: string, reportName?: string, groupId?: string, reportId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmbedConfig>>;
    public apiPBIGenerateTokenGet(hotCode?: string, reportName?: string, groupId?: string, reportId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmbedConfig>>;
    public apiPBIGenerateTokenGet(hotCode?: string, reportName?: string, groupId?: string, reportId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (reportName !== undefined && reportName !== null) {
            queryParameters = queryParameters.set('reportName', <any>reportName);
        }
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }
        if (reportId !== undefined && reportId !== null) {
            queryParameters = queryParameters.set('reportId', <any>reportId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EmbedConfig>('get',`${this.basePath}/api/PBIGenerateToken`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liste des rapports PowerBI   Mise en cache 5 min
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPBIReportListGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ApiReport>>;
    public apiPBIReportListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiReport>>>;
    public apiPBIReportListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiReport>>>;
    public apiPBIReportListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiReport>>('get',`${this.basePath}/api/PBIReportList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Liste des workSpaces disponibles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkSpacesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ApiWorkSpaces>>;
    public apiWorkSpacesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiWorkSpaces>>>;
    public apiWorkSpacesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiWorkSpaces>>>;
    public apiWorkSpacesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiWorkSpaces>>('get',`${this.basePath}/api/workSpaces`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
