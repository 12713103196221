import * as moment from "moment";

export class BudgetYearCalculate {
    budgetCalculate(dataYear: any[], prestKeys : string[], notIncludedArr: string[]) {
        // let prestKeys = ["p3", "p4", "p5", "p6", "p7", "p8", "p9"];
        let cols = [];
        cols.push("ca");
        let bugdetYear = [];
        let keys = Object.keys(dataYear[0]);
        let totalCol: {ca:string} = { ca: "Total Ca" };
        //Data des totaux des prestations p1 à p9 pour chaque colonne
        let pTotalCol: {ca:string} = { ca: "Ca Autre" };
        //keys : les titres des lignes du tableau comme 'Cah' ...
        keys.map((key) => {
          if (key.toLowerCase() != "month" && key.toLowerCase() != "hotcode") {
            let total = 0;
            let prestTotal = 0;
            //L'objet qui contient toutes les données correspondantes aux keys en ligne ainsi que leurs totaux
            // et les données des colonnes ainsi que leurs totaux  
            let obj: any = {};
            //Alimenter les la première colonne avec les tittres 
            obj["ca"] = key;
            //Pour chaque key on map la data pour calculer la somme des donnée correspondante.
            //Calcul des totaux en ligne
            dataYear.map((row) => {
              let m =  row.month > 9 ? moment(`${row.year}-${row.month}`) : moment(`${row.year}-0${row.month}`);
                let month = m.locale("fr")
                .format("MMM")
                .replace(".", "");
              let col = month.charAt(0).toUpperCase() + month.substring(1).toLowerCase();

              if (cols.indexOf(col) == -1) {
                cols.push(col);
              }
              let data = row[key];
              obj[col] = data;
              //Exclure la colonne year
              if (key.toLowerCase() != "year") {
                //calculer le total de la colonne key
                total += data;
                //Si la key est l'une des P1 à P9 donc calculer la somme des prestation pour la colonne donnée key
                if (prestKeys.indexOf(key.toLowerCase()) >= 0) {
                  prestTotal += data;
                  if (!pTotalCol[col]) {
                    pTotalCol[col] = 0;
                  }
                  pTotalCol[col] += data || 0;
                }
                //Exclure les data des colonnes dans notIncludedArr 
                if ( notIncludedArr.indexOf(key.toLowerCase()) == -1 ) {
                  if (!totalCol[col]) {
                    totalCol[col] = 0;
                  }
                  totalCol[col] += data || 0;
                }
              }
            });
            //Alimenter les données de la colonne Total pour chaque key
            //Calcul des totaux en colonne
            if (key.toLowerCase() != "year") {
              if (prestKeys.indexOf(key.toLowerCase()) >= 0) {
                if (!pTotalCol["Total"]) {
                  pTotalCol["Total"] = 0;
                }
                pTotalCol["Total"] += prestTotal || 0;
              }
              if (notIncludedArr.indexOf(key.toLowerCase()) == -1) {
                if (!totalCol["Total"]) {
                  totalCol["Total"] = 0;
                }
                totalCol["Total"] += total || 0;
              }
              // obj["Total"] = total;
              //  pour les lignes où key égal à to ou pm on calcul la moyenne sinon c'est la somme 
              if(key.toLowerCase() == "to" || key.toLowerCase() == "pm"){
                obj["Total"] = total/12
              } else {
                obj["Total"] = total;
              }             
            }
            bugdetYear.push(obj);
          }
        });
        cols.push("Total");
        bugdetYear.push(pTotalCol);
        bugdetYear.push(totalCol);
        console.log(bugdetYear);
        return {bugdetYear , cols}
      }
}
