/**
 * Spot.Live MisterBooking Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MappingConfig } from '../model/mappingConfig';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminService {

    protected basePath = 'https://services-dev.spotpilot.com/mrbookinglive';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Méthode de test de la présence du service
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Admin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtient les credentials pour un hôtel si il est configuré.
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminGetcredentialsGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminGetcredentialsGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminGetcredentialsGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminGetcredentialsGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminGetcredentialsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Admin/getcredentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupère la configuration des mappings pour un hôtel : les rateplan+roomtype SPOT + les rateplan+roomtype MB + les mappings
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminMappingsHotCodeGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminMappingsHotCodeGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminMappingsHotCodeGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminMappingsHotCodeGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminMappingsHotCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Admin/mappings/${encodeURIComponent(String(hotCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifie la configuration des mappings pour un hôtel.  Utiliser GET mappings/{hotCode} pour obtenir la config actuelle, la modifier et la sauvegarder avec PUT mappings/{hotCode}.
     * 
     * @param body 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminMappingsHotCodePut(body: Array<MappingConfig>, hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminMappingsHotCodePut(body: Array<MappingConfig>, hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminMappingsHotCodePut(body: Array<MappingConfig>, hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminMappingsHotCodePut(body: Array<MappingConfig>, hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adminMappingsHotCodePut.');
        }

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminMappingsHotCodePut.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Admin/mappings/${encodeURIComponent(String(hotCode))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Active ou désactive les envois de prix et de restriction pour un hôtel.
     * 
     * @param hotCode 
     * @param rateEnabled 
     * @param restrictionsEnabled 
     * @param simulation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSetactivationsPost(hotCode: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminSetactivationsPost(hotCode: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminSetactivationsPost(hotCode: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminSetactivationsPost(hotCode: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminSetactivationsPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (rateEnabled !== undefined && rateEnabled !== null) {
            queryParameters = queryParameters.set('rateEnabled', <any>rateEnabled);
        }
        if (restrictionsEnabled !== undefined && restrictionsEnabled !== null) {
            queryParameters = queryParameters.set('restrictionsEnabled', <any>restrictionsEnabled);
        }
        if (simulation !== undefined && simulation !== null) {
            queryParameters = queryParameters.set('simulation', <any>simulation);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/Admin/setactivations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure les credentials pour un hôtel.
     * 
     * @param hotCode 
     * @param misterBookingHotelId 
     * @param misterBookingLogin 
     * @param misterBookingPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSetcredentialsPost(hotCode: string, misterBookingHotelId: number, misterBookingLogin: string, misterBookingPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminSetcredentialsPost(hotCode: string, misterBookingHotelId: number, misterBookingLogin: string, misterBookingPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminSetcredentialsPost(hotCode: string, misterBookingHotelId: number, misterBookingLogin: string, misterBookingPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminSetcredentialsPost(hotCode: string, misterBookingHotelId: number, misterBookingLogin: string, misterBookingPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminSetcredentialsPost.');
        }

        if (misterBookingHotelId === null || misterBookingHotelId === undefined) {
            throw new Error('Required parameter misterBookingHotelId was null or undefined when calling adminSetcredentialsPost.');
        }

        if (misterBookingLogin === null || misterBookingLogin === undefined) {
            throw new Error('Required parameter misterBookingLogin was null or undefined when calling adminSetcredentialsPost.');
        }

        if (misterBookingPassword === null || misterBookingPassword === undefined) {
            throw new Error('Required parameter misterBookingPassword was null or undefined when calling adminSetcredentialsPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (misterBookingHotelId !== undefined && misterBookingHotelId !== null) {
            queryParameters = queryParameters.set('misterBookingHotelId', <any>misterBookingHotelId);
        }
        if (misterBookingLogin !== undefined && misterBookingLogin !== null) {
            queryParameters = queryParameters.set('misterBookingLogin', <any>misterBookingLogin);
        }
        if (misterBookingPassword !== undefined && misterBookingPassword !== null) {
            queryParameters = queryParameters.set('misterBookingPassword', <any>misterBookingPassword);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/Admin/setcredentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure les credentials et les activations pour un hôtel.
     * 
     * @param hotCode 
     * @param misterBookingHotelId 
     * @param misterBookingLogin 
     * @param misterBookingPassword 
     * @param rateEnabled 
     * @param restrictionsEnabled 
     * @param simulation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminSetcredentialsdetailedPost(hotCode: string, misterBookingHotelId?: number, misterBookingLogin?: string, misterBookingPassword?: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public adminSetcredentialsdetailedPost(hotCode: string, misterBookingHotelId?: number, misterBookingLogin?: string, misterBookingPassword?: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public adminSetcredentialsdetailedPost(hotCode: string, misterBookingHotelId?: number, misterBookingLogin?: string, misterBookingPassword?: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public adminSetcredentialsdetailedPost(hotCode: string, misterBookingHotelId?: number, misterBookingLogin?: string, misterBookingPassword?: string, rateEnabled?: boolean, restrictionsEnabled?: boolean, simulation?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling adminSetcredentialsdetailedPost.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (misterBookingHotelId !== undefined && misterBookingHotelId !== null) {
            queryParameters = queryParameters.set('misterBookingHotelId', <any>misterBookingHotelId);
        }
        if (misterBookingLogin !== undefined && misterBookingLogin !== null) {
            queryParameters = queryParameters.set('misterBookingLogin', <any>misterBookingLogin);
        }
        if (misterBookingPassword !== undefined && misterBookingPassword !== null) {
            queryParameters = queryParameters.set('misterBookingPassword', <any>misterBookingPassword);
        }
        if (rateEnabled !== undefined && rateEnabled !== null) {
            queryParameters = queryParameters.set('rateEnabled', <any>rateEnabled);
        }
        if (restrictionsEnabled !== undefined && restrictionsEnabled !== null) {
            queryParameters = queryParameters.set('restrictionsEnabled', <any>restrictionsEnabled);
        }
        if (simulation !== undefined && simulation !== null) {
            queryParameters = queryParameters.set('simulation', <any>simulation);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/Admin/setcredentialsdetailed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
