

export class BudgetCalcul {

    constructor(private nbDays: number, nbCh: number, cah: number, chv: number, chTrv: number, pm: number, to: number,
      public caPdj: number, public tauxRemplissage: number, public prixPdj: number,
      public p3: number, public p4: number, public p5: number, public p6: number, public p7: number, public p8: number, public p9: number) {
      this._nbCh = nbCh;
      this._cah = cah;
      this._chv = chv;
      this._chTrv = chTrv
      this._pm = pm;
      this._to = to;
      this.validate();
    }
  
    isbudgetValidate: boolean = false;
  
    private _cah: number;
    get cah(): number {
      return this._cah;
    }
  
    set cah(value: number) {
      this._cah = value;
      this.calcul("cah");
    }
  
    //----------------------------------
    private _chv: number;
    get chv(): number {
      return this._chv;
    }
  
    set chv(value: number) {
      this._chv = value;
      this.calcul("chv");
    }
  
    //----------------------------------
    private _nbCh: number;
    get nbCh(): number {
      return this._nbCh;
    }
  
    set nbCh(value: number) {
      this._nbCh = value;
      this.calcul("nbCh");
    }
  
    //----------------------------------
    private _chTrv: number;
    get chTrv(): number {
      return this._chTrv;
    }
  
    set chTrv(value: number) {
      this._chTrv = value;
      this.calcul("chTrv");
    }
  
    //----------------------------------
    private _pm: number;
    get pm(): number {
      return this._pm;
    }
  
    set pm(value: number) {
      this._pm = value;
      this.calcul("pm");
    }
  
    //----------------------------------
    private _to: number;
    get to(): number {
      return this._to;
    }
  
    set to(value: number) {
      this._to = value;
      this.calcul("to");
  
    }
  
    calcul(inputField: string): void {
  
      if (inputField == "chTrv") {
        let chVMax = this.nbCh * this.nbDays - this.chTrv;
  
  
        if (this.chv > chVMax) {
          this._chv = chVMax;
          this._to = ((this.nbCh * this.nbDays) - this._chTrv) > 0 ? ((this.chv) / ((this.nbCh * this.nbDays) - this._chTrv)) * 100 : 0;
          this._cah = this.pm * (this.chv);
        }
  
        this._to = ((this.nbCh * this.nbDays) - this._chTrv) > 0 ? ((this.chv) / ((this.nbCh * this.nbDays) - this._chTrv)) * 100 : 0;
      }
  
  
      if (inputField == "pm") {
        this._cah = this.pm * (this.chv);
      }
  
      if (inputField == "to") {
        this._chv = Math.round(this.to * (this.nbCh * this.nbDays) / 100);
        this._cah = this.pm * (this.chv);
        this._pm = this.chv > 0 ? this.cah / (this.chv) : 0;
      }
  
  
  
      if (inputField == "cah") {
        this._pm = this.chv > 0 ? this.cah / (this.chv) : 0;
      }
  
      if (inputField == "chv") {
  
        this._to = ((this.nbCh * this.nbDays) - this._chTrv) > 0 ? ((this.chv) / ((this.nbCh * this.nbDays) - this._chTrv)) * 100 : 0;
  
  
        let chVMax = this.nbCh * this.nbDays - this.chTrv;
        if (this.chv > chVMax) this._chv = chVMax;
  
        this._pm = this.chv > 0 ? this.cah / (this.chv) : 0;
        console.log(this._pm);
      }
  
      if (inputField == "nbCh") {
        this._to = ((this.nbCh * this.nbDays) - this._chTrv) > 0 ? ((this.chv) / ((this.nbCh * this.nbDays) - this._chTrv)) * 100 : 0;
  
      }
      this.validate();
    }
  
    validate(): void {
  
      if (this.cah <= 0 || this.chv <= 0 || this.nbCh <= 0 || this.chTrv < 0 || this.pm <= 0 || this.to <= 0)
        this.isbudgetValidate = false;
      else
        this.isbudgetValidate = true;
    }
  
  }
  
  