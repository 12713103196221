import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { ExportTablesService } from 'src/app/services/exports/export-tables.service';
import { MappingsService } from 'src/app/spotLive/services/api/mappings.service';
import { AllRatePlanMews } from 'src/app/spotLive/services/model/allRatePlanMews';
import { MappingItem } from 'src/app/spotLive/services/model/mappingItem';
import { MappingRateInventory } from 'src/app/spotLive/services/model/mappingRateInventory';
import { RatePlan } from 'src/app/spotLive/services/model/ratePlan';
import { RatePlanMews } from 'src/app/spotLive/services/model/ratePlanMews';

@Component({
    selector: 'app-rate-plans',
    templateUrl: './rate-plans.component.html',
    styleUrls: ['./rate-plans.component.css']
})
export class RatePlansComponent implements OnInit, OnDestroy, OnChanges {
    currentHotel: HotelAuth;
    dataLoaded: boolean;
    private subs = new SubSink();

    @Input() spotRatePlans: RatePlan[];
    @Input() mewsRatePlans: RatePlanMews[];
    @Input() mappingRateInventory: MappingRateInventory;
    @Input() hotelNotIntialized: boolean;

    mappingRatePlan: MappingItem[];

    AllmewsRatePlans: RatePlanMews[];  //copie avant le filtre
    AllspotRatePlans: RatePlan[]; //copie avant le filtre

    displayAddMapping: boolean;
    selectedMapping: any = [];
    selectedSpot: RatePlan = null;
    selectedMews: RatePlanMews = null;
    mewsRatePlansForMapping: RatePlanMews[];
    mewsRatePlansDrivative :RatePlanMews[];
    mewsMappingRate:RatePlanMews[];

    constructor(
        private mappingsService: MappingsService,
        private userInfoService: UserInfoService,
    ) { }
    ngOnChanges(changes: SimpleChanges): void {
        //filter les liste: garder que les elements non mappés
        this.AllmewsRatePlans = this.mewsRatePlans;
        this.AllspotRatePlans = this.spotRatePlans;

        this.buildNotmappingLists();
        this.dataLoaded = true;
        this.mappingRatePlan = this.mappingRateInventory?.mappingRatePlan;

        this.trierListesRatePlan();

    }

    ngOnInit(): void {
        this.currentHotel = this.userInfoService.currentHotel;
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
            });
    }

    buildNotmappingLists(): void {
        //items dérivés
        this.mewsRatePlansDrivative= this.mewsRatePlans?.filter( x => this.mewsRatePlans.find(y => x.id === y.baseRateId));
        
        this.mewsMappingRate = this.mewsRatePlans?.filter(x => this.mappingRateInventory.mappingRatePlan?.find(y => y.mews == x.id));
        //garder que les items non mappés
        this.mewsRatePlans = this.mewsRatePlans?.filter(x => !this.mappingRateInventory.mappingRatePlan?.find(y => y.mews == x.id));
        this.spotRatePlans = this.spotRatePlans?.filter(x => !this.mappingRateInventory.mappingRatePlan?.find(y => y.spot == x.ratePlanCode));

        this.trierListesRatePlan();
        this.mewsRatePlansForMapping  = this.mewsRatePlans?.filter(x => x.baseRateId == null);
    }

    trierListesRatePlan() {
        if (this.mewsRatePlans) {
            this.mewsRatePlans = this.mewsRatePlans.sort((a, b) => {
                if (a.name < b.name) return -1;
                return 1;
            });
        }

        if (this.spotRatePlans) {
            this.spotRatePlans = this.spotRatePlans.sort((a, b) => {
                if (a.ratePlanLabel < b.ratePlanLabel) return -1;
                return 1;
            });
        }
    }


    newMapping() {
        this.displayAddMapping = true;
        this.selectedMews=null;
        this.selectedSpot=null;
    }
    deleteFromMapping(itemTodelete: MappingItem) {
        //supprimer la ligne du mappingRatePlan
        const index = this.mappingRatePlan.findIndex(x => x.mews == itemTodelete.mews && x.spot == itemTodelete.spot);
        if (index > -1) {
            this.mappingRatePlan.splice(index, 1);
        }

        //Ajouter dans spotRatePlans
        this.spotRatePlans.push({
            ratePlanCode: itemTodelete.spot,
            ratePlanLabel: itemTodelete.spotName ?? "-NC-"
        });

        //ajouter dans  mewsRatePlan
        this.mewsRatePlans.push({
            id: itemTodelete.mews,
            name: itemTodelete.mewsName
            // les autres infos ne sont pas exploiter dans la mappings
        });

        this.trierListesRatePlan();

    }

    isNotMappingSpotCodeExist(mapItem: MappingItem): boolean {
        return !this.AllspotRatePlans?.find(x => x.ratePlanCode == mapItem.spot);
    }

    isNotMappingMewsCodeExist(mapItem: MappingItem): boolean {
        return !this.AllmewsRatePlans?.find(x => x.id == mapItem.mews);
    }


    validate() {
        //Ajouter un mapping
        this.mappingRatePlan.push({
            mews: this.selectedMews.id,
            spot: this.selectedSpot.ratePlanCode,
            mewsName: this.selectedMews.name,
            spotName: this.selectedSpot.ratePlanLabel
        })
        //filter les liste: garder que les elements non mappés
        this.buildNotmappingLists();

        this.cancel();
    }

    cancel() {
        this.displayAddMapping = false;
        this.selectedMews = {};
        this.selectedSpot = {};
    }

    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }

}
