import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ExpoService } from 'src/app/distrib/services/api/api';
import { Canal, Distributeur, ExpositionHotel, InventaireDistant, RatePlan, RatePlanCanal } from 'src/app/distrib/services/model/models';
import { DistribSessionService } from 'src/app/distrib/session/distrib-session.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';


export interface MatriceExpo {
    groupinfo: string;
    hotCode?: string;
    disCode?: string;
    disLibelle?: string;
    canCode?: string;
    canLibelle?: string;
    ratePlanCode?: string;
    ratePlanLibelle?: string;
    pricerCode?: string;
    pricerLibelle?: string;
    prdvuCode?: string;
    prdvuLibelle?: string;
    invDisCode?: string;
    invDisLibelle?: string;
}


@Component({
    selector: 'app-matrice-expo',
    templateUrl: './matrice-expo.component.html',
    styleUrls: ['./matrice-expo.component.css']
})
export class MatriceExpoComponent implements OnInit, OnDestroy, OnChanges {

    private readonly destroy$: Subject<void>;
    @Input() allExpo: ExpositionHotel;
    @Output() OnReLoadData = new EventEmitter()

    currentHotCode: string;
    dataLoaded: boolean;
    matrice: Array<MatriceExpo> = [];

    constructor(private expoService: ExpoService, private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {

        // this.userInfoService.getNewHotel$()
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe(hotel => {
        //         setTimeout(() => {
        //             //this.loadForm();
        //         }, 10);
        //     });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.currentHotCode = this.userInfoService.currentHotel.hotCode;
        if (this.allExpo) {
            this.dataLoaded = true;
            this.buildMatrice();
        }
    }

    buildMatrice() {
        this.matrice = [];
        if (this.allExpo?.matriceExposition) {
            for (let mat of this.allExpo.matriceExposition) {
                this.matrice.push({ groupinfo: mat.disCode + mat.canCode + mat.ratePlanCode, ...mat })
            }
        }
    }

}
