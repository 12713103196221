import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CellSci } from '../../models/CellSci';
import { RowSci } from '../../models/RowSci';
import { AbstractDataService } from '../../models/AbstractDataService';
import { CellBaseComponent } from '../cell-base/cell-base.component';

@Component({
    selector: 'app-cell-prdvupricer',
    templateUrl: './cell-prdvupricer.component.html',
    styleUrls: ['./cell-prdvupricer.component.css']
})
export class CellPrdvupricerComponent extends CellBaseComponent {

    getValue(): any {

        if (this.cell && this.rowSci) {
            let key = this.cell.cellKeyForDataService;
            key = `${this.cell.cellKeyForDataService}-${this.rowSci.parentRowSci.rowId}-${this.rowSci.rowId}`;
            return super.getValue(key);

        }
        return "";
    }

    isClose(): boolean {
        let value = this.getValue();
        if (value && !value.isOpen) return true;
        return false;
    }

    getClassJour(): string {
        const index = this.rowSci.cells[this.cellId].index;
        let day = this.rowSci.cells[this.cellId].cellDate.getDay();

        if (day == 1) return "cellLun";
        if (day == 5) return "cellVen";
        if (day == 6) return "cellSam";
        return "";

    }


}
