import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { BlocksComponent } from './blocks/blocks/blocks.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { SupportRoleRequiredGuard } from './azureAdB2C/SupportRoleRequired.guard';
import { UsersComponent } from './adminUsers/users/users.component';
import { GroupsComponent } from './adminUsers/groups/groups/groups.component';
import { RateShopperHotelsComponent } from './rateShopper/components/rate-shopper-hotels/rate-shopper-hotels.component';
import { RateShopperMappingsComponent } from './rateShopper/components/rate-shopper-mappings/rate-shopper-mappings.component';
import { DataLiveComponent } from './analyse/components/data-live.component';
import { ReservationsComponent } from './analyse/components/resa/reservations.component';
import { RateShopperPricesComponent } from './rateShopper/components/rate-shopper-prices/rate-shopper-prices.component';
import { ProduitsComponent } from './distrib/prodtuis/produits/produits.component';
import { PricersComponent } from './distrib/pricers/pricers/pricers.component';
import { BudgetTvaComponent } from './budgets/budget-tva/budget-tva.component';
import { BudgetsComponent } from './budgets/budgets/budgets.component';
import { RatePlansComponent } from './spotLive/components/mews/ratePlans/rate-plans/rate-plans.component';
import { HotelConfigComponent } from './spotLive/components/mews/hotel-config/hotel-config.component';
import { AdminWorkSpaceComponent } from './powerbi/admin-work-space/admin-work-space.component';
import { MisterBookingComponent } from './spotLive/mister-booking/mister-booking.component';
import { ExpositionComponent } from './distrib/exposition/exposition/exposition.component';
import { MewsComponent } from './pms/mews/components/mews/mews.component';
import { OperacloudComponent } from './pms/operacloud/components/operacloud/operacloud.component';
import { SpotYieldComponent } from './distrib/yield/spot-yield/spot-yield.component';
import { AppComponent } from './app.component';
import { HotelsComponent } from './hotels/components/hotels/hotels.component';
import { AsterioComponent } from './pms/asterio/components/asterio/asterio.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivate: [MsalGuard, SupportRoleRequiredGuard],
                children: [
                    { path: '', component: AppComponent },
                    { path: "adminusers/users", component: UsersComponent, },
                    { path: "adminusers/groups", component: GroupsComponent, },
                    { path: "adminusers/powerbi", component: AdminWorkSpaceComponent, },
                    { path: "rateshopper/hotels", component: RateShopperHotelsComponent, },
                    { path: "rateshopper/mappings", component: RateShopperMappingsComponent, },
                    { path: "analyse/data-live", component: DataLiveComponent, },
                    { path: "analyse/reservations", component: ReservationsComponent, },
                    { path: "rateshopper/prices", component: RateShopperPricesComponent, },
                    { path: "distrib/produits", component: ProduitsComponent, },
                    { path: "distrib/pricers", component: PricersComponent, },
                    { path: "distrib/exposition", component: ExpositionComponent, },
                    { path: "distrib/yield", component: SpotYieldComponent, },
                    { path: "budgets/budgetTva", component: BudgetTvaComponent, },
                    { path: "budgets", component: BudgetsComponent, },
                    { path: "spotlive/mews", component: HotelConfigComponent, },
                    { path: "spotlive/misterbooking", component: MisterBookingComponent, },
                    { path: "pms/mews", component: MewsComponent, },
                    { path: "pms/operacloud", component: OperacloudComponent, },
                    { path: "pms/asterio", component: AsterioComponent, },
                    { path: "hotels/hotels", component: HotelsComponent, },

                    // { path: 'uikit/formlayout', component: FormLayoutDemoComponent },
                    // { path: 'uikit/floatlabel', component: FloatLabelDemoComponent },
                    // { path: 'uikit/invalidstate', component: InvalidStateDemoComponent },
                    // { path: 'uikit/input', component: InputDemoComponent },
                    // { path: 'uikit/button', component: ButtonDemoComponent },
                    // { path: 'uikit/table', component: TableDemoComponent },
                    // { path: 'uikit/list', component: ListDemoComponent },
                    // { path: 'uikit/tree', component: TreeDemoComponent },
                    // { path: 'uikit/panel', component: PanelsDemoComponent },
                    // { path: 'uikit/overlay', component: OverlaysDemoComponent },
                    // { path: 'uikit/menu', loadChildren: () => import('./demo/view/menus/menus.module').then(m => m.MenusModule) },
                    // { path: 'uikit/media', component: MediaDemoComponent },
                    // { path: 'uikit/message', component: MessagesDemoComponent },
                    // { path: 'uikit/misc', component: MiscDemoComponent },
                    // { path: 'uikit/charts', component: ChartsDemoComponent },
                    // { path: 'uikit/file', component: FileDemoComponent },
                    // { path: 'utilities/icons', component: IconsComponent },
                    // { path: 'pages/crud', component: AppCrudComponent },
                    // { path: 'pages/calendar', component: AppCalendarComponent },
                    // { path: 'pages/timeline', component: AppTimelineDemoComponent },
                    // { path: 'pages/invoice', component: AppInvoiceComponent },
                    // { path: 'pages/help', component: AppHelpComponent },
                    // { path: 'pages/empty', component: EmptyDemoComponent },
                    // { path: 'documentation', component: DocumentationComponent },
                    // { path: 'blocks', component: BlocksComponent },
                ]
            },
           //{ path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            // { path: '**', redirectTo: '/notfound' },
        ],
            {
                initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
                scrollPositionRestoration: 'enabled',
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
