import { Injectable, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private subject = new Subject<any>();
    badgeCounter: number;
    messages: any;


    constructor(private messageService: MessageService) {
        let count = sessionStorage.getItem('countMessage') || '0';
        this.badgeCounter = parseInt(count) || 0;
        let message = sessionStorage.getItem('listeMessages') || '[]';
        this.messages = JSON.parse(message) || [];
    }


    incrementCount() {
        this.badgeCounter++;
      }
    decreaseCount() {
        if (this.badgeCounter > 0) {
            this.badgeCounter--;
        }
    }

    getCount() {
        return this.badgeCounter;
    }
    getMessage(): string[] {
        return this.messages;
    }
    setMessage(message: string, titre: string, isError: boolean) {
        let date = new Date().toLocaleString();
        let messageDate = date + ' : ' + message;
        this.messages.push({ message: messageDate, isError: isError });

       this.messageService.add({ severity: isError ? 'error' : 'success', summary: titre, detail: message })
        this.incrementCount();
    }
    deleteMessage(index: number) {
        this.messages = this.messages.filter((msg, i) => i != index);
        this.decreaseCount();

      }
    // success(message: string) {
    //   this.subject.next({ type: 'success', text: message });
    // }
    // error(message: string) {
    //     this.subject.next({ type: 'error', text: message });

    // }
}
