export class dateCal {
    dateJour: Date;
    isSelected: boolean;
    currentDate: boolean;
    moisCourant: number;
    sunday: boolean;
    week: boolean;
    weekend: boolean;
    dateNum: number;

}

export class Events {

    _DatejourGlissant?: Date;

    get DatejourGlissant(): Date {
        if (!this._DatejourGlissant) {
            this._DatejourGlissant = new Date();
            this._DatejourGlissant.setDate(this._DatejourGlissant.getDate() + this.jourGlissant);
            console.log("_DatejourGlissant", this._DatejourGlissant)
        }

        return this._DatejourGlissant;
    }

    _DateJourPlus?: Date;

    get DateJourPlus(): Date {
        if (!this._DateJourPlus) {
            this._DateJourPlus = new Date();
            this._DateJourPlus.setDate(this._DateJourPlus.getDate() + this.jourPlus);
        }
        return this._DateJourPlus;
    }


    isActif(dt: Date): boolean {
        if (dt < this.dateStart) return false;
        if (dt > this.dateEnd && this.dateEnd) return false;

        if (this.jourGlissant > 0) {
            if (dt > this.DatejourGlissant) return false;
        }
        if (this.jourPlus > 0) {
            if (dt < this.DateJourPlus) return false;
        }

        if (this.dateExclus) {
            for (let dtex of this.dateExclus) {
                if (dtex.getUTCFullYear() == dt.getUTCFullYear()
                    && dtex.getUTCMonth() == dt.getUTCMonth()
                    && dtex.getUTCDate() == dt.getUTCDate()
                )
                    return false;
            }

        }

        let day = dt.getDay();

        if (day == 0 && this.joursInEvent.Sun == false) return false;
        if (day == 1 && this.joursInEvent.Mon == false) return false;
        if (day == 2 && this.joursInEvent.Tue == false) return false;
        if (day == 3 && this.joursInEvent.Wed == false) return false;
        if (day == 4 && this.joursInEvent.Thu == false) return false;
        if (day == 5 && this.joursInEvent.Fri == false) return false;
        if (day == 6 && this.joursInEvent.Sat == false) return false;

        return true;
    }

    id: string;
    dateStart?: Date;
    dateEnd?: Date;
    jourPlus?: number;
    jourGlissant?: number;
    color: string;
    label: string;
    joursInEvent: {
        Mon: boolean;
        Tue: boolean;
        Wed: boolean;
        Thu: boolean;
        Fri: boolean;
        Sat: boolean;
        Sun: boolean;
    };
    dateExclus?: Array<Date>;
}

