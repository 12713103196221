import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, AbstractControl } from '@angular/forms'
import { FormParameter, PossibleValues } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX } from '../componentConfig'


@Component({
    selector: 'app-checkbox-button-group',
    templateUrl: './checkbox-button-group.component.html',
    styleUrls: ['./checkbox-button-group.component.css'],
})
export class CheckboxButtonGroupComponent implements OnInit {
    @Input() formGroup2: FormGroup2
    @Input() field: FormParameter
    formControlIn: FormControl2

    tooltipzindex = TOOL_TIP_Z_INDEX
    newFormGroup: FormGroup2
    newFormcontrol: FormControl2
    selectedValues: any
    constructor() { }

    ngOnInit(): void {
        let group: any = {}

        this.formControlIn = this.formGroup2.get(
            this.field.code
        ) as FormControl2
        this.formControlIn.updateValueAndValidity()
        this.formControlIn.registerOnDisabledChange((value) => {
            if (value == true) {
                this.newFormGroup.enable()
            } else {
                this.newFormGroup.disable()
            }
        })

        let valueIn = this.formControlIn.value
        let newValue = new Array<PossibleValues>()

        for (const property in valueIn) {
            // console.log(`${property}: ${object[property]}`);
            if (valueIn[property] == true) {
                newValue.push(
                    this.field.possibleValues.find((x) => x.code == property)
                )
            }
        }
        this.newFormcontrol = new FormControl2(this.field.code, newValue)
        group[this.field.code] = this.newFormcontrol

        this.newFormGroup = new FormGroup2(group, '')
        this.newFormGroup.enable()

        //ajouter la validation
        if (this.field.required) {
            let validators = [this.formControlIn.validator, validationRequired] //recuperer la validation serveur + validation composant
            this.formControlIn.setValidators(validators)
        }

        this.newFormGroup.valueChanges.subscribe((val) => {
            var that = this
            setTimeout(() => {
                let valueOut = {}
                if (that.field.possibleValues instanceof Array) {
                    that.field.possibleValues.forEach((element) => {
                        if (element.code) valueOut[element.code] = false
                    })
                }

                if (that.newFormcontrol.value instanceof Array) {
                    that.newFormcontrol.value.forEach((element) => {
                        if (element.code) valueOut[element.code] = true
                    })
                }

                that.formControlIn.setValue(valueOut)
                that.formControlIn.externalErrorMsg = undefined
                that.formControlIn.markAsDirty()
                that.formControlIn.markAsTouched()
                that.formControlIn.updateValueAndValidity()
            }, 10)
        })
    }
}

function validationRequired(c: AbstractControl): { [key: string]: boolean } {
    const frm2 = c as FormControl2
    for (const property in frm2.value) {
        if (frm2.value[property] === true) return null
    }
    return { required: true }
}
