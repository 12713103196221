import { CellSci } from "./CellSci";
import { TableSci } from "./TableSci";

export class RowSci {

    public currenttranslationX: number = 0;
    public cells: Array<CellSci>;
    public firstVisibleCellsInMonth: Array<CellSci>;

    public childrenRows: Array<RowSci> = [];

    constructor(public tableParent: TableSci, public title: string,
        public height: number, public y: number, public rowId: string, public rowType: string,
        cells: Array<CellSci> = null, public parentRowSci: RowSci = null, public visible: boolean = true) {

        if (cells == null) {
            this.cells = [];
            let i = 0;
            // si cellscout =30  alors on a de -30  à 60   ( partie visible est de 0 à 30 )
            for (let index = this.tableParent.cellsCount * -1; index <= this.tableParent.cellsCount * 2; index++) {
                let cell = new CellSci(this, index, i);
                this.cells.push(cell);
                i = i + 1;
            }
        }
        else {
            this.cells = cells;
        }
    }


    public addRowChild(row: RowSci) {
        this.childrenRows.push(row);
    }

    public translate(translationX: number): Array<number> {
        let result: Array<number> = [];
        this.firstVisibleCellsInMonth = [];
        this.currenttranslationX = this.currenttranslationX + translationX;
        for (let index = 0; index < this.cells.length; index++) {
            const _cell = this.cells[index];
            let newIndex = _cell.translate(translationX);

            if (newIndex) result.push(newIndex);

            if (_cell.isFisrtVisibleInMonth) {
                this.firstVisibleCellsInMonth.push(_cell);
            }
        }

        this.firstVisibleCellsInMonth = this.firstVisibleCellsInMonth.sort((a, b) => a.x - b.x);
        return result;
    }

    public translateMonth(): void {

        this.firstVisibleCellsInMonth = [];
        for (let index = 0; index < this.cells.length; index++) {
            const _cell = this.cells[index];

            if (_cell.isFisrtVisibleInMonth) {
                this.firstVisibleCellsInMonth.push(_cell);
            }
        }
        this.firstVisibleCellsInMonth = this.firstVisibleCellsInMonth.sort((a, b) => a.x - b.x);
    }
}
