import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AllPricersHotel } from '../../services/model/allPricersHotel';
import { Subject, Subscription, find, lastValueFrom, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PricerService } from '../../services/api/pricer.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { CreateGrilleCmd, CreatePricerNiveauCmd, CreatePricerRootCmd, DistribSession, FixerPrixPricerRackCmd, ModelDate, MovPrdvuToPricerCmd, PricerNiveau, PrixFixeProduitVenduUnit, ProduitVenduUnit, RenameGrilleCmd, RenamePricerNiveauCmd, SetDatesValidationForGrilleCmd, SetDatesValidationForNiveauCmd, SetPrdvuRefCmd } from '../../services/model/models'
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { GetDateValidationCmdValue, GetDateValidationFormValue, PeriodeDtvForm, PeriodeDtvFormList } from './PeriodeDtvForm';
import { Pricer } from '../../services/model/pricer';
import { PricerGrille } from '../../services/model/pricerGrille';
import { Events } from 'src/app/calendrier/annuel/model/dateCal';
import { CouleursCalendrier } from 'src/app/calendrier/annuel/model/OptionConfiguration';


@Component({
    selector: 'app-grille-bar',
    templateUrl: './grille-bar.component.html',
    styleUrls: ['./grille-bar.component.scss']
})
export class GrilleBarComponent implements OnInit, OnChanges, OnDestroy {

    @Input() allPricers: AllPricersHotel;
    @Input() isGrilleSpeciale: boolean
    @Output() OnRealodData = new EventEmitter<boolean>()

    @ViewChild('newMainPricer') formDynamicNewMainPricer: FormDynamicComponent;

    currentGrilleEvents: Array<Events>
    private readonly destroy$: Subject<void>;

    pricers: Array<Pricer> = [];
    dataLoaded: boolean;

    selectedPricer: Pricer;
    selectedGrille: PricerGrille;
    niveaux: PricerNiveau[];

    hotelInitialized: boolean = false;

    _fixSeul: boolean = false;
    createPricerRackForm: FormConfig;
    currentSession: DistribSession;
    fixPricerRackForm: FormConfig;

    fixePrixFormHeader: string;
    currentFixerPrixPricerRackCmd: FixerPrixPricerRackCmd;
    fixePrixFormValue: { prixFix: string | number; suppPrixFix: boolean; };

    currentHotCode: string;

    private _selectedNiveau: PricerNiveau;
    datesValidationForm: FormConfig;
    dateValidationFormValue: PeriodeDtvFormList;
    selectedPrdvu: ProduitVenduUnit;

    movPrdvuToPricerForm: FormConfig;
    displayMovPrdvuToPricerForm: boolean;
    displaydatesValidationNivForm: boolean;
    dateValidationNivFormValue: PeriodeDtvFormList;
    displayPlanningGrille: boolean;


    public get selectedNiveau(): PricerNiveau {
        return this._selectedNiveau;
    }
    public set selectedNiveau(value: PricerNiveau) {

        this._selectedNiveau = value;
        this.setMenuItemNiv();
    }

    menuItemNiv: MenuItem[];
    menuItemPrd: MenuItem[];

    menuItemPrc: MenuItem[];

    get fixSeul(): boolean {
        return this._fixSeul;
    }
    set fixSeul(value: boolean) {
        this._fixSeul = value;
        this.OnRealodData.emit(this.fixSeul);
    }
    displayCreatePriceRack: boolean = false;

    displayFixePrixForm: boolean = false;
    fixedProduitVenduUnit: Array<ProduitVenduUnit> = [];

    displayCreatePricerNivForm: boolean = false;
    createPricerNiveauForm: FormConfig;

    displayRenamePricerNivForm: boolean = false;
    renameObjectForm: FormConfig;
    RanameNivFormValue: { updateObj: { label: string; }; };
    RanameGrilleValue: { updateObj: { label: string; }; };


    displaydatesValidationForm: boolean = false;

    createGrilleForm: FormConfig;
    displayCreateGrilleForm: boolean;


    displayRenameGrilleForm: boolean;


    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private pricerService: PricerService,
        private confirmationService: ConfirmationService,
        private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
    }



    setMenuItemNiv() {
        this.menuItemNiv = [
            {
                label: 'Ajouter un niveau',
                icon: 'pi pi-plus',
                command: () => {
                    this.displayCreatePricerNivForm = true
                }
            },
            {
                label: this.selectedNiveau ? `Supprimer le niveau ${this.selectedNiveau?.libelle}` : 'Supprimer un niveau',
                icon: 'pi pi-times',
                disabled: !!!this.selectedNiveau,
                styleClass: "style1",
                iconStyle: { 'color': 'red' },

                command: () => {
                    this.deleteCurrentNiveau();
                }
            },
            {
                label: this.selectedNiveau ? `Renommer le niveau ${this.selectedNiveau?.libelle}` : 'Renommer un niveau',
                icon: 'pi pi-pencil',
                disabled: !!!this.selectedNiveau,
                command: () => {
                    this.RanameNivFormValue = {
                        updateObj: {
                            label: this.selectedNiveau?.libelle
                        }
                    }
                    this.displayRenamePricerNivForm = true;
                }
            },
            {
                label: 'Ajouter une période de validité',
                icon: 'pi pi-calendar-plus',
                disabled: (!!this.selectedNiveau?.datesValidation && this.selectedNiveau?.datesValidation?.periodes?.length > 0),
                command: () => {
                    this.displaydatesValidationNivForm = true
                    this.dateValidationNivFormValue = null;
                }

            },
            {
                separator: true,
            },
            {
                label: 'Simuler un niveau tarifaire',
                icon: 'pi pi-times',
                command: () => {

                }
            },
        ];
    }

    setMenuItemGrille() {
        this.menuItemPrd = [
            {
                label: 'Ajouter une grille',
                icon: 'pi pi-plus',
                command: async () => {
                    this.showCreateGrilleForm();
                }
            },
            {
                label: this.selectedGrille ? `Supprimer la grille ${this.selectedGrille?.libelle}` : 'Supprimer une grille',
                icon: 'pi pi-times',
                disabled: !!!this.selectedGrille,
                style: { 'color': 'red' },
                iconStyle: { 'color': 'red' },
                command: () => {
                    this.deleteCurrentGrilleConfirmation();
                }
            },
            {
                label: this.selectedGrille ? `Renommer la grille ${this.selectedGrille?.libelle}` : 'Renommer une grille',
                icon: 'pi pi-pencil',
                disabled: !!!this.selectedGrille,
                command: () => {
                    this.RanameGrilleValue = {
                        updateObj: {
                            label: this.selectedGrille?.libelle
                        }
                    }
                    this.displayRenameGrilleForm = true;
                }
            },
            {
                label: 'Ajouter une période de validité',
                icon: 'pi pi-calendar-plus',
                disabled: (!!this.selectedGrille?.datesValidation && this.selectedGrille?.datesValidation?.periodes?.length > 0),
                command: () => {
                    this.displaydatesValidationForm = true
                    this.dateValidationFormValue = null;
                }

            },
            {
                separator: true,
            },

            {
                label: 'Planning des grilles',
                icon: 'pi pi-calendar',
                disabled: !!!this.selectedGrille,
                command: () => {
                    this.displayPlanningGrille = true;
                }
            },

        ];
    }

    setMenuItemPricer() {
        this.menuItemPrc = [
            {
                label: 'Ajouter un Pricer',
                icon: 'pi pi-plus',
                command: async () => {
                    this.showCreatePricer();
                }
            },
            {
                label: this.selectedPricer ? `Supprimer le Pricer ${this.selectedPricer?.libelle}` : 'Supprimer le pricer',
                icon: 'pi pi-times',
                disabled: !!this.selectedPricer?.isMainRack,
                iconStyle: { 'color': 'red' },
                command: () => {

                }
            },
            {
                label: this.selectedPrdvu ? `Définir  ${this.selectedPrdvu?.libelle} comme produit de référence` : "Définir comme produit de référence",
                icon: 'pi pi-box',
                disabled: !this.selectedPrdvu || this.selectedPricer.produitDeReference?.code === this.selectedPrdvu?.code,
                command: () => {
                    this.setPrdvuRef()

                }
            },
            {
                label: this.selectedPrdvu ? `Déplacer le produit ${this.selectedPrdvu?.libelle} vers un autre Pricer` : "Déplacer le produit",
                icon: 'pi pi-arrow-up-right',
                disabled: !this.selectedPrdvu || this.selectedPricer.produitDeReference?.code === this.selectedPrdvu?.code,
                command: async () => {
                    if (!this.selectedPrdvu) return;

                    const frm = await lastValueFrom(this.pricerService.apiPricerMovPrdvuToPricerFormGet(this.currentHotCode, this.selectedPrdvu.code));
                    this.movPrdvuToPricerForm = JSON.parse(JSON.stringify(frm)) as FormConfig;
                    this.displayMovPrdvuToPricerForm = true;
                }
            },

        ];
    }

    deplacerPrdvuVersPricer(evt: any) {
        let cmd: MovPrdvuToPricerCmd = {
            hotCode: this.currentHotCode,
            prdvuCode: this.selectedPrdvu.code,
            sessionId: this.currentSession.sessionId,
            pricerCodeDestination: evt.movPrdvu.pricerCode,
            pricerLibelleDestination: evt.movPrdvu.pricerLibelle,
            prixFixe: evt.movPrdvu.prixFixe
        }

        this.pricerService.apiPricerMovPrdvuToPricerPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Deplacement du produit du produit ${this.selectedPrdvu.libelle} `, "Pricers", false);
                        this.displayMovPrdvuToPricerForm = false
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur de Deplacement du produit: ${this.selectedPrdvu.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur de Deplacement du produit: ${this.selectedPrdvu.libelle}`, "Pricers", true);
                }
            })
    }


    deleteCurrentGrilleConfirmation() {
        this.confirmationService.confirm({
            message: `Voulez-vous supprimer la grille ${this.selectedGrille.libelle}? `,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: "pricerConfirmation",
            accept: () => {
                this.deleteCurrentGrille();
            },
            reject: (type) => {
            }
        });
    }

    deleteCurrentGrille() {

        this.pricerService.apiPricerDeleteGrilleDelete(
            this.currentHotCode,
            this.selectedPricer.code,
            this.selectedGrille.code,
            this.currentSession.sessionId,
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Supression de la grille  ${this.selectedGrille.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur de supression de la grille: ${this.selectedGrille.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur de supression de la grille: ${this.selectedGrille.libelle}`, "Pricers", true);
                }
            })
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit(): void {
        this.distribSessionService.getNewSession$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(session => {
                this.currentSession = session;
            })
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.currentHotCode = this.userInfoService.currentHotel.hotCode;
        if (this.allPricers) this.dataLoaded = true;

        if (this.isGrilleSpeciale) {
            this.pricers = this.allPricers?.pricerList?.filter(x => !x.isRack && !x.isVirtual && !x.pricerParentCode)
        }
        else {
            this.pricers = this.allPricers?.pricerList?.filter(x => x.isRack == true)
        }

        if (this.pricers)
            this.pricers = this.pricers
                .sort((a, b) => {
                    if (a.isMainRack === b.isMainRack) {
                        // Si les propriétés 'isMainRack' sont égales, triez par ordre alphabétique sur la propriété 'libelle'
                        if (a.libelle < b.libelle) {
                            return -1;
                        } else if (a.libelle > b.libelle) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        // Si les propriétés 'isMainRack' sont différentes, le pricer mainRack en premier
                        return a.isMainRack ? -1 : 1;
                    }
                });


        if (this.selectedPricer)  //garder le même
            this.selectedPricer = this.pricers?.find(x => x.code == this.selectedPricer.code);

        if (!this.selectedPricer) // si pas trouvé alors le mainRack ou le premier pricer special
        {
            if (this.isGrilleSpeciale) {
                this.selectedPricer = this.pricers?.length > 0 ? this.pricers[0] : undefined;
            }
            else {
                this.selectedPricer = this.pricers?.find(x => x.isMainRack == true);
            }

        }

        if (this.allPricers?.errorMessage) {
            this.hotelInitialized = false;
        }
        else {
            this.hotelInitialized = true;
        }

        if (this.selectedPricer?.grilles) {
            let selectedPrd = this.selectedGrille;

            //garder la même periode
            if (selectedPrd) selectedPrd = this.selectedPricer.grilles.find(x => x.code == selectedPrd.code);
            //si non la premiere
            if (!selectedPrd) selectedPrd = this.selectedPricer.grilles[0]
            this.select_grille_Rack(selectedPrd);

            this.buildCalendarEvents();
        }


        if (this.hotelInitialized) {
            this.loadForms();
            this.setMenuItemPricer();
        }
    }


    async showCreateGrilleForm() {
        const frm = await lastValueFrom(this.pricerService.apiPricerCreateGrilleFormGet(this.currentHotCode,
            this.selectedPricer.code));
        this.createGrilleForm = JSON.parse(JSON.stringify(frm)) as FormConfig;
        this.displayCreateGrilleForm = true
    }


    async loadForms() {

        const frm = await lastValueFrom(this.pricerService.apiPricerCreatePricerRootFormGet(this.currentHotCode));

        this.createPricerRackForm = JSON.parse(JSON.stringify(frm)) as FormConfig;

        const frm2 = await lastValueFrom(this.pricerService.apiPricerFixPricerRackFormGet(this.currentHotCode));
        this.fixPricerRackForm = JSON.parse(JSON.stringify(frm2)) as FormConfig;

        const frm3 = await lastValueFrom(this.pricerService.apiPricerCreatePricerNiveauFormGet(this.currentHotCode));
        this.createPricerNiveauForm = JSON.parse(JSON.stringify(frm3)) as FormConfig;

        const frm4 = await lastValueFrom(this.pricerService.apiPricerRenameObjectFormGet(this.currentHotCode));
        this.renameObjectForm = JSON.parse(JSON.stringify(frm4)) as FormConfig;

        const frm5 = await lastValueFrom(this.pricerService.apiPricerDatesValidationFormGet(this.currentHotCode));
        this.datesValidationForm = JSON.parse(JSON.stringify(frm5)) as FormConfig;
    }


    select_Pricer_Rack(prc: Pricer): void {
        this.selectedPricer = prc;
        this.selectedPrdvu = undefined;
        this.setMenuItemPricer();
        this.select_grille_Rack(this.selectedPricer.grilles[0]);
        this.setMenuItemPricer();
        this.buildCalendarEvents();

    }

    buildCalendarEvents() {
        this.currentGrilleEvents = [];
        let _id: number = 0;
        let _idColor =0;
        if (this.selectedPricer?.grilles) {
            for (const gr of this.selectedPricer.grilles) {
                _idColor++;
                if (gr?.datesValidation?.periodes) {
                    let periodes = gr.datesValidation.periodes;
                    for (const pr of periodes) {
                        _id++;
                        const ev = new Events();
                        ev.id = _id.toString();
                        ev.dateStart = pr.debut ? new Date(pr.debut.year, pr.debut.month - 1, pr.debut.day) : null;
                        ev.dateEnd = pr.fin ? new Date(pr.fin.year, pr.fin.month - 1, pr.fin.day) : null;
                        ev.jourPlus = pr.jourPlus;
                        ev.jourGlissant = pr.jourGlissant;
                        ev.color = CouleursCalendrier.List[_idColor % CouleursCalendrier.List.length]
                        ev.label = gr.libelle;
                        ev.joursInEvent = {
                            Mon: pr.mon,
                            Tue: pr.tue,
                            Wed: pr.weds,
                            Thu: pr.thur,
                            Fri: pr.fri,
                            Sat: pr.sat,
                            Sun: pr.sun
                        };

                        ev.dateExclus = this.getDateExclus(pr.datesExclues);
                        this.currentGrilleEvents.push(ev)
                    }
                }
            }
        }
        else {
            this.currentGrilleEvents = null;
        }
    }
    getDateExclus(datesExclus: Array<ModelDate>): Date[] {

        if (datesExclus) {
            let result: Array<Date> = [];

            for (let dt of datesExclus) {
                result.push(new Date(dt.year, dt.month - 1, dt.day))
            }

            return result;
        }

        else return null;


    }

    select_grille_Rack(grl: PricerGrille): void {
        this.selectedGrille = grl;
        this.setMenuItemGrille();
        this.niveaux = this.selectedGrille?.niveaux;

        if (!this.niveaux || this.niveaux.length == 0) {
            this.selectedNiveau = null;
            return;
        }


        //trier les niveaux selon le prix du produite de ref
        this.niveaux = this.niveaux.sort((a, b) => {
            return this.getPrixProduitDeReference(a) - this.getPrixProduitDeReference(b)
        });

        if (this.selectedNiveau) this.selectedNiveau = this.selectedGrille?.niveaux?.find(x => x.code == this.selectedNiveau.code);

        if (!this.selectedNiveau) this.selectedNiveau = this.selectedGrille?.niveaux[0];



        this.fixedProduitVenduUnit = [];

        for (let niv of this.niveaux) {
            for (let prFix of niv.prixFixes) {

                if (!this.fixedProduitVenduUnit.find(x => x.code == prFix.produitVenduUnit.code)) {
                    this.fixedProduitVenduUnit.push(prFix.produitVenduUnit);
                }
            }
        }

        this.fixedProduitVenduUnit = this.fixedProduitVenduUnit.sort(
            (a, b) => {

                if (a.produitSourceType < b.produitSourceType) return 1;
                if (a.produitSourceType > b.produitSourceType) return -1;
                if (a.numeroSurClass < b.numeroSurClass) return -1;
                if (a.numeroSurClass > b.numeroSurClass) return 1;

                if (a.produitSourceCode < b.produitSourceCode) return -1;
                if (a.produitSourceCode > b.produitSourceCode) return 1;

                if (a.pax < b.pax) return 1;
                if (a.pax > b.pax) return -1;

                return 0;
            }
        )
    }

    selectNiveau(niv: PricerNiveau) {
        this.selectedNiveau = niv;
    }
    getClassNivSelected(niv: PricerNiveau): string {

        var result = ""
        if (niv?.isDefault) result = "defautNiveau "

        if (this.selectedNiveau == niv) result = result + " nivItemSelected"

        return result;
    }

    getClassPrdvuSelected(prdvu: ProduitVenduUnit): string {

        if (this.selectedPrdvu == prdvu) return "prdvuItemSelected"
        return "";
    }

    selectPrdvu(prdvu: ProduitVenduUnit) {
        this.selectedPrdvu = prdvu;
        this.setMenuItemPricer();
    }

    private getPrifxPrdvu(niv: PricerNiveau, prdvu: ProduitVenduUnit): PrixFixeProduitVenduUnit {
        for (let prFix of niv.prixFixes) {

            if (prFix.produitVenduUnit.code == prdvu.code) {
                return prFix;
            }
        }
        return null
    }

    getPrixFix(niv: PricerNiveau, prdvu: ProduitVenduUnit): number | string {

        const prfx = this.getPrifxPrdvu(niv, prdvu);
        if (prfx) return prfx.prix;
        return "";
    }

    GetPrixFixClass(niv: PricerNiveau, prdvu: ProduitVenduUnit): string {
        const prfx = this.getPrifxPrdvu(niv, prdvu);

        if (prfx?.origineDuCalcul == "PrixFixe") return "prixFixe";
        if (prfx?.origineDuCalcul == "Regression") return "regression";
        return "";
    }



    getSeletedRackClass(prc: Pricer): string {
        if (prc.code == this.selectedPricer.code)
            return "rackItemSelected";
        else return "";
    }

    GetPrdvClass(prdvu: ProduitVenduUnit): string {
        if (this.selectedPricer.produitDeReference.code == prdvu.code)
            return "prdvuRef prdvuItem";
        else return "prdvuItem";
    }



    getDatesValidationToolTip(desc: Array<string>): string {
        let result = "";
        if (!desc) return result;
        for (let ds of desc) {
            result = result + `<div>${ds} </div>`;
        }
        return result;
    }

    private getPrixProduitDeReference(niv: PricerNiveau): number {

        let result: number = 0;
        if (!niv) return result;

        for (let prfixe of niv.prixFixes) {
            if (this.selectedPricer.produitDeReference.code == prfixe.produitVenduUnit.code)
                return prfixe.prix
        }
        return result;
    }
    showCreatePricer(): void {
        this.displayCreatePriceRack = true;
    }

    CreatePricer(data: any) {
        let cmd: CreatePricerRootCmd = {
            hotCode: this.userInfoService.currentHotel.hotCode,
            sessionId: this.currentSession.sessionId,
            code: data.pricerRoot.code,
            libelle: data.pricerRoot.libelle,
            prdvuRef: data.pricerRoot.prdvuRef,
            prix: data.pricerRoot.prix,
            libNiveau: data.pricerRoot.libNiveau,
            isSpecial: this.isGrilleSpeciale,
            isMain: !this.isGrilleSpeciale && !this.selectedPricer,
            isRack: !this.isGrilleSpeciale && !!this.selectedPricer,
        }

        this.pricerService.apiPricerCreatePricerRootPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Création du pricer Rack ${data.pricerRoot.libelle}`, "Pricers", false);
                        this.displayCreatePriceRack = false;
                    }
                    else {
                        this.SetError(session.error);
                    }
                },
                error: err => {
                    this.SetError(err.error.error);
                    this.notifService.setMessage(err.error.error, "Pricers", true);
                }
            })
    }

    SetError(msg: string): void {
        let error = new FormErrors();
        error.errors = new ErrorDictionary();
        error.errors["pricerRack"] = [msg];  // inventaire c'est le vertical groupe racine
        this.formDynamicNewMainPricer.setExternalErrors(error);
    }


    displayFixePrix(niv: PricerNiveau, prdvu: ProduitVenduUnit): void {

        const prix = this.getPrixFix(niv, prdvu);
        this.fixePrixFormValue = { "prixFix": prix, "suppPrixFix": false };

        this.fixePrixFormHeader = `${prdvu.libelle} sur ${niv.libelle}`;

        this.currentFixerPrixPricerRackCmd = {
            hotCode: this.userInfoService.currentHotel.hotCode,
            sessionId: this.currentSession.sessionId,
            pricerCode: this.selectedPricer.code,
            grilleCode: this.selectedGrille.code,
            niveauCode: niv.code,
            prdvuCode: prdvu.code
        }
        this.displayFixePrixForm = true;
    }

    SetOrRemoveFixPrice(data: any): void {
        if (!data.suppPrixFix && data.prixFix != undefined) {
            this.fixPrice(data);
        }
        else if (data.suppPrixFix == true) {
            this.removeFixPrice(data)
        }
    }

    fixPrice(data: any): void {

        this.currentFixerPrixPricerRackCmd.prix = data.prixFix;

        this.pricerService.apiPricerFixerPrixPricerRackPost(this.currentFixerPrixPricerRackCmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Prix fixer pour  ${this.fixePrixFormHeader}`, "Pricers", false);
                        this.displayFixePrixForm = false;
                    }
                    else {
                        this.notifService.setMessage(session.error ?? "Erreur de mise à jour du prix", "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? "Erreur de mise à jour du prix", "Pricers", true);
                }
            })
    }

    removeFixPrice(data: any): void {

        this.pricerService.apiPricerDeleteFixePrixPricerRackDelete
            (
                this.currentFixerPrixPricerRackCmd.pricerCode,
                this.currentFixerPrixPricerRackCmd.grilleCode,
                this.currentFixerPrixPricerRackCmd.niveauCode,
                this.currentFixerPrixPricerRackCmd.prdvuCode,
                this.currentFixerPrixPricerRackCmd.hotCode,
                this.currentFixerPrixPricerRackCmd.sessionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Supression du prix fixé pour  ${this.fixePrixFormHeader}`, "Pricers", false);
                        this.displayFixePrixForm = false;
                    }
                    else {
                        this.notifService.setMessage(session.error ?? "Erreur de mise à jour du prix", "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? "Erreur de mise à jour du prix", "Pricers", true);
                }
            })
    }


    CreatePricerNiveau(data: any): void {

        console.log(data);

        let cmd: CreatePricerNiveauCmd = {
            hotCode: this.userInfoService.currentHotel.hotCode,
            sessionId: this.currentSession.sessionId,
            pricerCode: this.selectedPricer.code,
            grilleCode: this.selectedGrille.code,
            libelle: data.newNiv.label,
            prix: data.newNiv.prix
        }

        this.pricerService.apiPricerCreatePricerNiveauPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Création du niveau tarifaire ${cmd.libelle}`, "Pricers", false);
                        this.displayCreatePricerNivForm = false;
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la création du niveau ${cmd.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la création du niveau ${cmd.libelle}`, "Pricers", true);
                }
            })
    }

    deleteCurrentNiveau(): void {
        this.pricerService.apiPricerDeletePricerNiveauDelete(
            this.currentHotCode, this.selectedNiveau.code, this.selectedPricer.code, this.selectedGrille.code)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Supression du niveau tarifaire ${this.selectedNiveau.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la Supression du niveau ${this.selectedNiveau.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la Supression du niveau ${this.selectedNiveau.libelle}`, "Pricers", true);
                }
            })
    }

    renameCurrentNiveau(data: any): void {
        const newLibelle: string = data?.updateObj?.label;

        let cmd: RenamePricerNiveauCmd = {
            hotCode: this.currentHotCode,
            sessionId: this.currentSession.sessionId,
            code: this.selectedNiveau.code,
            libelle: newLibelle,
            pricerCode: this.selectedPricer.code,
            grilleCode: this.selectedGrille.code
        }

        this.pricerService.apiPricerRenamePricerNiveauPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.displayRenamePricerNivForm = false;
                        this.notifService.setMessage(`Niveau tarifaire: ${this.selectedNiveau.libelle} renommé en ${newLibelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans le renommage du niveau ${this.selectedNiveau.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans le renommage du niveau ${this.selectedNiveau.libelle}`, "Pricers", true);
                }
            })
    }


    SetDisplaydatesValidationForm(prd: PricerGrille) {
        this.displaydatesValidationForm = true;
        this.dateValidationFormValue = GetDateValidationFormValue(prd.datesValidation.periodes);
    }

    SetDisplaydatesValidationNivForm(niv: PricerNiveau) {
        this.displaydatesValidationNivForm = true;
        this.dateValidationNivFormValue = GetDateValidationFormValue(niv.datesValidation.periodes);
    }

    SetDatesValidationGrille(datesValitionValue: PeriodeDtvFormList) {

        let cmd: SetDatesValidationForGrilleCmd = {
            grilleCode: this.selectedGrille.code,
            hotCode: this.currentHotCode,
            pricerCode: this.selectedPricer.code,
            sessionId: this.currentSession?.sessionId,
            datesValidation: GetDateValidationCmdValue(datesValitionValue)
        };

        this.pricerService.apiPricerDatesValidationGrillePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.displaydatesValidationForm = false;
                        this.notifService.setMessage(`Modification des dates de validations : ${this.selectedGrille.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la Modification des dates de validations ${this.selectedGrille.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la Modification des dates de validations ${this.selectedGrille.libelle}`, "Pricers", true);
                }
            })

    }


    SetDatesValidationNiveau(datesValitionValue: PeriodeDtvFormList) {

        let cmd: SetDatesValidationForNiveauCmd = {
            grilleCode: this.selectedGrille.code,
            hotCode: this.currentHotCode,
            pricerCode: this.selectedPricer.code,
            nivCode: this.selectedNiveau.code,
            sessionId: this.currentSession?.sessionId,
            datesValidation: GetDateValidationCmdValue(datesValitionValue)
        };

        this.pricerService.apiPricerDatesValidationNiveauPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.displaydatesValidationNivForm = false;
                        this.notifService.setMessage(`Modification des dates de validations : ${this.selectedNiveau.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la Modification des dates de validations ${this.selectedNiveau.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la Modification des dates de validations ${this.selectedNiveau.libelle}`, "Pricers", true);
                }
            })

    }

    CreateGrille(evt: any) {
        let cmd: CreateGrilleCmd = {
            hotCode: this.currentHotCode,
            pricerCode: this.selectedPricer.code,
            sessionId: this.currentSession?.sessionId,
            fromGrilleCode: evt?.newGrl?.grilleFrom,
            grilleLibelle: evt?.newGrl?.label
        }
        this.pricerService.apiPricerCreateGrillePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.displayCreateGrilleForm = false;
                        this.notifService.setMessage(`Création de la grille : ${cmd.grilleLibelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la Création de la grille ${cmd.grilleLibelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la Création de la grille ${cmd.grilleLibelle}`, "Pricers", true);
                }
            })
    }

    renameCurrentGrille(data: any): void {
        const newLibelle: string = data?.updateObj?.label;

        let cmd: RenameGrilleCmd = {
            hotCode: this.currentHotCode,
            sessionId: this.currentSession.sessionId,
            newGrilleLibelle: newLibelle,
            pricerCode: this.selectedPricer.code,
            grilleCode: this.selectedGrille.code
        }

        this.pricerService.apiPricerRenameGrillePut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.displayRenameGrilleForm = false;
                        this.notifService.setMessage(`Girlle tarifaire: ${this.selectedGrille.libelle} renommée en ${newLibelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans le renommage de la  grille ${this.selectedGrille.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans le renommage de la grille ${this.selectedGrille.libelle}`, "Pricers", true);
                }
            })
    }

    setPrdvuRef() {
        let cmd: SetPrdvuRefCmd = {
            hotCode: this.currentHotCode,
            prdvuCode: this.selectedPrdvu.code,
            pricerCode: this.selectedPricer.code,
            sessionId: this.currentSession.sessionId
        }

        this.pricerService.apiPricerSetPrdvuRefPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(this.fixSeul);
                        this.notifService.setMessage(`Changement du produit de Ref pour ${this.selectedPrdvu.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur de changement du produit de Ref`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur de changement du produit de Ref`, "Pricers", true);
                }
            })
    }

}


