import { Injectable } from '@angular/core';

import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ExportTablesService {

  constructor() { }
  // exportPdf(cols, data, fileName) {
  //   let exportColumns = cols.map(col => ({title: col.header, dataKey: col.field}));
  //   import("jspdf").then((jsPDF) => {
  //     import("jspdf-autotable").then((x) => {
  //       const doc = new jsPDF.default(0, 0);
  //       doc.autoTable(exportColumns, data);
  //       doc.save(fileName);
  //     });
  //   });
  // }

  exportExcel(data, fileName) {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
      };
      const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
      });
      this.saveAsExcelFile(excelBuffer, fileName);
  });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    const date = new Date();
        FileSaver.saveAs(data, fileName + '_export_' + date.getFullYear() + '_'
                + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getHours() + '_' + date.getMinutes() + EXCEL_EXTENSION);
        ;
}
}
