import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { MenuItem } from 'primeng/api'
import { FormGroup2 } from '../../models/formGroup2'
import { InputNumber } from 'primeng/inputnumber'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.css'],
})
export class InputNumberComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() formGroup: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2;
    tooltipzindex=TOOL_TIP_Z_INDEX;

    parentFormGroup: FormGroup2
    typeParrentGroup: string

    @ViewChild('inputNumber1') inputNumber1: InputNumber;

    constructor() { }
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.field.readOnly === true) {
                this.inputNumber1.disabled = true;
            }
            else {
                this.inputNumber1.disabled = false;
            }
        }, 10);
    }
    ngOnChanges(changes: SimpleChanges): void { }

    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (
            (!this.formControl.value && this.formControl.value != 0) ||
            this.formControl.value === ''
        ) {
            this.formControl.setValue(null)
        }

        if (this.formControl.parent instanceof FormGroup2) {
            this.parentFormGroup = this.formControl.parent as FormGroup2
            if (this.parentFormGroup && this.parentFormGroup.formParam) {
                this.typeParrentGroup = this.parentFormGroup.formParam.type
            }
        }
    }

    onInput(event) {
        this.formControl.update_Value_And_Validity();
    }

    getErrorsMessage(): string {
        if (this.formControl.externalErrorMsg) {
            return this.formControl.externalErrorMsg;

        } else if (this.formControl.formParam.required) {
            return 'Champ requis'
        }
        return '';
    }
}
