/**
 * Spot Distrib Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllPricersHotel } from '../model/allPricersHotel';
import { DistribSession } from '../model/distribSession';
import { FormConfig } from '../model/formConfig';
import { UpdatePricerPrdvuFormulePropreListCmd } from '../model/updatePricerPrdvuFormulePropreListCmd';
import { UpsertLevierNumeriqueCmd } from '../model/upsertLevierNumeriqueCmd';
import { UpsertPricerDeriveCmd } from '../model/upsertPricerDeriveCmd';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PricerDeriveService {

    protected basePath = 'https://services-dev.spotpilot.com/distrib';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Liste des pricers d&#x27;un hôtel
     * 
     * @param fixSeulement 
     * @param hotCode 
     * @param sessionId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<AllPricersHotel>;
    public apiPricerDeriveAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllPricersHotel>>;
    public apiPricerDeriveAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllPricersHotel>>;
    public apiPricerDeriveAllPricersGet(fixSeulement: boolean, hotCode: string, sessionId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fixSeulement === null || fixSeulement === undefined) {
            throw new Error('Required parameter fixSeulement was null or undefined when calling apiPricerDeriveAllPricersGet.');
        }

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeriveAllPricersGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fixSeulement !== undefined && fixSeulement !== null) {
            queryParameters = queryParameters.set('FixSeulement', <any>fixSeulement);
        }
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('HotCode', <any>hotCode);
        }
        if (sessionId !== undefined && sessionId !== null) {
            queryParameters = queryParameters.set('SessionId', <any>sessionId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AllPricersHotel>('get',`${this.basePath}/api/pricerDerive/allPricers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Création d&#x27;un pricer derivé
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveCreatePricerDeriveFormGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerDeriveCreatePricerDeriveFormGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerDeriveCreatePricerDeriveFormGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerDeriveCreatePricerDeriveFormGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeriveCreatePricerDeriveFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricerDerive/createPricerDeriveForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Levier numerique
     * 
     * @param hotCode 
     * @param pricerCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveGetLevierNumeriqueFormGet(hotCode: string, pricerCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerDeriveGetLevierNumeriqueFormGet(hotCode: string, pricerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerDeriveGetLevierNumeriqueFormGet(hotCode: string, pricerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerDeriveGetLevierNumeriqueFormGet(hotCode: string, pricerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeriveGetLevierNumeriqueFormGet.');
        }

        if (pricerCode === null || pricerCode === undefined) {
            throw new Error('Required parameter pricerCode was null or undefined when calling apiPricerDeriveGetLevierNumeriqueFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('pricerCode', <any>pricerCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricerDerive/GetLevierNumeriqueForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pricerCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveLevierNumeriqueDelete(hotCode?: string, pricerCode?: string, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeriveLevierNumeriqueDelete(hotCode?: string, pricerCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeriveLevierNumeriqueDelete(hotCode?: string, pricerCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeriveLevierNumeriqueDelete(hotCode?: string, pricerCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('pricerCode', <any>pricerCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DistribSession>('delete',`${this.basePath}/api/pricerDerive/levierNumerique`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pricerCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveProduitsAvecPropreFormuleFormGet(hotCode: string, pricerCode: string, observe?: 'body', reportProgress?: boolean): Observable<FormConfig>;
    public apiPricerDeriveProduitsAvecPropreFormuleFormGet(hotCode: string, pricerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormConfig>>;
    public apiPricerDeriveProduitsAvecPropreFormuleFormGet(hotCode: string, pricerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormConfig>>;
    public apiPricerDeriveProduitsAvecPropreFormuleFormGet(hotCode: string, pricerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiPricerDeriveProduitsAvecPropreFormuleFormGet.');
        }

        if (pricerCode === null || pricerCode === undefined) {
            throw new Error('Required parameter pricerCode was null or undefined when calling apiPricerDeriveProduitsAvecPropreFormuleFormGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotCode !== undefined && hotCode !== null) {
            queryParameters = queryParameters.set('hotCode', <any>hotCode);
        }
        if (pricerCode !== undefined && pricerCode !== null) {
            queryParameters = queryParameters.set('pricerCode', <any>pricerCode);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FormConfig>('get',`${this.basePath}/api/pricerDerive/produitsAvecPropreFormuleForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour de la liste des prdvu appliquant leur propore formule
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost(body: UpdatePricerPrdvuFormulePropreListCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost(body: UpdatePricerPrdvuFormulePropreListCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost(body: UpdatePricerPrdvuFormulePropreListCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost(body: UpdatePricerPrdvuFormulePropreListCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricerDerive/updatePricerPrdvuFormulePropreList`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creation ou Mise à jour du Levier numerique
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveUpsertLevierNumeriquePost(body: UpsertLevierNumeriqueCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeriveUpsertLevierNumeriquePost(body: UpsertLevierNumeriqueCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeriveUpsertLevierNumeriquePost(body: UpsertLevierNumeriqueCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeriveUpsertLevierNumeriquePost(body: UpsertLevierNumeriqueCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiPricerDeriveUpsertLevierNumeriquePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricerDerive/UpsertLevierNumerique`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPricerDeriveUpsertPricerDerivePost(body: UpsertPricerDeriveCmd, observe?: 'body', reportProgress?: boolean): Observable<DistribSession>;
    public apiPricerDeriveUpsertPricerDerivePost(body: UpsertPricerDeriveCmd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistribSession>>;
    public apiPricerDeriveUpsertPricerDerivePost(body: UpsertPricerDeriveCmd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistribSession>>;
    public apiPricerDeriveUpsertPricerDerivePost(body: UpsertPricerDeriveCmd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiPricerDeriveUpsertPricerDerivePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DistribSession>('post',`${this.basePath}/api/pricerDerive/upsertPricerDerive`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
