<div class="container-fluid">
    <div class="main-user">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <p-button label="" icon="pi pi-user-plus" class="mr-1" (onClick)="newUser()"></p-button>
                <p-button label="" icon="pi pi-user-edit" class="mr-1" styleClass="p-button-success" (onClick)="editUser()"
                    [disabled]="!selectedUsers"></p-button>
                <p-button label="Profile" icon=" pi pi-id-card" class="mr-1" styleClass="p-button-info" (onClick)="showUser()"
                    [disabled]="!selectedUsers">
                </p-button>
                <!-- <p-button label="Gestion des groupes" icon="" (click)="manageGroups()" [disabled]=""></p-button> -->
                <p-button *ngIf="isAdmin()" label="Role" icon="pi pi-plus"class="mr-1" (onClick)="newRoles()"></p-button>
                <button pButton type="button" [label]="isUserDisabled(selectedUsers) ? 'Activer' : 'Désactiver'" class="mr-1"
                    [icon]="isUserDisabled(selectedUsers) ?  'pi pi-unlock': 'pi pi-lock'"
                    [ngClass]="{ 'p-button-success': isUserDisabled(selectedUsers), 'p-button-danger': !isUserDisabled(selectedUsers) }"
                    (click)="openEnableDisableUserDialog()" [disabled]="!selectedUsers">
                </button>
                <p-button label="Reset Password" icon="pi pi-key" styleClass="p-button-warning" class="mr-1"
                    (onClick)="openResetUserPasswordDialog()" [disabled]="!selectedUsers">
                </p-button>
            </div>
        </p-toolbar>
        <p-table #dt [columns]="cols" [value]="users" [resizableColumns]="true" [paginator]="" [rows]="20"
            styleClass="p-datatable-sm p-datatable-striped" [showCurrentPageReport]="true" selectionMode="single"
            [(selection)]="selectedUsers" dataKey="username" (onRowSelect)="onRowSelect($event)" [loading]="loading">
            <ng-template pTemplate="caption">
                <div style="text-align: left">
                    <input type="text" pInputText size="50" placeholder="" pInputText [(ngModel)]="searchedUser"
                        style="width:auto; margin: 4px 0px 2px 0;" (keyup.enter)="findUsers(searchedUser)" />
                    <a type="button" pButton icon="pi pi-search" (click)="findUsers(searchedUser)"></a>
                </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" class="ui-resizable-column">
                        <ng-container [ngSwitch]="true" [style.font-style]>
                            <ng-container *ngSwitchCase="col.field=='avatarProfile'">
                                <img [src]="transform(rowData.avatarProfile)"
                                    style="width: 30px; height: 30px; border-radius:15px;">
                            </ng-container>
                            <ng-container *ngSwitchCase="col.field=='familyName'">
                                <a (click)="editUser(rowData, true)">
                                    <button pButton type="button" [label]="rowData[col.field]" class="p-button-link"
                                        [ngClass]="{'strikethrough': isUserDisabled(rowData), 'white-text': rowData === this.selectedUsers}">
                                    </button>
                                </a>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <a (click)="editUser(rowData, true)">
                                    <span
                                    [ngClass]="isUserDisabled(rowData) ? 'strikethrough' : null">{{rowData[col.field]}}</span>
                                </a>

                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog  [(visible)]="displayUserForm"  [modal]="true"  [showHeader]="false" [style]="{ width: '80vw'}" >
        <div *ngIf="displayUserForm"  >
            <app-form-user (onValidate)="validate($event)" (onCancel)="cancel($event)" (onClose)="close($event)"
                (onCheckUserIfExist)="checkUserIfExist($event)" (onCopyText)="copyText($event)" [roles]="userRoles"
                [hotels]="userHotels" [groups]="userGroups" [selectedUserRoles]="selectedUserRoles"
                [cacheData]="cacheData" [avatarProfile]="avatarProfile" [avatarSrc]="avatarSrc"
                [isEmailError]="isEmailError" [selectedUserHotels]="selectedUserHotels" [saveLoading]="saveLoading"
                [selectedUserGroup]="selectedUserGroup" [selecetedUser]="lineValue" [infosUser]="infosUser"
                [userNameList]="userNameList" [emailList]="emailList" [isEditLoad]="isEditLoad">
            </app-form-user>
        </div>
    </p-dialog>
    <p-dialog *ngIf="displayUser" [(visible)]="displayUser" [modal]="true" [showHeader]="false"
        [style]="{ width: '80vw', height: '80hw' }">
            <p-toolbar styleClass="mt-2 mb-2">
                <div class="p-toolbar-group-left">
                </div>
                <div class="p-toolbar-group-right">
                    <p-button icon="pi pi-times" (click)="close()" styleClass="p-button-danger"></p-button>
                </div>
            </p-toolbar>
            <app-profile [cacheData]="cacheData" [rolesAutoriz]="rolesAutoriz" [groupsAutoriz]="groupsAutoriz"
                [hotelsAutoriz]="hotelsAutoriz" [selectedRolesAuthoriz]="selectedRolesAuthoriz" [lineValue]="lineValue"
                [avatarSrc]="avatarSrc">
            </app-profile>
    </p-dialog>
    <p-dialog [(visible)]="displayRole" [modal]="true" [style]="{width: '780px'}">
        <p-header>
        </p-header>
        <div class="p-grid">
            <div class="p-col">
                <p-panel header="Nouveau role">
                    <div class="p-m-0">
                        <div class="p-fluid">
                            <div class="p-field">
                                <label for="firstname1">Id</label>
                                <input id="firstname1" [(ngModel)]="newRole.id" type="text"
                                    [ngClass]="{ 'p-error': (!newRole.id || newRole.id == '') && isFormValidateRole }"
                                    pInputText>
                                <div *ngIf="(!newRole.id || newRole.id == '') && isFormValidateRole"
                                    class="ng-invalid ng-dirty">
                                    <small id="" class="p-error">id requis </small>
                                </div>
                            </div>
                            <div class="p-field">
                                <label for="lastname1">Name</label>
                                <input id="lastname1" [(ngModel)]="newRole.name" type="text"
                                    [ngClass]="{ 'p-error': (!newRole.name || newRole.name == '') && isFormValidateRole }"
                                    pInputText>
                                <div *ngIf="(!newRole.name || newRole.name == '') && isFormValidateRole"
                                    class="ng-invalid ng-dirty">
                                    <small id="" class="p-error">Nom requis </small>
                                </div>
                            </div>
                            <div class="p-field">
                                <label for="lastname1">Description</label>
                                <input id="lastname1" type="text" [(ngModel)]="newRole.description" [ngClass]="{ 'p-error': (!newRole.description || newRole.description == '')
                                 && isFormValidateRole }" pInputText>
                                <div *ngIf="(!newRole.description || newRole.description == '') && isFormValidateRole"
                                    class="ng-invalid ng-dirty">
                                    <small id="" class="p-error">description requise </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
        <p-footer style="margin-left: 25.2%;">
            <button type="button" pButton icon="pi pi-times" label="Annuler" (click)="cancel($event)"></button>
            <button type="button" pButton icon="pi pi-check" label="Valider" (click)="createRole()"></button>
        </p-footer>
    </p-dialog>
    <p-dialog *ngIf="displayResetPwd" [(visible)]="displayResetPwd" [modal]="true" [style]="{width: '780px'}">
        <p-header>
            Réinitialisation du mot de passe de {{selectedUsers.displayName}}
        </p-header>
        <div class="p-grid">
            <div class="p-col">
                <!-- <p-progressSpinner *ngIf="resettingPwd"></p-progressSpinner> -->
                <p-panel *ngIf="!displayResetPwdResult" header="Attention">
                    <div class="p-m-0">
                        <div class="p-fluid">
                            <div class="p-field">
                                <label for="pwd">Nouveau mot de passe</label>
                                <input id="pwd" type="text" [(ngModel)]="newPassword" pInputText>
                            </div>
                            <div class="p-field">
                                Etes-vous certain de vouloir réinitialiser le mot de passe pour le compte utilisateur
                                {{selectedUsers.displayName}} ({{selectedUsers.username}}) ?
                            </div>
                        </div>
                    </div>
                </p-panel>
                <p-panel *ngIf="displayResetPwdResult" header="Opération réussie">
                    <div class="p-m-0">
                        <div class="p-fluid">
                            <div class="p-field">
                                Le mot de passe pour le compte utilisateur {{selectedUsers.displayName}}
                                ({{selectedUsers.username}}) a
                                bien été réinitialisé.
                                Merci de transmettre le nouveau mot de passe temporaire à l'utilisateur. Il devra le
                                changer à sa
                                première connexion.
                            </div>
                            <div class="p-field">
                                Nouveau mot de passe temporaire : <strong>{{newTemporaryPassword}}</strong>
                                <i style="margin-left: 3px; cursor:pointer;" class="pi pi-copy"
                                    (click)="copyText(newTemporaryPassword)" value="click to copy"
                                    [ngClass]="{ 'p-success': isCopied }"></i>
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
        <p-footer *ngIf="!displayResetPwdResult" style="margin-left: 25.2%;">
            <button type="button" pButton icon="pi pi-times" label="Annuler" (click)="cancel($event)"></button>
            <button type="button" pButton icon="pi pi-check" label="Valider" class="p-button-success"
                [loading]="resettingPwd" (click)="resetUserPassword()"></button>
        </p-footer>
        <p-footer *ngIf="displayResetPwdResult" style="margin-left: 50.2%;">
            <button type="button" pButton icon="pi pi-check" label="Fermer"
                (click)="closeResetUserPasswordDialog()"></button>
        </p-footer>
    </p-dialog>
    <p-dialog *ngIf="displayEnabledDisableUser" [(visible)]="displayEnabledDisableUser" [modal]="true"
        [style]="{width: '700px'}">
        <p-header>
            {{isUserDisabled(selectedUsers) ? 'Activation' : 'Désactivation'}} de l'utilisateur
            {{selectedUsers.displayName}}
        </p-header>
        <div class="p-grid">
            <div class="p-col">
                <!-- <p-progressSpinner *ngIf=""></p-progressSpinner> -->
                <p-panel header="Attention">
                    <div class="p-m-0">
                        <div class="p-fluid">
                            <div class="p-field">
                                Etes-vous certain de vouloir {{isUserDisabled(selectedUsers) ? 'activer' :
                                'désactiver'}} le compte
                                utilisateur {{selectedUsers.displayName}} ({{selectedUsers.username}}) ?
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
        <p-footer style="margin-left: 25.2%;">
            <button type="button" pButton icon="pi pi-times" label="Annuler" (click)="cancel($event)"></button>
            <button type="button" pButton icon="pi pi-check" label="Valider" class="p-button-success"
                (click)="switchUserActivation()"></button>
        </p-footer>
    </p-dialog>
    <p-dialog *ngIf="displayPwd" [(visible)]="displayPwd" [modal]="true" [style]="{width: '780px'}">
        <p-header>
            Création de mot de passe de {{userForCreation.displayName}}
        </p-header>
        <div class="p-grid">
            <div class="p-col">
                <p-panel header="Opération réussie">
                    <div class="p-m-0">
                        <div class="p-fluid">
                            <div class="p-field">
                                Le mot de passe pour le compte utilisateur {{userForCreation.displayName}}
                                ({{userForCreation.username}}) que
                                vous venez de créer est: <b>{{password}}</b>
                                <i style="margin-left: 3px; cursor:pointer;" class="pi pi-copy"
                                    (click)="copyText(password)" value="click to copy"
                                    [ngClass]="{ 'p-success': isCopied }"></i>
                            </div>
                            <div class="p-field">
                                Merci de transmettre le mot de passe à l'utilisateur.
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
        <p-footer style="margin-left: 50.2%;">
            <button type="button" pButton icon="pi pi-times" label="Fermer" (click)="cancel($event)"></button>
        </p-footer>
    </p-dialog>
</div>
