import { Component } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent {

    constructor()
    {
        let a=10;

    }
}
