import { Component, OnInit, Input } from '@angular/core'
import { FormParameter } from '../models/form-parameter'
import { FormGroup2 } from '../models/formGroup2'
import { FormControl2 } from '../models/formControl2'
import { AbstractControl } from '@angular/forms'
import { IControl2 } from '../models/IControl2'

@Component({
    selector: 'app-form-renderer',
    templateUrl: './form-renderer.component.html',
    styleUrls: ['./form-renderer.component.css'],
})
export class FormRendererComponent implements OnInit {
    @Input() formGroup2: FormGroup2;
    @Input() column: number;
    @Input() tabviewItem: string;

    formParams: FormParameter[]
    constructor() { }

    ngOnInit(): void {
        this.formParams = this.formGroup2.formParam.childParameters
        if (this.column) {
            this.formParams = this.formParams.filter(x => x.column == this.column)
        }
        if (this.tabviewItem) {
            this.formParams = this.formParams.filter(x => x.tabViewItem == this.tabviewItem)
        }
        const parent = this.formGroup2?.parent
    }

    getvisibilityForField(field: FormParameter): boolean {
        let abs2 = this.formGroup2.get(field.code)
        if (abs2 instanceof FormControl2) {
            let frm2 = abs2 as FormControl2
            return !(frm2.visibilityConditionValue === false)
        }
        if (abs2 instanceof FormGroup2) {
            let frm2 = abs2 as FormGroup2
            return !(frm2.visibilityConditionValue === false)
        }
        return true
    }

    isTitle(field: FormParameter): boolean {
        if (!this.formGroup2.formParam.type) return true; //racine
        return this.formGroup2.formParam.type == 'VGroup'
    }
    getGridCalss(): string {
        if (this.formGroup2.formParam.type == 'HGroup') {
            return 'grid_groupH'
        }
        return ''
    }


    isLineSepartorVisible(field: FormParameter): boolean {
        return !field.lineSeparator === false;
    }
}
