import { ApplicationRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel/overlaypanel";
import { Subscription } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { HotelAuth } from "../azureAdB2C/services/model/hotelAuth";
import { UserInfoService } from "../azureAdB2C/UserInfoService/user-InfoService";
import { BreakpointService } from "../Breakpoint/BreakpointService";
@Component({
    selector: "app-top-bar-hotels",
    templateUrl: "./top-bar-hotels.component.html",
    styleUrls: ["./top-bar-hotels.component.css"],
})
export class TopBarHotelsComponent implements OnInit, OnDestroy {
    sub: Subscription;
    is_md: boolean;
    is_sm: boolean;
    is_lg: boolean;
    listhotel: Array<HotelAuth>;

    filtrerdlisthotel: Array<HotelAuth> = [];

    selectedHotels: Array<HotelAuth>;
    selectedType: string;
    textSearch: string;

    constructor(
        public userInfoService: UserInfoService,
        private appRef: ApplicationRef,
        private msalService: MsalService,
        private breakpointService: BreakpointService
    ) { }


    @ViewChild("op") op: OverlayPanel;
    @ViewChild("inputText") inputText: ElementRef;

    ngOnInit(): void {

        this.breakpointService.observeBreakpoint('md').subscribe(is_md => {
            this.is_md = is_md;
        });

        this.breakpointService.observeBreakpoint('sm').subscribe(is_sm => {
            this.is_sm = is_sm;
        });
        this.breakpointService.observeBreakpoint('lg').subscribe(is_lg => {
            this.is_lg = is_lg;
        });
        this.selectedHotels = [];
        if (this.checkAccount()) {
            this.loadListHotel();
        }
    }

    checkAccount(): boolean {
        return this.msalService.instance.getAllAccounts().length > 0
    }

    loadListHotel() {

        this.sub = this.userInfoService.getListHotel$().subscribe(list => {
            this.listhotel = list;
            this.filtrerdlisthotel = this.listhotel;
            this.updateSelecttedHotels();
        });
    }
    saveSelecttedHotels() {
        localStorage.setItem(
            "selectedHotels",
            JSON.stringify(this.selectedHotels)
        );
    }
    updateSelecttedHotels() {
        var selectedHotelsJson = localStorage.getItem("selectedHotels");

        if (!selectedHotelsJson) {
            for (let index = 0; index < 10; index++) {
                const element = this.listhotel[index];
                this.selectedHotels.push(element);
            }
            localStorage.setItem(
                "selectedHotels",
                JSON.stringify(this.selectedHotels)
            );
        } else {
            this.selectedHotels = JSON.parse(
                selectedHotelsJson
            ) as Array<HotelAuth>;
        }
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    getSelectedHotels(): Array<HotelAuth> {
        let result = new Array<HotelAuth>();

        for (let index = 0; index < this.selectedHotels.length; index++) {
            const element = this.selectedHotels[index];
            result.push(element);
        }

        if (result.length < 10) {
            for (
                let index = 0;
                index < 10 - this.selectedHotels.length;
                index++
            ) {
                result.push({ hotCode: "   ", name: "   " });
            }
        }
        if (!this.is_sm) {
            let newResult = result = result.slice(-2);

            const find = newResult.find((x) => {
                return x.hotCode === this.userInfoService.currentHotel.hotCode;
            });

            if(!find)
            {
                result = result.slice(-1);
                result.push(this.userInfoService.currentHotel);
            }
            else {
                result=newResult;
            }
        }
        else if (!this.is_md) {

            let newResult = result = result.slice(-6);

            const find = newResult.find((x) => {
                return x.hotCode === this.userInfoService.currentHotel.hotCode;
            });

            if(!find)
            {
                result = result.slice(-5);
                result.push(this.userInfoService.currentHotel);
            }
            else {
                result=newResult;
            }

        }

        return result;
    }
    addRemoveHotel(hotel: HotelAuth) {
        if (this.isSelected(hotel.hotCode)) {
            this.selectedHotels = this.selectedHotels.filter((x) => {
                return x.hotCode !== hotel.hotCode;
            });
        } else {
            if (this.selectedHotels.length >= 10) {
                this.selectedHotels.shift();
            }
            this.selectedHotels.push(hotel);
        }

        this.saveSelecttedHotels();
    }

    isSelected(hotCode: string): boolean {
        const find = this.selectedHotels.find((x) => {
            return x.hotCode === hotCode;
        });
        return !!find;
    }

    newHotel(hotel: HotelAuth) {
        this.userInfoService.newSelectedHotel(hotel);
    }

    getclassForbutton(hotel: HotelAuth): string {
        if (hotel?.hotCode == this.userInfoService.currentHotel?.hotCode)
            return "p-button-warning";
        else return "";
    }

    findHotels() {
        console.log(this.textSearch);
        let patt = new RegExp(this.textSearch, "i");
       let filtrerdlisthotel1 = this.listhotel.filter((x) => {
            return patt.test(x.hotCode);
        });

        let filtrerdlisthotel2 = this.listhotel.filter((x) => {
            return patt.test(x.name);
        });

        this.filtrerdlisthotel = filtrerdlisthotel1.concat(filtrerdlisthotel2);
        this.appRef.tick();
    }
}
