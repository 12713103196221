import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ExpoService } from 'src/app/distrib/services/api/api';
import { Canal, Distributeur, ExpositionHotel, InventaireDistant, RatePlan, RatePlanCanal } from 'src/app/distrib/services/model/models';
import { DistribSessionService } from 'src/app/distrib/session/distrib-session.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-inventaires-distants',
  templateUrl: './inventaires-distants.component.html',
  styleUrls: ['./inventaires-distants.component.css']
})
export class InventairesDistantsComponent implements OnInit, OnDestroy, OnChanges {

    private readonly destroy$: Subject<void>;
    @Input() allExpo: ExpositionHotel;
    @Output() OnReLoadData = new EventEmitter()

    currentHotCode: string;
    dataLoaded: boolean;
    _selectedDistributeur: Distributeur;

    selectedInvDist:InventaireDistant;

    get selectedDistributeur(): Distributeur {
        return this._selectedDistributeur;
    }

    set selectedDistributeur(value: Distributeur) {
        this._selectedDistributeur = value;

        if (this._selectedDistributeur?.canalList.length > 0) {

        }
        else {

        }
    }

    constructor(private expoService: ExpoService, private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {

        // this.userInfoService.getNewHotel$()
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe(hotel => {
        //         setTimeout(() => {
        //             //this.loadForm();
        //         }, 10);
        //     });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.currentHotCode = this.userInfoService.currentHotel.hotCode;
        if (this.allExpo) {
            this.dataLoaded = true;

            if (this.allExpo?.distributeurList?.length > 0) {
                this.selectedDistributeur = this.allExpo.distributeurList[0];
            }
        }
    }
}
