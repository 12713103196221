import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AttributionSurclassementItem, Inventaire, ProduitVendu, AllProduitstHotel, SetAttributionCmd } from '../../services/model/models';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProduitService } from '../../services/api/produit.service';
import { DistribSessionService } from '../../session/distrib-session.service';

@Component({
    selector: 'app-attribution-surclassement',
    templateUrl: './attribution-surclassement.component.html',
    styleUrls: ['./attribution-surclassement.component.scss']
})
export class AttributionSurclassementComponent implements OnChanges {

    private readonly destroy$: Subject<void>;
    @Input() allProduits: AllProduitstHotel;
    @Output() OnRealodData = new EventEmitter()


    // produitVenduCode?: string;
    // inventaireCode?: string;
    // isSurclassement?: boolean;

    prdvList: Array<ProduitVendu>;
    invList: Array<Inventaire>;

    titre: Array<AttrItem>;
    attributions: Array<Array<AttrItem>>;

    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService, private produitService: ProduitService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
        this.prdvList = this.allProduits?.produitVenduList?.filter(x => x.isObsolete == false);
    }



    ngOnChanges(changes: SimpleChanges): void {

        this.prdvList = this.allProduits.produitVenduList.filter(x => !x.isObsolete)
            .sort((a, b) => a.numeroSurClass - b.numeroSurClass);

        this.invList = this.allProduits.inventaireList.filter(x => !x.isObsolete)
            .sort((a, b) => a.numeroSurClass - b.numeroSurClass);

        this.attributions = [];

        this.titre = [];
        this.titre.push({
            titre: "Attr/surclass",
            invCode: ""
        });

        // tite du tableau
        for (let inv of this.invList) {
            let item = new AttrItem();
            item.titre = inv.libelle;
            item.invCode = inv.code;
            this.titre.push(item);
        }

        for (let prdv of this.prdvList) {
            let ligne: Array<AttrItem> = [];

            ligne.push(
                {
                    titre: prdv.libelle,
                    prdvCode: prdv.code
                }
            )
            for (let inv of this.invList) {
                let item = new AttrItem();
                item.invCode = inv.code;
                item.invLib = inv.libelle;
                item.prdvCode = prdv.code;
                item.prdvLib = prdv.libelle;

                ligne.push(item);
            }
            this.attributions.push(ligne);
        }
    }

    isAttribution(item: AttrItem): boolean {
        const attr = this.allProduits.matriceAttributionSurclassement.find(x =>
            x.inventaireCode == item.invCode && x.produitVenduCode == item.prdvCode);


        return !!attr;
    }
    getSurclass(item: AttrItem): string {

        const attr = this.allProduits.matriceAttributionSurclassement.find(x =>
            x.inventaireCode == item.invCode && x.produitVenduCode == item.prdvCode);


        if (attr && attr.isSurclassement) return "bg-Surclassement";

        if (attr) return "bg-attribution";

        return "";
    }

    currentCell: AttrItem;
    onHover(item: AttrItem) {
        this.currentCell = item;

    }

    onLeave(item: AttrItem) {
        this.currentCell = item;
    }


    getColumnBackgroudClass(item: AttrItem) {
        if (this.currentCell?.invCode == item?.invCode)
            return "bg-hover";
        else
            return ""
    }
    getRowBackgroudClass(item: AttrItem, index: number) {
        if (this.currentCell?.prdvCode == item?.prdvCode && index == 0)
            return "bg-hover";
        else
            return ""
    }

    setAttribution(attrib: boolean, item: AttrItem) {


        // prdvCode?: string;
        // invCode?: string;
        // attribution?: boolean;

        const cmd: SetAttributionCmd = {
            hotCode: this.userInfoService.currentHotel.hotCode,
            prdvCode: item.prdvCode,
            invCode: item.invCode,
            attribution: attrib
        }

        this.produitService.apiProduitAttributionPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                   this.OnRealodData.emit(true);
                },
                error: err => {

                }
            })
    }
}

class AttrItem {
    titre?: string;
    invCode?: string;
    invLib?: string;
    prdvCode?: string;
    prdvLib?: string;
}
