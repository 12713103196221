<div [ngClass]="'form-component-' + field.code">
  <div [formGroup]="formGroup">

    <div>
      <label [for]="field.code">{{ field.label }}
        <span *ngIf="field.required" class="fd-required">*</span>
      </label>
    </div>

    <div style="margin-bottom: 1em;position: relative">
      <p-inputNumber #inputNumber1 [formControlName]="field.code" (keydown)="onInput($event)" [pTooltip]="field.toolTip"
        tooltipPosition="top" [tooltipZIndex]="tooltipzindex" [inputStyle]="{
          width: field.type == 'ShortNumber' ? '95px' : ''}">
      </p-inputNumber>

      <div class="fd-errorText">
        <small>{{ formControl?.getErrorsMessage() }}
        </small>
      </div>
    </div>
  </div>
</div>