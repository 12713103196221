import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ExpoService } from 'src/app/distrib/services/api/api';
import { AddProduitsDiffuseCmd, ExpositionHotel, Pricer, PricerProduitVenduUnit, ProduitVenduUnit, RatePlan, UpsertRatePlanCmd } from 'src/app/distrib/services/model/models';
import { DistribSessionService } from 'src/app/distrib/session/distrib-session.service';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { RatePlanFormModel } from './RatePlanFormModel';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { cu } from '@fullcalendar/core/internal-common';

@Component({
    selector: 'app-planstarifaires',
    templateUrl: './planstarifaires.component.html',
    styleUrls: ['./planstarifaires.component.css']
})
export class PlanstarifairesComponent implements OnInit, OnDestroy, OnChanges {

    private readonly destroy$: Subject<void>;
    @Input() allExpo: ExpositionHotel;
    @Output() OnReLoadData = new EventEmitter<boolean>();

    currentHotCode: string;
    dataLoaded: boolean;

    displayRatePlan: boolean
    ratePlanForm: FormConfig;
    ratePlanFormValue: RatePlanFormModel;



    @ViewChild('formRatePlan') formRatePlan: FormDynamicComponent;
    pricerList: Array<Pricer> = [];
    displayProduitDiffuses: boolean;
    newRatePlan: boolean;

    canalDiffuseList: Array<{ code: string, libelle: string }>;

    constructor(private expoService: ExpoService, private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject<void>();

    }

    pricerPrdvuList: Array<ProduitVenduUnit> = [];

    selectedPricerPrdvuList: Array<string> = [];


    //selectedPricer pour la boit de dialogue
    _selectedPricer: Pricer;
    get selectedPricer(): Pricer {
        return this._selectedPricer;
    }
    set selectedPricer(value: Pricer) {
        this.pricerPrdvuList = value.pricerProduits.map(x => x.produitVenduUnit);
        this.selectedPricerPrdvuList = null;
        this._selectedPricer = value;
    }


    _selectedRatePlan: RatePlan;
    get selectedRatePlan(): RatePlan {
        return this._selectedRatePlan;
    }

    set selectedRatePlan(value: RatePlan) {
        this._selectedRatePlan = value;
        this.canalDiffuseList = [];
        if (this.allExpo && this.allExpo?.distributeurList) {
            for (let dis of this.allExpo?.distributeurList)
                for (let can of dis.canalList) {

                    if (can.ratePlansList.find(x => x.code == this.selectedRatePlan?.code))
                        this.canalDiffuseList.push({ code: can.code, libelle: can.libelle });
                }
        }
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    //this.loadForm();
                }, 10);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.currentHotCode = this.userInfoService.currentHotel.hotCode;
        if (this.allExpo) {
            this.dataLoaded = true;

            this.pricerList = this.allExpo?.pricerList

            if (this.selectedRatePlan) {
                if (this.allExpo?.ratePlanList?.length > 0) {
                    const newRatePlan = this.allExpo.ratePlanList.find(x => x.code == this.selectedRatePlan.code);
                    this.selectedRatePlan = newRatePlan ? newRatePlan : this.allExpo.ratePlanList[0]
                }
            }
            else {
                this.selectedRatePlan = null;
                if (this.allExpo?.ratePlanList?.length > 0) this.selectedRatePlan = this.allExpo?.ratePlanList[0];
            }
        }
    }

    async ratePlanShowdialog(newratePlan: boolean): Promise<void> {

        let rateplanCode = newratePlan ? "" : this.selectedRatePlan.code;

        const frm1 = await lastValueFrom(this.expoService.apiExpoRatePlanFormGet(this.currentHotCode, rateplanCode));
        this.ratePlanForm = JSON.parse(JSON.stringify(frm1)) as FormConfig;

        if (newratePlan) {

            //autoriser en lecture ecriture du champ
            var param = this.ratePlanForm.parameters[0].childParameters.find(x => x.code == "code")

            if (param) {
                param.readOnly = false;
                param.required = false;
                param.defaultValue = null;
            }

            this.ratePlanFormValue = null;
        }
        else {
            this.ratePlanFormValue =
            {
                ratePlan: {
                    code: this.selectedRatePlan.code,
                    isPassif: this.selectedRatePlan.isPassif,
                    libelle: this.selectedRatePlan.libelle,
                    tarifPmsAssocie: this.selectedRatePlan.tarifPmsAssocie,
                    typePackageAssocie: this.selectedRatePlan.pkgTypeCode,
                    restriction: {
                        maxlos: this.selectedRatePlan.maxlos,
                        minlos: this.selectedRatePlan.minlos
                    },
                }
            }
            //radio button
            if (this.selectedRatePlan.isOpaque) this.ratePlanFormValue.ratePlan.ratePlanType = "isOpaque";
            if (this.selectedRatePlan.isCorpo) this.ratePlanFormValue.ratePlan.ratePlanType = "isCorpo";
            if (this.selectedRatePlan.isPublic) this.ratePlanFormValue.ratePlan.ratePlanType = "isPublic";

            //checkbox
            this.ratePlanFormValue.ratePlan.ratePlanProps = [];
            if (this.selectedRatePlan.isGroupe) this.ratePlanFormValue.ratePlan.ratePlanProps.push("isGroupe");
            if (this.selectedRatePlan.isNonRemboursable) this.ratePlanFormValue.ratePlan.ratePlanProps.push("isNonRemboursable");
            if (this.selectedRatePlan.isRestrictionSeule) this.ratePlanFormValue.ratePlan.ratePlanProps.push("isRestrictionSeule");

            this.ratePlanFormValue.ratePlan.passifProps = {
                distributeurSource: this.selectedRatePlan.distributeurSource,
                codeSurDistributeur: this.selectedRatePlan.codeSurDistributeur,
                tarifMaitreCode: this.selectedRatePlan.tarifMaitreCode
            }
        }
        this.displayRatePlan = true;
        this.newRatePlan = newratePlan;
    }

    upsertRatePlan(model: RatePlanFormModel): void {

        let cmd: UpsertRatePlanCmd = {
            hotCode: this.currentHotCode,

            codeSurDistributeur: model.ratePlan.passifProps?.codeSurDistributeur,
            distributeurSource: model.ratePlan.passifProps?.distributeurSource,
            tarifMaitre: model.ratePlan.passifProps?.tarifMaitreCode,

            isCorpo: model.ratePlan.ratePlanType == "isCorpo",
            isOpaque: model.ratePlan.ratePlanType == "isOpaque",
            isPublic: model.ratePlan.ratePlanType == "isPublic",
            isGroupe: model.ratePlan.ratePlanProps?.indexOf("isGroupe") > -1,
            isNonRemboursable: model.ratePlan.ratePlanProps?.indexOf("isNonRemboursable") > -1,
            isRestrictionSeule: model.ratePlan.ratePlanProps?.indexOf("isRestrictionSeule") > -1,

            isPassif: model.ratePlan.isPassif,
            libelle: model.ratePlan.libelle,
            ratePlanCode: model.ratePlan.code,
            maxlos: model.ratePlan.restriction?.maxlos ?? 0,
            minlos: model.ratePlan.restriction?.minlos ?? 0,
            tarifPmsAssocie: model.ratePlan.tarifPmsAssocie,
            typePackageAssocie: model.ratePlan.typePackageAssocie,
        };

        this.expoService.apiExpoUpsertRatePlanPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        let error = new FormErrors();
                        error.errors = new ErrorDictionary();
                        error.errors["ratePlan"] = [session.error];  // ratePlan c'est le vertical groupe racine
                        this.formRatePlan.setExternalErrors(error);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayRatePlan = false;
                        if (cmd.ratePlanCode)
                            this.notifService.setMessage(`Mise à jour avec succès du tarif:${cmd.libelle}`, "Tarifs", false);
                        else
                            this.notifService.setMessage(`Création du tarif:${cmd.libelle} avec succès`, "Tarifs", false);


                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de mise à jour / création du tarif ${this.selectedRatePlan.libelle} `, "Tarifs", true);
                }
            });

    }

    updatePrdvuDiffuse(): void {
        let cmd: AddProduitsDiffuseCmd = {
            hotCode: this.currentHotCode,
            ratePlanCode: this.selectedRatePlan.code,
            pricerProduitList: []
        };

        this.selectedPricerPrdvuList.forEach(element => {
            cmd.pricerProduitList.push(
                {
                    pricerCode: this.selectedPricer.code,
                    produitVenduUnitCode: element
                }
            )
        });

        this.expoService.apiExpoUpdateProduistDiffusePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    if (session.error) {
                        this.notifService.setMessage(`Erreur de mise à jour des produits diffusés: ${session.error}`, "Tarifs", true);
                    }
                    else {
                        this.distribSessionService.newSession(session.hotCode, session.sessionId);
                        this.OnReLoadData.emit(true);
                        this.displayProduitDiffuses = false;
                        this.notifService.setMessage(`Mise à jour des produits diffusés avec succès`, "Tarifs", false);
                    }
                },
                error: err => {
                    this.notifService.setMessage(`Erreur de mise à jour des produits diffusés`, "Tarifs", true);
                }
            })
    }

    deletePrdExpose(prdExpose: PricerProduitVenduUnit): void {

        this.confirmationService.confirm({
            message: `Voulez-vous supprimer la produit ${prdExpose.produitVenduUnitLibelle}? `,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: "deleteProduitDiffuse",
            accept: () => {
                this.expoService.apiExpoDeleteProduitDiffuseDelete(
                    this.selectedRatePlan.code,
                    prdExpose.pricerCode,
                    prdExpose.produitVenduUnitCode,
                    this.currentHotCode
                )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: session => {
                            if (session.error) {
                                this.notifService.setMessage(`Erreur de mise à jour des produits diffusés: ${session.error}`, "Tarifs", true);
                            }
                            else {
                                this.distribSessionService.newSession(session.hotCode, session.sessionId);
                                this.OnReLoadData.emit(true);
                                this.displayProduitDiffuses = false;
                                this.notifService.setMessage(`Mise à jour des produits diffusés avec succès`, "Tarifs", false);
                            }
                        },
                        error: err => {
                            this.notifService.setMessage(`Erreur de mise à jour des produits diffusés`, "Tarifs", true);
                        }
                    })
            },
            reject: (type) => {

            }
        });
    }

}
