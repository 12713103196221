<p-panel header="Taux de tva" [style]="{height: '300px'}">
    <ng-template pTemplate="icons">
        <p-button [style]="{'margin-right':'5px'}" label="Annuler" (click)="cancel()"
        styleClass="p-button-primary"></p-button>
    <p-button [style]="{'margin-right':'5px'}" icon="pi pi-check" label="Valider"
        styleClass="p-button-success" (click)="validate()">
    </p-button>
    </ng-template>
    <div class=" flex flex-column md:flex-row gap-3 m-2">
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon " style="width:120px">Cah
                </span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.cah" mode="decimal" suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.cah == null || tvaFormValue.cah < 0 ">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon " style="width:120px">Ca Pdj</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.caPdj" mode="decimal" suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.caPdj == null || tvaFormValue.caPdj < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon " style="width:120px"> P3</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p3" mode="decimal" suffix="%"></p-inputNumber>
            </div> 
            <div *ngIf="tvaFormValue.p3 == null || tvaFormValue.p3 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
       
    </div>
    <div class="flex flex-column md:flex-row gap-3 m-2 ">
        <div>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon" style="width:120px"> P4</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p4" mode="decimal"
                    suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p4 == null || tvaFormValue.p4 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon " style="width:120px"> P5</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p5" mode="decimal"
                    suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p5 == null || tvaFormValue.p5 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon" style="width:120px"> P6</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p6" mode="decimal"
                    suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p6 == null || tvaFormValue.p6 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
    </div>
    <div class="flex flex-column md:flex-row gap-3 m-2 ">
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon " style="width:120px"> P7</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p7" mode="decimal" suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p7 == null|| tvaFormValue.p7 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon" style="width:120px"> P8</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p8" mode="decimal" suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p8 == null || tvaFormValue.p8 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon" style="width:120px"> P9</span>
                <p-inputNumber [minFractionDigits]="1" [maxFractionDigits]="2" [(ngModel)]="tvaFormValue.p9" mode="decimal" suffix="%"></p-inputNumber>
            </div>
            <div *ngIf="tvaFormValue.p9 == null|| tvaFormValue.p9 < 0">                                   
                <small id="" class="p-error">taux invalide</small>
            </div>
        </div>
       
    </div>
    <div class="flex flex-column md:flex-row gap-3 m-2">
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon" style="width:120px">Date début</span>
                <p-calendar [(ngModel)]="tvaFormValue.dateFrom" (onSelect)="selecteDate($event)" appendTo="body" 
                [disabled]="!isNewTva"  [maxDate]="tvaFormValue.dateEnd" dateFormat="yy-mm-dd"></p-calendar>
            </div>
            <div *ngIf="isBlank">                                   
                <small id="" class="p-error"> Date début non renseignée</small>
            </div>
        </div>
        <div>
            <div class="p-inputgroup ">
                <span class="p-inputgroup-addon" style="width:120px">Date fin</span>
                <p-calendar [(ngModel)]="tvaFormValue.dateEnd" appendTo="body" [minDate]="tvaFormValue.dateFrom" dateFormat="yy-mm-dd"></p-calendar>
            </div>
            <div *ngIf="!isValideDate">                                   
                <small id="" class="p-error"> Date Fin non valide </small>
            </div>
        </div>
    </div>
</p-panel>