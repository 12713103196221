<div>
  <p-header>
  </p-header>
  <div class="container">
    <div>
      <p-toolbar>
        <div class="p-toolbar-group-left flex flex-wrap gap-3 justify-content-center">
          <p-button label="New" icon="pi pi-plus" (click)="createNewGroup()"></p-button>
          <p-button label="Save" icon="pi pi-check" styleClass="p-button-success" (click)="saveNewGroup()"
            [disabled]="">
          </p-button>
          <p-button label="Annuler" icon="pi pi-times" (click)="close(true)"></p-button>
        </div>
      </p-toolbar>
      <div class="grid">
        <div class="col md:col-6 lg:col " style="margin-top: 8px;">
          <p-panel class="row p-2">
            <ng-template pTemplate="header" class="grid">
              <span class="col-10"><b>Liste groupes </b></span>
              <p-badge [value]="groups?.length" styleClass="mr-2"></p-badge>
              <!-- <span class=" p-col circle-class">
                <i class="circle-class-icon">{{groups?.length}}</i>
              </span>                 -->
            </ng-template>
            <p-listbox #listBox id="l1" [options]="groups" [(ngModel)]="selectedGroup" optionLabel="name"
              (onClick)="editSelectedGroup(selectedGroup)" [listStyle]="{'max-height':'510px'}" [multiple]="false"
              [style]="{'height':'515px'}" dataKey="id">
            </p-listbox>
          </p-panel>
        </div>
        <div class="col-6 md:col-6 lg:col-6 ">
          <div class="formgrid">
            <div class="col-12">
              <p-panel class="row p-2" [style]="{'max-height':'515px'}">
                <ng-template pTemplate="header" class="grid">
                  <span class="col-10" style="padding-top: 8px;"><b>Infos groupe </b></span>
                </ng-template>
                    <div class="field">
                      <label for="des">Description</label>
                      <input id="des" type="text" [value]="selectedGroup.description" class="w-full"
                        [(ngModel)]="selectedGroup.description" [ngClass]="{ 'p-error': (!selectedGroup.description || selectedGroup.description == '')
                                                 && isFormValidateGroup }" pInputText>
                      <div
                        *ngIf="(!selectedGroup.description || selectedGroup.description == '') && isFormValidateGroup"
                        class="ng-invalid ng-dirty">
                        <small id="" class="p-error">description requise </small>
                      </div>
                    </div>

                  <div class="formgrid grid" style=" padding: 5px;">
                    <div class="field col">
                      <label for="id1">Id du groupe</label>
                      <input id="id1" type="text" [value]="selectedGroup.id" [(ngModel)]="selectedGroup.id" class="w-full"
                        [disabled]="isId1Desabled"
                        [ngClass]="{ 'p-error': (idGroupList?.indexOf(selectedGroup?.id) >= 0 && (selectedGroup.id && selectedGroup.id != '')
                            || (!selectedGroup.id || selectedGroup.id == ''))
                            && isId1New && isFormValidateGroup }"
                        pInputText>
                      <div *ngIf="idGroupList?.indexOf(selectedGroup?.id) >= 0 && isId1New">
                        <small id="" class="p-error">Cet id existe déjà</small>
                      </div>
                      <div *ngIf="(!selectedGroup.id || selectedGroup.id == '') && isFormValidateGroup"
                        class="ng-invalid ng-dirty">
                        <small id="" class="p-error">id requis </small>
                      </div>
                    </div>
                    <div class="field col">
                      <label for="name1">Nom du groupe</label>
                      <input id="name1" type="text" [value]="selectedGroup.name" [(ngModel)]="selectedGroup.name" class="w-full"
                        [ngClass]="{ 'p-error': (!selectedGroup.name || selectedGroup.name == '')
                                                 && isFormValidateGroup }" pInputText>
                      <div *ngIf="(!selectedGroup.name || selectedGroup.name == '') && isFormValidateGroup"
                        class="ng-invalid ng-dirty">
                        <small id="" class="p-error">Nom requis </small>
                      </div>
                    </div>
                  </div>
                  <div class="grid">
                    <div class="col">
                      <p-panel >
                        <ng-template pTemplate="header" class="grid">
                          <span class="col-10"><b>Liste hotels </b></span>
                          <p-badge [value]="selectedHotels?.length" styleClass="mr-2"></p-badge>
                        </ng-template>
                        <p-listbox id="l3" class="m-0" [options]="hotels" optionLabel="name"
                          [(ngModel)]="selectedHotels" [checkbox]="true" [multiple]="true"
                          [listStyle]="{'height':'220px'}" dataKey="hotCode">
                          <ng-template pTemplate="header">
                            <div class="p-listbox-filter-container">
                              <input type="text" [value]="filterValue||''" [(ngModel)]="filterValue"
                                (input)="onCustomFilter(filterValue, 'hotels', 'hotCode', 'name', 'selectedHotels')"
                                class="p-listbox-filter p-inputtext p-component">
                              <span class="p-listbox-filter-icon pi pi-search"></span>
                            </div>
                          </ng-template>
                          <ng-template let-hotel pTemplate="item">
                            <div class="hotel-item">
                              <div>{{hotel.hotCode}} {{hotel.name}}</div>
                            </div>
                          </ng-template>
                        </p-listbox>
                      </p-panel>
                    </div>
                    <div class="col">
                      <p-panel >
                        <ng-template pTemplate="header" class="grid">
                          <span class="col-10"(click)="(displayListeRoles = true)" style="cursor:pointer;"><b>Liste des roles</b></span>
                          <p-badge [value]="selectedRoles?.length" styleClass="mr-2"></p-badge>
                        </ng-template>
                        <p-listbox id="l2" class="p-m-0" [options]="roles" optionLabel="name" [checkbox]="true"
                          [(ngModel)]="selectedRoles" [multiple]="true" [listStyle]="{'height':'262px'}"
                          dataKey="id">
                        </p-listbox>
                      </p-panel>
                    </div>
                  </div>
              </p-panel>
            </div>
          </div>
        </div>
        <div class="col-3 md:col-3 lg:col-3 " style="margin-top: 22px;">
          <p-panel >
            <ng-template pTemplate="header" class="grid">
              <span class="col-10"><b>Liste utilisateurs </b></span>
              <p-badge [value]="infosUserGroup?.length" styleClass="mr-2"></p-badge>
            </ng-template>
            <p-table [style]="{'height': '515px'}" #dt [columns]="cols" [value]="infosUserGroup"
              [resizableColumns]="true" [paginator]="true" [rows]="7" styleClass="p-datatable-sm p-datatable-striped"
              [showCurrentPageReport]="true">
              <ng-template pTemplate="caption">
                <div class="grid" style="text-align: left;">
                  <div class="col">
                    <input type="text" pInputText placeholder="" class="width-90"
                      (input)="dt.filterGlobal($event.target.value, 'contains')">
                    <i class="pi pi-search ml-1" style="margin:4px 4px 0 0"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [style.width]="col.width">
                </colgroup>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" class="ui-resizable-column" style="text-align: center;">
                    <ng-container [ngSwitch]="true" [style.font-style]>
                      <ng-container *ngSwitchCase="col.field=='avatarProfile'">
                        <img [src]="transform(rowData.avatarProfile)"
                          style="width: 35px; height: 35px; border-radius:17px;">
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{rowData[col.field]}}
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog *ngIf="displayListeRoles" [(visible)]="displayListeRoles" [modal]="true" [style]="{width: '620px'}">
  <p-header>
    Liste des roles
  </p-header>
  <p-table [columns]="colsRole" [value]="roles">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-table>

  <p-footer  style="margin-left: 50.2%;">
    <button type="button" pButton icon="pi pi-times" label="Fermer" (click)="cancel()"></button>
  </p-footer>
</p-dialog>
