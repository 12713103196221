import { RowSci } from "./RowSci";


// export enum RowSciTypes { Date, Month, To, N, Caz, Cahz, Pmz, Revpz };

export class TableSci {


    public dateNow: Date = new Date(); // todo il faudra tester le passage vers je jours suivant

    constructor(public cellWith: number, public cellsCount: number) {
        this.width = this.cellWith * this.cellsCount;
        this.addRowDateMonth();
    }


    public translate(translation: number): Array<number> {
        let result = this.rowSciDate.translate(translation);
        this.rowSciMonth.translateMonth();
        return result;
    }

    private ROWTYPE_DATE: string = "date";
    private ROWTYPE_Month: string = "month";

    public width: number;

    public rowSciList: Array<RowSci> = [];

    getRowSciListVisible(): Array<RowSci> {
        return this.rowSciList.filter(x => x.visible == true);
    }
    public rowSciDate: RowSci;
    public rowSciMonth: RowSci;

    public ligne_Date_height: number = 52;
    public ligne_Mois_height: number = 30;

    public current_Y: number = 0;



    /**
    height: hauteur de la ligne
    y: position de la ligne
  */
    public addRow(title: string, height: number, rowId: string, rowType: string, parentRowsci: RowSci = null, visible: boolean = true): RowSci {

        let _rowSci = new RowSci(this, title, height, this.current_Y, rowId, rowType,
            this.rowSciDate.cells, parentRowsci, visible);

        this.rowSciList.push(_rowSci);

        if (visible) this.current_Y = this.current_Y + height;

        if (parentRowsci) {
            parentRowsci.addRowChild(_rowSci);
        }
        return _rowSci;
    }

    public refresh() {
        this.current_Y = 0;
        this.rowSciList.forEach(row => {
            row.y = this.current_Y;
            if (row.visible) this.current_Y = this.current_Y + row.height;
        })
    }

    public toggleChildren(row: RowSci) {
        this.rowSciList.forEach(r => {
            if (r.parentRowSci == row) r.visible = !r.visible;
        });

        this.refresh();
    }
    private addRowDateMonth(): void {

        //la ligne date
        this.rowSciDate = new RowSci(this, "Date", this.ligne_Date_height, this.ligne_Mois_height, "Date", "Date"); //date

        //la ligne mois
        this.rowSciMonth = new RowSci(this, "Mois", this.ligne_Mois_height, 0, "Month", "Month", this.rowSciDate.cells); //mois

        this.rowSciMonth.translate(0); // pour initialiser les infos mois
    }
}
