import { Component, Input, OnInit } from '@angular/core';
import { CellSci } from '../../models/CellSci';
import { RowSci } from '../../models/RowSci';
import { TableSci } from '../../models/TableSci';

@Component({
  selector: 'app-cell-date',
  templateUrl: './cell-date.component.html',
  styleUrls: ['./cell-date.component.css']
})
export class CellDateComponent implements OnInit {

  constructor() { }

  @Input("rowSci")
  rowSci: RowSci

  @Input("cellid")
  cellId: number

  cellwidth: number;
  cellHeight: number;

  cell: CellSci;

  ngOnInit(): void {
    this.cell = this.rowSci.cells[this.cellId];
    this.cellwidth = this.rowSci.tableParent.cellWith;
    this.cellHeight = this.rowSci.height;
  }

  getDayWeekName(): string {
    let result = this.rowSci.cells[this.cellId].momentCell.format("ddd");

    result = result.replace('.', '');
    return result;

  }

  getDayInMonth(): string {

    const index = this.rowSci.cells[this.cellId].index;
    let result = this.rowSci.cells[this.cellId].momentCell.format("DD");

    return result;

  }
  getClass() {
    const index = this.rowSci.cells[this.cellId].index;
    let day = this.rowSci.cells[this.cellId].cellDate.getDay();

    if (index == 0) return "today";
    if (day == 0) return "cellDim";
    if (day == 5) return "cellVen";
    if (day == 6) return "cellSam";
    if (day == 1) return "cellLun";

    return "";

  }

}
