<div *ngIf="field.label" class="title-group mb-1">
    {{ field.label }}
</div>

<ng-container *ngIf="!formArray">
    <div class="grid_C">
        <div >
            <app-form-renderer [formGroup2]="formGroup" [column]="1" > </app-form-renderer>
        </div>
        <div>
            <app-form-renderer [formGroup2]="formGroup" [column]="2" > </app-form-renderer>
        </div>
        <div>
            <app-form-renderer [formGroup2]="formGroup" [column]="3" > </app-form-renderer>
        </div>
    </div>

    <div  class="mb-2">
        <span class="p-invalid">{{ formGroup?.getErrorsMessage() }} </span>
    </div>

    <hr *ngIf="isLineSeparatorVisible()" />
</ng-container>

