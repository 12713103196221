import { Component, OnInit, Input } from '@angular/core'
import {
    AbstractControl,
    FormArray,
    FormGroup,
    Validators,
} from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormArray2 } from '../../models/FormArray2'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'

@Component({
  selector: 'app-column-group',
  templateUrl: './column-group.component.html',
  styleUrls: ['./column-group.component.css']
})
export class ColumnGroupComponent {
    @Input() formGroup: FormGroup2
    @Input() formArray: FormArray2
    @Input() field: FormParameter

    frmgModel: FormGroup2
    constructor() {}

    ngOnInit(): void {
        if (this.formArray)
            this.frmgModel = this.formArray.controls[0] as FormGroup2
    }

    isLineSeparatorVisible(): boolean {

        if (this.field.lineSeparator===false)
        {
            return false
        }
        return true;
    }
}
