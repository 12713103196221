<span *ngIf="field.label" class="title-group mb-1">
    {{ field.label }}
</span>
<small class="p-invalid">{{ formControl?.getErrorsMessage() }}
</small>
<div class="mb-2">
    <p-listbox [options]="field.possibleValues" [(ngModel)]="selectedValues" optionLabel="label" optionValue="code"
        checkbox="true" [multiple]="true" [listStyle]="{'height': '310px'}">
    </p-listbox>
</div>
