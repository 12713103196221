<div class="bar-breadcrumb">

    <div class="ml-2">
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <span>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink"> {{item.label}} </a>
                <ng-container *ngIf="!item.routerLink"> {{item.label}} </ng-container>
            </span>
            <span *ngIf="!last">/</span>
        </ng-template>
    </div>

    <div>

    </div>

    <div *ngIf="isDistrib">
        <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger" [disabled]="!IsEnabled()"
            pTooltip="Valider les modifications" tooltipPosition="bottom" (onClick)="cancelSession($event)"></p-button>
    </div>

    <div *ngIf="isDistrib">
        <p-button label="Valider" icon="pi pi-save" [disabled]="!IsEnabled()" pTooltip="Annuler les modifications"
            tooltipPosition="bottom" (onClick)="confirmPublicationSession($event)"></p-button>
        <p-confirmPopup key="validationSession" ></p-confirmPopup>
    </div>

    <div *ngIf="isDistrib">
        <p-button icon="pi pi-info" styleClass="p-button-rounded p-button-text p-button-raised"
            pTooltip="Liste des modifications" [disabled]="!IsEnabled()" tooltipPosition="bottom"></p-button>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>
