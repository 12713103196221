<div *ngIf="field.label" class="title-group mb-1">
    {{ field.label }}
</div>

<ng-container *ngIf="!formArray">
    <div class="mb-2">
        <span class="p-invalid">{{ formGroup?.getErrorsMessage() }} </span>
    </div>
    <app-form-renderer [formGroup2]="formGroup"> </app-form-renderer>

    <hr *ngIf="isLineSeparatorVisible()" />
</ng-container>

<ng-container *ngIf="formArray" [formGroup]="formArray.parent">
    <div [formArrayName]="formArray.formParam.code">
        <div *ngFor="let control of formArray.controls; let i = index" class="formArrayItem">
            <app-form-renderer [formGroup2]="control"> </app-form-renderer>

            <div class="gridArray">
                <div class="mb-2" [style.visibility]="
                        i == formArray.controls.length - 1
                            ? 'visible'
                            : 'hidden'
                    ">
                    <p-button [label]="field.formArrayAddLabel" icon="pi pi-plus-circle"
                        styleClass="p-button-sm  p-button-rounded mt-2" (onClick)="addArrayItem()"></p-button>
                </div>
                <div></div>
                <div  *ngIf="formArray.controls.length > 1">
                    <p-button [label]="field.formArrayDelLabel" icon="pi pi-times-circle"
                        styleClass="p-button-sm p-button-rounded p-button-danger mt-2" (onClick)="removeArrayItem(i)">
                    </p-button>
                </div>
            </div>
            <hr />
        </div>
    </div>
</ng-container>
