<div *ngIf="field.label" class="title-group mb-1">
  {{ field.label }}
</div>

<ng-container *ngIf="!formArray">
  <div class="mb2">
    <span class="p-invalid">{{ formGroup?.getErrorsMessage() }} </span>
  </div>

  <app-form-renderer [formGroup2]="formGroup"> </app-form-renderer>


  <hr *ngIf="isLineSeparatorVisible()" />
</ng-container>

<ng-container *ngIf="formArray" [formGroup]="formArray.parent">
  <div [formArrayName]="formArray.formParam.code">
    <div *ngFor="let control of formArray.controls; let i = index" class="formArrayItem">
      <div class="gridArray">
        <div>
          <app-form-renderer [formGroup2]="control">
          </app-form-renderer>
        </div>

        <div>
          <div>
            <div class="mb2" [style.visibility]="
                                i == formArray.controls.length - 1
                                    ? 'visible'
                                    : 'hidden'
                            ">
              <p-button icon="pi pi-plus-circle" styleClass="p-button-sm" [pTooltip]="field.formArrayAddLabel"
                (onClick)="addArrayItem()"></p-button>
            </div>
            <div class="mt2" *ngIf="formArray.controls.length > 1">
              <p-button icon="pi pi-times-circle" [pTooltip]="field.formArrayDelLabel"
                styleClass="p-button-sm  p-button-danger" (onClick)="removeArrayItem(i)"></p-button>
            </div>
          </div>
        </div>
      </div>
      <hr *ngIf="isLineSeparatorVisible()" />
    </div>
    <hr *ngIf="!isLineSeparatorVisible()" />
  </div>
</ng-container>
