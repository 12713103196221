import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { HotelsService } from '../../services/api/hotels.service';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { Hotel } from '../../services/model/hotel';
import { HotelWithProperties } from '../../services/model/hotelWithProperties';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})

export class HotelsComponent {
  
  constructor(
    private breadcrumbService: BreadcrumbService,
    private hotelsService: HotelsService
) {
    this.breadcrumbService.setItems([{ label: "Hotels/Hotels" }]);
}
currentHotel: HotelAuth;
private subs = new SubSink();
hotels : Hotel[] = [];
selectedHotel : Hotel ={};
hotelWithProperties : HotelWithProperties = null;
isNew : boolean = false;
isLoading : boolean = false;


ngOnInit(): void {
  this.loadHotels();
  
}
ngOnDestroy(): void {
  if (this.subs != undefined) {
      this.subs.unsubscribe(); 
  }
}

loadHotels(){
this.isLoading = true;

this.subs.sink = this.hotelsService.hotelsGet().subscribe({
  next: (result) => { 
      this.hotels = result;
      this.selectedHotel = this.hotels[0];
      this.loadHotelDetails();
  },
  error: (error) => {
      console.log(error?.error);
      this.isLoading = false;
  },
});
}

loadHotelDetails(){
  if(this.selectedHotel.hotCode)
  this.subs.sink = this.hotelsService.hotelsHotcodeGet(this.selectedHotel.hotCode).subscribe({
    next: (result) => {
        this.hotelWithProperties = result;
        this.isLoading = false;
    },
    error: (error) => {
        console.log(error?.error);
        this.isLoading = false;
    },
  });
  
  }
  onRowSelect($event){
    this.loadHotelDetails();
  }
}
