import { Component, OnDestroy, OnInit } from '@angular/core';
import { PricerService } from '../../services/api/pricer.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { Subject, takeUntil } from 'rxjs';
import { AllPricersHotel } from '../../services/model/allPricersHotel';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/models';

@Component({
    selector: 'app-pricers',
    templateUrl: './pricers.component.html',
    styleUrls: ['./pricers.component.scss']
})
export class PricersComponent implements OnInit, OnDestroy {

    allPricers: AllPricersHotel;

    private readonly destroy$: Subject<void>;
    currentHotel: HotelAuth;
    dataLoaded: boolean;
    hotelNotIntialized: boolean;
    dataError:boolean;

    fixSeulement: boolean = false;

    constructor(private produitService: PricerService,
        private userInfoService: UserInfoService,
        private confirmationService: ConfirmationService,
        private notifService: NotificationsService,
        private breadcrumbService: BreadcrumbService,
        private distribSessionService: DistribSessionService) {

        this.breadcrumbService.setItems([
            {
                label: 'distrib/pricers'
            }
        ]);

        this.destroy$ = new Subject();

        this.allPricers = {

        }
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    this.currentHotel = hotel;
                    this.loadData();
                }, 100);
            });

        //rechargement des données suite à l'annulation de la session
        this.distribSessionService.getCancelSession$()
            .pipe(takeUntil(this.destroy$)).
            subscribe(sess => {
                this.loadData();
            })

    }

    loadData() {
        this.dataLoaded = false;
        this.hotelNotIntialized = false;
        this.dataError=false;
        this.produitService.apiPricerAllPricersGet(!this.fixSeulement, this.currentHotel.hotCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                {
                    next: data => {
                        this.allPricers = data;
                        this.dataLoaded = true;
                    },
                    error: err => {

                        if (err.status == '404') {
                            this.hotelNotIntialized = true;
                        }
                        else {
                            this.dataError= true;
                        }
                        this.dataLoaded = false;
                        this.allPricers = null;

                    }
                }
            );
    }

    RealodData(event) {
        this.fixSeulement = event;
        this.loadData();
    }

}
