import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { catchError, forkJoin, Subject, takeUntil, throwError } from 'rxjs';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProduitService } from '../../services/api/produit.service';
import { AllProduitstHotel, Inventaire, UpdateInventaireCmd } from '../../services/model/models';
import { ErrorDictionary, FormErrors } from '../../../dynamicForms/models/form-errors';
import { DistribSessionService } from '../../session/distrib-session.service';
import { HotelService as DistribHotelService } from '../../services/api/hotel.service';

@Component({
    selector: 'app-produits',
    templateUrl: './produits.component.html',
    styleUrls: ['./produits.component.scss']
})
export class ProduitsComponent implements OnInit, OnDestroy {
    currentHotel: HotelAuth;
    displayAlltables: boolean;
    private readonly destroy$: Subject<void>;
    allProduits: AllProduitstHotel;
    dataLoaded: boolean;

    @ViewChild('formInv') formDynamicInv: FormDynamicComponent;
    formInvValue: any;

    selectedInv: Inventaire;
    hotelNotIntialized: boolean;
    constructor(private produitService: ProduitService, private hotelService: DistribHotelService,
         private userInfoService: UserInfoService,
        private confirmationService: ConfirmationService,
        private notifService: NotificationsService,
        private breadcrumbService: BreadcrumbService,
        private distribSessionService: DistribSessionService) {

        this.breadcrumbService.setItems([
            {
                label: 'distrib/produits'
            }
        ]);

        this.destroy$ = new Subject();

        this.allProduits = {
            produitVenduList: [],
            inventaireList: [],
            matriceAttributionSurclassement: [],
            packageTypeList: [],
            produitVenduUnitList: [],
            prestationList: [],
            packageList: []
        }
    }

    ngOnInit(): void {
        this.userInfoService.getNewHotel$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(hotel => {
                setTimeout(() => {
                    this.currentHotel = hotel;
                    this.loadData();
                }, 100);
            });

        //rechargement des données suite à l'annulation de la session
        this.distribSessionService.getCancelSession$()
            .pipe(takeUntil(this.destroy$)).
            subscribe(sess => {
                this.loadData();
            })

    }

    loadData() {

        this.dataLoaded = false;
        this.hotelNotIntialized = false;
        this.produitService.apiProduitAllProduitsGet(this.currentHotel.hotCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                {
                    next: data => {
                        this.allProduits = data;
                        this.dataLoaded = true;
                        this.displayAlltables = true;
                    },
                    error: err => {

                        if (err.status == '404') {
                            this.hotelNotIntialized = true;
                        }

                        this.dataLoaded = true;
                        this.displayAlltables = false;
                        this.allProduits =
                        {
                            inventaireList: [],
                            produitVenduList: [],
                            packageList: [],
                            prestationList: []
                        }
                    }
                }
            );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    InitHotel(event): void {
        this.confirmationService.confirm({
            target: event.target,
            message: `Voulez-vous Initialiser l'hotel ${this.currentHotel.name} en Distrib?`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {
                this.hotelService.apiHotelInitDistribPost(this.currentHotel.hotCode, this.currentHotel.name)
                .subscribe( data=> {

                    setTimeout(() => {
                        this.loadData();
                    }, 1000);
                    this.notifService.setMessage(`Initialisation de l'hôtel${this.currentHotel.name}`, "Initialisation", false);
                })
            },
            reject: () => {
                // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            }
        });

    }

}
