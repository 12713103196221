import { DatePipe, registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr, "fr");

import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    HashLocationStrategy,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";

import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ColorPickerModule } from "primeng/colorpicker";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DragDropModule } from "primeng/dragdrop";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { FullCalendarModule } from "@fullcalendar/angular";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { Password, PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { ProgressSpinnerModule } from "primeng/progressspinner";

import { AppCodeModule } from "./blocks/app-code/app.code.component";
import { AppComponent } from "./app.component";
import { AppMainComponent } from "./app.main.component";
import { AppConfigComponent } from "./app.config.component";
import { AppRightPanelComponent } from "./app.rightpanel.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppBreadcrumbComponent } from "./app.breadcrumb.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { BlocksComponent } from "./blocks/blocks/blocks.component";
import { BlockViewer } from "./blocks/blockviewer/blockviewer.component";

import { BreadcrumbService } from "./app.breadcrumb.service";
import { MenuService } from "./app.menu.service";

import { environment } from "src/environments/environment";
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
} from "@azure/msal-browser";
import { TopBarHotelsComponent } from "./top-bar-hotels/top-bar-hotels.component";
import { RateShopperHotelsComponent } from "./rateShopper/components/rate-shopper-hotels/rate-shopper-hotels.component";
import { CompetitorsComponent } from "./rateShopper/components/competitors/competitors.component";
import { DataLiveComponent } from "./analyse/components/data-live.component";
import { RateShopperMappingsComponent } from "./rateShopper/components/rate-shopper-mappings/rate-shopper-mappings.component";
import { RateShopperPricesComponent } from "./rateShopper/components/rate-shopper-prices/rate-shopper-prices.component";
import { UsersComponent } from "./adminUsers/users/users.component";
import { GroupsComponent } from "./adminUsers/groups/groups/groups.component";
import { ReservationsComponent } from "./analyse/components/resa/reservations.component";
import { FormUserComponent } from "./adminUsers/users/formUser/form-user.component";
import { UserInfoService } from "./azureAdB2C/UserInfoService/user-InfoService";
import { RateShopperConfigService } from "./rateShopper/services/api/rateShopperConfig.service";
import { RateShopperService } from "./rateShopper/services/api/rateShopper.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { AuthorizationService_Azure } from "./azureAdB2C/services/api/authorization.service";
import { AuthorizationService } from "./adminUsers/services/api/authorization.service";
import { UserService } from "./adminUsers/services/api/user.service";
import { GroupService } from "./adminUsers/services/api/group.service";
import { RoleService } from "./adminUsers/services/api/role.service";
import { HotelService } from "./adminUsers/services/api/hotel.service";
import { DataLiveService } from "./analyse/services/api/dataLive.service";
import { IndicatorsLiveService } from "./analyse/services/api/indicatorsLive.service";
import { SupportRoleRequiredGuard } from "./azureAdB2C/SupportRoleRequired.guard";
import { ResnotifService } from "./spotConnect/services/api/resnotif.service";
import { SPDynamicFormsModule } from "./dynamicForms/spdynamic-forms.module";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { ProduitsComponent } from "./distrib/prodtuis/produits/produits.component";
import { ProduitService } from "./distrib/services/api/produit.service";
import { DistribSessionService } from "./distrib/session/distrib-session.service";
import { SessionService } from "./distrib/services/api/session.service";
import { BreakpointService } from "./Breakpoint/BreakpointService";
import { ProduitVendusComponent } from "./distrib/prodtuis/produit-vendus/produit-vendus.component";
import { InventairesComponent } from "./distrib/prodtuis/inventaires/inventaires.component";
import { PackagesComponent } from "./distrib/prodtuis/packages/packages.component";
import { PrestationsComponent } from "./distrib/prodtuis/prestations/prestations.component";

import { HotelService as DistribHotelService } from "./distrib/services/api/hotel.service";
import { AttributionSurclassementComponent } from "./distrib/prodtuis/attribution-surclassement/attribution-surclassement.component";
import { PricersComponent } from "./distrib/pricers/pricers/pricers.component";
import { PricerService } from "./distrib/services/api/pricer.service";
import { GrilleBarComponent } from "./distrib/pricers/grille-bar/grille-bar.component";
import { BudgetsComponent } from "./budgets/budgets/budgets.component";
import { FormBudgetComponent } from "./budgets/form-budget/form-budget.component";
import { BudgetTvaComponent } from "./budgets/budget-tva/budget-tva.component";
import { BudgetService } from "./budgets/services/api/budget.service";
import { SettingsService } from "./analyse/services/api/settings.service";
import { ProfileComponent } from "./adminUsers/users/profile/profile.component";
import { PricersAvancesComponent } from "./distrib/pricers/pricers-avances/pricers-avances.component";
import { FormBudgetTvaComponent } from "./budgets/budget-tva/form-budget-tva/form-budget-tva.component";
import { CalendrierAnnuelComponent } from "./calendrier/annuel/calendrier-annuel/calendrier-annuel.component";
import { CalendrierMensuelComponent } from "./calendrier/annuel/calendrier-mensuel/calendrier-mensuel.component";
import { AdminWorkSpaceComponent } from "./powerbi/admin-work-space/admin-work-space.component";
import { AdminWorkspaceService } from "./powerbi/services/api/api";
import { RatePlansComponent } from "./spotLive/components/mews/ratePlans/rate-plans/rate-plans.component";
import { CategoriesComponent } from "./spotLive/components/mews/categories/categories.component";
import { HotelConfigComponent } from "./spotLive/components/mews/hotel-config/hotel-config.component";
import { MappingsService } from "./spotLive/services/api/mappings.service";
import { MisterBookingComponent } from './spotLive/mister-booking/mister-booking.component';
import { AdminService } from "./spotLive/services/misterbooking/api/admin.service";
import { MappingComponent } from './spotLive/mister-booking/mapping/mapping.component';
import { ExpositionComponent } from './distrib/exposition/exposition/exposition.component';
import { ExpoService, PricerDeriveService, ViewsService } from "./distrib/services/api/api";
import { PlanstarifairesComponent } from './distrib/exposition/plans-tarifaires/planstarifaires/planstarifaires.component';
import { RoutageCannauxComponent } from './distrib/exposition/routage/routage-cannaux/routage-cannaux.component';
import { InventairesDistantsComponent } from './distrib/exposition/inventaires-distants/inventaires-distants/inventaires-distants.component';
import { MatriceExpoComponent } from './distrib/exposition/matrice/matrice-expo/matrice-expo.component';
import { MewsComponent } from './pms/mews/components/mews/mews.component';
import { MewsAnalyseService } from "./pms/mews/services/api/mewsAnalyse.service";
import { SpotYieldComponent } from './distrib/yield/spot-yield/spot-yield.component';
import { HorizontalScrollComponent } from './horizontal-scroll/horizontal-scroll.component';
import { CellDateComponent } from "./horizontal-scroll/cellCompoments/cell-date/cell-date.component";
import { CellMonthComponent }       from "./horizontal-scroll/cellCompoments/cell-month/cell-month.component";
import { CellIndicateurComponent } from "./horizontal-scroll/cellCompoments/cell-indicateur/cell-indicateur.component";
import { AdjustFontSizeDirective } from "./utilities/AdjustFontSizeDirective";
import { CellPricerrackComponent } from './horizontal-scroll/cellCompoments/cell-pricerrack/cell-pricerrack.component';
import { CellPrdvupricerComponent } from './horizontal-scroll/cellCompoments/cell-prdvupricer/cell-prdvupricer.component';
import { CellBaseComponent } from './horizontal-scroll/cellCompoments/cell-base/cell-base.component';
import { CellPricerComponent } from './horizontal-scroll/cellCompoments/cell-pricer/cell-pricer.component';
import { CellPrixrefComponent } from './horizontal-scroll/cellCompoments/cell-prixref/cell-prixref.component';
import { HotelsComponent } from './hotels/components/hotels/hotels.component';
import { HotelsService } from "./hotels/services/api/hotels.service";
import { AdminService as OperaCloudService } from "./pms/operacloud/services/pmslink/api/admin.service";
import { AdminService as OperaCloudComptaService } from "./pms/operacloud/services/compta/api/admin.service";
import { OperacloudComponent } from './pms/operacloud/components/operacloud/operacloud.component';
import { AsterioComponent } from './pms/asterio/components/asterio/asterio.component';
import { ComptaService } from "./services/bo-api";

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.auth.clientId,
            authority:
                environment.b2cPolicies.authorities.signUpSignIn.authority,
            redirectUri: "/",
            postLogoutRedirectUri: "/",
            knownAuthorities: [environment.b2cPolicies.authorityDomain],
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    if (environment.production) {
        protectedResourceMap.set(
            environment.apiConfig.uri,
            environment.apiConfig.scopes
        );

        protectedResourceMap.set(
            environment.apiConfig2023.uri,
            environment.apiConfig2023.scopes
        );
    }
    else {
        protectedResourceMap.set(
            environment.apiConfig.uri,
            environment.apiConfig.scopes
        );

        protectedResourceMap.set(
            environment.apiConfig_dev.uri,
            environment.apiConfig_dev.scopes
        );

        //temps
        protectedResourceMap.set(
            environment.apiConfig2023.uri,
            environment.apiConfig2023.scopes
        );
    }


    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.apiConfig.scopes],
        },
        loginFailedRoute: "/login-failed",
    };
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        MsalModule,
        SPDynamicFormsModule,
        ReactiveFormsModule,
        NgxJsonViewerModule,
        DragDropModule,
        ProgressSpinnerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        AppRightPanelComponent,
        AppTimelineDemoComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        BlocksComponent,
        BlockViewer,
        TopBarHotelsComponent,
        RateShopperHotelsComponent,
        CompetitorsComponent,
        DataLiveComponent,
        RateShopperMappingsComponent,
        RateShopperPricesComponent,
        UsersComponent,
        GroupsComponent,
        ReservationsComponent,
        FormUserComponent,
        ProduitsComponent,
        ProduitVendusComponent,
        InventairesComponent,
        PackagesComponent,
        PrestationsComponent,
        AttributionSurclassementComponent,
        PricersComponent,
        GrilleBarComponent,
        BudgetsComponent,
        FormBudgetComponent,
        BudgetTvaComponent,
        ProfileComponent,
        PricersAvancesComponent,
        FormBudgetTvaComponent,
        CalendrierAnnuelComponent,
        CalendrierMensuelComponent,
        RatePlansComponent,
        CategoriesComponent,
        HotelConfigComponent,
        CalendrierMensuelComponent,
        AdminWorkSpaceComponent,
        MisterBookingComponent,
        MappingComponent,
        ExpositionComponent,
        PlanstarifairesComponent,
        RoutageCannauxComponent,
        InventairesDistantsComponent,
        MatriceExpoComponent,
        MewsComponent,
        SpotYieldComponent,
        HorizontalScrollComponent,
        CellDateComponent,
        CellMonthComponent,
        CellIndicateurComponent,
        AdjustFontSizeDirective,
        CellPricerrackComponent,
        CellPrdvupricerComponent,
        CellPricerComponent,
        CellPrixrefComponent,
        HotelsComponent,
        OperacloudComponent,
        AsterioComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        BreadcrumbService,
        MenuService,
        UserInfoService,
        RateShopperConfigService,
        RateShopperService,
        MessageService,
        ConfirmationService,
        AuthorizationService_Azure,
        AuthorizationService,
        Password,

        UserService,
        GroupService,
        RoleService,
        HotelService,
        DataLiveService,
        IndicatorsLiveService,
        SupportRoleRequiredGuard,
        ResnotifService,
        BudgetService,
        SettingsService,
        DatePipe,
        MappingsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        { provide: LOCALE_ID, useValue: "fr-FR" },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        ProduitService,
        PricerService,
        DistribSessionService,
        SessionService,
        BreakpointService,
        DistribHotelService,
        AdminWorkspaceService,
        AdminService,
        ExpoService,
        PricerDeriveService,
        MewsAnalyseService,
        ViewsService,
        HotelsService,
        OperaCloudService,
        OperaCloudComptaService,
        ComptaService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
