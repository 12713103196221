<p-confirmDialog [style]="{ width: '40vw' }"  [position]="position"></p-confirmDialog>
<p-toolbar [style]="{'padding': '2px'}">
    <div class="p-toolbar-group-left">
    </div>
    <div class="p-toolbar-group-right">
        <p-button label="Sauvegarder" icon="pi pi-save" class="mr-1" styleClass="p-button-success"
            (onClick)="saveMappingComfirmation()">
        </p-button>
    </div>
</p-toolbar>
<div class="grid mt-1">
    <div class="col md:col-6 lg:col">
        <p-panel class="font-bold mb-1" header="Spot">
            <ng-template pTemplate="icons">
                <p-button
                    [style]="{ 'margin-right': '5px', height: '20px' }"
                    styleClass="p-button-text"
                    icon=""
                ></p-button>
            </ng-template>
            <div
                *ngIf="donneeCharge"
                style="min-height: 353px; max-height: 400px"
            >
                Chargement des données en cours
            </div>
            <p-scrollPanel  [style]="{width: '100%', height: 'calc(100vh - 360px)'}">
                <div
                *ngIf="!donneeCharge"
                style="min-height: 353px; max-height: 400px"
            >
                <li *ngFor="let option of listeSpot">
                    <span>
                        {{ option.spotRatePlanLabel }} &nbsp;
                        {{ option.spotProduitVenduUnitLabel }}</span
                    >
                </li>
            </div>
            </p-scrollPanel>
            
        </p-panel>
    </div>
    <div class="col-6 md:col-6 lg:col-6">
        <p-panel class="font-bold mb-1" header="Mappings">
            <ng-template pTemplate="icons">
                <p-button
                    [style]="{ height: '20px' }"
                    (onClick)="fusionsMapping()"
                    styleClass="p-button-info"
                    icon="pi pi-plus"
                ></p-button>
            </ng-template>
            <div
                *ngIf="donneeCharge"
                style="min-height: 353px; max-height: 400px"
            >
                Chargement des données en cours
            </div>
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 360px)'}">
                <div
                *ngIf="!donneeCharge"
                style="min-height: 353px; max-height: 400px"
            >
                <p-table
                    [columns]="mappingCols"
                    [value]="listemappe"
                    selectionMode="single"
                    [(selection)]="selectedMapping"
                >
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col
                                *ngFor="let col of columns"
                                [ngStyle]="{
                                    width:
                                        col.width != undefined
                                            ? col.width + 'px'
                                            : '50px'
                                }"
                            />
                        </colgroup>
                    </ng-template>

                    <ng-template
                        pTemplate="body"
                        let-rowData
                        let-columns="columns"
                    >
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns">
                                <ng-container [ngSwitch]="true">
                                    <ng-container
                                        *ngSwitchCase="
                                            col.field == 'spotRatePlanCode'
                                        "
                                    >
                                        <td>
                                            <span
                                                *ngIf="
                                                    rowData.spotRatePlanCode &&
                                                    rowData.spotProduitVenduUnitCode
                                                "
                                            >
                                                {{ rowData.spotRatePlanLabel }} -
                                                {{
                                                    rowData.spotProduitVenduUnitLabel
                                                }}
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container
                                        *ngSwitchCase="
                                            col.field == 'misterBookingRateId'
                                        "
                                    >
                                        <td>
                                            <span
                                                *ngIf="
                                                    !rowData.misterBookingRateHuman
                                                "
                                            >
                                                {{ rowData[col.field] }} -
                                                {{
                                                    rowData[
                                                        "misterBookingRoomTypeId"
                                                    ]
                                                }}
                                            </span>
                                            <span
                                                *ngIf="
                                                    rowData.misterBookingRateHuman
                                                "
                                            >
                                                {{
                                                    rowData.misterBookingRateHuman
                                                }}
                                            </span>
                                        </td>
                                    </ng-container>

                                    <ng-container
                                        *ngSwitchCase="col.field == ''"
                                    >
                                        <td>
                                            <p-button
                                                icon="pi pi-trash"
                                                [style]="{
                                                    width: '15px',
                                                    height: '6px',
                                                }"
                                                styleClass="p-button-rounded p-button-danger p-button-text"
                                                (onClick)="
                                                    deleteFromMapping(rowData)
                                                "
                                            ></p-button>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{ rowData[col.field] }}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </p-scrollPanel>
            
        </p-panel>
    </div>
    <div class="col-3 md:col-3 lg:col-3">
        <p-panel class="font-bold mb-1" header="MisterBooking">
            <ng-template pTemplate="icons">
                <p-button
                    [style]="{ 'margin-right': '5px', height: '20px' }"
                    styleClass="p-button-text"
                    icon=""
                ></p-button>
            </ng-template>
            <div
                *ngIf="donneeCharge"
                style="min-height: 353px; max-height: 400px"
                
            >
                Chargement des données en cours
            </div>
            <p-scrollPanel  [style]="{width: '100%', height: 'calc(100vh - 360px)'}">
                <div
                *ngIf="!donneeCharge"
                style="min-height: 353px; max-height: 400px"
                
            >
                <li *ngFor="let option of listeMisterBooking">
                    <span *ngIf="!option.misterBookingRateHuman">
                        {{ option.misterBookingRateId }} &nbsp;
                        {{ option.misterBookingRoomTypeId }}
                    </span>
                    <span *ngIf="option.misterBookingRateHuman">
                        {{ option.misterBookingRateHuman }}
                    </span>
                </li>
            </div>
            </p-scrollPanel>
            
        </p-panel>
    </div>
</div>
<div *ngIf="showMappingAdd">
    <div>
        <p-dialog
            [(visible)]="showMappingAdd"
            [modal]="true"
            [style]="{ width: '750px' }"
        >
            <p-panel
                header="Associer un élement Spot et un élement MisterBooking "
            >
                <div class="mappingColonne">
                    <p-panel header="Spot">
                            <p-listbox
                            [options]="listeSpot"
                            [listStyle]="{
                                'height': '400px',
                            }"
                            [style]="{ width: 'auto' }"
                            [(ngModel)]="selectedSpot"
                        >
                            <ng-template let-option pTemplate="option">
                                {{ option.spotRatePlanLabel }} -
                                {{ option.spotProduitVenduUnitLabel }}
                            </ng-template>
                        </p-listbox>
                    </p-panel>
                    <p-panel header="MisterBooking">
                        
                            <p-listbox
                            [options]="listeMisterBooking"
                            [listStyle]="{
                                'height': '400px',
                                
                            }"
                            [style]="{ width: 'auto' }"
                            [(ngModel)]="selectedMisterBooking"
                        >
                            <ng-template let-option pTemplate="option">
                                <span *ngIf="!option.misterBookingRateHuman">
                                    {{ option.misterBookingRateId }} -
                                    {{ option.misterBookingRoomTypeId }}</span
                                >
                                <span *ngIf="option.misterBookingRateHuman">
                                    {{ option.misterBookingRateHuman }}
                                </span>
                            </ng-template>
                        </p-listbox>
                       
                       
                    </p-panel>
                </div>
            </p-panel>
            <p-footer>
                <p-button
                    label="Fusionner"
                    styleClass="p-button-success"
                    class="mr-1"
                    (onClick)="validerMapping()"
                    [style]="{ 'margin-right': '5px' }"
                    [disabled]="!selectedMisterBooking || !selectedSpot"
                ></p-button>
                <p-button
                    [style]="{ 'margin-right': '5px' }"
                    label="Annuler"
                    (onClick)="cancel()"
                    styleClass="p-button-primary"
                ></p-button>
            </p-footer>
        </p-dialog>
    </div>
</div>
<p-dialog *ngIf="saveDialog" [(visible)]="saveDialog" [modal]="true" [style]="{ height:'240px' }">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Confirmation de sauvegarde</span>
    </ng-template>
    <p><b>Voulez-vous sauvegarder les modifications ?</b>
    </p>
    <div class="mt-2">
        <div class="mt-2 ">
            <span style="display: block;" >Veuillez entrer le code de l'Hôtel <b>{{currentHotel.name}}</b> :</span>
            <input type="text" pInputText [style]="{'width': '200px', 'margin-top': '5px'}" pInputText
                [(ngModel)]="hotCode" />
                <div
                    *ngIf="hotCode != null && hotCode != currentHotel.hotCode ">
                    <small id="" class="p-error">Code Hôtel incorrecte</small>
                </div>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button [disabled]="hotCode == null || hotCode != currentHotel.hotCode" icon="pi pi-check"
          label="Valider" styleClass="p-button-success" (onClick)="saveMapping($event)"></p-button>

    </ng-template>
</p-dialog>
