<div class="grid mt-1 gridRatePlan">
    <div class="col md:col-6 lg:col">
        <p-panel class="font-bold mb-1" header="Rate plans Spot">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px', 'height':'20px'}" styleClass="p-button-text"
                    icon=""></p-button>
            </ng-template>
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-10px'}">
                <p-table [value]="spotRatePlans" styleClass="p-datatable-striped p-datatable-sm"  dataKey="spot">
                    <ng-template pTemplate="body" let-spotRatePlanItem>
                        <tr>
                            <td>
                                <span>{{spotRatePlanItem.ratePlanLabel}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </p-panel>
    </div>
    <div class="col-6 md:col-6 lg:col-6">
        <p-panel class="font-bold mb-1" header="Mapping">
            <ng-template pTemplate="icons">
                <p-button [style]="{'height':'20px'}" (click)="newMapping()" styleClass="p-button-info"
                    icon="pi pi-plus"></p-button>
            </ng-template>
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-8px'}">
                <p-table [value]="mappingRatePlan" selectionMode="single" [(selection)]="selectedMapping" 
                styleClass="p-datatable-striped p-datatable-sm" dataKey="spot">
                    <ng-template pTemplate="body" let-mapItem>
                        <tr [pSelectableRow]="mapItem">
                            <td>{{ mapItem.spotName }}
                                <span *ngIf="isNotMappingSpotCodeExist(mapItem)">
                                    <i class="pi pi-exclamation-triangle" style="color: rgb(235, 85, 54); font-size: 1rem;"></i>
                                </span>
                            </td>
                            <td>{{ mapItem.mewsName }}
                                <span *ngIf="isNotMappingMewsCodeExist(mapItem)">
                                    <i class="pi pi-exclamation-triangle" style="color: rgb(235, 85, 54); font-size: 1rem;"></i>
                                </span>
                                    <span *ngFor="let rate of mewsMappingRate">
                                        <span  *ngIf="mapItem.mews == rate.id && rate.baseRateId != null" tooltipPosition="top"
                                         pTooltip="Erreur: Tarif dérivé " tooltipStyleClass="customtooltip">
                                         <i class="pi pi pi-info-circle" style="color: #de260a">
                                         </i> 
                                        </span>
                                    </span>
                            </td>
                            <td>
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    [style]="{'width':'15px', 'height':'6px'}"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="deleteFromMapping(mapItem)">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </p-panel>
    </div>

    <div class="col-3 md:col-3 lg:col-3">
        <p-panel class="font-bold mb-1" header="Rate plans Mews">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px', 'height':'20px'}" styleClass="p-button-text"
                    icon=""></p-button>
            </ng-template>
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-10px'}">
                    <p-table [value]="mewsRatePlans" styleClass="p-datatable-striped p-datatable-sm"  dataKey="spot">
                    <ng-template pTemplate="body" let-mewsRatePlanItem>
                        <tr>
                            <td>
                                <div class="grid" >
                                    <div class="col-1">
                                        <span  *ngIf="mewsRatePlanItem.baseRateId != null">
                                            <span *ngFor="let rate of mewsRatePlansDrivative">
                                                <span  *ngIf="mewsRatePlanItem.baseRateId == rate.id" tooltipPosition="top"
                                                 pTooltip="Tarif dérivé de {{rate.name}}">
                                                 <i class="pi pi pi-info-circle" style="color: #f09a29">
                                                 </i> 
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-11">
                                        <span>{{mewsRatePlanItem.name}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    </p-table>
            </p-scrollPanel>
        </p-panel>
    </div>

</div>
<p-dialog *ngIf="displayAddMapping" [(visible)]="displayAddMapping" [style]="{ width: '750px'}">
    <p-header></p-header>
    <div class="grid mt-1">
        <div class="col-6 md:col-6 lg:col-6">
            <p-panel header="Spot">
                <p-listbox [options]="spotRatePlans" optionLabel="ratePlanLabel" [(ngModel)]="selectedSpot"
                    [listStyle]="{  'height': '400px' }" [style]="{ width: 'auto'}">
                </p-listbox>
            </p-panel>
        </div>
        <div class="col-6 md:col-6 lg:col-6">
            <p-panel header="Mews">
                <p-listbox [options]="mewsRatePlansForMapping" optionLabel="name" [(ngModel)]="selectedMews"
                    [listStyle]="{  'height': '400px' }" [style]="{ width: 'auto' }">
                </p-listbox>
            </p-panel>
        </div>
    </div>
    <p-footer>
        <p-button [style]="{'margin-right':'5px'}" icon="pi pi-check" label="Valider" styleClass="p-button-success"
            (onClick)="validate()"  [disabled]="!selectedMews || !selectedSpot"  >
        </p-button>
        <p-button [style]="{'margin-right':'5px'}" label="Annuler" (click)="cancel()"
            styleClass="p-button-primary"></p-button>
    </p-footer>
