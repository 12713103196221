/**
 * Spot OperaCloud Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Hotel } from '../model/hotel';
import { HotelCredentials } from '../model/hotelCredentials';
import { RoomType } from '../model/roomType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminService {

    protected basePath = 'https://services.spotpilot.com/operacloud';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCredentialsHotCodeGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<Hotel>;
    public apiAdminCredentialsHotCodeGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Hotel>>;
    public apiAdminCredentialsHotCodeGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Hotel>>;
    public apiAdminCredentialsHotCodeGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminCredentialsHotCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Hotel>('get',`${this.basePath}/api/Admin/credentials/${encodeURIComponent(String(hotCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCredentialsPost(body: HotelCredentials, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminCredentialsPost(body: HotelCredentials, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminCredentialsPost(body: HotelCredentials, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminCredentialsPost(body: HotelCredentials, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling apiAdminCredentialsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/Admin/credentials`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminHasreservationsHotCodeGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminHasreservationsHotCodeGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminHasreservationsHotCodeGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminHasreservationsHotCodeGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminHasreservationsHotCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/Admin/hasreservations/${encodeURIComponent(String(hotCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param includeResas 
     * @param includeOO 
     * @param includeBlocks 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminLoadhistoryHotCodePost(hotCode: string, includeResas?: boolean, includeOO?: boolean, includeBlocks?: boolean, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminLoadhistoryHotCodePost(hotCode: string, includeResas?: boolean, includeOO?: boolean, includeBlocks?: boolean, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminLoadhistoryHotCodePost(hotCode: string, includeResas?: boolean, includeOO?: boolean, includeBlocks?: boolean, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminLoadhistoryHotCodePost(hotCode: string, includeResas?: boolean, includeOO?: boolean, includeBlocks?: boolean, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminLoadhistoryHotCodePost.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeResas !== undefined && includeResas !== null) {
            queryParameters = queryParameters.set('includeResas', <any>includeResas);
        }
        if (includeOO !== undefined && includeOO !== null) {
            queryParameters = queryParameters.set('includeOO', <any>includeOO);
        }
        if (includeBlocks !== undefined && includeBlocks !== null) {
            queryParameters = queryParameters.set('includeBlocks', <any>includeBlocks);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Admin/loadhistory/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param pmsReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminLoadreservationHotCodePost(hotCode: string, pmsReservationId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminLoadreservationHotCodePost(hotCode: string, pmsReservationId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminLoadreservationHotCodePost(hotCode: string, pmsReservationId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminLoadreservationHotCodePost(hotCode: string, pmsReservationId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminLoadreservationHotCodePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pmsReservationId !== undefined && pmsReservationId !== null) {
            queryParameters = queryParameters.set('pmsReservationId', <any>pmsReservationId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('post',`${this.basePath}/api/Admin/loadreservation/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime toutes les données stockés dans TableStorage pour l&#x27;hôtel, sauf sa configuration (credentials, types de chambres, données de références)
     * 
     * @param hotCode 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRazHotCodeDelete(hotCode: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminRazHotCodeDelete(hotCode: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminRazHotCodeDelete(hotCode: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminRazHotCodeDelete(hotCode: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminRazHotCodeDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('delete',`${this.basePath}/api/Admin/raz/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime toutes les données stockés dans TableStorage pour l&#x27;hôtel, sauf sa configuration (credentials, types de chambres, données de références)
     * 
     * @param hotCode 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRazooHotCodeDelete(hotCode: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminRazooHotCodeDelete(hotCode: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminRazooHotCodeDelete(hotCode: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminRazooHotCodeDelete(hotCode: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminRazooHotCodeDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('delete',`${this.basePath}/api/Admin/razoo/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtient l&#x27;objet Reservation (resa ou block) stocké dans TableStorage
     * 
     * @param hotCode 
     * @param pmsReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminReservationHotCodePmsReservationIdGet(hotCode: string, pmsReservationId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminReservationHotCodePmsReservationIdGet(hotCode: string, pmsReservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminReservationHotCodePmsReservationIdGet(hotCode: string, pmsReservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminReservationHotCodePmsReservationIdGet(hotCode: string, pmsReservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminReservationHotCodePmsReservationIdGet.');
        }

        if (pmsReservationId === null || pmsReservationId === undefined) {
            throw new Error('Required parameter pmsReservationId was null or undefined when calling apiAdminReservationHotCodePmsReservationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/Admin/reservation/${encodeURIComponent(String(hotCode))}/${encodeURIComponent(String(pmsReservationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtient les objets Reservation SpecA (resa ou block) stockés dans TableStorage pour le PmsReservationId
     * 
     * @param hotCode 
     * @param pmsReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminReservationHotCodePmsReservationIdSpecaGet(hotCode: string, pmsReservationId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminReservationHotCodePmsReservationIdSpecaGet(hotCode: string, pmsReservationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminReservationHotCodePmsReservationIdSpecaGet(hotCode: string, pmsReservationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminReservationHotCodePmsReservationIdSpecaGet(hotCode: string, pmsReservationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminReservationHotCodePmsReservationIdSpecaGet.');
        }

        if (pmsReservationId === null || pmsReservationId === undefined) {
            throw new Error('Required parameter pmsReservationId was null or undefined when calling apiAdminReservationHotCodePmsReservationIdSpecaGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/Admin/reservation/${encodeURIComponent(String(hotCode))}/${encodeURIComponent(String(pmsReservationId))}/speca`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminRoomtypesHotCodeGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RoomType>>;
    public apiAdminRoomtypesHotCodeGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RoomType>>>;
    public apiAdminRoomtypesHotCodeGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RoomType>>>;
    public apiAdminRoomtypesHotCodeGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminRoomtypesHotCodeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RoomType>>('get',`${this.basePath}/api/Admin/roomtypes/${encodeURIComponent(String(hotCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminStartHotCodePut(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminStartHotCodePut(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminStartHotCodePut(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminStartHotCodePut(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminStartHotCodePut.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('put',`${this.basePath}/api/Admin/start/${encodeURIComponent(String(hotCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param hotCode 
     * @param clearState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminStopHotCodePut(hotCode: string, clearState?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminStopHotCodePut(hotCode: string, clearState?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminStopHotCodePut(hotCode: string, clearState?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminStopHotCodePut(hotCode: string, clearState?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotCode === null || hotCode === undefined) {
            throw new Error('Required parameter hotCode was null or undefined when calling apiAdminStopHotCodePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clearState !== undefined && clearState !== null) {
            queryParameters = queryParameters.set('clearState', <any>clearState);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('put',`${this.basePath}/api/Admin/stop/${encodeURIComponent(String(hotCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminTestGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminTestGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminTestGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminTestGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/test`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
