<div class="grid mt-4" *ngIf="isLoading">
    <div class="col-6"></div>
    <div class="col-6">
    <div style="height: 300px;"></div>
        <div *ngIf="isLoading" style="height: 500px">
            <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
        </div>
    </div>
    <div class="col-6"></div>
</div>
<p-toolbar *ngIf="hotelWithProperties" [style]="{'padding': '2px'}">
    <div class="p-toolbar-group-left">
        <p-button icon="pi pi-refresh" class="mr-1" styleClass="p-button-success" [disabled]="!isNew"></p-button>
        <p-button label="Ajouter" icon="pi pi-plus" class="mr-1" [disabled]="!isNew" />
    </div>
    <div class="p-toolbar-group-right">
       
    </div>
</p-toolbar>
<div *ngIf="hotelWithProperties" class="grid ml-2 mt-1">
    <div class="col">
        <p-table [value]="hotels" [(selection)]="selectedHotel" #dt1 [globalFilterFields]="['hotCode', 'cbc', 'raisonSociale']"
            selectionMode="single" (onRowSelect)="onRowSelect($event)" [showCurrentPageReport]="true"
            [resizableColumns]="true" [scrollable]="true" scrollHeight="calc(100vh - 270px)"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                <div class="grid" style="text-align: left;">
                    <span class="col-1 mt-2">Hotels</span>
                    <div class="col">
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"  (input)=" dt1.filterGlobal($event.target.value, 'contains') " />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="hotCode" style="width:20%">Code <p-sortIcon field="hotCode"></p-sortIcon></th>
                    <th pSortableColumn="raisonSociale" style="width:20%">Raison Sociale <p-sortIcon field="raisonSociale"></p-sortIcon></th>
                    <th pSortableColumn="cbc" style="width:20%">CBC <p-sortIcon field="cbc"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-hotel>
                <tr [pSelectableRow]="hotel">
                    <td>{{hotel.hotCode}}</td>
                    <td>{{hotel.raisonSociale}}</td>
                    <td>{{hotel.cbc}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col  mt-1">
        <p-panel class="grid" [style]="{'width': '100%', 'max-height':'calc(100vh - 300px)', 'margin':'3px'}">
            <ng-template pTemplate="header" class="grid">
                <span class="col-10" style="padding-top: 8px;"><b>Infos hotel </b></span>
            </ng-template>
            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 295px)'}">
                <div class="grid">
                    <label class="col-3">Distrib activée</label>
                    <p-checkbox class="col-9" [style]="{'margin-left': '-5px'}"
                        [(ngModel)]="hotelWithProperties.isDistribActivated" [binary]="true"></p-checkbox>
                </div>
                <div class="grid">
                    <label class="col-3">RateShopper Configuré</label>
                    <p-checkbox class="col-9" [style]="{'margin-left': '-5px'}"
                        [(ngModel)]="hotelWithProperties.isRateShopperCorrectlyConfigured" [binary]="true"></p-checkbox>
                </div>
                <div class="grid">
                    <label class="col-3">MyPreco initializée</label>
                    <p-checkbox class="col-9" [style]="{'margin-left': '-5px'}"
                        [(ngModel)]="hotelWithProperties.isMyPrecoInitialized" [binary]="true"
                        inputId="binary"></p-checkbox>
                </div>

                <div class="grid">
                    <label class="col-3">Segmentation activée</label>
                    <p-checkbox class="col-9" [style]="{'margin-left': '-5px'}"
                        [(ngModel)]="hotelWithProperties.isSegmentationActivated" [binary]="true"></p-checkbox>

                </div>
                <div class="grid mt-2">
                    <label class="col-3 ">HotCode</label>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.hotCode" />
                </div>
                <div class="grid mt-2">
                    <label class="col-3">Raison Sociale</label>
                    <input class="col-9" type="text" pInputText pInputText
                        [(ngModel)]="hotelWithProperties.raisonSociale" />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">CBC</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.cbc" readonly />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">Raison Sociale Abrégée</span>
                    <input class="col-9" type="text" pInputText
                        [(ngModel)]="hotelWithProperties.raisonSocialeAbregee" />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">Adresse</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.adresse" />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">Code Postal</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.codePostal" />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">Ville</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.ville" readonly />
                </div>
                <div class="grid mt-2">
                    <span class="col-3">Code pays</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.countryNameCode"
                        readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Franchise</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.franchise" readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Groupe</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.groupe" readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Contact </span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.contact" readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Télephone</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.telephone" readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Fax</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.fax" readonly />
                </div>
                <div class="grid mt-2 mb-2">
                    <span class="col-3">Date Creation</span>
                    <input class="col-9" type="text" pInputText [(ngModel)]="hotelWithProperties.dateCreation"
                        readonly />
                </div>
                <div class="grid mt-3 mb-2">
                    <span class="col-3">Nb Etoiles</span>
                    <p-rating [(ngModel)]="hotelWithProperties.nbEtoiles" [cancel]="false"></p-rating>
                        
                </div>
            </p-scrollPanel>
        </p-panel>
    </div>
</div>