import { DatePipe } from "@angular/common";
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
} from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { UserInfoService } from "src/app/azureAdB2C/UserInfoService/user-InfoService";
import { HotelAuth } from "src/app/azureAdB2C/services/model/hotelAuth";
import { SubSink } from "src/app/dynamicForms/tools/subsink";
import { MappingsService } from "src/app/spotLive/services/api/mappings.service";
import { HotelCredentials } from "src/app/spotLive/services/model/hotelCredentials";
import { MappingRateInventory } from "src/app/spotLive/services/model/mappingRateInventory";
import { ServiceMews } from "src/app/spotLive/services/model/serviceMews";
import { RatePlansComponent } from "../ratePlans/rate-plans/rate-plans.component";
import { CategoriesComponent } from "../categories/categories.component";
import { MessageService } from "primeng/api";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { MappingItem } from "src/app/spotLive/services/model/mappingItem";
import { ProduitVenduUnit, RatePlan, RatePlanMews, ResourceCategoryMews } from "src/app/spotLive/services/model/models";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";

@Component({
    selector: "app-hotel-config",
    templateUrl: "./hotel-config.component.html",
    styleUrls: ["./hotel-config.component.css"],
})
export class HotelConfigComponent implements OnInit, OnDestroy {
    currentHotel: HotelAuth;
    private subs = new SubSink();
    hotelCredentials: HotelCredentials;
    serviceMews: ServiceMews[];
    selectedServiceMews: ServiceMews;
    hotelNotIntialized: boolean;
    mappingRateInventory: MappingRateInventory;
    stpotRatePlans: RatePlan[];
    mewsRatePlans: RatePlanMews[];
    resourceCategoryMews: ResourceCategoryMews[];
    produitVenduUnits: ProduitVenduUnit[];
    hotCode: string = null;
    position: string = 'center';
    mewsSaveDialog : boolean = false;

    get clientToken(): string {
        return this.hotelCredentials?.clientToken;
    }
    set clientToken(value: string) {
        if (!this.hotelCredentials) this.hotelCredentials = {};
        this.hotelCredentials.clientToken = value;
    }

    get accessToken(): string {
        return this.hotelCredentials?.accessToken;
    }
    set accessToken(value: string) {
        if (!this.hotelCredentials) this.hotelCredentials = {};
        this.hotelCredentials.accessToken = value;
    }

    get rateEnabled(): boolean {
        return this.hotelCredentials?.rateEnabled;
    }
    set rateEnabled(value: boolean) {
        if (!this.hotelCredentials) this.hotelCredentials = {};
        this.hotelCredentials.rateEnabled = value;
    }

    get restrictionEnabled(): boolean {
        return this.hotelCredentials?.restrictionEnabled;
    }
    set restrictionEnabled(value: boolean) {
        if (!this.hotelCredentials) this.hotelCredentials = {};
        this.hotelCredentials.restrictionEnabled = value;
    }


    constructor(
        private mappingsService: MappingsService,
        private userInfoService: UserInfoService,
        private breadcrumbService: BreadcrumbService,
        private notifService: NotificationsService
    ) {
        this.breadcrumbService.setItems([
            { label: 'SpotLive/Mews' }
        ]);
    }

    ngOnInit(): void {
        this.currentHotel = this.userInfoService.currentHotel;

        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
                    this.loadData();
            });
    }

    isUserAdmin(): boolean {
        return this.userInfoService.isUserAdmin();
    }

    loadData(): void {
        try {
            this.hotelNotIntialized = false;

            const obs1$ = this.mappingsService.apiMappingsHotelCredentialsGet(
                this.currentHotel.hotCode
            );

            this.subs.sink = obs1$
                .subscribe({
                    next: data => {
                        this.hotelNotIntialized = false;
                        this.hotelCredentials = data;



                        const obs2$ = this.mappingsService.apiMappingsMewsServicesGet(
                            this.currentHotel.hotCode
                        );

                        const obs4$ = this.mappingsService.apiMappingsSpotRatePlansGet(
                            this.currentHotel.hotCode
                        );

                        const obs5$ = this.mappingsService.apiMappingsMewsRatePlansGet(
                            this.currentHotel.hotCode
                        );

                        const obs6$ = this.mappingsService.apiMappingsMewsResourceCategoriesGet(
                            this.currentHotel.hotCode
                        );

                        const obs7$ = this.mappingsService.apiMappingsSpotProduitVendusGet(
                            this.currentHotel.hotCode
                        );

                        const obs8$ = this.mappingsService.apiMappingsGet(
                            this.currentHotel.hotCode
                        );

                        this.subs.sink = forkJoin([obs2$, obs4$, obs5$, obs6$, obs7$,obs8$]).pipe()
                            .subscribe({
                                next: data => {
                                    this.serviceMews = data[0];
                                    this.stpotRatePlans = data[1];
                                    this.mewsRatePlans = data[2].rates;
                                    this.resourceCategoryMews = data[3]
                                    this.produitVenduUnits = data[4];
                                    this.mappingRateInventory = data[5];

                                    setTimeout(() => {
                                        this.selectedServiceMews = this.serviceMews?.find(x=>x.id == this.mappingRateInventory.serviceId);
                                    }, 1);

                                },
                                error: err => {
                                    (err) => {
                                        this.mappingRateInventory = {};
                                        this.serviceMews = [];
                                        this.stpotRatePlans = [];
                                        this.mewsRatePlans = [];
                                    }
                                }
                            });
                    },
                    error: err => {
                        if (err.status == "404") {
                            this.hotelNotIntialized = true;
                        }
                        this.hotelCredentials = {};
                        this.mappingRateInventory = {};
                        this.serviceMews = [];
                        this.stpotRatePlans = [];
                        this.mewsRatePlans = [];
                    }
                })
        } catch (error) { }
    }


    initHotel(event): void {
        this.hotelCredentials = {
            hotCode: this.currentHotel.hotCode,
            clientToken: "",
            accessToken: "",
            rateEnabled: false,
            restrictionEnabled: false,
        };
        this.mappingRateInventory = {
            hotCode: this.currentHotel.hotCode,
            mappingInventory: [],
            mappingRatePlan: []
        };

        this.mewsRatePlans = null;
        this.stpotRatePlans = null;
        this.resourceCategoryMews = null;
        this.produitVenduUnits = null;

        this.hotelNotIntialized = false;
    }
    saveMappingComfirmation(){
        this.hotCode = null;
        this.mewsSaveDialog = true;
    }
    saveMapping() {
        this.mewsSaveDialog = false;
        if (!this.hotelCredentials) return;
        const obs1$ = this.mappingsService.apiMappingsPost(this.mappingRateInventory);
        const obs2$ = this.mappingsService.apiMappingsHotelCredentialsPost(this.hotelCredentials);

        this.mappingRateInventory.serviceId = this.selectedServiceMews?.id;
        this.hotelCredentials.serviceId = this.selectedServiceMews?.id;

        this.subs.sink = forkJoin([obs1$,obs2$])
            .subscribe({
                next: data => {

                    this.hotelCredentials.etag = data[1].etag;
                    this.mappingRateInventory.etag = data[0].etag;
                    this.notifService.setMessage(
                        `Enregistrement avec succès du Mapping `,
                        "Mews",
                        false
                    );
                this.loadData();
                },
                error: err => {
                    this.notifService.setMessage(
                        err?.error,
                        "Mapping Mews",
                        true
                    );
                }
            });
    }


    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }
}
