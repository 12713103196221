import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import {User, RoleAction, HotelAction, Group} from '../../services/model/models';
import { UserService } from '../../services/api/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';

@Component({
  selector: 'app-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.css'],
})
export class FormUserComponent implements OnInit  {
  @Input() hotels: HotelAction[];
  @Input() roles: RoleAction[];
  @Input() groups: Group[];
  @Input() isEditLoad: boolean;
  @Input() userNameList: string[];
  @Input() emailList: string[];
  @Input() infosUser: User;
  @Input() avatarProfile: string | ArrayBuffer;
  @Input() avatarSrc: string;
  @Input() cacheData: any;
  @Input() isEmailError: boolean;
  @Input() selecetedUser: User;
  @Input() selectedUserHotels: HotelAction[];
  @Input() selectedUserRoles: RoleAction[];
  @Input() selectedUserGroup: Group[];
  @Output() onValidate = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onCheckUserIfExist = new EventEmitter();
  @Output() onCopyText = new EventEmitter();
  @ViewChild('inputText') inputText: ElementRef;
  @ViewChild('inputFile') inputFile: ElementRef;
  registerForm: FormGroup;
  submitted = false;
  isSizeExceeded: boolean = false;
  isHotelselected : boolean = false;
  isCopied: boolean = false;
  userGroups: Group[];
  userHotels: HotelAction[];
  userRoles: RoleAction[] = [];
  isDefautAvatarProfile: boolean = true;
  filterValue: string;
  colsRole: any[] = [];
  isUserPasswordLoaded: boolean = false;
  displayListeRoles: boolean = false;
  lineValue: User = {};
  userPassword: string;
  sub1: Subscription;
  supportRole : RoleAction ={};
  adminRole : RoleAction ={};
  languages: { name: string, code: string, codeLanguage:string }[];
  selectedLanguage :{ name: string, code: string, codeLanguage:string } = null;
  countries: {name: string, code: string, codeCountry: string }[];
  selectedCountry :{name: string, code: string, codeCountry: string }= null ;
  isCountrySelected: boolean = false;

_saveLoading: boolean;
get saveLoading(): boolean {
    return this._saveLoading;
}
@Input() set saveLoading(value: boolean) {
    this._saveLoading = value;
}
  constructor(
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    this.breadcrumbService.setItems([{ label: 'adminusers/users' }]);
  }

  ngOnInit(): void {
    this.initForm();
    this.initModels();
    this.initDatas();
  }
  initForm() {
    this.registerForm =  this.formBuilder.group({
      givenName: ['', Validators.required],
      id: [''],
      azureADB2CId: [''],
      familyName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      mobilePhone: [null, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')],
      displayName: ['', Validators.required],
      avatarProfile: [''],
      expirationDate: [new Date()],
      accessDate: [new Date()],
    });
  }
  initDatas() {
    this.lineValue = this.selecetedUser;
    let selectedCountry =[];
     selectedCountry.push(this.countries.find(x=>x.code ==this.selecetedUser.codeCountry )) ;
    this.userRoles = this.roles;
    this.userGroups = this.groups;
    this.userHotels = this.hotels;
    this.adminRole = this.selectedUserRoles.find(role=> role.id == 'Administrator');
    this.supportRole = this.selectedUserRoles.find(role=> role.id == 'Support');
    if (this.isEditLoad) {
      this.setForm(this.selecetedUser);
    } else {
      this.registerForm.reset();
    }
  }
  initModels() {
    this.colsRole = [
      { field: 'name', header: 'Nom', width: '15px' },
      { field: 'description', header: 'Description', width: '32px' },
    ];
    this.lineValue = {};
    this.userPassword = '';
    this.submitted = false;
    this.filterValue = '';
    this.languages = [
      { name: 'Français', code: 'FR', codeLanguage:'fr' },
      { name: 'Espanõl', code: 'ES', codeLanguage:'es' },
      { name: 'English', code: 'UK', codeLanguage:'en' },
      { name: 'Italiano', code: 'IT', codeLanguage:'it' }
  ];
   this.countries= [
    { name: 'France', code: 'FR', codeCountry:'FR' },
    { name: 'Espãna', code: 'ES', codeCountry:'ES' },
    { name: 'United kingdom', code: 'UK', codeCountry:'EN' },
    { name: 'Italia', code: 'IT', codeCountry:'IT'},
    { name: 'Belgium', code: 'BE', codeCountry:'BE'},
    { name: 'Suisse', code: 'CH', codeCountry:'CH'},
    
];

  }
  userHotelsFilter(
    value: string,
    objectName: string,
    prop1: string,
    prop2?: string,
    selectionName?: string
  ) {
    let filtredArray = this.cacheData[objectName].filter(
      (elem) =>
        elem[prop1]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0 ||
        elem[prop2]?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
    );
    let sortedData = filtredArray?.sort((a, b) => {
      let isSelected = this[selectionName]?.some(
        (sel) => sel[prop1] == a[prop1]
      );
      if (isSelected) {
        return -1;
      } else {
        return 1;
      }
      return 0;
    });
    this[objectName] = sortedData;
  }
  setForm(updateValues) {
    let expirationDate = this.infosUser.expirationDate
      ? new Date(this.infosUser.expirationDate)
      : null;
    let accessDate = this.infosUser.accessDate
      ? new Date(this.infosUser.accessDate)
      : null;
       this.selectedCountry = this.countries.find(x=>x.code ==this.infosUser.codeCountry );
       this.selectedLanguage = this.languages.find(x=>x.codeLanguage ==this.infosUser.language );
       this.isDefautAvatarProfile = this.infosUser.isDefautAvatarProfile;
       this.registerForm.controls['username'].disable();
       this.registerForm.patchValue(
      {
        id: updateValues.id,
        azureADB2CId: this.infosUser.azureADB2CId,
        username: updateValues.username,
        givenName: updateValues.givenName,
        familyName: updateValues.familyName,
        email: updateValues.email,
        mobilePhone: this.infosUser.mobilePhone,
        displayName: updateValues.displayName,
        expirationDate: expirationDate,
        accessDate: accessDate,
      },
      { onlySelf: true, emitEvent: true }
    );
  }

  checkUserIfExist(event) {
    this.onCheckUserIfExist.emit(event);
  }
  setDenyUserHotel(hotel: HotelAction) {
    let selectedUserHotels = this.selectedUserHotels?.map(
      (hot: HotelAction) => {
        if (hot.hotCode == hotel.hotCode) {
          hot.deny = !hotel.deny;
        }
        return hot;
      }
    );
    setTimeout(() => {
      this.selectedUserHotels = selectedUserHotels;
    }, 1);
  }
  setDenyUserRole(role: RoleAction) {
    let selectedUserRoles = this.selectedUserRoles?.map((rol: RoleAction) => {
      if (rol.id == role.id) {
        rol.deny = !role.deny;
      }
      return rol;
    });
    setTimeout(() => {
      this.selectedUserRoles = selectedUserRoles;
    }, 1);
  }
  get f() {
    this.adminRole = this.selectedUserRoles.find(role=> role .id == 'Administrator');
    this.supportRole = this.selectedUserRoles.find(role=> role .id == 'Support')
    if(this.selectedCountry && this.selectedLanguage == null) this.selectedLanguage = this.languages.find(x=>x.code ==this.selectedCountry["code"] );
    return this.registerForm.controls;

  }
  getBase64(file): Observable<string> {
    return new Observable<string>((sub) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        sub.next(reader.result.toString());
        sub.complete();
      };
      reader.onerror = (error) => {
        sub.error(error);
      };
    });
  }
  handleProfilePictureInput(event) {
    const files = event.target.files;
    if (files && files[0].size <= 102400) {
      this.isSizeExceeded = false;
      const file = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        this.avatarProfile = fileReader.result;
        const image = new Image();
        image.src = e.target.result;
        image.onload = (img) => {
          this.avatarSrc = e.target.result;
        };
      };
      this.isDefautAvatarProfile = false;
    } else {
      this.isSizeExceeded = true;
    }
  }
  deleteImg(event) {
    this.avatarSrc = '';
    this.avatarProfile = '';
    this.isDefautAvatarProfile = true;
  }
  close() {
    this.registerForm.reset();
    this.onClose.emit(event);
  }
  cancel(event) {
    this.registerForm.reset();
    this.onCancel.emit(event);
  }
  validate(formResult) {
    this.submitted = true;
    this.isCountrySelected = this.selectedCountry == null || this.selectedCountry == undefined;
    if (this.registerForm.invalid || this.isCountrySelected) {
      return;
    }

    let validateData = {
      formResult: formResult,
      selectedUserHotels: this.selectedUserHotels,
      selectedUserRoles: this.selectedUserRoles,
      selectedUserGroup: this.selectedUserGroup,
      avatarProfile: this.avatarProfile,
      isDefautAvatarProfile: this.isDefautAvatarProfile,
      language: this.selectedLanguage.codeLanguage,
      codeCountry: this.selectedCountry.codeCountry
    };
    this.onValidate.emit(validateData);
    //this.validateUserLoading = true;
  }
  getUserPassword() {
    this.isCopied = false;
    this.isUserPasswordLoaded = false;
    if (this.lineValue) {
      let idUser = this.lineValue.id;
      if (this.sub1) {
        this.sub1.unsubscribe;
      }
      this.sub1 = this.userService
        .apiUsersIdUserPasswordGet(idUser)
        .subscribe((password: any) => {
          this.userPassword = password;
          this.isUserPasswordLoaded = true;
        });
    }
  }
  closeDisplayRole() {
    this.displayListeRoles = false;
  }
  /* To copy any Text */
  copyText(event) {
    this.onCopyText.emit(event);
    this.isCopied = true;
  }
  ngOnDestroy(): void {
    if (this.sub1 != undefined) {
      this.sub1?.unsubscribe();
    }
  }
}
