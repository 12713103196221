<div class="exception-body notfound">
    <div class="logo">
        <img src="assets/layout/images/harmony-logo.png" alt="harmony-layout"/>
        <span class="app-name">Backoffice</span>
    </div>

    <div class="exception-panel">
        <img src="assets/layout/images/template/image-404-mobile.jpg" alt="harmony-layout" class="mobile"/>
        <div class="left-part">
            <span class="exception-panel-header">Page Not Found</span>
            <span class="exception-panel-subheader">Requested resource is not available.</span>

            <button type="button" pButton label="Home" class="p-button-raised" [routerLink]="['/']"></button>
        </div>
        <div class="right-part">
            <img src="assets/layout/images/template/image-404.png" alt="harmony-layout"/>
        </div>
    </div>
</div>
