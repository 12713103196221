import { FormGroup, AbstractControl, FormArray } from '@angular/forms'
import { IControl2 } from './IControl2'
import { FormParameter } from './form-parameter'
import { FormControl2 } from './formControl2'

export class FormArray2 extends FormArray implements IControl2 {
    constructor(controls: AbstractControl[], public code: string) {
        super(controls)
    }

    formParam: FormParameter
    externalErrorMsg?: string
    visibilityConditionValue: boolean
    activationConditionValue: boolean

     update_Value_And_Validity(): void {
        //todo: 
     }
}
