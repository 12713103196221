import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.css'],
})
export class ColorSelectorComponent implements OnInit {
    @Input() formGroup: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2
    tooltipzindex=TOOL_TIP_Z_INDEX;

    constructor() {}

    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (!this.formControl.value) {
            this.formControl.setValue('#d32f2f')
        }
    }
    handleChange():void {
        this.formControl.update_Value_And_Validity();
    }
}
