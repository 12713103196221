import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { OptionConfiguration } from '../model/OptionConfiguration';
import { Events, dateCal } from '../model/dateCal';

@Component({
    selector: 'app-calendrier-mensuel',
    templateUrl: './calendrier-mensuel.component.html',
    styleUrls: ['./calendrier-mensuel.component.css'],
})
export class CalendrierMensuelComponent implements AfterViewInit, OnChanges {
    @Input() optionConfiguration: OptionConfiguration;
    @Input() events: Array<Events>;
    @Input() Mois: number;
    @Input() Annee: number;

    nombreDeJoursDansLeMois: number;
    jours: number[];
    dateMois: Array<dateCal> = [];
    eventDetails: Array<Events> = [];

    moisLibelle:string;
    moisLibelleCourt:string;

    constructor(private elementRef: ElementRef) { }

    getMonthName(): string {
        let m =this.Mois>9? moment(`${this.Annee}-${this.Mois}`).locale('fr').format('MMMM') :  moment(`${this.Annee}-0${this.Mois}`).locale('fr').format('MMMM');
        return m;
    }

    joursSemaine: string[] = [];
    ngOnInit() {
        const daysOfWeek = moment.weekdaysShort();
        this.joursSemaine = [
            daysOfWeek[1],
            daysOfWeek[2],
            daysOfWeek[3],
            daysOfWeek[4],
            daysOfWeek[5],
            daysOfWeek[6],
            daysOfWeek[0],
        ];
    }
    sunday(jour: dateCal): boolean {
        return jour.dateJour.getDay() === 0;
    }
    weekend(dateJour: Date): boolean {
        const dayOfWeek = dateJour.getDay();
        return dayOfWeek === 6 || dayOfWeek === 5;
    }
    week(dateJour: Date): boolean {
        const dayOfWeek = dateJour.getDay();
        return dayOfWeek >= 1 && dayOfWeek <= 4;
    }
    jeudi(jour: dateCal): boolean {
        const dayOfWeek = jour.dateJour.getDay();
        return jour.dateJour.getDay() === 4;
    }

    getWeekNumber(date: Date): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear =
            (date.getTime() - firstDayOfYear.getTime()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.moisLibelle= this.getMonthName()
        this.moisLibelleCourt = this.moisLibelle.slice(0, 3)

        this.setMois();

        //filter la liste this.events
        const moisSelect = moment(this.Annee + '-' + this.Mois, 'YYYY-MM');
        this.nombreDeJoursDansLeMois = moisSelect.daysInMonth();

        let eventsFiltre: Array<Events> = [];

        if (this.events) {
            for (const ev of this.events) {
                for (let j = 0; j < this.nombreDeJoursDansLeMois; j++) {
                    let dt = moisSelect.toDate();

                    dt.setDate(dt.getDate() + j);

                    if (ev.isActif(dt)) {
                        let exiingEv = eventsFiltre.find((x) => x.id == ev.id);
                        if (!exiingEv) eventsFiltre.push(ev);

                        break;
                    }
                }
            }
            this.events = eventsFiltre;
        }
    }

    setMois(): void {
        const moisSelect = moment(this.Annee + '-' + this.Mois, 'YYYY-MM');
        this.nombreDeJoursDansLeMois = moisSelect.daysInMonth();

        let premierJour = moisSelect;
        const currentDate = moment();

        for (let index = 0; index < 31; index++) {
            const dateJour = premierJour.toDate();
            const isSelected = false;
            const isCurrentDate = premierJour.isSame(currentDate, 'day');
            const moisCourant = premierJour.format('M');

            this.dateMois.push({
                dateJour,
                isSelected,
                currentDate: isCurrentDate,
                moisCourant: parseInt(moisCourant, 10),
                sunday: dateJour.getDay() === 0,
                week:this.week(dateJour),
                weekend: this.weekend(dateJour),
                dateNum:dateJour.getDate()
            });
            premierJour = premierJour.add(1, 'day');
        }
    }

    setDetailEvent(event) {
        console.log(event);
    }
    eventDetail(dateJour: Date, event: Events): boolean {
        return event.isActif(dateJour);
    }

    divWidth!: number;
    maxNumberOfDivs: number;

    // @ViewChild('tailleMois') divElement!: ElementRef<HTMLDivElement>;

    // @HostListener('window:resize')
    // onWindowResize() {
    //     this.updateDivWidth();
    // }

    ngAfterViewInit() { }

    updateDivWidth() {
        // this.divWidth = this.divElement.nativeElement.offsetWidth;
    }
}
