import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ComptaService } from 'src/app/services/bo-api';
import { CredentialsAsterio, CredentialsDetails, PipelineStatus } from 'src/app/services/bo-api';
import { firstValueFrom, throwError, forkJoin } from 'rxjs';
import { promises } from 'dns';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-asterio',
  templateUrl: './asterio.component.html',
  styleUrls: ['./asterio.component.css']
})

export class AsterioComponent implements OnInit, OnDestroy {
  currentHotel: HotelAuth;
  private subs = new SubSink();
  hotelCredentialsCompta: CredentialsAsterio = null;
  hotelPipelineStatusCompta: PipelineStatus[] = null;
  hotelComptaInitialized: boolean = false;
  hotelComptaInitializing: boolean = false;
  loading: boolean = false;
  startingCompta: boolean = false;
  stoppingCompta: boolean = false;
  savingCredentialsCompta: boolean = false;
  loadingPipelineStatusCompta: boolean = false;
  activationSteps: MenuItem[] = [
    {label: 'Test connexion'},
    {label: 'Sauvegarde des credentials'},
    {label: 'Activation'}
  ];
  activeStep: number = 0;

  /*
  Extrait les états intéressants des derniers pipelines et en fait un résumé pour l'affichage
  */
  public get hotelPipelineStatusComptaSummary() {
    if(this.hotelPipelineStatusCompta && this.hotelPipelineStatusCompta != null && this.hotelPipelineStatusCompta.length > 0) {
      const countSucceeded = this.hotelPipelineStatusCompta.filter(p => p.status == "Succeeded").length;
      const countFailed = this.hotelPipelineStatusCompta.filter(p => p.status == "Failed").length;
      const countInProgress = this.hotelPipelineStatusCompta.filter(p => p.status == "In progress" || p.status == "Queued").length;

      return {
        countSucceeded: countSucceeded,
        countFailed: countFailed,
        countInProgress: countInProgress,
        // hotelPipelineStatusCompta arrive trié par ordre de runStart décroissant
        runs: this.hotelPipelineStatusCompta
      };
    }

    return null;
  }

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private userInfoService: UserInfoService,
    private notifService: NotificationsService,
    private datePipe: DatePipe,
    private comptaService: ComptaService,
    private fb: FormBuilder
  ) {
    this.breadcrumbService.setItems([{ label: "Asterio" }]);
  }
  
  ngOnInit(): void {
    this.subs.sink = this.userInfoService
        .getNewHotel$()
        .subscribe((hotCode) => { 
            this.loading = true;
            this.loadingPipelineStatusCompta = true;
            this.startingCompta = false;
            this.stoppingCompta = false;
            this.currentHotel = hotCode;
            this.hotelComptaInitialized = false;
            this.hotelComptaInitializing = false;
            this.loadData(0);
        });
  }
  ngOnDestroy(): void {
    if (this.subs != undefined) {
        this.subs.unsubscribe(); 
    }
  }

  async loadData(step: number) : Promise<void> {
    if(!this.currentHotel || !this.currentHotel.hotCode) {
      this.hotelCredentialsCompta = null;
      this.hotelPipelineStatusCompta = null;
    }
    else {
      try {
        //pas d'await sur loadpipelines qui peut être long et est lancé en arrière-plan
        this.loadDataPipelines();
        this.hotelCredentialsCompta = await firstValueFrom(this.comptaService.apiComptaAsterioGet(this.currentHotel.hotCode));
      }
      catch {
        this.hotelCredentialsCompta = null;
        this.hotelPipelineStatusCompta = null;
      }
    }

    this.activeStep = step < 0 ? 0 : step;
    this.hotelComptaInitialized = this.hotelCredentialsCompta != null && this.hotelCredentialsCompta.hotCode.trim() != '';
    if(step <= 0) {
      this.hotelComptaInitializing = false;
    }
    this.loading = false;
    this.startingCompta = false;
    this.stoppingCompta = false;
    this.savingCredentialsCompta = false;
  }

  async loadDataPipelines() : Promise<void> {
    try {
      this.loadingPipelineStatusCompta = true;

      const result = await firstValueFrom(this.comptaService.apiComptaAsterioPipelineStatusGet(this.currentHotel.hotCode, 2));
      if(result && result.length > 0 && result[0].hotCode == this.currentHotel.hotCode && this.hotelCredentialsCompta && this.hotelCredentialsCompta.hotCode == this.currentHotel.hotCode) {
        this.hotelPipelineStatusCompta = result;
      }
    }
    catch {
      this.hotelPipelineStatusCompta = null;
    }
    finally {
      this.loadingPipelineStatusCompta = false;
    }
  }

  initHotelCompta($event): void {
    this.hotelCredentialsCompta = {
      hotCode: this.currentHotel.hotCode,
      idPms: "",
      idSpot: this.currentHotel.hotCode,
      pms: "Asterio",
      typeInformation: "HOT_CODE",
      credentialsDetails: {
        kmlCode: "",
        login: "",
        password: "",
        siteCode: ""
      }
    };
    this.hotelComptaInitializing = true;
  }

  modifyCredentialsCompta(): void {
    this.hotelComptaInitializing = true;
  }

  validateCompta(credentialsAsterio : CredentialsAsterio) : boolean {
    return this.hotelCredentialsCompta != null
      && this.hotelCredentialsCompta.credentialsDetails != null
      && (Boolean)(this.hotelCredentialsCompta.credentialsDetails.kmlCode
        && this.hotelCredentialsCompta.credentialsDetails.login
        && this.hotelCredentialsCompta.credentialsDetails.password
        && this.hotelCredentialsCompta.credentialsDetails.siteCode);
  }

  async testConnectionCompta(): Promise<void> { 
    this.savingCredentialsCompta = true;
    try
    {
      var rep = await firstValueFrom(this.comptaService.apiComptaAsterioTestConnectionPost(
        this.currentHotel.hotCode,
        this.hotelCredentialsCompta.credentialsDetails.kmlCode,
        this.hotelCredentialsCompta.credentialsDetails.login,
        this.hotelCredentialsCompta.credentialsDetails.password,
        this.hotelCredentialsCompta.credentialsDetails.siteCode
      ));

      this.activeStep = this.activeStep + 1;
      this.notifService.setMessage(`Connexion réussie pour l'hôtel ${this.currentHotel.name}`, "Asterio Compta/POS", false);
    }
    catch(ex) {
      console.log(ex.error);
      this.notifService.setMessage(`Erreur de Connexion : ${ex.error}`, "Asterio Compta/POS", true);
    }
    finally
    {
      this.savingCredentialsCompta = false;
    }
  }

  async saveCredentialsCompta() : Promise<void> {
    if(!this.hotelCredentialsCompta?.hotCode || !this.hotelCredentialsCompta.credentialsDetails?.kmlCode){
      return;
    }

    this.savingCredentialsCompta = true;
    try
    {
      var result = await firstValueFrom(this.comptaService.apiComptaAsterioSaveCredentialsPost(
        this.currentHotel.hotCode,
        this.hotelCredentialsCompta.credentialsDetails.kmlCode,
        this.hotelCredentialsCompta.credentialsDetails.login,
        this.hotelCredentialsCompta.credentialsDetails.password,
        this.hotelCredentialsCompta.credentialsDetails.siteCode
      ));

      await this.loadData(this.activeStep + 1);
      this.notifService.setMessage(`Démarrage de l'hôtel ${this.currentHotel.name} réussi`, "Asterio Compta/POS", false);
    }
    catch(ex) {
      console.log(ex.error);
      this.notifService.setMessage(`Erreur de sauvegarde : ${ex.error}`, "Asterio Compta/POS", true);
    }
    finally
    {
      this.savingCredentialsCompta = false;
    }
  }

  async activateCompta(): Promise<void> { 
    this.savingCredentialsCompta = true;
    try
    {
      var rep = await firstValueFrom(this.comptaService.apiComptaAsterioStartPipelineInitPost(
        this.currentHotel.hotCode
      ));

      await this.loadData(0);
      this.notifService.setMessage(`Démarrage de l'activation réussie pour l'hôtel ${this.currentHotel.name}`, "Asterio Compta/POS", false);
    }
    catch(ex) {
      console.log(ex.error);
      this.notifService.setMessage(`Erreur d'activation' : ${ex.error}`, "Asterio Compta/POS", true);
    }
    finally
    {
      this.savingCredentialsCompta = false;
    }
  }

  back(): void {
    if(this.hotelComptaInitializing && this.activeStep == 0) {
      this.hotelComptaInitializing = false;
    }
    else if(this.hotelComptaInitializing && this.activeStep == 1) {
      this.activeStep = 0;
    }
    else if(this.hotelComptaInitializing && this.activeStep == 2) {
      this.activeStep = 1;
    }
  }
}
