<p-confirmPopup></p-confirmPopup>

<p-toolbar>
    <div class="p-toolbar-group-left">
        <p-button
            label="Nouveau WorkSpace"
            icon="pi pi-plus"
            class="mr-1"
            (onClick)="AjouterUnWorkspaceVisible()"
            [disabled]="!isAdmin()"
        ></p-button>
        <p-button
            label="Supprimer le WorkSpace"
            icon="pi pi-times"
            styleClass="p-button-danger"
            class="mr-1"
            [disabled]="suppWorkspaceDialogVisible || !isAdmin()"
            (onClick)="confirmSuppWorkspace($event)"
            pTooltip="Pour supprimer un Workspace ce dernier ne doit contenir aucun rapport."
            tooltipPosition="bottom"
            [loading]="loadingSuppWorkspace"
        ></p-button>
        <p-button
            label="Ajouter un utilisateur"
            icon="pi pi-plus"
            class="mr-1"
            (onClick)="AjouterUnUser()"
            [disabled]="!isAdmin()"
        ></p-button>
        <p-button
            label="Supprimer l'utilisateur"
            icon="pi pi-times"
            styleClass="p-button-danger"
            class="mr-1"
            [disabled]="!isUserSelected || !isAdmin()"
            (onClick)="confirmSuppUser($event)"
            [loading]="loadingSuppUser"
        ></p-button>
    </div>
</p-toolbar>

<div class="placement" style="background-color: transparent">
    <p-panel header="Workspaces">
        <div *ngIf="loadingNameWorkspace" style="height: 500px">
            <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
        </div>
        <div *ngIf="!loadingNameWorkspace">
            <p-listbox
                [options]="workspace"
                [(ngModel)]="selectedWorkspace"
                (onClick)="editSelectedWorkspace(selectedWorkspace)"
                optionValue="id"
                optionLabel="name"
                [style]="{
                    width: '100%',
                    height: '500px',
                    'overflow-y': 'scroll'
                }"
            ></p-listbox>
        </div>
    </p-panel>
    <p-panel header="Info Workspace">
        Role
        <div *ngIf="loadingInfoWorkspace" style="margin-bottom: 1vw">
            <div class="lisderoulante">
                <p-dropdown
                    placeholder="Chargement en cours"
                    [style]="{
                        width: '100%'
                    }"
                ></p-dropdown>
                <p-button
                    label="Sauvegarder le rôle"
                    styleClass="p-button-success"
                    class="mr-1"
                    [style]="{
                        display: 'block',
                        width: '100%',
                        margin: '0 5%'
                    }"
                    [disabled]="!isAdmin()"
                ></p-button>
            </div>
        </div>
        <div *ngIf="!loadingInfoWorkspace">
            <div class="lisderoulante">
                <p-dropdown
                    *ngIf="currentRole !== null; else defaultOption"
                    [options]="role"
                    optionLabel="name"
                    optionValue="id"
                    [(ngModel)]="selectedRole"
                    [style]="{
                        width: '100%'
                    }"
                    [showClear]="true"
                    (onClear)="handleClearRole()"
                ></p-dropdown>
                <ng-template #defaultOption>
                    <p-dropdown
                        placeholder="{{ descriptionWorkspace }}"
                        [options]="role"
                        optionLabel="name"
                        optionValue="id"
                        [(ngModel)]="selectedRole"
                        [style]="{
                            width: '100%'
                        }"
                    ></p-dropdown>
                </ng-template>

                <p-button
                    label="Sauvegarder le rôle"
                    styleClass="p-button-success"
                    class="mr-1"
                    (onClick)="roleSave()"
                    [style]="{
                        display: 'block',
                        width: '100%',
                        margin: '0 5%'
                    }"
                    [disabled]="!isAdmin()"
                ></p-button>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <p-panel header="Rapports">
                    <div *ngIf="loadingInfoWorkspace" style="height: 375px">
                        <p-progressSpinner
                            styleClass="w-4rem h-4rem"
                        ></p-progressSpinner>
                    </div>
                    <div *ngIf="!loadingInfoWorkspace">
                        <p-listbox
                            [options]="rapport"
                            optionLabel="name"
                            [style]="{
                                width: '100%',
                                height: '375px',
                                'overflow-y': 'scroll'
                            }"
                        ></p-listbox>
                    </div>
                </p-panel>
            </div>
            <div class="col">
                <p-panel header="Users">
                    <div *ngIf="loadingInfoWorkspace" style="height: 375px">
                        <p-progressSpinner
                            styleClass="w-4rem h-4rem"
                        ></p-progressSpinner>
                    </div>
                    <div *ngIf="!loadingInfoWorkspace">
                        <p-listbox
                            [options]="user"
                            [(ngModel)]="userSeleced"
                            optionLabel="displayName"
                            (onClick)="updateIsUsertSelected($event)"
                            [style]="{
                                width: '100%',
                                height: '375px',
                                'overflow-y': 'scroll'
                            }"
                        ></p-listbox>
                    </div>
                </p-panel>
            </div>
        </div>
    </p-panel>
</div>

<div *ngIf="addUserDialogVisible">
    <div>
        <p-dialog
            [(visible)]="addUserDialogVisible"
            [modal]="true"
            [style]="{
                width: '450px',
                height: '500px',
                'text-align': 'center'
            }"
        >
            <p-panel
                header="Ajouter un utilisateur au workspace {{ workspaceName }}"
            >
                <div style="margin-bottom: 1rem">
                    <p-listbox
                        [options]="filterUsers()"
                        optionLabel="displayName"
                        [checkbox]="true"
                        [filter]="true"
                        [style]="{
                            height: '300px',
                            'overflow-y': 'scroll'
                        }"
                        [(ngModel)]="addSelectedUsers"
                    ></p-listbox>
                </div>
                <p-button
                    label="valider"
                    icon="pi pi-plus"
                    styleClass="p-button-success"
                    class="mr-1"
                    (click)="addUserInWorkspace($event)"
                    [disabled]="selectedUserIds"
                    [loading]="loadingAddUser"
                ></p-button>
            </p-panel>
        </p-dialog>
    </div>
</div>

<div *ngIf="addWorkspaceVisible">
    <div>
        <p-dialog
            [(visible)]="addWorkspaceVisible"
            [modal]="true"
            [style]="{
                width: '20vw',
                height: 'auto',
                'text-align': 'center'
            }"
        >
            <p-panel header="Créer un nouveau Workspace">
                <th>Nom du workspace: <span class="etoile">*</span></th>
                <input
                    type="text"
                    pInputText
                    [(ngModel)]="nomNouveauWorkspace"
                    placeholder="Nom du Workspace"
                    style="width: 100%"
                    [ngClass]="
                        isFormInvalid && !nomNouveauWorkspace
                            ? 'ng-dirty ng-invalid'
                            : ''
                    "
                />
                <div
                    *ngIf="
                        isFormInvalid &&
                        !nomNouveauWorkspace &&
                        addWorkspaceVisible
                    "
                    class="ng-invalid ng-dirty"
                    style="text-align: left"
                >
                    <small id="" class="p-error"
                        >Nom du workspace requis
                    </small>
                </div>
                <div style="margin: 1rem 0">
                    <th>
                        Utilisateur à ajouter au workspace:
                        <span class="etoile">*</span>
                    </th>

                    <p-listbox
                        [options]="filterUsersInCreationWorkspace()"
                        optionLabel="displayName"
                        [filter]="true"
                        [style]="{
                            height: '14vw',
                            'overflow-y': 'scroll'
                        }"
                        [(ngModel)]="createWorkspaceWithSelectedUsers"
                        [ngClass]="
                            isFormInvalid && !createWorkspaceWithSelectedUsers
                                ? 'ng-dirty ng-invalid'
                                : ''
                        "
                    ></p-listbox>
                    <div
                        *ngIf="
                            isFormInvalid && !createWorkspaceWithSelectedUsers
                        "
                        class="ng-invalid ng-dirty"
                        style="text-align: left"
                    >
                        <small id="" class="p-error"
                            >L'ajout d'un utilisateur est requis
                        </small>
                    </div>
                </div>
                <p-button
                    label="valider"
                    icon="pi pi-plus"
                    styleClass="p-button-success"
                    class="mr-1"
                    (click)="addWorkspace()"
                    [loading]="loadingAddWorkspace"
                ></p-button>
            </p-panel>
        </p-dialog>
    </div>
</div>
