<div style="overflow: hidden;">
    <div class="debug" *ngIf="debug">
        <!-- <div>status = {{ formGroup2.status }}</div> -->
        <div>dirty = {{ formGroup2.dirty }}</div>
        <div>touched = {{ formGroup2.touched }}</div>
        <div>Validation = {{ formGroup2.status }}</div>
        <div>json = {{ formGroup2.value | json}}</div>
        <hr>
    </div>

    <div>
        <span style="color:red">{{globalError}}</span>
    </div>

    <div [formGroup]="formGroup2" *ngIf="isFormVisible">
        <app-form-renderer [formGroup2]="formGroup2"> </app-form-renderer>
    </div>

    <div class="toolbar-grid mt3" *ngIf="formModel?.toolBar.enabled">
        <div></div>
        <div class="mr3">
            <p-button [label]="formModel?.toolBar.btnSubmitLabel" icon="pi pi-check" (click)="validate($event)"
                class="formValidate" [style]="{width:'100%'}"></p-button>
        </div>
        <div>
            <p-button [label]="formModel?.toolBar.btnCancelLabel" icon="pi pi-times" (click)="cancel($event)"
                class="p-button-secondary formCancel" [style]="{width:'100%'}"></p-button>
        </div>
    </div>
</div>
