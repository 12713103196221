import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.css'],
})
export class PriceComponent implements OnInit {
    @Input() formGroup: FormGroup2;
    @Input() field: FormParameter;
    formControl: FormControl2;
    tooltipzindex=TOOL_TIP_Z_INDEX;

    constructor() {}

    ngOnInit() {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        // if (!this.formControl.value) this.formControl.setValue(null);

        if (
            (!this.formControl.value && this.formControl.value != 0) ||
            this.formControl.value === ''
        ) {
            this.formControl.setValue(null)
        }
    }
    onInput(event) {
        this.formControl.update_Value_And_Validity();
    }
}
