import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CalendarModule } from 'primeng/calendar'
import { TooltipModule } from 'primeng/tooltip'
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message'
import { PanelModule } from 'primeng/panel'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { FormDynamicComponent } from './form-dynamic/form-dynamic.component'
import { InputNumberComponent } from './rendered-components/input-number/input-number.component'
import { InputTextComponent } from './rendered-components/input-text/input-text.component'
import { SelectComponent } from './rendered-components/select/select.component'
import { ColoredRadioButtonGroupComponent } from './rendered-components/colored-radio-button-group/colored-radio-button-group.component'
import { DatetimeComponent } from './rendered-components/datetime/datetime.component'
import { CheckboxButtonGroupComponent } from './rendered-components/checkbox-button-group/checkbox-button-group.component'
import { RadioGroupComponent } from './rendered-components/radio-group/radio-group.component'
import { VgroupDynamicComponent } from './form-group/vgroup-dynamic/vgroup-dynamic.component'
import { FormRendererComponent } from './form-renderer/form-renderer.component'
import { HgroupDynamicComponent } from './form-group/hgroup-dynamic/hgroup-dynamic.component'
import { SwitchComponent } from './rendered-components/switch/switch.component'
import { DuplicateButtonComponent } from './rendered-components/duplicate-button/duplicate-button.component'
import { AccordionModule } from 'primeng/accordion'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ButtonModule } from 'primeng/button'
import { CardModule } from 'primeng/card'
import { CarouselModule } from 'primeng/carousel'
// import { ChartModule } from "primeng/chart";
import { CheckboxModule } from 'primeng/checkbox'
import { ChipsModule } from 'primeng/chips'
import { CodeHighlighterModule } from 'primeng/codehighlighter'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ColorPickerModule } from 'primeng/colorpicker'
import { ContextMenuModule } from 'primeng/contextmenu'
import { DataViewModule } from 'primeng/dataview'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { EditorModule } from 'primeng/editor'
import { FieldsetModule } from 'primeng/fieldset'
import { FileUploadModule } from 'primeng/fileupload'
// import { FullCalendarModule } from "primeng/fullcalendar";
import { GalleriaModule } from 'primeng/galleria'
import { InplaceModule } from 'primeng/inplace'
import { InputMaskModule } from 'primeng/inputmask'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { LightboxModule } from 'primeng/lightbox'
import { ListboxModule } from 'primeng/listbox'
import { MegaMenuModule } from 'primeng/megamenu'
import { MenuModule } from 'primeng/menu'
import { MenubarModule } from 'primeng/menubar'
import { MultiSelectModule } from 'primeng/multiselect'
import { OrderListModule } from 'primeng/orderlist'
// import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { PaginatorModule } from 'primeng/paginator'
import { PanelMenuModule } from 'primeng/panelmenu'
import { PasswordModule } from 'primeng/password'
import { PickListModule } from 'primeng/picklist'
import { ProgressBarModule } from 'primeng/progressbar'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RatingModule } from 'primeng/rating'
import { ScrollPanelModule } from 'primeng/scrollpanel'
import { SelectButtonModule } from 'primeng/selectbutton'
import { SlideMenuModule } from 'primeng/slidemenu'
import { SliderModule } from 'primeng/slider'
import { SpinnerModule } from 'primeng/spinner'
import { SplitButtonModule } from 'primeng/splitbutton'
import { StepsModule } from 'primeng/steps'
import { TableModule } from 'primeng/table'
import { TabMenuModule } from 'primeng/tabmenu'
import { TabViewModule } from 'primeng/tabview'
import { TerminalModule } from 'primeng/terminal'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { ToastModule } from 'primeng/toast'
import { ToggleButtonModule } from 'primeng/togglebutton'
import { ToolbarModule } from 'primeng/toolbar'
import { TreeModule } from 'primeng/tree'
import { TreeTableModule } from 'primeng/treetable'

import { InputNumberModule } from 'primeng/inputnumber'
import { CheckBoxComponent } from './rendered-components/check-box/check-box.component'
import { PriceComponent } from './rendered-components/price/price.component'
import { ColorSelectorComponent } from './rendered-components/color-selector/color-selector.component'
import { ScenariosSelectorComponent } from './rendered-components/scenarios-selector/scenarios-selector.component'
import { AvatarGroupModule } from 'primeng/avatargroup'
import { AvatarModule } from 'primeng/avatar'
import { BadgeModule } from 'primeng/badge'
import { CascadeSelectModule } from 'primeng/cascadeselect'
import { ChartModule } from 'primeng/chart'
import { ChipModule } from 'primeng/chip'
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { DividerModule } from 'primeng/divider'
import { ImageModule } from 'primeng/image'
import { KnobModule } from 'primeng/knob'
import { OrganizationChartModule } from 'primeng/organizationchart'
import { RippleModule } from 'primeng/ripple'
import { ScrollTopModule } from 'primeng/scrolltop'
import { SidebarModule } from 'primeng/sidebar'
import { SkeletonModule } from 'primeng/skeleton'
import { SplitterModule } from 'primeng/splitter'
import { TagModule } from 'primeng/tag'
import { TimelineModule } from 'primeng/timeline'
import { VirtualScrollerModule } from 'primeng/virtualscroller'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ColumnGroupComponent } from './form-group/column-group/column-group.component';
import { MultiSelectComponent } from './rendered-components/multi-select/multi-select.component';
import { MultiCheckBoxComponent } from './rendered-components/multi-check-box/multi-check-box.component';
import { HiddenTextComponent } from './rendered-components/hidden-text/hidden-text.component';
import { TabviewGroupComponent } from './form-group/tabview-group/tabview-group.component';
import { CheckBoxGroupComponent } from './rendered-components/check-box-group/check-box-group.component';
import { ListBoxComponent } from './rendered-components/list-box/list-box.component';

@NgModule({
    declarations: [
        FormDynamicComponent,
        InputNumberComponent,
        InputTextComponent,
        SelectComponent,
        ColoredRadioButtonGroupComponent,
        DatetimeComponent,
        CheckboxButtonGroupComponent,
        RadioGroupComponent,
        VgroupDynamicComponent,
        FormRendererComponent,
        HgroupDynamicComponent,
        SwitchComponent,
        DuplicateButtonComponent,
        CheckBoxComponent,
        PriceComponent,
        ColorSelectorComponent,
        ScenariosSelectorComponent,
        ColumnGroupComponent,
        MultiSelectComponent,
        MultiCheckBoxComponent,
        HiddenTextComponent,
        TabviewGroupComponent,
        CheckBoxGroupComponent,
        ListBoxComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        ReactiveFormsModule,
    ],

    exports: [FormDynamicComponent],
})
export class SPDynamicFormsModule {}
