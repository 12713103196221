import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { RateShopperConfigService, RateShopperService } from '../../services/api/api';
import { RateShopperHotelAllPrices, RateShopperPrice } from '../../services/model/models';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';

@Component({
  selector: 'app-rate-shopper-prices',
  templateUrl: './rate-shopper-prices.component.html',
  styleUrls: ['./rate-shopper-prices.component.css']
})
export class RateShopperPricesComponent implements OnInit, OnDestroy {

  sub1: Subscription;
  sub2: Subscription;
  currentHotel: HotelAuth;
  dataLoaded: boolean;
  dataError: boolean;
  prices: RateShopperHotelAllPrices;
  dateStart: Date;
  dateEnd: Date;
  dateRanges: Date[];

  constructor(private rateShopperConfigService: RateShopperConfigService,
    private rateShopperService: RateShopperService,
    private messageService: MessageService,
    private userInfoService: UserInfoService , private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([{ label: "rateshopper/prix" }]);
    }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  ngOnInit(): void {
    let currentDate = new Date();

    // currentDate.setDate(currentDate.getDate() - 80);

    this.dateStart = new Date(currentDate);
    this.dateStart.setUTCHours(0, 0, 0);

    this.dateEnd = new Date(currentDate);
    this.dateEnd.setDate(this.dateEnd.getDate() + 20);
    this.dateEnd.setUTCHours(0, 0, 0, 0);

    this.dateRanges = this.getDates(this.dateStart, this.dateEnd);
    console.log(this.dateRanges);

    console.log(this.dateStart, this.dateEnd);

    this.sub1 = this.userInfoService.getNewHotel$().subscribe(hotel => {
      setTimeout(() => {
        this.currentHotel = hotel;
        this.dataLoaded = false;
        this.dataError = false;
        this.loadData();
      }, 100);
    });
  }

  showErrorDataAccessMessage() {
    this.messageService.add({
      severity: 'error',
      summary: 'RateShopper',
      detail: "Echec d'accès aux données!", life: 5000
    });
  }

  loadData() {
    this.sub2 = this.rateShopperService.apiHotCodeAllpricesGet(this.currentHotel.hotCode, this.dateStart, this.dateEnd).subscribe({
      next: (prices) => {
      this.prices = prices;
      this.dataLoaded = true;
      this.dataError = false;
      console.log(this.prices);
    }, error:(err) => {
      this.showErrorDataAccessMessage();
      this.dataError = true;
      this.dataLoaded = false;
    }})
  }



  getDates(startDate: Date, stopDate: Date): Array<Date> {
    var dateArray = new Array<Date>();
    var currentDate = new Date(startDate);
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1)
    }
    dateArray.push(new Date(currentDate));
    return dateArray;
  }

  getFormatTh(date: Date): string {
    return date?.getDate() + "/" + (date?.getMonth() + 1);
  }

  getPrice(price: RateShopperPrice): number {
    if (price.pricePromo && price.priceBar && price.pricePromo < price.priceBar) return price.pricePromo;
    if (price.pricePromo && !price.priceBar) return price.pricePromo;
    return price.priceBar;
  }


  isPromo(price: RateShopperPrice): boolean {
    if (price.pricePromo && price.priceBar && price.pricePromo < price.priceBar) return true;
    if (price.pricePromo && !price.priceBar) return true
    return false;
  }
  isFull(price: RateShopperPrice): boolean {
    return price.status=="NotFound"
  }

}


