<div [class]="'form-component-' + field.code">
  <div class="p-fluid" [formGroup]="formGroup">
    <label [for]="field.code">{{ field.label }}
      <span *ngIf="field.required && field.label" class="fd-required">*</span>
    </label>

    <div [ngSwitch]="field.type" style="margin-bottom: 1em;position: relative">
      <ng-container *ngSwitchCase="'Time'">
        <p-calendar [formControlName]="field.code" [id]="field.code" [locale]="fr" [pTooltip]="field.toolTip"
          [placeholder]="field.placeholder" [timeOnly]="true" dataType="string" hourFormat="24"
          (keydown)="onKeydown($event)" tooltipZIndex="1000000" appendTo="body" baseZIndex="90000">
        </p-calendar>
      </ng-container>

      <!-- todo : pour le composant à la racine  width =100%   -->
      <ng-container *ngSwitchCase="'DateTime'">
        <p-calendar [formControlName]="field.code" [id]="field.code" [placeholder]="field.placeholder"
          [pTooltip]="field.toolTip" showIcon="true" dateFormat="dd/mm/yy" [minDate]="getMinDate()" showTime="true"
          (keydown)="onKeydown($event)" tooltipZIndex="1000000" appendTo="body" baseZIndex="90000">
        </p-calendar>
      </ng-container>

      <ng-container *ngSwitchCase="'Date'">
        <p-calendar [formControlName]="field.code" [id]="field.code" [placeholder]="field.placeholder" showIcon="true"
          dateFormat="dd/mm/yy" (onSelect)="onKeydown($event)" [pTooltip]="field.toolTip" tooltipPosition="top"
          [tooltipZIndex]="tooltipzindex" appendTo="body">
        </p-calendar>
      </ng-container>

      <ng-container *ngSwitchCase="'DateMultiple'">
        <p-calendar [readonlyInput]="true" selectionMode="multiple" [numberOfMonths]="2" [pTooltip]="field.toolTip"
          [formControlName]="field.code" [placeholder]="field.placeholder" (onSelect)="onKeydown($event)"
          showIcon="true" [tooltipZIndex]="tooltipzindex" dateFormat="dd/mm/yy" appendTo="body">
        </p-calendar>
      </ng-container>

      <div class="fd-errorText">
        <small> {{ formControl?.getErrorsMessage()}} </small>
      </div>
    </div>
  </div>
</div>