<div class="layout-topbar" [ngStyle]="{'background-image' : (!environment.production ? 'linear-gradient(to right, #a23069, #df1111)' : '')}">

    <a href="/" class="logo-container">
        <img class="logo" alt="Eaxess" src="assets/layout/images/e-axess-logo.png" />
        <span class="app-name">BACKOFFICE</span>
        <div class="versionApp">{{getVersion()}}</div>
    </a>


    <a href="#" id="topbar-sidebar-button" (click)="app.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
    </a>
    <div>
        <app-top-bar-hotels *ngIf="checkAccount()"></app-top-bar-hotels>

    </div>


    <div id="bo12">
       <i *ngIf="getCount() != 0" class="pi pi-bell" pBadge value="{{getCount()}}" severity="danger" style="font-size: 1.5rem"href="#" (click)="app.onRightPanelButtonClick($event)" ></i>
       <i *ngIf="getCount() == 0" class="pi pi-bell" href="#" (click)="app.onRightPanelButtonClick($event)" ></i>
    </div>

    <div id="bo11">
        <span id="userName">{{getUserName()}}</span>
        <a id="userimg" (click)="menu.toggle($event)">
            <img [src]="avatar" alt="" style="border-radius: 25px;" height="50" width="50" class="bo-topbar-profile" />
        </a>
    </div>

    <div id="rightPanel">
        <a id="userimg" href="#" (click)="app.onRightPanelButtonClick1($event)">
            <i class="pi pi-ellipsis-v" style="font-size: 28px;"></i>
        </a>

    </div>
</div>
<p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
