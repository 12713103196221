import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MessageService } from "primeng/api";
import { forkJoin, Subscription } from "rxjs";
import { FormDynamicComponent } from "src/app/dynamicForms/form-dynamic/form-dynamic.component";
import { FormConfig } from "src/app/dynamicForms/models/FormConfig";
import { RateShopperConfigService } from "../../services/api/api";
import { Hotel } from "../../services/model/hotel";
import * as FileSaver from 'file-saver';
import { HotelForCreation } from "../../services/model/hotelForCreation";
import { HotelForUpdate } from "../../services/model/hotelForUpdate";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ExportTablesService } from "src/app/services/exports/export-tables.service";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";

@Component({
    selector: "app-rate-shopper-hotels",
    templateUrl: "./rate-shopper-hotels.component.html",
    styleUrls: ["./rate-shopper-hotels.component.css"],
})
export class RateShopperHotelsComponent implements OnInit, OnDestroy {
    hotels: Array<Hotel>;
    selectedHotels: Hotel;
    cols: any[] = [];

    lineValue: HotelForUpdate;
    displayHotelForm: boolean;
    hotelFormsModel: FormConfig;
    dataLoaded: boolean;

    @ViewChild(FormDynamicComponent) formDynamicCompoment: FormDynamicComponent;

    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;
    constructor(
        private hotelsService: RateShopperConfigService,
        private notifService: NotificationsService,
        private exportTablesService: ExportTablesService,
        private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([{ label: "rateshopper/hôtels" }]);
    }


    ngOnInit(): void {
        this.cols = [
            { field: "rateShopperId", header: "Id", width: "15px" },
            { field: "eaxessId", header: "EaxessId", width: "15px" },
            { field: "hotCode", header: "HotCode", width: "10px" },
            { field: "name", header: "Nom", width: "40px" },
            { field: "address", header: "adresse", width: "28px" },
            { field: "city", header: "Ville", width: "15px" },
            { field: "bookingUrl", header: "Url booking", width: "60px" },
        ];

        const obs1$ = this.hotelsService.apiHotelsGet();
        const obs2$ = this.hotelsService.apiHotelsHotelformGet();

        this.sub3 = forkJoin([obs1$, obs2$]).subscribe({
            next: (data) => {
                this.hotels = data[0];
                this.hotelFormsModel = JSON.parse(JSON.stringify(data[1])) as FormConfig;
                this.dataLoaded = true;
            }, error: err => {
                this.showErrorDataAccessMessage();
            }
        });
    }

    showErrorDataAccessMessage() {
        this.notifService.setMessage("Echec d'accès aux données!", "RateShopper", true)
    }

    onRowSelect(event) {
        console.log(this.selectedHotels);
    }

    newHotel() {
        if (this.dataLoaded) {
            this.lineValue = null;
            this.displayHotelForm = true;
        }
    }

    toHotelForCreation(hotel: Hotel): HotelForCreation {
        return {
            address: {
                line1: hotel?.address,
                city: hotel?.city,
                zipCode: hotel?.zipCode
            },
            bookingName: hotel?.bookingName,
            bookingUrl: hotel?.bookingUrl,
            expediaUrl: hotel?.expediaUrl,
            identifiers: {
                eaxessId: hotel?.eaxessId,
                hotCode: hotel?.hotCode
            },
            name: hotel?.name,
            tripadvisorUrl: hotel?.tripadvisorUrl,
            googleUrl: hotel?.googleUrl,
            accorUrl: hotel?.accorUrl
        };
    }

    toHotelForUpdate(hotel: Hotel): HotelForUpdate {
        return {
            address: {
                line1: hotel?.address,
                city: hotel?.city,
                zipCode: hotel?.zipCode
            },
            bookingName: hotel?.bookingName,
            bookingUrl: hotel?.bookingUrl,
            expediaUrl: hotel?.expediaUrl,
            identifiers: {
                eaxessId: hotel?.eaxessId,
                hotCode: hotel?.hotCode
            },
            name: hotel?.name,
            tripadvisorUrl: hotel?.tripadvisorUrl,
            googleUrl: hotel?.googleUrl,
            accorUrl: hotel?.accorUrl,
            id: hotel?.id

        };
    }


    editHotel() {
        if (this.dataLoaded && this.selectedHotels) {
            this.lineValue = this.toHotelForUpdate(this.selectedHotels);
            this.displayHotelForm = true;
        }
    }

    desableHotel() {
        if (this.dataLoaded && this.selectedHotels) {
            this.hotelsService.apiHotelsToggleStatePut(this.selectedHotels.rateShopperId).subscribe({
                next: () => {

                    this.selectedHotels.isDisabled = !this.selectedHotels.isDisabled;
                    this.notifService.setMessage(`Hôtel ${this.selectedHotels.name} déactivé`, 'Rate Shopper Hotels', false);
                }, error: err => {
                    this.notifService.setMessage(`Echec de modification de  l'hôtel: ${this.selectedHotels.name} `, 'Rate Shopper Hotels', true);
                }
            })
        };
    }
    validate(formResult): void {

        if (this.lineValue != null) {  //update
            this.updateHotel(this.lineValue, formResult);
        }
        else {
            let newHotel: HotelForCreation = formResult;
            this.createHotel(newHotel);
            console.log(JSON.stringify(newHotel));
        }
    }


    getHotelById(id: string): Hotel {
        for (let index = 0; index < this.hotels.length; index++) {
            const element = this.hotels[index];
            if (element.id == id) return element;
        }
        return null;
    }

    createHotel(hotelForCreation: HotelForCreation) {

        this.sub5 = this.hotelsService.apiHotelsPost(hotelForCreation).subscribe({
            next: newhotelfromDb => {

                this.notifService.setMessage(`Créaton l'hôtel ${newhotelfromDb.name}`, 'Rate Shopper Hotels', false);
                this.hotels.push(newhotelfromDb);
                this.displayHotelForm = false;
            }, error: err => {
                this.formDynamicCompoment.setExternalErrors(err.error);
                this.notifService.setMessage("Echec de création de l'hôtel:" + hotelForCreation.name, 'Rate Shopper Hotels', true);
            }
        });
    }
    updateHotel(hotelForUpdate: HotelForUpdate, newValues: any) {

        if (hotelForUpdate?.id) newValues.id = hotelForUpdate.id;
        this.sub4 = this.hotelsService.apiHotelsPut(newValues).subscribe({
            next: hotelfromDb => {
                this.notifService.setMessage(`Mise à jour avec succès de l'hôtel ${hotelForUpdate.name}`, 'Rate Shopper Hotels', false);

                const hotel = this.getHotelById(hotelfromDb.id);

                if (hotelfromDb && hotel) {
                    hotel.address = hotelfromDb.address;
                    hotel.bookingName = hotelfromDb.bookingName;
                    hotel.city = hotelfromDb.city;
                    hotel.eaxessId = hotelfromDb.eaxessId;
                    hotel.expediaUrl = hotelfromDb.expediaUrl;
                    hotel.hotCode = hotelfromDb.hotCode;
                    hotel.isDisabled = hotelfromDb.isDisabled;
                    hotel.bookingUrl = hotelfromDb.bookingUrl;
                    hotel.name = hotelfromDb.name;
                    hotel.rateShopperId = hotelfromDb.rateShopperId;
                    hotel.tripadvisorUrl = hotelfromDb.tripadvisorUrl;
                    hotel.updateDate = hotelfromDb.updateDate;
                    hotel.zipCode = hotelfromDb.zipCode;
                    hotel.googleUrl = hotelfromDb.googleUrl;
                    hotel.accorUrl = hotelfromDb.accorUrl;
                }
                this.displayHotelForm = false;
            },
            error: err => {
                this.formDynamicCompoment.setExternalErrors(err.error);
                this.notifService.setMessage(err?.error, 'Rate Shopper Hotels', true);
            }
        });
    }


    cancel(event): void {
        this.displayHotelForm = false;
        console.log(event);
    }

    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
        this.sub5?.unsubscribe();
    }

    // exportExcel() {
    //     import("xlsx").then(xlsx => {
    //         const worksheet = xlsx.utils.json_to_sheet(this.hotels);
    //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         this.saveAsExcelFile(excelBuffer, "RateShopper_Hotels");
    //     });
    // }
    // saveAsExcelFile(buffer: any, fileName: string): void {
    //     let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //     let EXCEL_EXTENSION = '.xlsx';
    //     const data: Blob = new Blob([buffer], {
    //         type: EXCEL_TYPE
    //     });
    //     const date = new Date();
    //     FileSaver.saveAs(data, fileName + '_export_' + date.getFullYear() + '_'
    //             + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getHours() + '_' + date.getMinutes() + EXCEL_EXTENSION);
    //     ;
    // }
    exportExcel() {
        this.exportTablesService.exportExcel(this.hotels, "rate-shopper-Hotels");
    }

}
