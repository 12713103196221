import { FormGroup, AbstractControl } from '@angular/forms'
import { IControl2 } from './IControl2'
import { FormParameter } from './form-parameter'
import { FormControl2 } from './formControl2'

export class FormGroup2 extends FormGroup implements IControl2 {
    constructor(
        controls: { [key: string]: AbstractControl },
        public code: string
    ) {
        super(controls)
    }
    formParam: FormParameter
    externalErrorMsg?: string
    visibilityConditionValue: boolean
    activationConditionValue: boolean

    getErrorsMessage(): string {
        if (this.dirty || this.touched) {
            if (this.errors?.required) return 'Champ requis';
            if (this.externalErrorMsg) return this.externalErrorMsg;
            return '';
        } else {
            return '';
        }
    }

    update_Value_And_Validity(): void {

        this.updateValueAndValidity();
        this.externalErrorMsg = undefined;

        let parentControl = this.parent as FormGroup2;

     try  {
            if (parentControl) parentControl.update_Value_And_Validity();
     }
     catch {
        console.log(parentControl);
     }
       


    }
}
