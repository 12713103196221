import { Component, OnInit, Input } from '@angular/core'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.css'],
})
export class DatetimeComponent implements OnInit {
    @Input() formGroup: FormGroup2
    @Input() field: FormParameter
    formControl: FormControl2
    separator: string = ',';

    tooltipzindex=TOOL_TIP_Z_INDEX;

    constructor() { }

    today: Date = new Date()
    ngOnInit(): void {
        // var ctrl = this.form.get(this.field.code);
        // ctrl?.patchValue(new Date(ctrl.value));
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (this.formControl.value) {
            if (this.field.type == 'Time') {
                this.formControl.patchValue(this.formControl.value)
            } else if (this.field.type == 'DateMultiple') {
                var newvalues = []
                if (
                    this.formControl.value &&
                    Array.isArray(this.formControl.value)
                ) {
                    for (let i = 0; i < this.formControl.value.length; i++) {
                        newvalues.push(new Date(this.formControl.value[i]))
                    }
                }
                this.formControl.setValue(newvalues)
            } else {
                let newValue = new Date(this.formControl.value)
                this.formControl.patchValue(newValue)
            }
        } else {
            this.formControl.patchValue(null)
        }
    }

    onKeydown(event) {
        this.formControl.update_Value_And_Validity()
    }
    getMinDate(): Date {
        return new Date()
    }
}
