import { Component, OnInit, Input } from '@angular/core'
import { AbstractControl, FormGroup, Validators } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormArray2 } from '../../models/FormArray2'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'

@Component({
  selector: 'app-hgroup-dynamic',
  templateUrl: './hgroup-dynamic.component.html',
  styleUrls: ['./hgroup-dynamic.component.css'],
})
export class HgroupDynamicComponent implements OnInit {
  @Input() formGroup: FormGroup2
  @Input() formArray: FormArray2
  @Input() field: FormParameter

  constructor() { }

  ngOnInit(): void { }

  addArrayItem(): void {
    if (this.formArray) {
      let frmgModel = this.formArray.controls[0] as FormGroup2

      let formParam = frmgModel.formParam
      let frmgNew = this.buildFormGroup(formParam, '')
      this.formArray.push(frmgNew)
    }
  }
  removeArrayItem(index: number): void {
    this.formArray.removeAt(index)
  }

  isLineSeparatorVisible(): boolean {

    if (this.field.lineSeparator == true) return true;

    if (this.field.lineSeparator === false) {
      return false
    }
    else if (this.formGroup) {
      let parent = this.formGroup?.parent as FormArray2
      let res = parent?.formParam?.lineSeparator;
      return res
    }

    return false
  }
  buildFormGroup(formParam: FormParameter, formValue: any): AbstractControl {
    let group: any = {}

    for (let index = 0; index < formParam.childParameters.length; index++) {
      const fromParamItem = formParam.childParameters[index]

      if (fromParamItem.type == 'Label') {
        continue
      }

      let value = formValue[fromParamItem.code] || ''

      if (fromParamItem.childParameters != null) {
        group[fromParamItem.code] = this.buildFormGroup(
          fromParamItem,
          value
        )
      } else {
        let frmControl: FormControl2
        frmControl = new FormControl2(fromParamItem.code, value)
        frmControl.formParam = fromParamItem

        //--Validation
        let validateurs = [] //par defaut

        if (fromParamItem.required) {
          validateurs.push(Validators.required)
        }

        frmControl.setValidators(validateurs)

        group[fromParamItem.code] = frmControl
      }
    }
    var frmg2 = new FormGroup2(group, formParam.code)
    frmg2.formParam = formParam
    //frmg2.setValidators(this.externalValidation);
    return frmg2
  }
}
