/**
 * Spot Analyse Api
 * Version Décembre 2021
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { SettingsRoomTypes } from '../model/settingsRoomTypes';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class SettingsService {
 
     protected basePath = 'https://services.spotpilot.com/analyse';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * Obtient pour un hôtel la liste des types de chambre valides pendant la période de dates demandée
      * 
      * @param hotCode 
      * @param from Date de début
      * @param to Date de fin
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiSettingsHotCodeRoomtypesGet(hotCode: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<SettingsRoomTypes>;
     public apiSettingsHotCodeRoomtypesGet(hotCode: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SettingsRoomTypes>>;
     public apiSettingsHotCodeRoomtypesGet(hotCode: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SettingsRoomTypes>>;
     public apiSettingsHotCodeRoomtypesGet(hotCode: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiSettingsHotCodeRoomtypesGet.');
         }
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (from !== undefined && from !== null) {
             queryParameters = queryParameters.set('From', <any>from.toISOString());
         }
         if (to !== undefined && to !== null) {
             queryParameters = queryParameters.set('To', <any>to.toISOString());
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<SettingsRoomTypes>('get',`${this.basePath}/api/settings/${encodeURIComponent(String(hotCode))}/roomtypes`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 