import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DistribSessionService } from './distrib/session/distrib-session.service';
import { DistribSession } from './distrib/services/model/models';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    styleUrls: ['./app.breadcrumb.component.css']
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {

    subscription: Subscription;
    subscriptionSession: Subscription;

    items: MenuItem[];
    currentSession: DistribSession = null;
    confirmation: boolean;

    isDistrib: boolean = true;
    constructor(public breadcrumbService: BreadcrumbService,
        private confirmationService: ConfirmationService, private messageService: MessageService, private distribSession: DistribSessionService) {

        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
            this.isDistrib = this.items && this.items.length > 0 && this.items[0]?.label.toLowerCase().includes("distrib");
        });
    }
    ngOnInit(): void {
        this.subscriptionSession = this.distribSession.getNewSession$().subscribe(session => {
            this.currentSession = session;
        })
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionSession) {
            this.subscriptionSession.unsubscribe();
        }
    }

    getStyleButton(): string {
        if (this.currentSession) {
            return 'p-button-danger';
        }

        return 'p-button-secondary';
    }

    IsEnabled(): boolean {

        if (!this.currentSession) return false;
        return !!this.currentSession.sessionId
    }

    confirmPublicationSession(event: Event): void {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous publier les modifications?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            key: "validationSession",
            accept: () => {
                this.distribSession.validateSession(this.currentSession);
                this.messageService.add({ severity: 'info', summary: 'Publication', detail: 'Publication des modifications en cours...' });
            },
            reject: () => {
                // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            }
        });
    }


    cancelSession(event: Event): void {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous annuler les modifications?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            key: "validationSession",
            accept: () => {
                this.distribSession.cancelSession(this.currentSession);
                this.messageService.add({ severity: 'info', summary: 'Annulation', detail: 'Annulation des modifications en cours...' });
            },
            reject: () => {
                // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            }
        });

    }

}
