
<div class="grid mt-1 gridCategories">
    <div class="col md:col-6 lg:col">
        <p-panel class="font-bold mb-1" header="Type de chambre Spot">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px', 'height':'20px'}"  styleClass="p-button-text"
                    icon=""></p-button>
            </ng-template>
            <p-scrollPanel  [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-10px'}">
                <p-table [value]="produitVenduUnits" styleClass="p-datatable-striped p-datatable-sm"  dataKey="spot">
                    <ng-template pTemplate="body" let-produitVenduItem>
                        <tr>
                            <td>
                                <span>{{produitVenduItem.produitVenduUnitLabel}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </p-panel>

    </div>
    <div class="col-6 md:col-6 lg:col-6">
        <p-panel class="font-bold mb-1" header="Mapping">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px', 'height':'20px'}" (click)="newMapping()" styleClass="p-button-info"
                    icon="pi pi-plus"></p-button>
            </ng-template>
            <p-scrollPanel  [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-8px'}">

                <p-table [value]="mappingInventories" selectionMode="single" styleClass="p-datatable-striped p-datatable-sm"
                    dataKey="spot">

                    <ng-template pTemplate="body" let-mapItem>
                        <tr [pSelectableRow]="mapItem">
                            <td>{{ mapItem.spotName }}
                                <span *ngIf="isNotMappingSpotCodeExist(mapItem)">
                                    <i class="pi pi-exclamation-triangle" style="color: rgb(235, 85, 54); font-size: 1rem;"></i>
                                </span>
                            </td>
                            <td>{{ mapItem.mewsName }}
                                <span *ngIf="isNotMappingMewsCodeExist(mapItem)">
                                    <i class="pi pi-exclamation-triangle" style="color: rgb(235, 85, 54); font-size: 1rem;"></i>
                                </span>
                            </td>
                            <td>
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    [style]="{'width':'15px', 'height':'6px'}"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    (click)="deleteFromMapping(mapItem)">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </p-scrollPanel>
        </p-panel>
    </div>
    <div class="col-3 md:col-3 lg:col-3">
        <p-panel class="font-bold mb-1" header="Type de chambre Mews">
            <ng-template pTemplate="icons">
                <p-button [style]="{'margin-right':'5px', 'height':'20px'}"  styleClass="p-button-text"
                    icon=""></p-button>
            </ng-template>
            <p-scrollPanel  [style]="{width: '100%', height: 'calc(100vh - 360px)', margin:'-10px'}">
                <p-table [value]="resourceCategoryMews" styleClass="p-datatable-striped p-datatable-sm"  dataKey="spot">
                    <ng-template pTemplate="body" let-categoryMewsItem>
                        <tr>
                            <td>
                                <span>{{categoryMewsItem.label}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-scrollPanel>
        </p-panel>
    </div>
</div>
<p-dialog  *ngIf="displayAddCategoriesMapping" [(visible)]="displayAddCategoriesMapping" [style]="{ width: '750px'}">
    <p-header></p-header>
   <div class="grid mt-1">
    <div class="col-6 md:col-6 lg:col-6">
        <p-panel header="Spot">
            <p-listbox [options]="produitVenduUnits"  optionLabel="produitVenduUnitLabel" [(ngModel)]="selectedUnit"
             [listStyle]="{ 'height': '400px' }" [style]="{ width: 'auto'}">
            </p-listbox>
        </p-panel>
    </div>
    <div class="col-6 md:col-6 lg:col-6">
        <p-panel header="Mews">
            <p-listbox [options]="resourceCategoryMews"  optionLabel="names" [(ngModel)]="selectedCat"
            [listStyle]="{ 'height': '400px' }" [style]="{ width: 'auto' }">
                <ng-template let-cat pTemplate="item">
                    <div >
                        <div>{{cat.label}}</div>
                    </div>
                </ng-template>
            </p-listbox>
        </p-panel>
    </div>
   </div>
   <p-footer>
    <p-button [style]="{'margin-right':'5px'}" icon="pi pi-check" label="Valider"
        styleClass="p-button-success" (onClick)="validate()" [disabled]="!selectedCat || !selectedUnit" >
    </p-button>
    <p-button [style]="{'margin-right':'5px'}" label="Annuler" (click)="cancel()"
        styleClass="p-button-primary"></p-button>
   </p-footer>
</p-dialog>
