<p-card *ngIf="hotelNotIntialized">
    <div>
        Il faut initialisé l'hôtel <span class="font-semibold text-pink-900">{{currentHotel.name }} </span> et créer ses
        produits
    </div>
</p-card>

<p-tabView styleClass="tabview-custom" *ngIf="!hotelNotIntialized && !!allExpo">

      <!-- Routage de cannaux -->
      <p-tabPanel>
        <ng-template pTemplate="header">
            <span class="material-icons-outlined mr-1" style="font-size: 1.6em;">
                swap_calls
            </span>
            <span>Routage Cannaux</span>

        </ng-template>
        <!-- ecrans  distributeur/cannaux
                ajouter un distributeur / création d'un nouveaux  pour les admin
                ajouter des cannaux au distributeur  / création d'un nouveaux cannal pour les admin

                difusion de tarif dans un canal avec les exclusions de prdvu
            -->
        <app-routage-cannaux [allExpo]="allExpo"  (OnReLoadData)="reloadData($event)"></app-routage-cannaux>

    </p-tabPanel>


       <!-- Plan tarifaire -->
       <p-tabPanel>
        <ng-template pTemplate="header">
            <span class="material-icons-outlined mr-1" style="font-size: 1.6em;">
                price_change
            </span>
            <span>Plans tarifaires</span>
        </ng-template>
        <!-- Tableau de xtous les tarifs avec les colonnes importantes + formulaire d'editions
                 popup produits difusés
                 popup  cannaux de diffusions
            -->
        <app-planstarifaires [allExpo]="allExpo" (OnReLoadData)="reloadData($event)"></app-planstarifaires>
    </p-tabPanel>






    <!-- inventaires distants -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span class="material-icons-outlined" style="font-size: 1.6em; margin: 0;">
                share
            </span>
            <span>Inventaires distants</span>


        </ng-template>
        <app-inventaires-distants [allExpo]="allExpo"  (OnReLoadData)="reloadData($event)"></app-inventaires-distants>
    </p-tabPanel>


    <!-- matrice d'exposition -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <span class="material-icons-outlined" style="font-size: 1.6em; margin: 0;">
                view_compact
            </span>
            <span>Matrice d'exposition </span>
        </ng-template>
        <app-matrice-expo [allExpo]="allExpo"></app-matrice-expo>
    </p-tabPanel>


</p-tabView>

<p-card *ngIf="!hotelNotIntialized && !allExpo">
    <div style="display: grid; grid-template-columns: auto auto 1fr; align-items: center; column-gap: 10px;">
        <span class="material-icons-outlined" style="font-size: 2.1em;">
            sentiment_dissatisfied
        </span>
        <span style="font-size: 1.2em;">
            Erreur de chargement des données
        </span>
    </div>
</p-card>
