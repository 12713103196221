<div>
    <p-toolbar>
        <div class="p-toolbar-group-left">
            <div class="ui-helper-clearfix" style="text-align: left">
                <div class="grid" style="width: 420px;">
                    <div class="col-1">
                        <p-button icon="pi pi-file-excel" iconPos="left" styleClass="p-button-success" label=""
                            (click)="exportExcel()" class="ui-button-success">
                        </p-button>
                    </div>
                    <div class="col-11">
                        <p-calendar #rangeCalendar [(ngModel)]="rangeDates" [style]="{'width': '270px'}" selectionMode="range" [monthNavigator]="true" [yearNavigator]="true"
                        yearRange="2011:2030" dateFormat="yy-mm-dd"
                            (onSelect)="getLiveTimeLine(rangeDates, rangeCalendar)"
                            (keyup.enter)="getLiveTimeLine(rangeDates, rangeCalendar)" showButtonBar="true" inputId="range"
                            placeholder="Date début - Date fin" [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </p-toolbar>
    <div>
        <p-table class="p-m-1" #dt [columns]="cols" [value]="resasList" [paginator]="true" [rows]="rows"
            [showCurrentPageReport]="true" [loading]="loading" [(selection)]="selectedResa" selectionMode="single"
            styleClass="p-datatable-striped" [globalFilterFields]="['name','date','status']">
            <ng-template pTemplate="caption">
                <div class="table-header" class="p-d-flex p-ai-center p-jc-between">
                    Liste des Résas
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Nom, Date" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns"
                        [ngStyle]="{'width': col.width != undefined ? col.width + 'px' : '180px'}">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="col.field=='date'">
                                <th pSortableColumn="date">Date<p-sortIcon field="date">
                                    </p-sortIcon>
                                </th>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <th>
                                    {{col.header}}
                                </th>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData" [ngClass]="{'row-status': rowData.status == 'S'}">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="true">
                            <ng-container *ngSwitchCase="col.field=='json'">
                                <button pButton pRipple icon="pi pi-file" class="p-button-rounded p-button-help"
                                   style="width: 23px; height: 1.6rem;" (click)="displayJsonResnotif('right', rowData)">
                                </button>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog [(visible)]="displayJson" [position]="position" [modal]="true" [style]="{width: '38vw'}"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <pre *ngIf="resnotif && resnotif.length > 0" style="margin-top: 0px;">
            <div style="margin-top: -30px;">
                <ngx-json-viewer [json]="currentResnotif"></ngx-json-viewer>
             </div>
         </pre>
         <p-footer >
             <button type="button" pButton icon="pi pi-angle-left" label="" (click)="onPrev()" [disabled]="index <= 0"></button>
             <button type="button" pButton icon="pi pi-angle-right" label="" (click)="onNext()"[disabled]="index >= resnotif.length - 1"></button>
          </p-footer>

    </p-dialog>
</div>
