import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './app.error.component.html',
})
export class AppErrorComponent implements OnInit {

  visible: boolean = false;

  ngOnInit(): void {
    setTimeout(() => {

      this.visible = true;
    }, 5000);
  }


}
