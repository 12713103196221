
//const authorityDomain = "eleven011.b2clogin.com";
const authorityDomain ="login.spotpilot.com";

export const environment = {
    production: false,
    version: "Dev:0.2.10",
    urlRateShopper: "https://services-dev.spotpilot.com/rateshopper",
    urlUser: "https://services-dev.spotpilot.com/user",
    urlAnalyse: "https://services-dev.spotpilot.com/analyse",
    urlSpotConnect: "https://services-dev.spotpilot.com/connect",
    urlDistrib: "https://services-dev.spotpilot.com/distrib",
    urlBudget: "https://services-dev.spotpilot.com/budget",
    urlPowerBi: "https://services-dev.spotpilot.com/pbiembedded",

    urlMews: "https://services-dev.spotpilot.com/mews",  //attention : pas de -v2
    urlOperaCloud: "https://services-dev.spotpilot.com/operacloud",

    urlOperaCloudCompta: "https://services-dev.spotpilot.com/operacloudcompta", // pas de -v2

    urlMisterBookingLive: "https://services-dev.spotpilot.com/mrbookinglive", //pas de -v2
    urlMewsLive: "https://services-dev.spotpilot.com/mewslive",  // pas de -v2
    urlOperaCloudLive: "https://services-dev.spotpilot.com/operacloudlive", //pas de -v2

    urlHotels: "https://services-dev.spotpilot.com/hotels",

    urlBoBackendApi: "https://services-dev.spotpilot.com/backoffice-backend",
     
    // urlBudget:"http://localhost:8210",
    //urlDistrib: "http://localhost:8160",
    //urlUser: "http://localhost:9049",
     //urlPowerBi:"http://localhost:9014",
    //urlMewsLive:  "http://localhost:8294",


    msalConfig: {
        auth: {
            clientId: '6e944074-dcc6-486f-9399-a96eb7a916d0',
        }
    },
    apiConfig: {
        scopes: ['https://eleven011.onmicrosoft.com/elevenapi/write', 'https://eleven011.onmicrosoft.com/elevenapi/read'],
        uri: 'https://services.spotpilot.com'
    },
    apiConfig2023: {
        scopes: ['https://eleven011.onmicrosoft.com/elevenapi/write', 'https://eleven011.onmicrosoft.com/elevenapi/read'],
        uri: 'http://localhost:9014'
    },
    apiConfig_dev: {
        scopes: ['https://eleven011.onmicrosoft.com/elevenapi/write', 'https://eleven011.onmicrosoft.com/elevenapi/read'],
        uri: 'https://services-dev.spotpilot.com'
    },
    b2cPolicies: {
        names: {
            signUpSignIn: "B2C_1_SignIn_BO",
            resetPassword: "B2C_1_ResetPasswordEmail",
            editProfile: "B2C_1_ProfileEdit",

        },
        authorities: {
            signUpSignIn: {
                authority: `https://${authorityDomain}/eleven011.onmicrosoft.com/B2C_1_SignIn_BO`
            },
            resetPassword: {
                authority: `https://${authorityDomain}/eleven011.onmicrosoft.com/B2C_1_ResetPasswordEmail`,
            },
            editProfile: {
                authority: `https://${authorityDomain}/eleven011.onmicrosoft.com/B2C_1_ProfileEdit`
            }
        },
        authorityDomain: authorityDomain
    }
};
