<div class="container" style="user-select: none;">

    <div>
        <p-table #dt2 selectionMode="single" [(selection)]="selectedDistributeur" [value]="allExpo?.distributeurList"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Distributeurs
                </div>
                <div class="flex">
                    <button pButton label="" class="p-button-success ml-2" pTooltip="Ajouter un distributeur"
                        icon="pi pi-plus"></button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <!-- <tr>
                    <th>Code</th>
                    <th>Libellé</th>
                    <th></th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-dist>
                <tr [pSelectableRow]="dist">
                    <td>{{ dist.code }}</td>
                    <td>{{ dist.libelle }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div>
        <p-table #dt3 selectionMode="single" [(selection)]="selectedInvDist" [value]="selectedDistributeur?.inventaireDistantList"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
            [globalFilterFields]="['libelle','code']" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Inventraires distants
                </div>
                <div class="flex">
                    <div class="flex">
                        <button pButton label="" class="p-button-success ml-2" pTooltip="Ajouter un canal"
                            icon="pi pi-pencil"></button>
                    </div>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt3.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Code</th>
                    <th>Libellé</th>
                    <th>Max stock</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invDist>
                <tr [pSelectableRow]="invDist" >
                    <td>{{invDist.produitVenduCode}}</td>
                    <td>{{invDist.produitVenduLabel}}</td>
                    <td>{{invDist.maxStock}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>


</div>
