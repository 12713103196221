/**
 * Spot Analyse Api
 * Version 28-06-2021: Indicateur Live entre deux date, timeLine, retroJournal
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { AnalyseIndicateursLive } from '../model/analyseIndicateursLive';
 import { AnalyseResaTimeLine } from '../model/analyseResaTimeLine';
 import { RetroJournalItem } from '../model/retroJournalItem';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class IndicatorsLiveService {
 
     protected basePath = 'https://services-lx.spotpilot.com/analyse-v2';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * Indicateur live
      * 
      * @param hotCode Code de l&#x27;hôtel
      * @param dateStart date début de création de resa: si null  date aujourd&#x27;hui à minuit
      * @param dateEnd date de fin (date incluse) si null  date aujourd&#x27;hui à minuit
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiIndicatorsliveHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'body', reportProgress?: boolean): Observable<AnalyseIndicateursLive>;
     public apiIndicatorsliveHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnalyseIndicateursLive>>;
     public apiIndicatorsliveHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnalyseIndicateursLive>>;
     public apiIndicatorsliveHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsliveHotCodeGet.');
         }
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (dateStart !== undefined && dateStart !== null) {
             queryParameters = queryParameters.set('dateStart', <any>dateStart.toISOString());
         }
         if (dateEnd !== undefined && dateEnd !== null) {
             queryParameters = queryParameters.set('dateEnd', <any>dateEnd.toISOString());
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<AnalyseIndicateursLive>('get',`${this.basePath}/api/indicatorslive/${encodeURIComponent(String(hotCode))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * RetroJournal  entre deux dates
      * 
      * @param hotCode Code de l&#x27;hôtel
      * @param dateStart date début de création de resa: si null  date aujourd&#x27;hui à minuit
      * @param dateEnd date de fin (date incluse) si null  date aujourd&#x27;hui à minuit
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiIndicatorsliveRetrojournalHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<RetroJournalItem>>;
     public apiIndicatorsliveRetrojournalHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RetroJournalItem>>>;
     public apiIndicatorsliveRetrojournalHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RetroJournalItem>>>;
     public apiIndicatorsliveRetrojournalHotCodeGet(hotCode: string, dateStart?: Date, dateEnd?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsliveRetrojournalHotCodeGet.');
         }
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (dateStart !== undefined && dateStart !== null) {
             queryParameters = queryParameters.set('dateStart', <any>dateStart);
         }
         if (dateEnd !== undefined && dateEnd !== null) {
             queryParameters = queryParameters.set('dateEnd', <any>dateEnd);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<RetroJournalItem>>('get',`${this.basePath}/api/indicatorslive/retrojournal/${encodeURIComponent(String(hotCode))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * TimeLine des resa reçues entre deux dates
      * 
      * @param hotCode Code de l&#x27;hôtel
      * @param dateStart date début de création de resa: si null  date aujourd&#x27;hui à minuit
      * @param dateEnd date de fin (date incluse) si null  date aujourd&#x27;hui à minuit
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiIndicatorsliveTimeLineHotCodeGet(hotCode: string, dateStart?: Date | string, dateEnd?:  Date | string, observe?: 'body', reportProgress?: boolean): Observable<Array<AnalyseResaTimeLine>>;
     public apiIndicatorsliveTimeLineHotCodeGet(hotCode: string, dateStart?:  Date | string, dateEnd?:  Date | string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AnalyseResaTimeLine>>>;
     public apiIndicatorsliveTimeLineHotCodeGet(hotCode: string, dateStart?:  Date | string, dateEnd?: Date | string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AnalyseResaTimeLine>>>;
     public apiIndicatorsliveTimeLineHotCodeGet(hotCode: string, dateStart?:  Date | string, dateEnd?:  Date | string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiIndicatorsliveTimeLineHotCodeGet.');
         }
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (dateStart !== undefined && dateStart !== null) {
             queryParameters = queryParameters.set('dateStart', <any>dateStart);
         }
         if (dateEnd !== undefined && dateEnd !== null) {
             queryParameters = queryParameters.set('dateEnd', <any>dateEnd);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<AnalyseResaTimeLine>>('get',`${this.basePath}/api/indicatorslive/timeLine/${encodeURIComponent(String(hotCode))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 