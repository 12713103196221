<div class="form-group row">
    <label class="col-auto col-form-label">{{ field.label }}</label>
    <button (click)="addFrm()" type="button" class="btn btn-info">
        Ajouter
    </button>
</div>

<div *ngFor="let control of frmArray.controls; index as i">
    <app-form-renderer [formGroup2]="frmArray.controls[i]"> </app-form-renderer>
</div>
