/**
 * Mews
 * Version: 25-03-2024
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { EndModel } from '../model/endModel';
 import { HotelCredentials } from '../model/hotelCredentials';
 import { HotelCredentialsForPost } from '../model/hotelCredentialsForPost';
 import { InitHotelModel } from '../model/initHotelModel';
 import { Loadreservations } from '../model/loadreservations';
 import { PushRoomTypesRequest } from '../model/pushRoomTypesRequest';
 //import { PushRoomTypesRoomType } from '../model/pushRoomTypesRoomType';
 import { Service } from '../model/service';
 import { StartModel } from '../model/startModel';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
import { PushRoomTypesRoomType } from '../model/pushRoomTypesRoomType';
 
 
 @Injectable()
 export class MewsAnalyseService {
 
     protected basePath = 'https://services-dev.spotpilot.com/mews';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * Les credentials d&#x27;un hotel
      * 
      * @param hotCode 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseCredentialsGet(hotCode?: string, observe?: 'body', reportProgress?: boolean): Observable<HotelCredentials>;
     public apiMewsAnalyseCredentialsGet(hotCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelCredentials>>;
     public apiMewsAnalyseCredentialsGet(hotCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelCredentials>>;
     public apiMewsAnalyseCredentialsGet(hotCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (hotCode !== undefined && hotCode !== null) {
             queryParameters = queryParameters.set('hotCode', <any>hotCode);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<HotelCredentials>('get',`${this.basePath}/api/mewsAnalyse/credentials`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Creation/Mise à jour  hotelCredentials
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseCredentialsPost(body?: HotelCredentialsForPost, observe?: 'body', reportProgress?: boolean): Observable<string>;
     public apiMewsAnalyseCredentialsPost(body?: HotelCredentialsForPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
     public apiMewsAnalyseCredentialsPost(body?: HotelCredentialsForPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
     public apiMewsAnalyseCredentialsPost(body?: HotelCredentialsForPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<string>('post',`${this.basePath}/api/mewsAnalyse/credentials`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param hotCode 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseHotCodeServicesGet(hotCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Service>>;
     public apiMewsAnalyseHotCodeServicesGet(hotCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Service>>>;
     public apiMewsAnalyseHotCodeServicesGet(hotCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Service>>>;
     public apiMewsAnalyseHotCodeServicesGet(hotCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiMewsAnalyseHotCodeServicesGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<Service>>('get',`${this.basePath}/api/mewsAnalyse/${encodeURIComponent(String(hotCode))}/services`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Importer l&#x27;historique des resas et des régules
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseInitHotelPost(body?: InitHotelModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseInitHotelPost(body?: InitHotelModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseInitHotelPost(body?: InitHotelModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseInitHotelPost(body?: InitHotelModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/InitHotel`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Importer l&#x27;historique des régules
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseInitRegulsPost(body?: InitHotelModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseInitRegulsPost(body?: InitHotelModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseInitRegulsPost(body?: InitHotelModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseInitRegulsPost(body?: InitHotelModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/InitReguls`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Importer l&#x27;historique des resa
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseInitResaPost(body?: InitHotelModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseInitResaPost(body?: InitHotelModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseInitResaPost(body?: InitHotelModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseInitResaPost(body?: InitHotelModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/InitResa`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param hotCode 
      * @param startUtc 
      * @param endUtc 
      * @param idMewsList 
      * @param forceUpdate 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseLoadreservationByIdsPost(hotCode?: string, startUtc?: Date, endUtc?: Date, idMewsList?: string, forceUpdate?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseLoadreservationByIdsPost(hotCode?: string, startUtc?: Date, endUtc?: Date, idMewsList?: string, forceUpdate?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseLoadreservationByIdsPost(hotCode?: string, startUtc?: Date, endUtc?: Date, idMewsList?: string, forceUpdate?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseLoadreservationByIdsPost(hotCode?: string, startUtc?: Date, endUtc?: Date, idMewsList?: string, forceUpdate?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (hotCode !== undefined && hotCode !== null) {
             queryParameters = queryParameters.set('hotCode', <any>hotCode);
         }
         if (startUtc !== undefined && startUtc !== null) {
             queryParameters = queryParameters.set('startUtc', <any>startUtc.toISOString());
         }
         if (endUtc !== undefined && endUtc !== null) {
             queryParameters = queryParameters.set('endUtc', <any>endUtc.toISOString());
         }
         if (idMewsList !== undefined && idMewsList !== null) {
             queryParameters = queryParameters.set('IdMewsList', <any>idMewsList);
         }
         if (forceUpdate !== undefined && forceUpdate !== null) {
             queryParameters = queryParameters.set('forceUpdate', <any>forceUpdate);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/LoadreservationByIds`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseLoadreservationsPost(body?: Loadreservations, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseLoadreservationsPost(body?: Loadreservations, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseLoadreservationsPost(body?: Loadreservations, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseLoadreservationsPost(body?: Loadreservations, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/Loadreservations`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalysePushRoomTypesPost(body?: PushRoomTypesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalysePushRoomTypesPost(body?: PushRoomTypesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalysePushRoomTypesPost(body?: PushRoomTypesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalysePushRoomTypesPost(body?: PushRoomTypesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/pushRoomTypes`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Calculer  les types de chambres avec un objet hotelCredentials
      * 
      * @param hotCode 
      * @param useSpotRoomTypeCode 
      * @param serviceIds 
      * @param codeLanguage 
      * @param stockRoomOnly 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseRoomtypesGet(hotCode: string, useSpotRoomTypeCode?: boolean, serviceIds?: Array<string>, codeLanguage?: string, stockRoomOnly?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<PushRoomTypesRoomType>>;
     public apiMewsAnalyseRoomtypesGet(hotCode: string, useSpotRoomTypeCode?: boolean, serviceIds?: Array<string>, codeLanguage?: string, stockRoomOnly?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PushRoomTypesRoomType>>>;
     public apiMewsAnalyseRoomtypesGet(hotCode: string, useSpotRoomTypeCode?: boolean, serviceIds?: Array<string>, codeLanguage?: string, stockRoomOnly?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PushRoomTypesRoomType>>>;
     public apiMewsAnalyseRoomtypesGet(hotCode: string, useSpotRoomTypeCode?: boolean, serviceIds?: Array<string>, codeLanguage?: string, stockRoomOnly?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (hotCode === null || hotCode === undefined) {
             throw new Error('Required parameter hotCode was null or undefined when calling apiMewsAnalyseRoomtypesGet.');
         }
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (hotCode !== undefined && hotCode !== null) {
             queryParameters = queryParameters.set('HotCode', <any>hotCode);
         }
         if (useSpotRoomTypeCode !== undefined && useSpotRoomTypeCode !== null) {
             queryParameters = queryParameters.set('UseSpotRoomTypeCode', <any>useSpotRoomTypeCode);
         }
         if (serviceIds) {
             serviceIds.forEach((element) => {
                 queryParameters = queryParameters.append('ServiceIds', <any>element);
             })
         }
         if (codeLanguage !== undefined && codeLanguage !== null) {
             queryParameters = queryParameters.set('CodeLanguage', <any>codeLanguage);
         }
         if (stockRoomOnly !== undefined && stockRoomOnly !== null) {
             queryParameters = queryParameters.set('StockRoomOnly', <any>stockRoomOnly);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<PushRoomTypesRoomType>>('get',`${this.basePath}/api/mewsAnalyse/roomtypes`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Demarrer le traitement des resa pour l&#x27;hôtel
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseStartPost(body?: StartModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseStartPost(body?: StartModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseStartPost(body?: StartModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseStartPost(body?: StartModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/Start`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * Arrêter de traiter les resa pour l&#x27;hotel
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiMewsAnalyseStopPost(body?: EndModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiMewsAnalyseStopPost(body?: EndModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiMewsAnalyseStopPost(body?: EndModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiMewsAnalyseStopPost(body?: EndModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // authentication (oauth2) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<any>('post',`${this.basePath}/api/mewsAnalyse/Stop`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 