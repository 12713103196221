import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { AllPricersHotel, UpsertPricerDeriveCmd, Pricer, PricerNiveau, SetDatesValidationForGrilleCmd, SetDatesValidationForNiveauCmd, UpsertLevierNumeriqueCmd, UpdatePricerPrdvuFormulePropreListCmd, DatesValidation } from '../../services/model/models';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PricerService } from '../../services/api/pricer.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';
import { GetDateValidationCmdValue, GetDateValidationFormValue, PeriodeDtvFormList } from '../grille-bar/PeriodeDtvForm';
import { PricerDeriveService } from '../../services/api/pricerDerive.service';
import { Subject, Subscription, find, lastValueFrom, takeUntil } from 'rxjs';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { LevierParam, ProduitsPropreFormuleForm, levierNumeriqueForm } from './FormModels';
@Component({
    selector: 'app-pricers-avances',
    templateUrl: './pricers-avances.component.html',
    styleUrls: ['./pricers-avances.component.css']
})
export class PricersAvancesComponent implements OnInit, OnChanges {

    @Input() allPricers: AllPricersHotel;
    pricerTreeNodeList: TreeNode<Pricer>[] = [];

    @Output() OnRealodData = new EventEmitter<boolean>()

    destroy$: Subject<string>;
    currentHotCode: string;
    dataLoaded: boolean;

    selectedPricer: Pricer;
    selectNiveau: PricerNiveau;
    niveau: PricerNiveau;

    datesValidation:DatesValidation;

    displaydatesValidationForm: boolean;
    datesValidationForm: FormConfig;
    dateValidationFormValue: any;

    menuItemPrc: MenuItem[];

    upsertPricerDeriveForm: FormConfig;
    displayUpsertPricerDeriveForm: boolean;
    upsertPricerDeriveFormInitValue: any;

    pricerModeUpdate: boolean;


    @ViewChild('formPricer') formDynamicPricer: FormDynamicComponent;
    displayLevierNumerique: boolean;
    levierNumForm: FormConfig;

    levierNumFormValue: levierNumeriqueForm;
    produitPropreFormuleForm: FormConfig;
    displayProduitPropreFormule: boolean;
    produitPropreFormuleFormValue:ProduitsPropreFormuleForm;

    displayProduitScrollPanel:boolean;


    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService,
        private pricerService: PricerService,
        private confirmationService: ConfirmationService,
        private distribSessionService: DistribSessionService,
        private pricerDeriveService: PricerDeriveService) {

        this.destroy$ = new Subject();
    }
    ngOnInit(): void {

    }

    _selectedTreeNodePricer: TreeNode;
    get selectedTreeNodePricer() {
        return this._selectedTreeNodePricer;
    }

    set selectedTreeNodePricer(value: TreeNode) {
        this._selectedTreeNodePricer = value;

        this.selectedPricer = this._selectedTreeNodePricer?.data;

        this.displayProduitScrollPanel=false;

        //trouver le 1er niveau de la premier grille pour un pricer enfant:
        //un pricer enfant possede une grille et un niveau
        this.niveau = null;
        if (this.selectedPricer?.pricerParentCode && this.selectedPricer?.grilles
            && this.selectedPricer?.grilles.length > 0) {
            let niveaux = this.selectedPricer.grilles[0].niveaux
            if (niveaux?.length > 0) {
                this.niveau = niveaux[0];
                this.dateValidationFormValue = GetDateValidationFormValue(this.niveau.datesValidation.periodes);
                this.datesValidation = this.niveau.datesValidation;
            }
        }
        else {
            this.datesValidation = null;
            this.niveau=null;
        }
        this.setMenuItemPricer();
        setTimeout(() => {
            this.displayProduitScrollPanel=true;
        }, 1);
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.currentHotCode = this.userInfoService.currentHotel.hotCode;
        this.loadForms();
        if (this.allPricers) {
            this.dataLoaded = true;
            this.BuildTreeNode();

            if (!this.selectedTreeNodePricer || !this.allPricers.pricerList.find(x => x.code == this.selectedPricer.code)) {
                this.selectedTreeNodePricer = this.pricerTreeNodeList?.find(x => x.data?.isMainRack == true);
                this.selectedPricer = this.selectedTreeNodePricer?.data;
            }
            else {
                this.selectedTreeNodePricer = this.findFristTreeNode(this.selectedPricer.code);
                this.selectedPricer = this.selectedTreeNodePricer?.data;
            }
        }
    }

    findFristTreeNode(pricerCode: string, currentNode: TreeNode<Pricer> = null): TreeNode<Pricer> {
        let result: TreeNode<Pricer> = null;

        if (currentNode != null) {
            for (const _node of currentNode.children) {

                if (_node.data.code == pricerCode) {
                    return _node;
                }
                else {
                    result = this.findFristTreeNode(pricerCode, _node);
                    if (result) return result;
                }

            }
        }
        else {
            result = this.pricerTreeNodeList.find(x => x.data.code == pricerCode);
            if (result) {
                return result;
            }
            else {
                for (const _node of this.pricerTreeNodeList) {
                    result = this.findFristTreeNode(pricerCode, _node);
                    if (result) return result;
                }
            }
        }
        return result;
    }

    BuildTreeNode() {
        let newTreeNode: TreeNode<Pricer>[] = [];
        if (this.allPricers?.pricerList) {

            for (let i = 0; i < this.allPricers.pricerList.length; i++) {
                const _prc = this.allPricers.pricerList[i];
                if (_prc.pricerParentCode == null) {
                    newTreeNode.push({
                        label: _prc.libelle,
                        key: _prc.code,
                        // icon:"pi pi-inbox",
                        data: _prc,
                        children: this.GetChildrenNode(_prc)
                    })
                }
            }
        }
        this.pricerTreeNodeList = newTreeNode;
        this.expandAll();
    }

    setMenuItemPricer() {
        this.menuItemPrc = [
            {
                label: 'Ajouter un Pricer derivé',
                icon: 'pi pi-plus',
                command: async () => {

                    this.pricerModeUpdate = false;
                    this.upsertPricerDeriveFormInitValue = {
                        pricerUpdateMode: false,
                        pricerInfos: {
                            pricerParentCode: this.selectedPricer.code,
                        }
                    };

                    this.displayUpsertPricerDeriveForm = true;
                }
            },
            {
                label: `Modifier le Pricer ${this.selectedPricer?.libelle}`,
                icon: 'pi pi-pencil',
                disabled: this.selectedPricer?.isRack,
                command: () => {

                    this.displayViewUpdateCurrentPricer();
                }
            },
            {
                label: 'Supprimer le Pricer',
                icon: 'pi pi-times',
                disabled: this.selectedPricer?.isRack,
                iconStyle: { 'color': 'red' },
                command: () => {

                }
            },
            {
                label: `Periode de validité`,
                icon: 'pi pi-calendar',
                disabled: this.selectedPricer?.isRack,
                command: () => {
                    this.displaydatesValidationForm = true
                }
            },

            {
                label: `Levier numérique`,
                icon: 'pi pi-arrow-up-right',
                disabled: this.selectedPricer?.isRack,
                command: async () => {
                    await this.displayLevierNumeriqueDialog();
                }
            },
            {
                label: `Regle d'arrondi`,
                icon: 'pi pi-pencil',
                disabled: this.selectedPricer?.isRack,
                command: () => {

                }
            },

            {
                label: `Les produits appliquant leur propre formule`,
                icon: 'pi pi-chevron-circle-right',
                disabled: this.selectedPricer?.isRack,
                command: async () => {
                    await this.displayProduitAvecPropreFormule();
                }
            },
            {
                label: `Dupliquer le Pricer ${this.selectedPricer?.libelle}`,
                icon: 'pi pi-copy',
                disabled: this.selectedPricer?.isRack,
                command: () => {

                }
            },
        ];
    }
    async displayProduitAvecPropreFormule(): Promise<void> {
        const frm1 = await lastValueFrom(this.pricerDeriveService.apiPricerDeriveProduitsAvecPropreFormuleFormGet(this.currentHotCode, this.selectedPricer?.code));
        this.produitPropreFormuleForm = JSON.parse(JSON.stringify(frm1)) as FormConfig;
        this.displayProduitPropreFormule = true;


        let produit = this.selectedPricer.pricerProduits.
            filter(x => x.appliquerLaFormuleProduit == true).map(x => x.produitVenduUnit.code);

        this.produitPropreFormuleFormValue=  {
            produitsPropreFormule: produit
        }
    }

    async displayLevierNumeriqueDialog(): Promise<void> {
        const frm1 = await lastValueFrom(this.pricerDeriveService.apiPricerDeriveGetLevierNumeriqueFormGet(this.currentHotCode, this.selectedPricer?.code));
        this.levierNumForm = JSON.parse(JSON.stringify(frm1)) as FormConfig;

        this.displayLevierNumerique = true

        this.levierNumFormValue = {
            pricerCode: this.selectedPricer.code,
        }

        const selectedLevierNum = this.selectedPricer?.formule?.levierNumerique;

        if (selectedLevierNum) {
            this.levierNumFormValue =
            {
                pricerCode: this.selectedPricer.code,
                levierType: selectedLevierNum.levierType,
            }
            if (selectedLevierNum.levierType == "Pourcentage") {
                this.levierNumFormValue.levierPourcent = {
                    valeurParDefaut: selectedLevierNum.valeurParDefaut,
                    valeursAutorisees: selectedLevierNum.valeursAutorisees,
                    format: selectedLevierNum.format
                }
            }
            else {
                this.levierNumFormValue.levierMontant = {
                    valeurParDefaut: selectedLevierNum.valeurParDefaut,
                    valeursAutorisees: selectedLevierNum.valeursAutorisees,
                    format: selectedLevierNum.format
                }
            }
        }


    }
    displayViewUpdateCurrentPricer(): void {
        this.pricerModeUpdate = true;
        this.upsertPricerDeriveFormInitValue = {
            pricerUpdateMode: true,
            pricerInfos: {
                libelle: this.selectedPricer.libelle,
                pourcentage: this.selectedPricer.formule.pourcentage,
                montant: this.selectedPricer.formule.montant,
                pricerParentCode: this.selectedPricer.pricerParentCode,
            },
            produits: this.selectedPricer.pricerProduits.map(x => x.produitVenduUnit.code)
        };

        this.displayUpsertPricerDeriveForm = true;
    }
    GetChildrenNode(prcParent: Pricer): TreeNode<Pricer>[] {
        let result: TreeNode<Pricer>[] = [];
        for (const _prc of this.allPricers.pricerList) {
            if (_prc.pricerParentCode == prcParent.code) {
                result.push({
                    label: _prc.libelle,
                    key: _prc.code,
                    data: _prc,
                    // icon:"pi pi-inbox"
                    children: this.GetChildrenNode(_prc)
                })
            }
        }
        return result;
    }

    expandAll() {
        this.pricerTreeNodeList.forEach((node) => {
            this.expandRecursive(node, true);
        });
    }

    collapseAll() {
        this.pricerTreeNodeList.forEach((node) => {
            this.expandRecursive(node, false);
        });
    }
    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    selectionChanged() {



    }

    getDatesValidationToolTip(desc: Array<string>): string {
        let result = "";
        if (!desc) return result;
        for (let ds of desc) {
            result = result + `<div>${ds} </div>`;
        }
        return result;

    }

    async loadForms() {
        const frm = await lastValueFrom(this.pricerDeriveService.apiPricerDeriveCreatePricerDeriveFormGet(this.currentHotCode));
        this.upsertPricerDeriveForm = JSON.parse(JSON.stringify(frm)) as FormConfig;



        // formulaire  dateValidations
        let frm2 = await lastValueFrom(this.pricerService.apiPricerDatesValidationFormGet(this.currentHotCode));
        this.datesValidationForm = JSON.parse(JSON.stringify(frm2)) as FormConfig;


    }

    upserPricerDerive(obj: any): void {

        const cmd: UpsertPricerDeriveCmd = {
            hotCode: this.currentHotCode,
            libelle: obj.pricerInfos.libelle,
            pourcentage: obj.pricerInfos.pourcentage,
            montant: obj.pricerInfos.montant,
            produitVendus: obj.produits,
            pricerParentCode: obj.pricerInfos.pricerParentCode
        }

        if (this.pricerModeUpdate) {
            cmd.code = this.selectedPricer.code;
        }

        this.pricerDeriveService.apiPricerDeriveUpsertPricerDerivePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(false);
                        this.notifService.setMessage(`Création du pricers dérivé ${cmd.libelle}`, "Pricers", false);
                        this.displayUpsertPricerDeriveForm = false;
                    }
                    else {
                        // this.notifService.setMessage(session.error ?? `Erreur dans la création pricers dérivé ${cmd.libelle}`, "Pricers", true);
                        console.log(session.error);
                        this.SetError(session.error);
                    }
                },
                error: error => {
                    this.SetError(error);
                    this.notifService.setMessage(error.error.error ?? `Erreur dans la créationpricers dérivé ${cmd.libelle}`, "Pricers", true);
                }
            });
    }

    SetError(msg: string): void {
        let error = new FormErrors();
        error.errors = new ErrorDictionary();
        error.errors["pricerInfos"] = [msg];
        this.formDynamicPricer.setExternalErrors(error);
    }



    SetDatesValidationGrille(datesValitionValue: PeriodeDtvFormList) {

        let cmd: SetDatesValidationForNiveauCmd = {
            grilleCode: this.selectedPricer.grilles[0].code,
            hotCode: this.currentHotCode,
            pricerCode: this.selectedPricer.code,
            datesValidation: GetDateValidationCmdValue(datesValitionValue),
            nivCode: this.selectedPricer.grilles[0].niveaux[0].code
        };

        this.pricerService.apiPricerDatesValidationNiveauPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(false);
                        this.displaydatesValidationForm = false;
                        this.notifService.setMessage(`Modification des dates de validations  du Pricer ${this.selectedPricer.libelle}`, "Pricers", false);
                    }
                    else {
                        this.notifService.setMessage(session.error ?? `Erreur dans la Modification des dates de validations du Pricer ${this.selectedPricer.libelle}`, "Pricers", true);
                    }
                },
                error: err => {
                    this.notifService.setMessage(err.error.error ?? `Erreur dans la Modification des dates de validations du Pricer ${this.selectedPricer.libelle}`, "Pricers", true);
                }
            })

    }

    upserLevierNumerique(formValue: levierNumeriqueForm): void {

        let cmd: UpsertLevierNumeriqueCmd;

        if (!formValue) return;

        let levierParam: LevierParam;

        if (formValue.levierType == "Pourcentage") {
            levierParam = formValue.levierPourcent;
        }
        else {
            levierParam = formValue.levierMontant;
        }
        cmd = {
            hotCode: this.currentHotCode,
            pricerCode: formValue.pricerCode,
            format: levierParam.format,
            levierType: formValue.levierType,
            valeurParDefaut: levierParam.valeurParDefaut,
            valeursAutorisees: levierParam.valeursAutorisees
        }


        this.pricerDeriveService.apiPricerDeriveUpsertLevierNumeriquePost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.distribSessionService.newSession(session.hotCode, session.sessionId);
                    if (!session.error) {
                        this.OnRealodData.emit(false);
                        this.notifService.setMessage(`Mise à jour du pricers dérivé ${this.selectedPricer.libelle}`, "Pricers", false);
                        this.displayLevierNumerique = false;
                    }
                    else {
                        this.SetError(session.error);
                    }
                },
                error: error => {
                    this.SetError(error);
                    this.notifService.setMessage(error.error.error ?? `Erreur dans la mise à jour du pricer dérivé ${this.selectedPricer.libelle}`, "Pricers", true);
                }
            });
    }

    confirmDeleteLevierNum(): void {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: `Voulez-vous supprimer le levier numerique pour le pricer ${this.selectedPricer?.libelle}?`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            key: "delLevierNum",
            accept: () => {

                this.pricerDeriveService.apiPricerDeriveLevierNumeriqueDelete(this.currentHotCode, this.selectedPricer.code
                )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                        next: (session) => {
                            this.distribSessionService.newSession(session.hotCode, session.sessionId);
                            if (!session.error) {
                                this.OnRealodData.emit(false);
                                this.notifService.setMessage(`Levier numerique supprimer pour le pricer ${this.selectedPricer?.libelle}?`
                                    , "Pricers", false);
                            }
                        }
                    });
            },
            reject: () => {
            }
        });
    }

    updatePricerPrdvuFormulePropre(value:any):void {

        let cmd: UpdatePricerPrdvuFormulePropreListCmd = {
            hotCode: this.currentHotCode,
            pricerCode:this.selectedPricer.code,
            prdvuFormulePropreList : value.produitsPropreFormule
        };

        this.pricerDeriveService.apiPricerDeriveUpdatePricerPrdvuFormulePropreListPost(cmd)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
            next: session => {
                this.distribSessionService.newSession(session.hotCode, session.sessionId);
                if (!session.error) {
                    this.OnRealodData.emit(false);
                    this.notifService.setMessage(`Mise à jour de la liste des produits appliquant leur propre formule ${this.selectedPricer.libelle}`, "Pricers", false);
                    this.displayProduitPropreFormule = false;
                }
                else {
                    this.SetError(session.error);
                }
            },
            error: error => {
                this.SetError(error);
                this.notifService.setMessage(error.error.error ?? `Erreur dans la mise à jour du pricer dérivé ${this.selectedPricer.libelle}`, "Pricers", true);
            }
        });
    }
}
