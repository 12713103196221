<p-tabView styleClass="tabview-custom">
    <!-- Configuration PmsLink -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-cog mr-1"></i>
            <span>Configuration PmsLink</span>
            <i class=" ml-3 "></i>
        </ng-template>
        <p-progressSpinner
            styleClass="w-1rem h-1rem"
            strokeWidth="8"
            *ngIf="loading"
        ></p-progressSpinner>
        <p-card *ngIf="!loading && hotelPmsLinkNotInitialized">
            <div class="mb-3">
                L'hôtel <span class="font-semibold text-pink-900">{{ currentHotel.name }}</span> n'est pas initialisé pour OperaCloud PmsLink
            </div>
            <p-button label="Initialiser PmsLink" icon="pi pi-chevron-circle-right" (onClick)="initHotel($event) ">
            </p-button>
        </p-card>
        <div class="mr-1" *ngIf="!loading && !hotelPmsLinkNotInitialized && hotelCredentialsPmsLink != null">
            <p-toolbar [style]="{'padding': '2px'}">
                <div class="p-toolbar-group-left">
                    <p-button label="Activer" icon="pi pi-check-circle" class="mr-1" styleClass="p-button-success" (click)="start()" [disabled]="!hotelCredentialsPmsLinkIsSaved || hotelCredentialsPmsLink.enabled" [loading]="startingPmsLink"></p-button>
                    <p-button label="Désactiver" icon="pi pi-times" class="mr-1" styleClass="p-button-danger" (click)="stop()"  [disabled]="!hotelCredentialsPmsLinkIsSaved || !hotelCredentialsPmsLink.enabled" [loading]="stoppingPmsLink"></p-button>
                </div>
                <!-- <div class="p-toolbar-group-right">
                    <p-button [label]="(hotelCredentialsPmsLinkIsSaved ? 'Modifier' : 'Sauvegarder')" icon="pi pi-save" class="mr-1" styleClass="p-button-warning" 
                        [disabled]="!validate(hotelCredentialsPmsLink)"
                        (onClick)="saveCredentials()"></p-button>
                </div> -->
            </p-toolbar>
            <div class="grid ml-2 mt-1">
                <p-card class="col-5 grid mt-1" header="Credentials PmsLink" [style]="{width:'100%',  margin:'3px'}">
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">Activé</label>
                            <p-inputSwitch  class="col-9 pt-0 pb-0" aria-label="" [(ngModel)]="hotelCredentialsPmsLink.enabled" disabled></p-inputSwitch>
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">HotelId</label>
                            <input class="col-9" type="text" pInputText [(ngModel)]="hotelCredentialsPmsLink.operaCloudHotelId" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">Password</label>
                            <p-password class="col-9 p-0" [feedback]="false" [toggleMask]="true" [(ngModel)]="hotelCredentialsPmsLink.operaCloudPassword" 
                                inputStyleClass="w-full" styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right"></p-password>
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">Tenant</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsPmsLink.operaCloudTenant" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">UserName</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsPmsLink.operaCloudUserName" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">BaseUrl</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsPmsLink.operaCloudBaseUrl" />
                        </div>
                    </div>

                    <div>
                        <div class="grid mt-2">
                            <span class="col-3">LoginSpecA</span>
                            <input class="col-9" type="text" pInputText [(ngModel)]="hotelCredentialsPmsLink.loginSpecA" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <span class="col-3">PwdSpecA</span>
                            <p-password class="col-9 p-0" [feedback]="false" [toggleMask]="true" [(ngModel)]="hotelCredentialsPmsLink.pwdSpecA" 
                                inputStyleClass="w-full" styleClass="w-full p-password p-component p-inputwrapper p-input-icon-right"></p-password>
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <span class="col-3">HotelCodeSpecA</span>
                            <input class="col-9" type="text" pInputText [(ngModel)]="hotelCredentialsPmsLink.hotelCodeSpecA" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-4">
                            <p-button [label]="(hotelCredentialsPmsLinkIsSaved ? 'Modifier' : 'Sauvegarder')" icon="pi pi-save" class="w-full" styleClass="w-full p-button-warning"  
                                [disabled]="!validate(hotelCredentialsPmsLink)"
                                (onClick)="saveCredentials()"
                                [loading]="savingCredentials"></p-button>
                        </div>
                    </div>
                </p-card>
                <div class="col-7 mt-1">
                    <p-card class="mt-1" header="Chargement de l'historique" [style]="{width:'100%', margin:'3px'}" *ngIf="hotelCredentialsPmsLinkIsSaved && hasNoReservationOrIsAdmin()">
                        <form class="grid" [formGroup]="histoForm" (ngSubmit)="submitHistoForm()">
                            <div class="col">
                                <div class="grid mt-2">
                                    <label class="col-3">Depuis le </label>
                                    <p-calendar class="col-9 p-0" appendTo="body" dateFormat="dd/mm/yy"
                                        [showIcon]="true" formControlName="histoStart"
                                        [class.valid]="histoForm.get('histoStart').valid && (histoForm.get('histoStart').dirty || histoForm.get('histoStart').touched)"
                                        [class.invalid]="histoForm.get('histoStart').invalid && (histoForm.get('histoStart').dirty || histoForm.get('histoStart').touched)"
                                        ></p-calendar>
                                </div>
                                <div class="grid mt-2">
                                    <label class="col-3">Jusqu'au (optionnel) <span class="pi pi-info-circle" pTooltip="Si laissée vide, on chargera jusqu'à la fin de l'année AN+2"></span></label>
                                    <p-calendar class="col-9 p-0" appendTo="body" dateFormat="dd/mm/yy"
                                        [showIcon]="true" formControlName="histoEnd"></p-calendar>
                                </div>
                            </div>
                            <div class="col">
                                <div class="grid mt-2">
                                    <label class="col-2">Résas</label>
                                    <p-inputSwitch class="col-10 pt-0 pb-0" aria-label="" formControlName="histoResas"></p-inputSwitch>
                                </div>
                                <div class="grid mt-2">
                                    <label class="col-2">Travaux</label>
                                    <p-inputSwitch class="col-10 pt-0 pb-0" aria-label="" formControlName="histoOOs"></p-inputSwitch>
                                </div>
                                <div class="grid mt-2">
                                    <label class="col-2">Groupes</label>
                                    <p-inputSwitch class="col-10 pt-0 pb-0" aria-label="" formControlName="histoBlocks"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="col-12 mt-1">
                                <p-button label="Charger l'historique depuis cette date" class="w-full" styleClass="w-full" type="submit" [disabled]="histoForm.invalid"
                                    [loading]="loadingHisto" />
                            </div>
                        </form>
                        <!-- <ng-template pTemplate="footer">
                            <div class="grid gap-3 mt-1">
                                <p-button label="Charger l'historique depuis cette date" class="w-full" styleClass="w-full" type="submit" />
                            </div>
                        </ng-template> -->
                    </p-card>
                    <p-card class="mt-4" header="Outils" [style]="{width:'100%',  margin:'3px'}" *ngIf="hotelCredentialsPmsLinkIsSaved">
                        <div class="grid mt-2">
                            <label class="col-3">Recharger une reservation <span class="pi pi-info-circle" pTooltip="Action payante car recharge depuis les apis OperaCloud"></span>
                            </label>
                            <input class="col-2 m-0" type="text" pInputText [(ngModel)]="reservationIdToLoad" />
                            <p-button label="Charger" icon="pi pi-chevron-circle-right" class="col-3 p-0" 
                                [disabled]="!reservationIdToLoad"
                                (click)="reloadReservation(reservationIdToLoad)"
                                [loading]="reloadingReservation"/>
                        </div>
                        <div class="grid mt-2">
                            <label class="col-3">Obtenir une reservation <span class="pi pi-info-circle" pTooltip="Lit la réservation dans storage telle qu'elle a été traitée la dernière fois"></span>
                            </label>
                            <input class="col-2 m-0" type="text" pInputText [(ngModel)]="reservationIdToRead" />
                            <p-button label="Obtenir&nbsp;" icon="pi pi-chevron-circle-right" class="col-3 p-0" 
                                [disabled]="!reservationIdToRead"
                                (click)="getReservation(reservationIdToRead)"
                                [loading]="gettingReservation"/>
                        </div>
                    </p-card>
                    <p-panel class="mt-4" [style]="{width: '100%', margin:'3px'}">
                        <ng-template pTemplate="header" >
                            <div class="col grid" style="padding: 0px; margin: 0px;">
                                <span class="col-6" style="font-size: 1.5rem; font-weight: 700; margin-bottom: 0.5rem;">Types de chambres</span>
                                <span class="col-6" style="text-align: end;">
                                    <b>Total chambres: </b>
                                    <p-badge  [value]="nbrooms" styleClass="mr-2"></p-badge>
                                    <b class="ml-2">Dont réelles: </b>
                                    <p-badge  [value]="nbroomsReal" styleClass="mr-2"></p-badge>
                                </span>
                            </div>
                        </ng-template>
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 590px)'}">
                            <p-table [value]="hotelRoomTypes"  dataKey="code" [scrollable]="true" scrollHeight="calc(100vh - 530px)" styleClass="p-datatable-striped p-datatable-sm">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="code" style="width:10%">Code <p-sortIcon field="code"></p-sortIcon></th>
                                        <th pSortableColumn="name" style="width:20%">Libelle <p-sortIcon field="name"></p-sortIcon></th>
                                        <th pSortableColumn="stock" style="width:13%">Chambres <p-sortIcon field="stock"></p-sortIcon></th>
                                        <th pSortableColumn="isReal" style="width:13%">Réelle <p-sortIcon field="isReal"></p-sortIcon></th>
                                        <th pSortableColumn="isSuite" style="width:13%">Suite <p-sortIcon field="isSuite"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-roomType>
                                    <tr>
                                        <ng-container [ngSwitch]="true" [style.font-style]>
                                            <ng-container *ngSwitchCase="roomType?.name?.length > 80">
                                                <td>{{roomType.code}}</td>
                                                <td>{{roomType.name}}</td>
                                                <td style="max-height: 5px !important;">{{roomType.name?.slice(0,80)}}<span >...</span></td>
                                                <td style="text-align: center;">{{roomType.stock}}</td>
                                                <td>{{roomType.isReal}}</td>
                                                <td>{{roomType.isSuite}}</td>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <td>{{roomType.code}}</td>
                                                <td>{{roomType.name}}</td>
                                                <td style="text-align: center;">{{roomType.stock}}</td>
                                                <td>{{roomType.isReal}}</td>
                                                <td>{{roomType.isSuite}}</td>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-scrollPanel>
                    </p-panel>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <!-- Configuration Compta -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-cog mr-1"></i>
            <span>Configuration Compta</span>
            <i class="ml-3"></i>
        </ng-template>
        <p-progressSpinner
            styleClass="w-1rem h-1rem"
            strokeWidth="8"
            *ngIf="loading"
        ></p-progressSpinner>
        <p-card *ngIf="!loading && hotelComptaNotInitialized">
            <div class="mb-3">
                L'hôtel <span class="font-semibold text-pink-900">{{ currentHotel.name }}</span> n'est pas initialisé pour OperaCloud Compta
            </div>
            <p-button label="Initialiser Compta" icon="pi pi-chevron-circle-right" *ngIf="hotelPmsLinkNotInitialized || !hotelCredentialsPmsLinkIsSaved" (onClick)="initHotelCompta($event) "></p-button>
            <p-button label="Initialiser Compta Depuis PmsLink" icon="pi pi-chevron-circle-right" class="ml-3" *ngIf="!hotelComptaNotPmsLinkInitialized" 
                (onClick)="initFromPmsLinkHotel($event)"
                [loading]="savingComptaFromPmsLink"></p-button>
        </p-card>
        <div class="mr-1" *ngIf="!loading && !hotelComptaNotInitialized && hotelCredentialsCompta != null">
            <p-toolbar [style]="{'padding': '2px'}">
                <div class="p-toolbar-group-left">
                    <p-button label="Activer" icon="pi pi-check-circle" class="mr-1" styleClass="p-button-success" (click)="startCompta()" [disabled]="!hotelCredentialsComptaIsSaved || hotelCredentialsCompta.enabled" [loading]="startingCompta"></p-button>
                    <p-button label="Désactiver" icon="pi pi-times" class="mr-1" styleClass="p-button-danger" (click)="stopCompta()"  [disabled]="!hotelCredentialsComptaIsSaved || !hotelCredentialsCompta.enabled" [loading]="stoppingCompta"></p-button>                   
                </div>
                <!-- <div class="p-toolbar-group-right">
                    <p-button [label]="(hotelCredentialsComptaIsSaved ? 'Modifier' : 'Sauvegarder')" icon="pi pi-save" class="mr-1" styleClass="p-button-warning" [disabled]="!validateCompta(hotelCredentialsCompta)"></p-button>
                </div> -->
            </p-toolbar>
            <div class="grid ml-2 mt-1">
                <p-card class="col-5 grid mt-1" header="Credentials Compta" [style]="{width:'100%',  margin:'3px'}">
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">Activé</label>
                            <p-inputSwitch  class="col-9 pt-0 pb-0" aria-label="" [(ngModel)]="hotelCredentialsCompta.enabled" disabled></p-inputSwitch>
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">HotelId</label>
                            <input class="col-9" type="text" pInputText [(ngModel)]="hotelCredentialsCompta.operaCloudHotelId" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">Password</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.operaCloudPassword" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">UserName</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.operaCloudUserName" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-2">
                            <label class="col-3">BaseUrl</label>
                            <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.operaCloudBaseUrl" />
                        </div>
                    </div>
                    <div>
                        <div class="grid mt-4">
                            <p-button [label]="(hotelCredentialsPmsLinkIsSaved ? 'Modifier' : 'Sauvegarder')" icon="pi pi-save" class="w-full" styleClass="w-full p-button-warning"  
                                [disabled]="!validateCompta(hotelCredentialsCompta)"
                                (onClick)="saveCredentialsCompta()"
                                [loading]="savingCredentialsCompta"></p-button>
                        </div>
                    </div>
                </p-card>
                <div class="col-7 mt-1">
                    <p-card class="mt-1" header="Chargement de l'historique" [style]="{width:'100%', margin:'3px'}" *ngIf="hotelCredentialsComptaIsSaved">
                        <form class="grid" [formGroup]="histoComptaForm" (ngSubmit)="submitHistoComptaForm()">
                            <div class="col">
                                <div class="grid mt-2">
                                    <label class="col-3">Depuis le </label>
                                    <p-calendar class="col-9 p-0" appendTo="body" dateFormat="dd/mm/yy"
                                        [showIcon]="true" formControlName="histoComptaStart"
                                        [class.valid]="histoComptaForm.get('histoComptaStart').valid && (histoComptaForm.get('histoComptaStart').dirty || histoComptaForm.get('histoComptaStart').touched)"
                                        [class.invalid]="histoComptaForm.get('histoComptaStart').invalid && (histoComptaForm.get('histoComptaStart').dirty || histoComptaForm.get('histoComptaStart').touched)"
                                        ></p-calendar>
                                </div>
                            </div>
                            <div class="col"></div>
                            <div class="col-12 mt-1">
                                <p-button label="Charger l'historique depuis cette date" class="w-full" styleClass="w-full" type="submit" [disabled]="histoComptaForm.invalid"
                                    [loading]="loadingHisto" />
                            </div>
                        </form>
                        <!-- <ng-template pTemplate="footer">
                            <div class="grid gap-3 mt-1">
                                <p-button label="Charger l'historique depuis cette date" class="w-full" styleClass="w-full" type="submit" />
                            </div>
                        </ng-template> -->
                    </p-card>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-dialog header="Data" [modal]="true" [(visible)]="dialogJsonData" (onHide)="dialogJsonData = null" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
        <p-toolbar [style]="{'padding': '2px'}">
            <div class="p-toolbar-group-left"></div>
            <div class="p-toolbar-group-right">
                <p-button (click)="copyToClipboard(dialogJsonData)" icon="pi pi-copy" pTooltip="Copier dans le presse-papier"></p-button>
            </div>
        </p-toolbar>
        <textarea 
            rows="20"
            class="w-full"
            pInputTextarea 
            [(ngModel)]="dialogJsonData">
        </textarea>
    </p-dialog>
</p-tabView>