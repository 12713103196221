export class FormErrors {
    errors: ErrorDictionary
    type: string
    title: string
    status: number
    traceId: string
}

export class ErrorDictionary {
    [index: string]: string[]
}
