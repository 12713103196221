<p-card *ngIf="!hotelInitialized && dataLoaded">
    <div>
        {{allPricers.errorMessage }}
    </div>
</p-card>


<div *ngIf="hotelInitialized">

    <div *ngIf="!selectedPricer">
        <p-toolbar>
            <div class="p-toolbar-group-start">

                <p-button *ngIf="!isGrilleSpeciale" styleClass="p-button-success"
                    label="Initialiser une grille Rack principal" (onClick)="showCreatePricer()" icon="pi pi-plus"
                    class="mr-2"></p-button>

                <p-button *ngIf="isGrilleSpeciale" styleClass="p-button-success" label="Créer une grille Spéciale"
                    (onClick)="showCreatePricer()" icon="pi pi-plus" class="mr-2"></p-button>
            </div>
        </p-toolbar>
    </div>

    <div *ngIf="selectedPricer">
        <p-toolbar>
            <div class="p-toolbar-group-start">
                <label for="switch1" class="mr-2">Afficher les produits dérivés</label>
                <p-inputSwitch inputId="switch1" [(ngModel)]="fixSeul"></p-inputSwitch>

                <i class="p-toolbar-separator pi pi-bars mr-2" style="vertical-align: middle"></i>

                <p-splitButton label="Pricer" icon="pi pi-plus" [model]="menuItemPrc" class="mr-3 ml-3"
                    [style]="{'width':'170px'}" styleClass="p-button-success" [menuStyle]="{'min-width':'400px'}">
                </p-splitButton>

                <p-splitButton label="Grille" icon="pi pi-plus" [model]="menuItemPrd" class="mr-3"
                    [style]="{'width':'170px'}" styleClass="p-button-success" [menuStyle]="{'min-width':'400px'}"
                    (onClick)="showCreateGrilleForm()">
                </p-splitButton>

                <p-splitButton label="Niveau tarifaire" icon="pi pi-plus" [model]="menuItemNiv"
                    [style]="{'width':'170px'}" styleClass="p-button-success" [menuStyle]="{'min-width':'400px'}"
                    (onClick)="displayCreatePricerNivForm=true">
                </p-splitButton>


            </div>

        </p-toolbar>

        <div class="rackList mt-3">
            <div *ngFor="let prc of pricers" class="rackItem  mr-3" [ngClass]="getSeletedRackClass(prc)"
                (click)="select_Pricer_Rack(prc)">
                {{prc.libelle}}
            </div>
        </div>

        <!-- //*ngIf="selectedPricer.grilles.length>1" -->
        <div class="mt-3 ml-3">
            <div *ngFor="let grd of selectedPricer?.grilles" class="grilleItem  mr-3"
                [ngClass]="grd.code==selectedGrille.code?'rackItemSelected':''" (click)="select_grille_Rack(grd)"
                [pTooltip]="getDatesValidationToolTip(grd?.datesValidation?.description)" [escape]="false"
                tooltipPosition="top">
                {{grd.libelle}}
                <i *ngIf="grd?.datesValidation" (click)="SetDisplaydatesValidationForm(grd)" class="pi pi-calendar ml-2"
                    style="font-size: 1rem">
                </i>
            </div>
        </div>





        <div class="mt-3 tablePrice" *ngIf="!!niveaux">
            <table class="tablePrix">
                <thead class="p-datatable-thead">
                    <tr>
                        <th></th>
                        <th *ngFor="let niv of niveaux; let index=index" class="nivItem" (click)="selectNiveau(niv)">
                            <div [pTooltip]="niv.datesValidation?.description" tooltipPosition="top">
                                <span [ngClass]="getClassNivSelected(niv)">{{niv.libelle}} </span>

                                <i *ngIf="niv.datesValidation" (click)="SetDisplaydatesValidationNivForm(niv)"
                                    class="pi pi-calendar ml-2" style="font-size: 1rem"></i>
                            </div>

                        </th>
                    </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr *ngFor="let prdvu of fixedProduitVenduUnit" (click)="selectPrdvu(prdvu)">
                        <td [ngClass]="GetPrdvClass(prdvu)">
                            <span [ngClass]="getClassPrdvuSelected(prdvu)">
                                {{prdvu.libelle}}
                            </span>
                        </td>
                        <td *ngFor="let niv of niveaux; let index=index" class="prix"
                            [ngClass]="GetPrixFixClass(niv,prdvu)" (click)="displayFixePrix(niv,prdvu)">

                            {{getPrixFix(niv,prdvu) | number: '1.2-2' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="displayCreatePriceRack">
    <p-dialog [(visible)]="displayCreatePriceRack" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            <span *ngIf="!isGrilleSpeciale">
                Initialiser une grille Rack
            </span>
            <span *ngIf="isGrilleSpeciale">
                Créer une Grille Spéciale
            </span>

        </p-header>
        <app-form-dynamic #newMainPricer [formModel]="createPricerRackForm" [formValue]=""
            (OnCancel)="displayCreatePriceRack=false" (OnValidate)="CreatePricer($event)" [debug]="false">
        </app-form-dynamic>

    </p-dialog>
</div>

<div *ngIf="displayFixePrixForm">
    <p-dialog [(visible)]="displayFixePrixForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Fixer le prix de {{fixePrixFormHeader}}
        </p-header>
        <app-form-dynamic [formModel]="fixPricerRackForm" [formValue]="fixePrixFormValue"
            (OnCancel)="displayFixePrixForm=false" (OnValidate)="SetOrRemoveFixPrice($event)">
        </app-form-dynamic>
    </p-dialog>
</div>


<div *ngIf="displayCreatePricerNivForm">
    <p-dialog [(visible)]="displayCreatePricerNivForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Ajout d'un niveau tarifaire
        </p-header>
        <app-form-dynamic [formModel]="createPricerNiveauForm" [formValue]=""
            (OnCancel)="displayCreatePricerNivForm=false" (OnValidate)="CreatePricerNiveau($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayRenamePricerNivForm">
    <p-dialog [(visible)]="displayRenamePricerNivForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Renommer le niveau tarifaire
        </p-header>
        <app-form-dynamic [formModel]="renameObjectForm" [formValue]="RanameNivFormValue"
            (OnCancel)="displayRenamePricerNivForm=false" (OnValidate)="renameCurrentNiveau($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displaydatesValidationForm">
    <p-dialog [(visible)]="displaydatesValidationForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Config de la période de validité de la grille {{ selectedGrille.libelle}}
        </p-header>
        <app-form-dynamic [formModel]="datesValidationForm" [formValue]="dateValidationFormValue"
            (OnCancel)="displaydatesValidationForm=false" (OnValidate)="SetDatesValidationGrille($event)">
        </app-form-dynamic>
    </p-dialog>
</div>
<div *ngIf="displaydatesValidationNivForm">
    <p-dialog [(visible)]="displaydatesValidationNivForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Config de la période de validité du niveau {{ selectNiveau.libelle}}
        </p-header>
        <app-form-dynamic [formModel]="datesValidationForm" [formValue]="dateValidationNivFormValue"
            (OnCancel)="displaydatesValidationNivForm=false" (OnValidate)="SetDatesValidationNiveau($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayCreateGrilleForm">
    <p-dialog [(visible)]="displayCreateGrilleForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Nouvelle grille
        </p-header>
        <app-form-dynamic [formModel]="createGrilleForm" [formValue]="" (OnCancel)="displayCreateGrilleForm=false"
            (OnValidate)="CreateGrille($event)">
        </app-form-dynamic>
    </p-dialog>
</div>


<div *ngIf="displayRenameGrilleForm">
    <p-dialog [(visible)]="displayRenameGrilleForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Renommer la grille tarifaire
        </p-header>
        <app-form-dynamic [formModel]="renameObjectForm" [formValue]="RanameGrilleValue"
            (OnCancel)="displayRenameGrilleForm=false" (OnValidate)="renameCurrentGrille($event)">
        </app-form-dynamic>
    </p-dialog>
</div>


<div *ngIf="displayMovPrdvuToPricerForm">
    <p-dialog [(visible)]="displayMovPrdvuToPricerForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Déplacer le produit {{selectedPrdvu?.libelle}}
        </p-header>
        <app-form-dynamic [formModel]="movPrdvuToPricerForm" (OnCancel)="displayMovPrdvuToPricerForm=false"
            (OnValidate)="deplacerPrdvuVersPricer($event)">
        </app-form-dynamic>
    </p-dialog>
</div>



<div *ngIf="displayPlanningGrille">
    <div>
        <p-dialog [(visible)]="displayPlanningGrille" [modal]="true" [style]="{width: '96vw'}">
            <p-header>
                Planning des grilles pour le pricer {{selectedPricer?.libelle}}
            </p-header>
            <app-calendrier-annuel [events]="currentGrilleEvents"></app-calendrier-annuel>
        </p-dialog>
    </div>
</div>

