<p-toolbar styleClass="mb-1">
    <div class="p-toolbar-group-left">
        <p-button [disabled]="(selectedUserRoles?.length > 0 && selectedUserHotels.length == 0 || selectedUserRoles?.length == 0 && selectedUserHotels.length > 0)
            && !selectedUserRoles.includes(adminRole) && !selectedUserRoles.includes(supportRole)" [loading]="saveLoading"
            label="Valider" icon="pi pi-check" class="mr-2" styleClass="p-button-success" (onClick)="validate(f)">
        </p-button>
        <button type="button" pButton icon="pi pi-times" label="Annuler" (click)="cancel($event)"></button>
    </div>
    <div class="p-toolbar-group-right">
        <p-button icon="pi pi-times" (click)="close()" styleClass="p-button-danger"></p-button>
    </div>
</p-toolbar>
<div class="grid">
    <div class="col-6">

        <p-panel>
            <ng-template pTemplate="header">
                <span style="padding: 6px;"><b>Avatar</b></span>
            </ng-template>
            <div class="grid">
                <div class="col-fixed" style="width: 70Px;">
                    <label class="hoverable" style="color: rgb(169, 8, 150); font-size: 12px; cursor: pointer;">
                        <input #inputFile type="file" (change)="handleProfilePictureInput($event)"
                            style="display: none;" formControlName="avatarProfile" accept="image/*" pInputText />
                        <img style="border-radius: 25px; margin-left: 15px;" [src]="avatarSrc" alt="" srcset=""
                            height="50" width="50" />
                        <div class="hover-text"><i
                                [ngClass]="{'pi pi-plus' : avatarSrc == null || avatarSrc == '' , 'pi pi-pencil': avatarSrc != null || avatarSrc != '' }"></i>
                        </div>
                        <div class="background"></div>
                    </label>
                </div>
                <div class="col-fixed" style="width: 20Px;">
                    <div *ngIf="avatarSrc != null && avatarSrc != ''" class="mt-2"
                        style="display: inline-block; margin-left: 10px;  ">
                        <p-button icon="pi pi-trash" styleClass="p-button-danger" (click)="deleteImg($event)"
                            [disabled]="">
                        </p-button>
                    </div>
                </div>
                <div class="col-fixed ml-6" style="width: 225Px;">
                    <span class="ml-2">Pays</span>
                    
                        <p-dropdown [options]="countries"  [(ngModel)]="selectedCountry" optionLabel="name"  placeholder="-"
                        [style]="{'width': '223px', 'min-height': '28px', 'margin-top':'5px', 'margin-left':'5px'}" >
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                                    <div>{{ selectedCountry.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                   
                </div>
                <div class="col-fixed " style="width: 220Px;">
                    <span class="m-2">Langue</span>
                        <p-dropdown [options]="languages"  [(ngModel)]="selectedLanguage" optionLabel="name"  placeholder="-"
                        [style]="{'width': '223px', 'min-height': '28px', 'margin-top':'5px', 'margin-left':'5px'}" [ngClass]="{'p-error': submitted && f.displayName.errors }">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedLanguage">
                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedLanguage.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ selectedLanguage.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ country.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                </div>
                
            </div>
            <div *ngIf="isSizeExceeded"><small id="" class="p-error"> La taille de l'image ne doit
                    pas dépasser 100 ko </small>
            </div>
        <div *ngIf="submitted" class="invalid-feedback" style="margin-left: 130px;">
            <div *ngIf="isCountrySelected"><small id="" class="p-error">
                Pays est un champ requis</small>
            </div>
        </div>
        </p-panel>

        <div style="padding-bottom: 0;">
            <form *ngIf="lineValue" [formGroup]="registerForm" (ngSubmit)="validate()">
                <div class="formgrid grid mt-2">
                    <div class="field col">
                        <label for="azureADB2CId">Azure AD B2C</label>
                        <input pInputText id="azureADB2CId" class=" w-full" formControlName="azureADB2CId" readonly>
                    </div>
                    <div class="field col">
                        <label for="Mot de passe"> Mot de passe</label>
                        <span class="p-input-icon-right w-full ">
                            <i *ngIf="!isUserPasswordLoaded" class="pi pi-eye" (click)="getUserPassword()"
                                style="z-index: 2000;"></i>
                            <i *ngIf="isUserPasswordLoaded" class="pi pi-copy" (click)="copyText(userPassword)"
                                style="z-index: 2000; cursor:pointer;" [ngClass]="{ 'p-success': isCopied }"></i>
                            <input class="w-full" [value]="userPassword" disabled pInputText />
                        </span>


                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <label for="username">Login<i style="color:red"> * </i> </label>

                        <input formControlName="username" class="w-full" (blur)="checkUserIfExist(f.username.value)"
                            [ngClass]="{'p-error':(submitted && f.familyName.errors) || (userNameList?.indexOf(f.username.value) >= 0 || f.username.value == ''  || f.username.value == ' ')  && f.username.value != lineValue.username}"
                            pInputText />

                        <div
                            *ngIf="userNameList?.indexOf(f.username.value) >= 0 && f.username.value != ''  && f.username.value != ' ' && f.username.value != lineValue.username">
                            <small id="" class="p-error">un user existe avec le même
                                username </small>
                        </div>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required"><small id="" class="p-error">
                                    Login est un champ requis</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col ">
                        <label for="familyName" class="col-6 ">Nom<i style="color:red">*</i></label>
                        <input formControlName="familyName" class="w-full"
                            [ngClass]="{ 'p-error': submitted && f.familyName.errors }" pInputText />
                        <div *ngIf="submitted && f.familyName.errors" class="invalid-feedback">
                            <div *ngIf="f.familyName.errors?.required"><small id="" class="p-error">Nom est
                                    un
                                    champ requis</small>
                            </div>
                        </div>
                    </div>
                    <div class="field col">
                        <label for="givenName" class="col-6">Prénom<i style="color:red">*</i></label>
                        <input formControlName="givenName" class="w-full"
                            [ngClass]="{ 'p-error': submitted && f.givenName.errors }" pInputText />
                        <div *ngIf="submitted && f.givenName.errors" class="invalid-feedback">
                            <div *ngIf="f.givenName.errors.required"><small id="" class="p-error">Prénom est un
                                    champ requis </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="email"> Email<i style="color:red">*</i> </label>
                        <input formControlName="email" class="w-full" (blur)="checkUserIfExist(f.email.value, true)"
                            [ngClass]="{'p-error': (isEmailError) || (submitted && f.email.errors) ||(emailList?.indexOf(f.email.value) >= 0 || f.email.value == ''  || f.email.value == ' ')  &&
                                    f.email.value != lineValue.email}" pInputText />
                        <div
                            *ngIf="(isEmailError) || (emailList?.indexOf(f.email.value) >= 0 && f.email.value != ''  && f.email.value != ' ' && f.email.value != lineValue.email)">
                            <small id="" class="p-error">un user existe avec le
                                même email</small>
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required"><small id="" class="p-error">
                                    Email est un champ requis</small></div>
                        </div>
                    </div>
                    <div class="field col ">
                        <label for="mobilePhone">Téléphone</label>
                        <input type="mobilePhone" formControlName="mobilePhone"  pInputText class="w-full" [ngClass]="{'p-error': submitted && f.mobilePhone.errors }" />
                        <div *ngIf="submitted && f.mobilePhone.hasError('pattern')" class="invalid-feedback">
                            <div *ngIf="f.mobilePhone.hasError('pattern')"><small id="" class="p-error">Numero de téléphone invalid</small></div>
                        </div>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="displayName">Nom d'affichage<i style="color:red">*</i> </label>
                        <input formControlName="displayName" class="w-full"
                            [ngClass]="{'p-error': submitted && f.displayName.errors }" pInputText />
                        <div *ngIf="submitted && f.displayName.errors" class="invalid-feedback">
                            <div *ngIf="f.displayName.errors.required"><small id="" class="p-error">Nom
                                    d'affichage
                                    est un champ requis</small></div>
                        </div>
                    </div>
                </div>
                <div class="formgrid grid" style="margin-bottom: -1.2rem;">
                    <div class="field col">
                        <label for="accessDate_id"> Date d'accès </label>
                        <div class="w-full">
                            <p-calendar inputId="calendar" formControlName="accessDate" id="accessDate_id"
                                [style]="{'width':'100%'}" appendTo="body" dateFormat="dd/mm/yy"
                                [showIcon]="true"></p-calendar>
                        </div>
                    </div>

                    <div class="field col">
                        <label for="expirationDate"> Date expiration </label>
                        <div class="w-full">
                            <p-calendar inputId="calendar" class="w-full" formControlName="expirationDate"
                                [style]="{'width':'100%'}" dateFormat="dd/mm/yy" [minDate]="f.accessDate.value"
                                [readonlyInput]="true"
                                [ngClass]="{'ng-invalid ng-dirty':f.expirationDate.value && f.accessDate.value > f.expirationDate.value }"
                                [showIcon]="true">
                            </p-calendar>
                            <div *ngIf="f.expirationDate.value && f.accessDate.value > f.expirationDate.value "
                                class="ng-invalid ng-dirty">
                                <small id="" class="p-error">
                                    Choisir une date suppérieur à la date d'accès
                                </small>
                            </div>
                        </div>

                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="col-6">
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-6">
                <p-panel>
                    <ng-template pTemplate="header" class="grid">
                        <span class="col-10"><b>Liste hotels
                            </b></span>
                        <!-- <p-badge [value]="selectedUserHotels?.length" styleClass="mr-2"></p-badge> -->
                    </ng-template>
                    <p-listbox class="m-0" [options]="userHotels" optionLabel="name" [(ngModel)]="selectedUserHotels"
                        [checkbox]="true" [multiple]="true" [listStyle]="{'max-height':'115px'}">
                        <ng-template pTemplate="header">
                            <div class="p-listbox-filter-container">
                                <input type="text" [value]="filterValue||''" [(ngModel)]="filterValue"
                                    (input)="userHotelsFilter(filterValue, 'userHotels', 'hotCode', 'name', 'selectedUserHotels')"
                                    class="p-listbox-filter p-inputtext p-component">
                                <span class="p-listbox-filter-icon pi pi-search"></span>
                            </div>
                        </ng-template>
                        <ng-template let-hotel pTemplate="item">
                            <div class="hotel-item" style="width: 100%;">
                                <div class="grid">
                                    <div class="col-11">
                                        <span class="">{{hotel.hotCode}}
                                            {{hotel.name}}
                                        </span>
                                    </div>
                                    <div class="col-1  p-text-right">
                                        <i class="pi" style="display: inline-block;" (click)="setDenyUserHotel(hotel)"
                                            [ngClass]="hotel.deny ? 'pi-times-circle color-red': 'pi-check-circle color-green'">
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </p-panel>
                <div
                    *ngIf="selectedUserRoles?.length > 0 && selectedUserHotels.length == 0 && !selectedUserRoles.includes(adminRole) && !selectedUserRoles.includes(supportRole) ">
                    <small id="" class="p-error">Aucun hôtel n'est selectionné </small>
                </div>
            </div>
            <div class="col-12 md:col-12 lg:col-6">
                <p-panel>
                    <ng-template pTemplate="header" class="grid">
                        <span class="col-10" (click)="(displayListeRoles = true)" style="cursor:pointer;"><b>Liste des
                                roles</b></span>
                        <p-badge [value]="selectedUserRoles?.length" styleClass="mr-2"></p-badge>
                    </ng-template>
                    <p-listbox class="m-0" [options]="userRoles" optionLabel="name" [checkbox]="true"
                        [(ngModel)]="selectedUserRoles" [multiple]="true" [listStyle]="{'max-height':'157px'}">
                        <ng-template let-role pTemplate="item">
                            <div class="role-item" style="width: 100%;">
                                <div class="grid">
                                    <div class="col-10">
                                        {{role.name}}</div>
                                    <div class="col p-text-right">
                                        <i class="pi" style="display: inline-block;" (click)="setDenyUserRole(role)"
                                            [ngClass]="role.deny ? 'pi-times-circle color-red': 'pi-check-circle color-green'">
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-listbox>
                </p-panel>
                <div
                    *ngIf="selectedUserRoles?.length == 0 && selectedUserHotels.length > 0 && !selectedUserRoles.includes(adminRole) && !selectedUserRoles.includes(supportRole) ">
                    <small id="" class="p-error">Aucun rôle n'est selectionné </small>
                </div>
            </div>
            <div class="col-12 md:col-12 lg:col-12">
                <p-panel>
                    <ng-template pTemplate="header" class="grid">
                        <span class="col-11"><b>Liste Groupes </b></span>
                        <p-badge [value]="selectedUserGroup?.length" styleClass="mr-2"></p-badge>
                    </ng-template>
                    <p-listbox class="m-0" [options]="userGroups" optionLabel="name" [(ngModel)]="selectedUserGroup"
                        [checkbox]="true" [multiple]="true" [listStyle]="{'max-height':'157px'}">
                    </p-listbox>
                </p-panel>
            </div>
        </div>

    </div>

</div>

<p-dialog *ngIf="displayListeRoles" position="right" [(visible)]="displayListeRoles" [modal]="true"
    [style]="{width: '620px'}">
    <p-header>
        Liste des roles
    </p-header>
    <p-table [columns]="colsRole" [value]="roles">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-footer style="margin-left: 50.2%;">
        <button type="button" pButton icon="pi pi-times" label="Fermer" (click)="closeDisplayRole()"></button>
    </p-footer>
</p-dialog>
