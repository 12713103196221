import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProduitVendu } from '../../services/model/produitVendu';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribSessionService } from '../../session/distrib-session.service';
import { ActivatePrestationCmd, ActivateProduitVenduCmd, AllProduitstHotel, DistribSession, NewPrestationCmd, NewProduitVenduCmd, Prestation, UpdatePrestationCmd, UpdateProduitVenduCmd } from '../../services/model/models';
import { FormDynamicComponent } from 'src/app/dynamicForms/form-dynamic/form-dynamic.component';
import { ProduitService } from '../../services/api/api';
import { ErrorDictionary, FormErrors } from 'src/app/dynamicForms/models/form-errors';
import { FormConfig } from 'src/app/dynamicForms/models/FormConfig';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss']
})
export class PrestationsComponent implements OnInit, OnDestroy  {

    private readonly destroy$: Subject<void>;
    @Input() allProduits: AllProduitstHotel;
    @Output() OnRealodData = new EventEmitter()

    displayPrsForm: boolean;
    prsFormConfig: FormConfig;

    @ViewChild('formprs') formDynamicPrs: FormDynamicComponent;
    formPrsValue: any;

    selectedPrs: Prestation;
    isNewPrs: boolean;

    constructor(private userInfoService: UserInfoService,
        private messageService: MessageService, private notifService: NotificationsService, private produitService: ProduitService,
        private confirmationService: ConfirmationService, private distribSessionService: DistribSessionService) {

        this.destroy$ = new Subject();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {

        this.produitService.apiProduitPrsFormGet(this.userInfoService.currentHotel.hotCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe(frm => {
                this.prsFormConfig = JSON.parse(JSON.stringify(frm)) as FormConfig;
            });

    }

    cancel($event): void {
        this.displayPrsForm = false;
    }


    UpdatePrs(selectedPrs: Prestation): void {
        this.SetParamCode(true);  // rendre le champ code non editable et  requis
        this.isNewPrs = false;
        this.selectedPrs = selectedPrs;
        this.formPrsValue = {
            prestation: selectedPrs
        };
        this.displayPrsForm = true;
    }

    NewPrs(): void {

        this.SetParamCode(false);  // rendre le champ code editable et non requis
        this.isNewPrs = true;
        this.formPrsValue = {
            produitVendu: {}
        };
        this.displayPrsForm = true;
    }


    DelPrs(selectedPrs: Prestation):void {

        let cmd: ActivatePrestationCmd = {
            hotCode: this.allProduits.hotCode,
            activate:!selectedPrs.isObsolete,
            code:selectedPrs.code
        };

        this.produitService.apiProduitPrestationActivatePut(cmd)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
            next: session => {
                this.distribSessionService.newSession(session.hotCode, session.sessionId);
                this.OnRealodData.emit();
            },
            error: err => {
                this.notifService.setMessage(err.error.error, "Prestations", true);
            }
        })

    }

    validateFormPrs(value: any): void {
        if (this.isNewPrs) {
            this.NewPrsInDB(value)
        }
        else {
            this.UpdatePrsInDB(value);
        }
    }

    UpdatePrsInDB(value: any): void {
        const newPrs: Prestation = value.prestation;

        let cmd: UpdatePrestationCmd = {
            hotCode: this.allProduits.hotCode,
            prestation: newPrs,
        };

        this.produitService.apiProduitPrestationPut(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.UpdateCompoment(session, newPrs);
                },
                error: err => {
                    this.SetError(err);
                }
            })
    }

    NewPrsInDB(value: any): void {
        const newPrs: Prestation = value.prestation;

        let cmd: NewPrestationCmd = {
            hotCode: this.allProduits.hotCode,
            prestation: newPrs,
        };

        this.produitService.apiProduitPrestationPost(cmd)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: session => {
                    this.UpdateCompoment(session, newPrs);
                    this.OnRealodData.emit();
                },
                error: err => {
                    this.SetError(err);
                }
            })
    }

    UpdateCompoment(session: DistribSession, newPrs: Prestation): void {
        this.displayPrsForm = false;
        let oldInv = this.allProduits.prestationList.find(x => x.code == newPrs.code);
        if (oldInv) {
            Object.assign(oldInv, { ...newPrs });
        }
        else {
            this.allProduits.prestationList.push(newPrs);
        }
        this.distribSessionService.newSession(session.hotCode, session.sessionId);
    }

    SetError(session: any): void {
        let error = new FormErrors();
        error.errors = new ErrorDictionary();
        error.errors["produitVendu"] = [session.error.error];  // inventaire c'est le vertical groupe racine
        this.formDynamicPrs.setExternalErrors(error);
    }

    private SetParamCode(update: boolean) {
        let paramCode = this.prsFormConfig?.parameters[0]?.childParameters?.find(x => x.code == "code");
        if (paramCode) {
            paramCode.readOnly = update;
            paramCode.required = update;
        }
    }

}
