import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormParameter } from '../../models/form-parameter';
import { FormControl2 } from '../../models/formControl2';
import { FormGroup2 } from '../../models/formGroup2';
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
  selector: 'app-multi-check-box',
  templateUrl: './multi-check-box.component.html',
  styleUrls: ['./multi-check-box.component.css']
})
export class MultiCheckBoxComponent implements OnInit, AfterViewInit {

    @Input() formGroup: FormGroup;
    @Input() field: FormParameter;
    formControl: FormControl2;

    parentFormGroup: FormGroup2
    typeParrentGroup: string;
    tooltipzindex=TOOL_TIP_Z_INDEX;

    _selectedValues:Array<string>;


    ngAfterViewInit(): void {

    }


 get selectedValues():Array<string>
    {
        return this._selectedValues;
    }

    set selectedValues(value:Array<string> )
    {
        this._selectedValues=value;

        this.formControl.update_Value_And_Validity();
        this.formControl.setValue(value)
    }
    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        console.log(this.formControl.value)
        this._selectedValues=this.formControl.value;

        if (this.formControl.parent instanceof FormGroup2) {
            this.parentFormGroup = this.formControl.parent as FormGroup2
            if (this.parentFormGroup && this.parentFormGroup.formParam) {
                this.typeParrentGroup = this.parentFormGroup.formParam.type
            }
        }
    }



}
