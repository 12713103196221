import {Component, Output} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {environment} from '../environments/environment';
import { NotificationsService } from './services/notifications/notifications.service';

@Component({
    selector: 'app-rightpanel',
    template: `
        <div class="layout-right-panel" [ngClass]="{'layout-right-panel-active': app.rightPanelActive}" (click)="app.onRightPanelClick()">
                <div class="right-panel-scroll-content">
                    <p-tabView (onChange)="chooseScreen($event)" >
                    <p-tabPanel *ngIf="!app.rightPanelActive1"  header="Notifications" [selected]="true" >
                            <div  class="submenu-content notes">
                                <ul>
                                <li class="grid " *ngFor="let msg of getMessages(); let i = index">
                                   <div  class="col-1 m-2" style = "margin-top: 10px;" class="message-actions">
                                            <i class="pi pi-circle-on delete"  [ngStyle]="{ 'color':msg.isError ? 'red' : 'limegreen'}" ></i>
                                    </div>
                                   <div class="col-10 ">
                                   {{msg.message}}
                                   </div>
                                    
                                    <div  class="col-1 m-2" style = "margin-top: 10px;" class="message-actions">
                                            <i class="pi pi-times-circle delete" (click)="deleteMessage(i)" [ngStyle]="{ 'color': 'red' }" ></i>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel *ngIf="isStatus" header="Status" >
                            <div   class="submenu-content settings">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box wireless-status">
                                            <i class="pi pi-wifi box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">WIRELESS STATUS</span>
                                            <span class="status">Stable</span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box cooling-systems">
                                            <i class="pi pi-compass box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">COOLING SYSTEMS</span>
                                            <span class="status">Stable</span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box hq-security">
                                            <i class="pi pi-lock box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">HQ SECURITY</span>
                                            <span class="status">Critical</span>
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box help-desk-load">
                                            <i class="pi pi-envelope box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">HELP DESK LOAD</span>
                                            <div class="progress-bar">
                                                <div class="progress"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box meeting-intensity">
                                            <i class="pi pi-calendar box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">MEETING INTENSITY</span>
                                            <span class="status">Stable</span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <div class="status-box energy-backup ">
                                            <i class="pi pi-info-circle box-icon"></i>
                                            <i class="pi pi-angle-right icon-details"></i>
                                            <span class="status-name">ENERGY BACKUP</span>
                                            <div class="progress-bar">
                                                <div class="progress"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel *ngIf="isEvironement" header="Environments">
                            <div  class="submenu-content messages">
                                <ul>
                                    <li>
                                        <span> Production:{{environments.production}}
                                        </span>
                                    </li>
                                    <li>
                                        <span>Version: {{environments.version}}
                                        </span>
                                    </li>
                                    <li>
                                        <span>urlRateShopper:<br>{{environments.urlRateShopper}}
                                        </span>
                                    </li>
                                    <li>
                                    <span>urlUser:<br> {{environments.urlUser}}
                                    </span>
                                    </li>
                                    <li>
                                    <span>urlAnalyse:<br> {{environments.urlAnalyse}}
                                    </span>
                                    </li>
                                    <li>
                                    <span>urlDistrib:<br> {{environments.urlDistrib}}
                                    </span>
                                    </li>
                                    <li>
                                    <span> {{environments.urlServices}}
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </p-tabPanel>
                        
                    </p-tabView>
                </div>
        </div>
        <div class="layout-right-panel" [ngClass]="{'layout-right-panel-active': app.rightPanelActive1}" (onClick)="app.onRightPanelClick1()">
                <div class="right-panel-scroll-content">
                    <p-tabView >
                        
                        <p-tabPanel  header="Environments">
                            <div  class="submenu-content messages">
                                <ul>
                                    <li>
                                        <span> Production:{{environments.production}}
                                        </span>
                                    </li>
                                    <li>
                                        <span>Version: {{environments.version}}
                                        </span>
                                    </li>
                                    <li *ngFor="let key of keys">
                                        <span>{{ environments[key]}}</span>
                                    </li>                                    
                                </ul>
                            </div>
                        </p-tabPanel>
                        
                    </p-tabView>
                </div>
        </div>
    `
})
export class AppRightPanelComponent {
    environments: any;
    activeIndex : number =0;
    isNotes: boolean = true;
    isEvironement: boolean = false;
    isStatus: boolean = false;
    keys: string[];
    constructor(public app: AppMainComponent,
        private notificationService: NotificationsService) {
        this.environments = environment;
     } 
     ngOnInit(): void {
        this.chooseScreen(0);
        this.keys = Object.keys(this.environments).filter(key=> key.indexOf('url') == 0);
      } 
     getMessages(){
        return this.notificationService.getMessage();
        this.chooseScreen(0);
     } 

     deleteMessage(index: number){
        this.notificationService.deleteMessage(index);
        //this.notificationService.decreaseCount();
        
    }
    chooseScreen(event){
        console.log(event);
        let index = event.index;
        this.isNotes = true;
        this.isEvironement = false;
        this.isStatus = false;
        switch (index) {
          case 0:
            this.isNotes = true;
            break;
          case 1:
            this.isEvironement = true; 
          break;
           case 2:
            this.isStatus = true;   
            break;
          default:
            this.isNotes = true;
        }
      }
    
}
