import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TauxTva } from '../../services/model/tauxTva';

@Component({
  selector: 'app-form-budget-tva',
  templateUrl: './form-budget-tva.component.html',
  styleUrls: ['./form-budget-tva.component.css']
})
export class FormBudgetTvaComponent implements OnInit, AfterViewInit {

  @Input() tvaFormValue: TauxTva;
  @Output() OnValidate = new EventEmitter();
  @Output() OnCancel = new EventEmitter();
  @Output() OnSelecteDate = new EventEmitter();
  @Input() isNewTva: boolean;
   minDate: Date;
   maxDate : Date;
  @Input() isValideDate: boolean = true;
  @Input() isBlank: boolean = false;

  afterViewInit: boolean;
  ngAfterViewInit(): void {
    this.afterViewInit = true;
  }

  ngOnInit(): void {
  }
 
  validate(event) {
    this.OnValidate.emit(event);
  }
  selecteDate(event){
    this.OnSelecteDate.emit(event);
  }

  cancel(event){
  this.OnCancel.emit(event);

  }

}
