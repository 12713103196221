import { Component, Input, OnInit } from '@angular/core';
import { RowSci } from '../../models/RowSci';

@Component({
  selector: 'app-cell-month',
  templateUrl: './cell-month.component.html',
  styleUrls: ['./cell-month.component.css']
})
export class CellMonthComponent implements OnInit {

  constructor() { }

  @Input("rowSci")
  rowSci: RowSci

  @Input("cellid")
  cellId: number

  cellHeight:number;

  ngOnInit(): void {
    this.cellHeight = this.rowSci.height;
  }

  getClass(): string {
    let month = this.rowSci.cells[this.cellId].momentCell.format("M");
    let monthValue = Number(month);
    if (monthValue % 2 == 0) return "cellEven";
    if (monthValue % 1 == 0) return "cellOdd";
    return"";
  }

  getMonthName(): string {
    let result = this.rowSci.cells[this.cellId].momentCell.format("MMMM");
    result = result.charAt(0).toUpperCase() + result.slice(1)
    result = result.replace('.', '');
    return result;
  }
  getYear(): string {
    let result = this.rowSci.cells[this.cellId].momentCell.format("YYYY");
    return result;
  }

  getX() {
    return this.rowSci.cells[this.cellId].x;
  }

  getFisrtDay(): boolean {
    return this.rowSci.cells[this.cellId].isFisrtVisibleInMonth;
  }

  //determiner la longeur de la cellule  ( parcourrir la liste jusqu'au visible suivant)

  getWith(): number {
    let result = 0;

    if (this.getFisrtDay()) {

      let currentCell = this.rowSci.cells[this.cellId];

      let nextCellId: number = null;
      for (let i = 0; i < this.rowSci.firstVisibleCellsInMonth.length; i++) {
        const _cell = this.rowSci.firstVisibleCellsInMonth[i];

        if (_cell.cellId != currentCell.cellId && _cell.index > currentCell.index) {  // index represente le temps
          nextCellId = _cell.cellId;
          break;
        }
      }
      if (nextCellId != null) {
        let nextCell = this.rowSci.cells[nextCellId];
        result = (nextCell.index - currentCell.index) * this.rowSci.tableParent.cellWith;
      }
      else result = (this.rowSci.tableParent.cellsCount * this.rowSci.tableParent.cellWith - currentCell.x);

    }
    return result;
  }
}
