<span *ngIf="field.label" class="title-group mb-1">
    {{ field.label }}
</span>

<div class="mb-2">
    <p-listbox [options]="field.possibleValues" [(ngModel)]="selectedValues" [filter]="field.possibleValues.length>20"
        optionLabel="label" optionValue="code" [ngClass]="formControl?.getErrorsMessage()?'ng-invalid ng-dirty':''"
        [listStyle]="{'height': '310px'}" [pTooltip]="field.toolTip" tooltipPosition="top"
        [tooltipZIndex]="tooltipzindex">
    </p-listbox>
    <small class="p-invalid">{{ formControl?.getErrorsMessage() }}
    </small>
</div>