<p-card *ngIf="hotelNotIntialized">
    <div>
        l'hôtel
        <span class="font-semibold text-pink-900"
            >{{ currentHotel.name }}
        </span>
        n'est pas initialisé
    </div>
</p-card>
<p-tabView styleClass="tabview-custom">
    <!-- Configuration -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-cog mr-1"></i>
            <span>Configuration</span>
        </ng-template>
        <div class="colonne">
            <span style="align-self: center">Hôtel Id</span>

            <div *ngIf="dataLoad">
                <input
                    type="text"
                    placeholder="Chargement en cours"
                    pInputText
                    [style]="{
                        width: '560px'
                    }"
                />
            </div>
            <div *ngIf="!dataLoad">
                <p-inputNumber
                    [(ngModel)]="idHotel"
                    mode="decimal"
                    [useGrouping]="false"
                    inputId="integeronly"
                    [style]="{ width: '560px' }"
                    [ngClass]="
                        isFormInvalid && !idHotel ? 'ng-dirty ng-invalid' : ''
                    "
                ></p-inputNumber>

                <div
                    *ngIf="isFormInvalid && !idHotel"
                    class="ng-invalid ng-dirty"
                    style="text-align: left"
                >
                    <small id="" class="p-error"
                        >L'ID de l'hôtel est requis
                    </small>
                </div>
            </div>

            <span style="align-self: center">Login</span>
            <div *ngIf="dataLoad">
                <input
                    type="text"
                    placeholder="Chargement en cours"
                    pInputText
                    [style]="{
                        width: '560px'
                    }"
                />
            </div>
            <div *ngIf="!dataLoad">
                <input
                    [(ngModel)]="loginHotel"
                    type="text"
                    pInputText
                    [style]="{
                        width: '560px'
                    }"
                    [ngClass]="
                        isFormInvalid && !loginHotel
                            ? 'ng-dirty ng-invalid'
                            : ''
                    "
                />
                <div
                    *ngIf="isFormInvalid && !loginHotel"
                    class="ng-invalid ng-dirty"
                    style="text-align: left"
                >
                    <small id="" class="p-error"
                        >Le Login de l'hôtel est requis
                    </small>
                </div>
            </div>
            <span style="align-self: center">Password</span>
            <div *ngIf="dataLoad">
                <input
                    type="text"
                    placeholder="Chargement en cours"
                    pInputText
                    [style]="{
                        width: '560px'
                    }"
                />
            </div>
            <div *ngIf="!dataLoad">
                <p-password
                    [(ngModel)]="passwordValue"
                    [feedback]="false"
                    [toggleMask]="true"
                    [inputStyle]="{ width: '560px' }"
                    [ngClass]="
                        isFormInvalid && !passwordValue
                            ? 'ng-dirty ng-invalid'
                            : ''
                    "
                ></p-password>
                <div
                    *ngIf="isFormInvalid && !passwordValue"
                    class="ng-invalid ng-dirty"
                    style="text-align: left"
                >
                    <small id="" class="p-error"
                        >Le mot de passe de l'hôtel est requis
                    </small>
                </div>
            </div>
        </div>
        <div class="colonneActivations" style="padding-top: 10px">
            <div>
                <span style="align-self: center">Prix</span>
            </div>
            <div *ngIf="dataLoad">
                <p-progressSpinner
                    styleClass="w-1rem h-1rem"
                    strokeWidth="8"
                ></p-progressSpinner>
            </div>
            <div *ngIf="!dataLoad">
                <p-inputSwitch [(ngModel)]="prixOn"></p-inputSwitch>
            </div>

            <div>
                <span style="align-self: center">Restriction</span>
            </div>
            <div *ngIf="dataLoad">
                <p-progressSpinner
                    styleClass="w-1rem h-1rem"
                    strokeWidth="8"
                ></p-progressSpinner>
            </div>

            <div *ngIf="!dataLoad">
                <p-inputSwitch [(ngModel)]="restrictionOn"></p-inputSwitch>
            </div>

            <div>
                <span style="align-self: center">Simulation</span>
            </div>
            <div *ngIf="dataLoad">
                <p-progressSpinner
                    styleClass="w-1rem h-1rem"
                    strokeWidth="8"
                ></p-progressSpinner>
            </div>
            <div *ngIf="!dataLoad">
                <p-inputSwitch [(ngModel)]="simulationOn"></p-inputSwitch>
            </div>
        </div>

        <div *ngIf="!hotelNotIntialized">
            <p-button
                label="Modifier"
                icon="pi pi-save"
                (onClick)="putConfirmation()"
                styleClass="p-button-success"
            >
            </p-button>
        </div>
        <div *ngIf="hotelNotIntialized">
            <p-button
                label="Initialiser"
                icon="pi pi-chevron-circle-right"
                (onClick)="initialiseHotel()"
            >
            </p-button>
        </div>
    </p-tabPanel>

    <!-- Rate-plans -->
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-file-edit mr-1"></i>
            <span>Mappings</span>
        </ng-template>
        <div *ngIf="!hotelNotIntialized">
            <app-mapping></app-mapping>
        </div>
    </p-tabPanel>
</p-tabView>
<p-dialog *ngIf="misterBookingPutDialog" [(visible)]="misterBookingPutDialog" [modal]="true" [style]="{ width: '35vw' }">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Confirmation de sauvegarde</span>
    </ng-template>
    <p><b>Voulez-vous sauvegarder les modifications ?</b>
    </p>
    <div class="mt-2">
        <div class="mt-2 ">
            <span>Veillez entrer le code de l'Hotel <b>{{currentHotel.name}}</b> :</span>
            <input type="text" pInputText [style]="{'width': '200px', 'margin': '15px'}" pInputText
                [(ngModel)]="hotCode" />
                <div
                    *ngIf="hotCode != null && hotCode != currentHotel.hotCode ">
                    <small id="" class="p-error">Code Hotel incorrecte</small>
                </div>
        </div>
        
    </div>
    <ng-template pTemplate="footer">
        <p-button [disabled]="hotCode == null || hotCode != currentHotel.hotCode" icon="pi pi-check"
          label="Valider" styleClass="p-button-success" (onClick)="initialiseHotel()"></p-button>
       
    </ng-template>
</p-dialog>

