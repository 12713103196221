import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RateShopperConfigService } from '../../services/api/api';
import { RateShopperMappings } from '../../services/model/rateShopperMappings';
import { RateShopperHotelMapping } from '../../services/model/rateShopperHotelMapping';
import { RoomTypeStandard } from '../../services/model/roomTypeStandard';
import { RateShopperRoomType } from '../../services/model/rateShopperRoomType';
import { RateShopperMappingInfos } from '../../services/model/rateShopperMappingInfos';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Hotel } from '../../services/model/hotel';
import { RateShopperHotelMappingForUpdate } from '../../services/model/rateShopperHotelMappingForUpdate';
import { RateShopperMappingsForUpdate } from '../../services/model/rateShopperMappingsForUpdate';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';


@Component({
    selector: 'app-rate-shopper-mappings',
    templateUrl: './rate-shopper-mappings.component.html',
    styleUrls: ['./rate-shopper-mappings.component.css']
})
export class RateShopperMappingsComponent implements OnInit, OnDestroy {
    currentHotel: HotelAuth;
    ratShopperMappings: RateShopperMappings;
    dataUpdated: boolean;  // coté client
    dataError: boolean;
    dataLoaded: boolean;
    selectedCompetitor: RateShopperHotelMapping;
    hotels: Hotel[];
    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    displayAddCompForm: boolean;

    selectedCompetitorToAdd: Hotel;

    colsHotels: any[] = [];
    sub4: Subscription;
    typStdToAddorUpdate: RoomTypeStandard = {};

    selectedTypeStd: RoomTypeStandard;

    @ViewChild('op', { static: false })
    overlayPanel: OverlayPanel;

    modeUpdateTypeStd: boolean; // si true en mode mise à jour tu type std


    constructor(
        private rateShopperConfigService: RateShopperConfigService,
        private userInfoService: UserInfoService,
        private notifService: NotificationsService,
        private confirmationService: ConfirmationService, private breadcrumbService: BreadcrumbService) {

        this.breadcrumbService.setItems([
            { label: 'rateshopper/mappings' }
        ]);
    }
    ngOnDestroy(): void {
        this.sub1?.unsubscribe();
        this.sub2?.unsubscribe();
        this.sub3?.unsubscribe();
        this.sub4?.unsubscribe();
    }

    ngOnInit(): void {

        this.colsHotels = [
            { field: "eaxessId", header: "eaxessId", width: "15px" },
            { field: "hotCode", header: "HotCode", width: "15px" },
            { field: "name", header: "Nom", width: "45px" },
            { field: "address", header: "Adresse", width: "45px" },
            { field: "city", header: "Ville", width: "45px" },
        ];


        this.sub3 = this.rateShopperConfigService.apiHotelsGet().subscribe(data => {
            this.hotels = data;
        })

        this.sub1 = this.userInfoService.getNewHotel$().subscribe(hotel => {
            setTimeout(() => {
                this.currentHotel = hotel;
                this.dataLoaded = false;
                this.dataError = false;
                this.loadData();
            }, 100);
        });
    }

    loadData(): void {
        this.sub2 = this.rateShopperConfigService.apiHotelsHotCodeMappingsGet(this.currentHotel.hotCode).subscribe({next:(data) => {
            this.ratShopperMappings = data;
            this.dataLoaded = true;
            this.dataUpdated = false;
            this.dataError = false;
        },error: err => {
            console.error("Erreur http", err);
            this.dataLoaded = false;
            this.dataError = true;
            this.dataUpdated = false;
        }});
    }

    updateTypeStdDialog(event: Event): void {
        this.typStdToAddorUpdate = Object.assign({}, this.selectedTypeStd);
        this.modeUpdateTypeStd = true;
        this.overlayPanel.toggle(event, event.target);
    }

    addTypeStdDialog(event: Event): void {
        this.typStdToAddorUpdate = {};
        this.modeUpdateTypeStd = false;
        this.overlayPanel.toggle(event, event.target);
    }

    updateMappings() {

        var ratShopperMappingsForUpdate = JSON.parse(JSON.stringify(this.ratShopperMappings)) as RateShopperMappingsForUpdate;

        this.rateShopperConfigService.apiHotelsHotCodeMappingsPut(this.currentHotel.hotCode, ratShopperMappingsForUpdate).subscribe(
            {
                next:
                    response => {
                        if (response.errorCode) {
                            this.notifService.setMessage(response.message, 'Mappings', true);
                        }
                        else {
                            this.dataUpdated = false;
                            this.notifService.setMessage(`Enregistrement du mappings avec succès`, 'Mappings', false);
                            this.ratShopperMappings.etag = response.newEtag;
                        }
                    },
                error: response => {
                    var errorHttp = response.error;

                    let msg = errorHttp.message;

                    if (errorHttp.errorCode == 412) msg = msg + " Merci de recharger le mapping"
                    this.notifService.setMessage(msg, 'Mappings', true);
                }
            });

    }

    getMessageError(objErr: any) {
        if (objErr.error.errors)
            for (const property in objErr.error.errors) {
                return objErr.error.errors[property];
            }
    }

    selectTypeStandard(type: RoomTypeStandard): void {
        if (this.selectedTypeStd && this.selectedTypeStd.roomTypeNumber == type.roomTypeNumber) {
            this.selectedTypeStd = null;
        }
        else {
            this.selectedTypeStd = type;
        }
    }

    addOrUpdateTypeStd(): void {

        if (this.modeUpdateTypeStd) {
            let existingTypsStd = this.ratShopperMappings.roomTypeStandards.find(x => x.roomTypeNumber == this.typStdToAddorUpdate?.roomTypeNumber);
            if (existingTypsStd) {

                //existingTypsStd.roomTypeStd = this.typStdToAddorUpdate.roomTypeStd;
                //parcourir le mapping et modifier roomTypeStd  par  this.typStdToAddorUpdate.roomTypeStd;

                for (let index = 0; index < this.ratShopperMappings.mappings.length; index++) {
                    const mappingsItem = this.ratShopperMappings.mappings[index];

                    for (let index2 = 0; index2 < mappingsItem.mappedRoomTypes.length; index2++) {
                        const mappedRoomTypesItem = mappingsItem.mappedRoomTypes[index2];
                        if (mappedRoomTypesItem.roomTypeNumber == this.typStdToAddorUpdate.roomTypeNumber) {
                            mappedRoomTypesItem.roomTypeStd = this.typStdToAddorUpdate.roomTypeStd
                        }
                    }

                }
                existingTypsStd.roomTypeStd = this.typStdToAddorUpdate.roomTypeStd;
                this.dataUpdated = true;

            }
        }
        else {

            let existingTypsStd = this.ratShopperMappings.roomTypeStandards.find(x => x.roomTypeNumber == this.typStdToAddorUpdate?.roomTypeNumber
                || x.roomTypeStd.trim().toLowerCase() == this.typStdToAddorUpdate?.roomTypeStd?.trim()?.toLowerCase());

            if (!existingTypsStd) {
                this.ratShopperMappings.roomTypeStandards.push({
                    roomTypeStd: this.typStdToAddorUpdate.roomTypeStd,
                    roomTypeNumber: this.typStdToAddorUpdate.roomTypeNumber,
                });
                this.dataUpdated = true;
            }
            else {
                this.notifService.setMessage(`Un type equivalent existe : ${existingTypsStd.roomTypeStd}`, 'Mappings', true);

            }
        }
        this.overlayPanel.hide();
        this.typStdToAddorUpdate = {};
    }
    selectCompetitors(event, rateShopperHotelMapping: RateShopperHotelMapping): void {

        if (rateShopperHotelMapping.isPrincipal === false) {
            if (this.selectedCompetitor && this.selectedCompetitor.rateShopperId == rateShopperHotelMapping.rateShopperId) {
                this.selectedCompetitor = null
            }
            else {
                this.selectedCompetitor = rateShopperHotelMapping;

            }
        }
    }
    getMappedRoomType(rateShopperHotelMapping: RateShopperHotelMapping, roomTypeStandard: RoomTypeStandard): Array<RateShopperMappingInfos> {
        let result: Array<RateShopperMappingInfos> = [];

        if (!rateShopperHotelMapping.mappedRoomTypes || rateShopperHotelMapping.mappedRoomTypes.length == 0) return result;

        for (let index = 0; index < rateShopperHotelMapping.mappedRoomTypes.length; index++) {
            const mapedRoomInfos = rateShopperHotelMapping.mappedRoomTypes[index];

            if (mapedRoomInfos.roomTypeNumber == roomTypeStandard.roomTypeNumber) {
                result.push(mapedRoomInfos);
            }
        }
        return result;
    }


    showErrorsMapping(): void {
        this.notifService.setMessage("Echec de modification du mapping", "RateShopper", true)
    }

    //---------------------------------------------------------------------------------------------------------
    // depuis le non mappé
    dragNotMap_RateShopperHotelMapping: RateShopperHotelMapping;
    dragNotMap_RateShopperRoomType: RateShopperRoomType;

    dragNotMappedStart(rateShopperHotelMapping: RateShopperHotelMapping, rateShopperRoomType: RateShopperRoomType) {
        this.dragNotMap_RateShopperHotelMapping = rateShopperHotelMapping;
        this.dragNotMap_RateShopperRoomType = rateShopperRoomType;
    }
    dragNotMappedEnd(event) {
        this.dragNotMap_RateShopperHotelMapping = null;
        this.dragNotMap_RateShopperRoomType = null;
        console.log("Drag END: dragNotMappedEnd");
    }

    // evenement drop dans la partie mappée
    dropToMapped(rateShopperHotelMapping: RateShopperHotelMapping, roomTypeStandard: RoomTypeStandard) {
        // mapper un type chambre
        // Tester  d'ou vient le type ( de quelle colonne mappée ou non )

        if (this.dragNotMap_RateShopperRoomType) {
            if (rateShopperHotelMapping.rateShopperId != this.dragNotMap_RateShopperHotelMapping.rateShopperId) {
                // console.error(" Mapper un type  non mappé - Erreur!!");
                this.showErrorsMapping();
            }
            // console.log("Mapper un type  non mappé", this.dragNotMap_RateShopperRoomType);
            const index = rateShopperHotelMapping.notMappedRoomTypes.indexOf(this.dragNotMap_RateShopperRoomType);
            if (index > -1) {
                rateShopperHotelMapping.notMappedRoomTypes.splice(index, 1);

                let newMapInfos = {
                    roomTypeStd: roomTypeStandard.roomTypeStd,
                    roomTypeName: this.dragNotMap_RateShopperRoomType.roomTypeName,
                    roomTypeNumber: roomTypeStandard.roomTypeNumber,
                    roomTypeCode: this.dragNotMap_RateShopperRoomType.roomTypeCode,
                    pax: this.dragNotMap_RateShopperRoomType.pax
                }

                rateShopperHotelMapping.mappedRoomTypes.push(newMapInfos);
                this.dataUpdated = true;
            }
        }
        else if (this.dragMap_rateShopperMappingInfos) {
            if (rateShopperHotelMapping.rateShopperId != this.dragMap_RateShopperHotelMapping?.rateShopperId) {
                console.error("Deplacer un mapping- Erreur!!");
                this.showErrorsMapping();
            }
            else {

                console.log("Deplacer un mapping", this.dragMap_rateShopperMappingInfos);

                this.dragMap_rateShopperMappingInfos.roomTypeStd = roomTypeStandard.roomTypeStd;
                this.dragMap_rateShopperMappingInfos.roomTypeNumber = roomTypeStandard.roomTypeNumber;
                this.dataUpdated = true;
            }

        }
        this.dragMappedEnd(null);
        this.dragNotMappedEnd(null);
    }
    //---------------------------------------------------------------------------------------------------------


    //---------------------------------------------------------------------------------------------------------
    //depuis le mappé
    dragMap_RateShopperHotelMapping: RateShopperHotelMapping;
    dragMap_rateShopperMappingInfos: RateShopperMappingInfos;


    dragMappedStart(rateShopperHotelMapping: RateShopperHotelMapping, rateShopperMappingInfos: RateShopperMappingInfos) {
        this.dragMap_RateShopperHotelMapping = rateShopperHotelMapping;
        this.dragMap_rateShopperMappingInfos = rateShopperMappingInfos;
    }

    dragMappedEnd(event) {
        this.dragMap_RateShopperHotelMapping = null;
        this.dragMap_rateShopperMappingInfos = null;
        console.log("Drag END: dragMappedEnd");
    }

    // evenement drop dans la partie mappée
    dropToNotMapped(rateShopperHotelMapping: RateShopperHotelMapping) {

        if (this.dragMap_rateShopperMappingInfos) {
            //depuis la zone mappée
            if (this.dragMap_RateShopperHotelMapping.rateShopperId != rateShopperHotelMapping.rateShopperId) {
                console.error("Erreur de supression du mapping ", this.dragMap_rateShopperMappingInfos);
                this.showErrorsMapping();
            }
            else {
                console.log("Supression du mapping pour", this.dragMap_rateShopperMappingInfos)
                const index = rateShopperHotelMapping.mappedRoomTypes.indexOf(this.dragMap_rateShopperMappingInfos);
                if (index > -1) {
                    rateShopperHotelMapping.mappedRoomTypes.splice(index, 1);

                    let notMapInfos = {
                        roomTypeCode: this.dragMap_rateShopperMappingInfos.roomTypeCode,
                        roomTypeName: this.dragMap_rateShopperMappingInfos.roomTypeName,
                        pax: this.dragMap_rateShopperMappingInfos.pax
                    }
                    rateShopperHotelMapping.notMappedRoomTypes.push(notMapInfos);
                    this.dataUpdated = true;
                }
            }
        }
        else if (rateShopperHotelMapping.rateShopperId != this.dragNotMap_RateShopperHotelMapping.rateShopperId) {
            if (!this.dragMap_RateShopperHotelMapping) {
                console.error("Erreur de supression du mapping ", this.dragNotMap_RateShopperRoomType);
                this.showErrorsMapping();
            }
        }

        this.dragMappedEnd(null);
        this.dragNotMappedEnd(null);

    }


    //--------------------------------------------------------------------------------------------------------

    InitHotel(event): void {
        this.confirmationService.confirm({
            target: event.target,
            message: "Confirmation de l'initialisation de l'hôtel?",
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {
                this.rateShopperConfigService.apiHotelsHotCodeInitRateShopperHotelPost(this.currentHotel.hotCode)
                    .subscribe({next:(value) => {
                        this.notifService.setMessage(`Initialisation en cours de l'hôtel ${this.currentHotel.name}`, "RateShopper", false)
                        //Rechargement des données
                        this.dataLoaded = false;
                        this.dataError = false;
                        this.loadData()

                    },
                        error:err => {
                            // console.log(err);
                            this.notifService.setMessage(err.error, "RateShopper", true)
                        }})
            },
            reject: () => {
                this.notifService.setMessage('Opération annulée', "RateShopper", true)
            }
        });

    }

    removeSelectedCompetitor(event): void {
        if (!this.selectedCompetitor) return;
        this.confirmationService.confirm({
            target: event.target,
            message: `Voulez-vous supprimer le concurent ${this.selectedCompetitor.hotelName}?`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {

                const index = this.ratShopperMappings.mappings.indexOf(this.selectedCompetitor);
                if (index > -1) {
                    this.ratShopperMappings.mappings.splice(index, 1);
                    this.dataUpdated = true;
                    this.selectedCompetitor = null;
                }
                this.notifService.setMessage(`concurent ${this.selectedCompetitor.hotelName} supprimé.`, "RateShopper", false)
            },
            reject: () => {
                this.notifService.setMessage('Opération annulée', "RateShopper", true)
            }
        });
    }
    removeSelectedTypeStd(event): void {
        if (!this.selectedTypeStd) return;
        this.confirmationService.confirm({
            target: event.target,
            message: `Voulez-vous supprimer le type ${this.selectedTypeStd.roomTypeStd}?`,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Oui",
            rejectLabel: "Non",
            accept: () => {

                //Supprimer le type de la liste des types standard
                const index = this.ratShopperMappings.roomTypeStandards.indexOf(this.selectedTypeStd);
                if (index > -1) {
                    this.ratShopperMappings.roomTypeStandards.splice(index, 1);
                }


                for (let i = 0; i < this.ratShopperMappings.mappings.length; i++) {
                    const rateShopperHotelMapping = this.ratShopperMappings.mappings[i];

                    const mapingToDeletes = rateShopperHotelMapping.mappedRoomTypes.filter(x => x.roomTypeNumber == this.selectedTypeStd.roomTypeNumber);

                    for (let j = 0; j < mapingToDeletes.length; j++) {
                        const mapingToDelete = mapingToDeletes[j];
                        const indexElement = rateShopperHotelMapping.mappedRoomTypes.indexOf(mapingToDelete);
                        //supprimer su mappings
                        if (indexElement > -1) rateShopperHotelMapping.mappedRoomTypes.splice(index, 1);

                        //ajouter à la liste des non mappés
                        rateShopperHotelMapping.notMappedRoomTypes.push(
                            {
                                roomTypeCode: mapingToDelete.roomTypeCode,
                                roomTypeName: mapingToDelete.roomTypeName,
                                pax: mapingToDelete.pax
                            });

                    }
                }

                this.notifService.setMessage(`Type ${this.selectedTypeStd.roomTypeStd} supprimé.`, 'RateShopper', false)
                this.selectedTypeStd = null;

            },
            reject: () => {
                this.notifService.setMessage('Opération annulée', "RateShopper", true)
            }
        });
    }



    OpenAddCompetitorsDialog(): void {
        this.displayAddCompForm = true;

    }

    addCompetitorsDesabled(): boolean {

        let result = !this.selectedCompetitorToAdd || !this.selectedCompetitorToAdd.eaxessId;

        if (!result && this.ratShopperMappings && this.ratShopperMappings.mappings) {
            let existingComp = this.ratShopperMappings.mappings.filter(x => x.rateShopperId == this.selectedCompetitorToAdd.rateShopperId);
            if (existingComp.length > 0) return true;
        }
        return result;
    }

    addCompetitors(): void {

        this.displayAddCompForm = false;

        this.sub4 = this.rateShopperConfigService.apiHotelsRateShopperIdRoomTypesGet(this.selectedCompetitorToAdd.rateShopperId)
            .subscribe(roomTypes => {

                this.ratShopperMappings.mappings.push({
                    isPrincipal: false,
                    rateShopperId: this.selectedCompetitorToAdd.rateShopperId,
                    hotelName: this.selectedCompetitorToAdd.name,
                    bookingUrl: this.selectedCompetitorToAdd.bookingUrl,
                    notMappedRoomTypes: roomTypes,
                    mappedRoomTypes: []
                });

                this.dataUpdated = true;
            });

    }

}


