import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormParameter } from '../../models/form-parameter';
import { FormControl2 } from '../../models/formControl2';
import { FormGroup2 } from '../../models/formGroup2';
import { MultiSelect } from 'primeng/multiselect';
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent {

    @Input() formGroup: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2

    parentFormGroup: FormGroup2
    typeParrentGroup: string
    tooltipzindex=TOOL_TIP_Z_INDEX;

    // newFormGroup: FormGroup2
    // newformControl: FormControl2

    isFocus = false;

    @ViewChild('selectbox1') selectbox: MultiSelect;
    constructor() { }
    ngAfterViewInit(): void {
        if (this.field.readOnly === true) {
            // this.formControl.disable();
            this.selectbox.disabled = true;
        }
        else {
            // this.formControl.enable();
            this.selectbox.disabled = false;
        }
    }


    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (this.formControl.parent instanceof FormGroup2) {
            this.parentFormGroup = this.formControl.parent as FormGroup2
            if (this.parentFormGroup && this.parentFormGroup.formParam) {
                this.typeParrentGroup = this.parentFormGroup.formParam.type
            }
        }

        // let group: any = {}

        // let value = this.formControl.value

        // let valueForSelect = this.field.possibleValues.find(
        //     (x) => x.code == value
        // )

        // this.newformControl = new FormControl2(
        //     this.field.code,
        //     valueForSelect?.code
        // )

        // this.newformControl.setValidators(this.formControl.validator)
        // group[this.field.code] = this.newformControl
        // this.newFormGroup = new FormGroup2(group, '')


    }

    onChange(event) {
        this.formControl.update_Value_And_Validity();
    }
}
