import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { Dropdown } from 'primeng/dropdown';
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit, AfterViewInit {
    @Input() formGroup: FormGroup;
    @Input() field: FormParameter;
    formControl: FormControl2;

    parentFormGroup: FormGroup2;
    typeParrentGroup: string;
    tooltipzindex=TOOL_TIP_Z_INDEX;

    @ViewChild('selectbox1') selectbox: Dropdown;


    isFocus = false

    constructor() { }
    ngAfterViewInit(): void {
        if (this.field.readOnly === true) {
            // this.formControl.disable();
            this.selectbox.disabled = true;
        }
        else {
            // this.formControl.enable();
            this.selectbox.disabled = false;
        }
    }


    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (this.formControl.parent instanceof FormGroup2) {
            this.parentFormGroup = this.formControl.parent as FormGroup2
            if (this.parentFormGroup && this.parentFormGroup.formParam) {
                this.typeParrentGroup = this.parentFormGroup.formParam.type
            }
        }
    }

    onChange(event) {
        this.formControl.update_Value_And_Validity();
    }

    getStyleClass(): string {

        if(this.field.type=="ShortSelect") return "shortSelect";

        if (this.typeParrentGroup == "ColumnGroup") return "selectForColumnGroup";

        if (this.typeParrentGroup == "VGroup") return "inputMaxLarge"
        if (!this.typeParrentGroup) return "inputMaxLarge"

        return "normalSelect";
    }
}
