import { Injectable } from '@angular/core';
import { AbstractDataService } from 'src/app/horizontal-scroll/models/AbstractDataService';
import { ViewsService } from '../../services/api/api';
import { Subject, takeUntil } from 'rxjs';
import { PricersOutputView } from '../../services/model/models';

@Injectable()
export class YieldDataService extends AbstractDataService {
    pricersOutputView: PricersOutputView;

    Data: Map<string, any> = new Map();
    hotCode: string;

    constructor(private viewsService: ViewsService) {
        super();
    }

    getFormattedDate(date: Date): string {
        const year: number = date.getFullYear();
        const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
        const day: string = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`; // Retourne le format yyyy-MM-dd
    }

    loadData(hotCode: string, dateMin: Date, dateMax: Date): void {

        if (hotCode != this.hotCode) {
            this.Data.clear();
            this.hotCode = hotCode;
        }
        let subsciption = this.viewsService.apiViewsAllPricesGet(this.getFormattedDate(dateMin), hotCode, this.getFormattedDate(dateMax), null, null)
            .subscribe(
                {
                    next: data => {
                        this.pricersOutputView = data;

                        if (this.pricersOutputView?.pricersOutputViewList) {

                            this.pricersOutputView.pricersOutputViewList.forEach(pricerOutputViewItem => {

                                if (pricerOutputViewItem?.pricerPrdvuOuputViewList) {
                                    pricerOutputViewItem.pricerPrdvuOuputViewList.forEach(item => {
                                        let key = `${item.dateSejour.year}-${item.dateSejour.month}-${item.dateSejour.day}-${pricerOutputViewItem.code}-${item.prdvuCode}`;
                                        this.Data.set(key, item);
                                    })
                                }

                                if (pricerOutputViewItem?.pricerOutputViewList) {
                                    pricerOutputViewItem.pricerOutputViewList.forEach(item => {
                                        let key = `${item.dateSejour.year}-${item.dateSejour.month}-${item.dateSejour.day}-${pricerOutputViewItem.code}`;
                                        this.Data.set(key, item);
                                    })
                                }

                            })
                        }
                        subsciption.unsubscribe();
                    },
                    error: err => {

                        if (err.status == '404') {

                        }
                        else {

                        }
                    }
                }
            );
    }
    getCellValue(key: string): any {
        return this.Data.get(key);
    }

}
