<p-table id="tableAttrib" [value]="attributions" styleClass="p-datatable-gridlines" (mouseleave)="onLeave(null)">
    <ng-template pTemplate="header">
        <tr>
            <th [ngStyle]="{'text-align':index > 0 ? 'center':''}" [class]="getColumnBackgroudClass(column)"
                *ngFor="let column of titre; let index=index">
                <div>
                    {{column.titre}}
                </div>

            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-prdv>
        <tr>
            <td *ngFor="let item of prdv;let index=index" [class]="getRowBackgroudClass(item,index)">
                <div (mouseenter)="onHover(item)">

                    <div *ngIf="index==0">
                        {{item.titre}}
                    </div>

                    <div *ngIf="index>0" class="attrCell" [class]="getSurclass(item)">

                        <i *ngIf="isAttribution(item)" class="pi pi-check" (click)="setAttribution(false, item)"
                            style="cursor: pointer; color: green; font-weight: bold;font-size: 1.2rem">
                        </i>
                        <i *ngIf="!isAttribution(item)" class="pi pi-minus" (click)="setAttribution(true, item)"
                            style="cursor: pointer; color: red;font-size: 0.8rem ">
                        </i>

                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
