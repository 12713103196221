import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormParameter } from '../../models/form-parameter'
import { FormControl2 } from '../../models/formControl2'
import { FormGroup2 } from '../../models/formGroup2'
import { TOOL_TIP_Z_INDEX} from '../componentConfig'

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.css'],
})
export class InputTextComponent implements OnInit {
    @Input() formGroup: FormGroup
    @Input() field: FormParameter
    formControl: FormControl2
    parentFormGroup: FormGroup2
    typeParrentGroup: string
    tooltipzindex=TOOL_TIP_Z_INDEX;

    constructor() { }

    ngOnInit(): void {
        this.formControl = this.formGroup.get(this.field.code) as FormControl2

        if (this.formControl.parent instanceof FormGroup2) {
            this.parentFormGroup = this.formControl.parent as FormGroup2
            if (this.parentFormGroup && this.parentFormGroup.formParam) {
                this.typeParrentGroup = this.parentFormGroup.formParam.type
            }
        }
    }

    onKeydown(event) {
        this.formControl.update_Value_And_Validity();
    }

    getStyleClass(): string {
        if (this.typeParrentGroup == "VGroup") return "inputMaxLarge"
        if (this.typeParrentGroup == "ColumnGroup") return "inputColumnGroup";
        // if (this.typeParrentGroup == "HGroup") return "inputMaxLarge" //par defaut

        if (!this.typeParrentGroup) return "inputMaxLarge"

        return "";
    }
}
