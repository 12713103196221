<div *ngIf="field.type!=='RadioGroupHorizontal'">
    <span>{{ field.label }}</span>
    <div class="mt-3" [formGroup]="formGroup" *ngIf="true">
        <div *ngFor="let childField of field.possibleValues" class="p-field-radiobutton" [pTooltip]="field.toolTip"
            tooltipPosition="top" [tooltipZIndex]="tooltipzindex">
            <div class="mb-3 gridH">
                <p-radioButton [inputId]="childField.code" [name]="field.code" [value]="childField.code"
                    [formControlName]="field.code" (onClick)="click()">
                </p-radioButton>
                <label [for]="childField.code" class="ml-1">{{childField.label}}</label>
            </div>

        </div>
    </div>
</div>

<div *ngIf="field.type==='RadioGroupHorizontal'">
    <span>{{ field.label }}</span>
    <div class="mt-2" [formGroup]="formGroup" *ngIf="true">
        <div *ngFor="let childField of field.possibleValues" class="p-field-radiobutton" [pTooltip]="field.toolTip"
            tooltipPosition="top" [tooltipZIndex]="tooltipzindex" style="display: inline-block;">
            <div class="mr-1">
                <p-radioButton [inputId]="childField.code" [name]="field.code" [value]="childField.code"
                    [formControlName]="field.code" (onClick)="click()"></p-radioButton>
                <label [for]="childField.code" class="ml-2">{{childField.label}}</label>
            </div>

        </div>
    </div>
</div>