import { AfterViewInit, Component, Directive, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { BudgetMonth } from '../services/model/budgetMonth';
import { BudgetDay } from '../services/model/budgetDay';
import { BudgetCalcul } from '../budgets/budgetCalcul';

@Component({
  selector: 'app-form-budget',
  templateUrl: './form-budget.component.html',
  styleUrls: ['./form-budget.component.css']
})
export class FormBudgetComponent implements OnInit, AfterViewInit {

  dayCols: ICols[];
  budgetMonth: BudgetMonth = {};
  @Input() newBudgetMonth: BudgetMonth;
  @Input()selectedYear: number;
  @Input()selectedMonth: string;
  @Input() displayBudgetDays: boolean;
  @Input() isError: boolean;
  @Input() budgetDay: BudgetDay[];
  @Input() budgetCalcul: BudgetCalcul;
  @Output() OnValidate = new EventEmitter();
  @Output() OnProcess = new EventEmitter();
  nbChambres: number;
  nbRooms: number;
  displayBudget: boolean;

  constructor() { }

  afterViewInit: boolean;
  ngAfterViewInit(): void {
    this.afterViewInit = true;
  }

  ngOnInit(): void {

    this.dayCols = [
      { field: "dayOfWeek", header: "", width: 30 },
      { field: "date", header: "", width: 30 },
      { field: "cah", header: "cah", width: 90 },
      { field: "caPdj", header: "caPdj" },
      { field: "p3", header: "p3" },
      { field: "p4", header: "p4" },
      { field: "p5", header: "p5" },
      { field: "p6", header: "p6" },
      { field: "p7", header: "p7" },
      { field: "p8", header: "p8" },
      { field: "p9", header: "p9" },
      { field: "occup", header: "occup" },
      { field: "nuitees", header: "nuits" },
      { field: "hs", header: "hs" },
      { field: "offerts", header: "offert" },
      { field: "chVendu", header: "chV" },
      { field: "chTravaux", header: "chTrav" },
      { field: "nbCh", header: "ChConst" },
    ];
  }

  budgetValidate(event) {
    this.OnValidate.emit(event);
  }

  async processPost(event){
    await this.OnProcess.emit(event);

  }
}
