<div [formGroup]="formGroup2">
  <label [for]="field.code">{{ field.label }}
    <span *ngIf="field.required && field.label" class="fd-error">*</span>
  </label>
  <div [pTooltip]="field.toolTip" tooltipPosition="top" [tooltipZIndex]="tooltipzindex"
    style="margin-bottom: 1.2em;position: relative" class="mt3">

    <ng-container *ngFor="let childField of field.possibleValues">
      <!-- todo: refaire le bouton en simple div  -->
      <button style="margin-right: 5px;" pButton pRipple type="button" [label]="childField.label"
        class="p-button-sm p-button-outlined" [ngClass]="getColorClass(childField.code)"
        (click)="onClick(childField.code)" [disabled]="!formControl2.activationConditionValue">
      </button>

    </ng-container>

    <div class="fd-errorText">
      <small>{{ formControl2?.getErrorsMessage() }}
      </small>
    </div>
  </div>
</div>