import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserInfoService } from 'src/app/azureAdB2C/UserInfoService/user-InfoService';
import { HotelAuth } from 'src/app/azureAdB2C/services/model/hotelAuth';
import { ProduitVenduUnit } from 'src/app/spotLive/services/model/produitVenduUnit';
import { SubSink } from 'src/app/dynamicForms/tools/subsink';
import { ExportTablesService } from 'src/app/services/exports/export-tables.service';
import { MappingsService } from 'src/app/spotLive/services/api/mappings.service';
import { MappingItem } from 'src/app/spotLive/services/model/mappingItem';
import { ResourceCategoryMews } from 'src/app/spotLive/services/model/resourceCategoryMews';
import { ICols } from 'src/app/analyse/services/model/cols-table';
import { MappingRateInventory } from 'src/app/spotLive/services/model/models';
@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit, OnDestroy, OnChanges {

    currentHotel: HotelAuth;
    dataLoaded: boolean;
    private subs = new SubSink();

    @Input() produitVenduUnits: ProduitVenduUnit[];
    @Input() resourceCategoryMews: ResourceCategoryMews[];
    @Input() mappingRateInventory: MappingRateInventory;
    @Input() hotelNotIntialized: boolean;

    selectedUnit: ProduitVenduUnit = null;
    selectedCat: ResourceCategoryMews = null;
    mappingInventories: MappingItem[];
    allProduitVenduUnits: ProduitVenduUnit[];
    allResourceCategoryMews: ResourceCategoryMews[];

    displayAddCategoriesMapping: boolean;

    constructor(
        private mappingsService: MappingsService,
        private userInfoService: UserInfoService,
    ) { }

    ngOnInit(): void {
        this.currentHotel = this.userInfoService.currentHotel;
        this.subs.sink = this.userInfoService
            .getNewHotel$()
            .subscribe((hotCode) => {
                this.currentHotel = hotCode;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        //filter les liste: garder que les elements non mappés
        this.allProduitVenduUnits = this.produitVenduUnits
        this.allResourceCategoryMews = this.resourceCategoryMews

        this.dataLoaded = true;
        this.mappingInventories = this.mappingRateInventory?.mappingInventory;

        this.buildNotmappingLists();
        this.trierListesTypeChambres();
    }

    trierListesTypeChambres() {
        if (this.produitVenduUnits) {
            this.produitVenduUnits = this.produitVenduUnits.sort((a, b) => {
                if (a.produitVenduUnitLabel < b.produitVenduUnitLabel) return -1;
                return 1;
            });
        }

        if (this.resourceCategoryMews) {
            this.resourceCategoryMews = this.resourceCategoryMews.sort((a, b) => {
                if (a.label < b.label) return -1;
                return 1;
            });
        }
    }

    buildNotmappingLists(): void {
        //garder que les items non mappés
        this.produitVenduUnits = this.produitVenduUnits?.filter(x => !this.mappingInventories?.find(y => y.spot == x.produitVenduUnitCode));
        //garder tous les items pour mews
        this.resourceCategoryMews = this.resourceCategoryMews;
    }


    deleteFromMapping(itemTodelete: MappingItem) {
        //supprimer la ligne du mappingRatePlan
        const index = this.mappingInventories.findIndex(x => x.mews == itemTodelete.mews && x.spot == itemTodelete.spot);
        if (index > -1) {
            this.mappingInventories.splice(index, 1);
        }

        //Ajouter dans spotRatePlans
        this.produitVenduUnits.push({
            produitVenduUnitCode: itemTodelete.spot,
            produitVenduUnitLabel: itemTodelete.spotName
        });

        //ajouter dans  mewsRatePlan
        // this.resourceCategoryMews.push({
        //     id: itemTodelete.mews,
        //     label: itemTodelete.mewsName
            // les autres infos ne sont pas exploiter dans la mappings
        //});

        this.trierListesTypeChambres();
    }

    validate() {
        //Ajouter un mapping
        this.mappingInventories.push({
            mews: this.selectedCat.id,
            spot: this.selectedUnit.produitVenduUnitCode,
            mewsName: this.selectedCat.label,
            spotName: this.selectedUnit.produitVenduUnitLabel
        })
        //filter les liste: garder que les elements non mappés
        this.buildNotmappingLists();

        this.cancel();
    }

    newMapping() {
        this.displayAddCategoriesMapping = true;
        this.selectedCat = null;
        this.selectedUnit = null;
    }
    cancel() {
        this.displayAddCategoriesMapping = false;
        this.selectedCat = {};
        this.selectedUnit = {};
    }

    ngOnDestroy(): void {
        if (this.subs != undefined) {
            this.subs.unsubscribe();
        }
    }
    isNotMappingSpotCodeExist(mapItem: MappingItem): boolean {
        return !this.allProduitVenduUnits?.find(x => x.produitVenduUnitCode == mapItem.spot);
    }

    isNotMappingMewsCodeExist(mapItem: MappingItem): boolean {
        return !this.allResourceCategoryMews?.find(x => x.id == mapItem.mews);
    }


}
