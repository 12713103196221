<p-table [value]="allProduits.packageList" rowGroupMode="subheader" groupRowsBy="pkgTypeCode"
[scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'"
    styleClass="p-datatable-sm p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Libellé </th>
            <th>Prix Ref</th>
            <th>Prix Min</th>
            <th>Prix Max</th>
            <th>Pax Std</th>
            <th>Pax Min</th>
            <th>Pax Max</th>
            <th style="text-align: center;">
                <p-button icon="pi pi-plus" pTooltip="Nouveau" (onClick)="NewPkg()"
                    styleClass="p-button-rounded p-button-success">
                </p-button>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-pkg>
        <tr pRowGroupHeader>
            <td colspan="5">
                <span class="font-bold ml-2 text-pink-900">{{pkg.pkgTypeCode}}</span>
                -
                <span class="font-bold ml-2 text-pink-900">{{pkg.pkgTypeLibelle}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-pkg>
        <tr [pSelectableRow]="pkg" [ngClass]="pkg.isObsolete? 'text-pink-500':''">
            <td>{{ pkg.code }}</td>
            <td>{{ pkg.libelle }}
                <span *ngIf="pkg.isObsolete" class="ml-2">
                    <i class="pi pi-times" style="color: red"></i>
                </span>
            </td>
            <td>{{ pkg.prixRef | currency: 'EUR' }}</td>
            <td>{{ pkg.prixMin | currency: 'EUR' }}</td>
            <td>{{ pkg.prixMax | currency: 'EUR' }}</td>

            <td>{{ pkg.stdPax }}</td>
            <td>{{ pkg.minPax }}</td>
            <td>{{ pkg.maxPax }}</td>
            <td style="text-align: center;">
                <p-button icon="pi pi-pencil" (onClick)="UpdatePkg(pkg)" pTooltip="Modifier"
                    styleClass="p-button-rounded p-button-success p-button-raised p-button-text">
                </p-button>
                <p-button *ngIf="!pkg.isObsolete" icon="pi pi-times" pTooltip="Désactiver" (onClick)="DelPkg(pkg)"
                    class="ml-1" styleClass="p-button-rounded p-button-danger p-button-raised p-button-text ">
                </p-button>
                <p-button *ngIf="pkg.isObsolete" icon="pi pi-check" pTooltip="Activer" (onClick)="DelPkg(pkg)"
                    class="ml-1" styleClass="p-button-rounded p-button-success p-button-raised p-button-text ">
                </p-button>
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="displayPkgForm">
    <p-dialog [(visible)]="displayPkgForm" [modal]="true" [style]="{width: '600px'}">
        <p-header>
            Package
        </p-header>
        <app-form-dynamic #formpkg [formModel]="pkgFormConfig" [formValue]="formPkgValue"
            (OnValidate)="UpdatePkgInDB($event)" (OnCancel)="cancel($event)" [debug]="false">
        </app-form-dynamic>

    </p-dialog>
</div>

<div *ngIf="displayNewPkgdiag">
    <p-dialog [(visible)]="displayNewPkgdiag" [modal]="true" [style]="{width: '550px' }">
        <p-header>
            Nouveau Package
        </p-header>
        <div [style]="{height:'300px' }">
            <p-dropdown [options]="packageTypeList" [(ngModel)]="selectedPkgType"
                [style]="{width: '177px' }" placeholder="Selectionner un type" optionLabel="libelle"
                optionValue="pkgTypeCode" [autofocus]="true">
            </p-dropdown>
            <hr />
            <app-form-dynamic #newFormpkg [formModel]="newPkgFormConfig" *ngIf="displayNewPkgForm" [formValue]="{}"
                (OnValidate)="CreatePackage($event)" (OnCancel)="cancel($event)" [debug]="false">
            </app-form-dynamic>
        </div>

    </p-dialog>
</div>
