<p-tabView styleClass="tabview-custom">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-cog mr-1"></i>
            <span>Configuration Compta</span>
            <i class="ml-3"></i>
        </ng-template>
        <p-progressSpinner
            styleClass="w-1rem h-1rem"
            strokeWidth="8"
            *ngIf="loading"
        ></p-progressSpinner>

        <div class="mr-1" *ngIf="!loading && !hotelComptaInitializing && hotelComptaInitialized && hotelCredentialsCompta != null">
            <div class="grid ml-2 mt-1">
                <p-card class="col-5 grid mt-1" header="Credentials Compta/POS" [style]="{width:'100%',  margin:'3px'}">
                    <span class="pi pi-info-circle" [style]="{ 'align-content': 'center'}"></span>&nbsp;<label class="w-full">Vous pouvez ajuster les credentials en cliquant sur Modifier.</label>
                    <div class="mt-4">
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Activé le <span class="pi pi-info-circle" [style]="{ 'align-content': 'center'}" pTooltip="Date du premier lancement du pipeline ADF d'initialisation"></span></label>
                                <label class="col-9">{{(hotelCredentialsCompta.credentialsDetails.activatedOn | date:'medium') || '-'}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Etats (depuis 2j)</label>
                                <p-progressSpinner
                                    class="col-9"   
                                    styleClass="w-1rem h-1rem"
                                    strokeWidth="8"
                                    *ngIf="loadingPipelineStatusCompta"
                                ></p-progressSpinner>
                                <div class="grid col-9 align-content-center" *ngIf="!loadingPipelineStatusCompta && hotelPipelineStatusComptaSummary">
                                    <div class="flex align-content-center">
                                        <div class="p-0 align-content-start">{{hotelPipelineStatusComptaSummary.countSucceeded}}</div>
                                        <div class="p-0 ml-1 mr-3 svg-i-16x16-StatusSuccessful"></div>
                                        <div class="p-0 align-content-start">{{hotelPipelineStatusComptaSummary.countFailed}}</div>
                                        <div class="p-0 ml-1 mr-3 svg-i-16x16-StatusFailed"></div>
                                        <div class="p-0 align-content-start">{{hotelPipelineStatusComptaSummary.countInProgress}}</div>
                                        <div class="p-0 ml-1 mr-3 svg-i-16x16-InProgress spin"></div>
                                        <span (click)="op.toggle($event)" class="pi pi-eye beat ml-3" [style]="{ 'align-content': 'center' }" pTooltip="Cliquer ici pour voir la liste complète"></span>
                                    </div>
                                    <p-overlayPanel #op [style]="{ width: '600px' }">
                                        <ng-template pTemplate="content">
                                            <p-table [value]="hotelPipelineStatusComptaSummary.runs" 
                                                responsiveLayout="scroll" 
                                                [paginator]="true" 
                                                [rows]="5">
                                                <ng-template pTemplate="caption">
                                                    <div class="flex align-items-center justify-content-between">
                                                        Exécutions des pipelines depuis 2j
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>état</th>
                                                        <th>nom</th>
                                                        <th>démarré à</th>
                                                        <th>terminé à</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-run>
                                                    <tr>
                                                        <td *ngIf="run.status == 'Succeeded'"><div class="mr-2 svg-i-16x16-StatusSuccessful"></div></td>
                                                        <td *ngIf="run.status == 'Failed'">
                                                            <div class="flex">
                                                                <div class="mr-2 svg-i-16x16-StatusFailed"></div>
                                                                <span class="pi pi-info-circle" [style]="{ 'align-content': 'center'}" [pTooltip]="run.message"></span>
                                                            </div>
                                                        </td>
                                                        <td *ngIf="run.status == 'In progress' || run.status == 'Queued'"><div class="mr-2 svg-i-16x16-InProgress spin"></div></td>
                                                        <td *ngIf="run.status != 'In progress'
                                                            && run.status != 'Queued'
                                                            && run.status != 'Succeeded'
                                                            && run.status != 'Failed'">{{ run.status }}</td>
                                                        <td>{{ run.pipelineName }}</td>
                                                        <td>{{ run.runStart | date:'medium' }}</td>
                                                        <td>{{ run.runEnd | date:'medium' }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </ng-template>
                                    </p-overlayPanel>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">KmlCode</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.kmlCode}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Login</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.login}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Password</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.password}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">SiteCode</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.siteCode}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-4">
                                <p-button [label]="'Modifier'" icon="pi pi-pencil" class="col-11" styleClass="w-full p-button-warning"
                                    (onClick)="modifyCredentialsCompta()">
                                </p-button>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>
        
        <p-card *ngIf="!loading && !hotelComptaInitialized">
            <div class="mb-3">
                L'hôtel <span class="font-semibold text-pink-900">{{ currentHotel.name }}</span> n'est pas initialisé pour Asterio Compta/Pos
            </div>
            <p-button label="Initialiser Compta/Pos" icon="pi pi-chevron-circle-right" (onClick)="initHotelCompta($event)"></p-button>
        </p-card>

        <div class="mr-1" *ngIf="!loading && hotelComptaInitializing && hotelCredentialsCompta != null">
            <div class="grid ml-2 mt-1">
                <p-card class="col-5 grid mt-1" header="Credentials Compta/POS" [style]="{width:'100%',  margin:'3px'}">
                    <p-steps class="mb-1" [activeIndex]="activeStep" [model]="activationSteps" />
                    <div *ngIf="activeStep == 0" class="mt-4">
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">KmlCode</label>
                                <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.credentialsDetails.kmlCode" />
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Login</label>
                                <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.credentialsDetails.login" />
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Password</label>
                                <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.credentialsDetails.password"/>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">SiteCode</label>
                                <input class="col-9" type="text" pInputText pInputText [(ngModel)]="hotelCredentialsCompta.credentialsDetails.siteCode" />
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-4">
                                <p-button icon="pi pi-step-backward-alt" class="col-1" styleClass="w-full p-button"
                                    (onClick)="back()">
                                </p-button>
                                <p-button [label]="'Tester la connexion'" icon="pi pi-wifi" class="col-11" styleClass="w-full p-button-warning"  
                                    [disabled]="!validateCompta(hotelCredentialsCompta)"
                                    (onClick)="testConnectionCompta()"
                                    [loading]="savingCredentialsCompta">
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activeStep == 1">
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">KmlCode</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.kmlCode}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Login</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.login}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">Password</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.password}}</label>
                            </div>
                        </div>
                        <div>
                            <div class="grid mt-2">
                                <label class="col-3">SiteCode</label>
                                <label class="col-9">{{hotelCredentialsCompta.credentialsDetails.siteCode}}</label>
                            </div>
                        </div>
                        <div>
                            <span class="pi pi-info-circle mt-4" [style]="{ 'align-content': 'center'}"></span>&nbsp;<label class="w-full">La sauvegarde va vérifier que les infos permettent l'accès aux données et les sauvegarder dans tablestorage.</label>
                            <div class="grid mt-4">
                                <p-button icon="pi pi-step-backward-alt" class="col-1" styleClass="w-full p-button"
                                    (onClick)="back()">
                                </p-button>
                                <p-button [label]="'Sauvegarder'" icon="pi pi-save" class="col-11" styleClass="w-full p-button-warning"  
                                    [disabled]="!validateCompta(hotelCredentialsCompta)"
                                    (onClick)="saveCredentialsCompta()"
                                    [loading]="savingCredentialsCompta">
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activeStep == 2">
                        <div class="mt-4">
                            <span class="pi pi-info-circle" [style]="{ 'align-content': 'center'}"></span>&nbsp;<label>L'activation va appeler les pipelines d'initialisation ADF pour l'hôtel.</label>
                            <div class="grid mt-4">
                                <p-button icon="pi pi-step-backward-alt" class="col-1" styleClass="w-full p-button"
                                    (onClick)="back()">
                                </p-button>
                                <p-button [label]="'Activer'" icon="pi pi-bolt" class="col-11" styleClass="w-full p-button-warning"  
                                    [disabled]="!validateCompta(hotelCredentialsCompta)"
                                    (onClick)="activateCompta()"
                                    [loading]="savingCredentialsCompta">
                                </p-button>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </div>

    </p-tabPanel>
</p-tabView>
