<div class="container" style="user-select: none;">

    <div>
        <p-table #dt2 selectionMode="single" [(selection)]="selectedDistributeur" [value]="allExpo?.distributeurList"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" [scrollHeight]="'calc(100vh - 340px)'">
            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Distributeurs
                </div>
                <div class="flex">
                    <button pButton label="" class="p-button-success ml-2" pTooltip="Ajouter un distributeur"
                        icon="pi pi-plus" (click)="displayDialogNewDistributeur()"></button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <!-- <tr>
                    <th>Code</th>
                    <th>Libellé</th>
                    <th></th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-dist>
                <tr [pSelectableRow]="dist">
                    <td>{{ dist.code }}</td>
                    <td>{{ dist.libelle }}</td>
                    <td> <i class="pi pi-times delProduit"></i></td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div>
        <p-table #dt3 selectionMode="single" [(selection)]="selectedCanal" [value]="selectedDistributeur?.canalList"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
            [globalFilterFields]="['libelle','code']" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Cannaux de diffusions
                </div>
                <div class="flex">
                    <div class="flex">
                        <button pButton label="" class="p-button-success ml-2" pTooltip="Ajouter un canal"
                            icon="pi pi-plus" (click)="displayDialogNewCanal()"> </button>
                    </div>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt3.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <!-- <tr>
                    <th>Code</th>
                    <th>Libellé</th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-can>
                <tr [pSelectableRow]="can">
                    <td>{{ can.code }}</td>
                    <td>{{ can.libelle }}</td>
                    <td> <i class="pi pi-times delProduit"></i></td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!-- Plans tarifaires -->
    <div>
        <p-table #dt1 [value]="selectedCanal?.ratePlansList" selectionMode="single" [(selection)]="selectedRatePlan"
            dataKey="code" styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
            [globalFilterFields]="['Code','libelle']" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Plans tarifaires
                </div>
                <div class="flex">
                    <button pButton label="" class="p-button-success ml-3" icon="pi pi-plus"
                        (click)="displayDialogAddRatePlans()" pTooltip="Ajouter un plan tarifaire"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code">Code <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="libelle">Libellé <p-sortIcon field="libelle"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ratePlan>
                <tr [pSelectableRow]="ratePlan" [ngClass]="ratePlan.isObsolete? 'text-pink-500':''">
                    <td>{{ ratePlan.code }}</td>
                    <td>
                        <span>{{ ratePlan.libelle }}</span>
                        <span *ngIf="ratePlan.isObsolete" class="ml-2">
                            <i class="pi pi-times" style="color: red"></i>
                        </span>
                    </td>
                    <td> <i class="pi pi-times delProduit"></i></td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!-- Produit ecxlus -->
    <div>
        <p-table #dt4 [value]="matriceExpoItemsExcluList" selectionMode="single" dataKey="code"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true"
            [globalFilterFields]="['prdvuLibelle','prdvuCode']" [scrollHeight]="'calc(100vh - 340px)'">

            <ng-template pTemplate="caption">
                <div class="listTitle">
                    Produits exclus
                </div>
                <div class="flex">
                    <button pButton label="" class="p-button-success ml-3" icon="pi pi-plus"
                        (click)="displayDialogAddPrdvUExclus()"
                        pTooltip="Modifier la liste des produits exclus"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt4.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="libelle">Libellé <p-sortIcon field="libelle"></p-sortIcon></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-prdvu>
                <tr>
                    <td>
                        <span>{{ prdvu.prdvuLibelle }}</span>
                    </td>
                    <td>
                        <i class="pi pi-times delProduit" (click)="delProduitExclu(prdvu)">
                        </i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>

<div *ngIf="displayNewDistributeur">
    <p-dialog [(visible)]="displayNewDistributeur" [modal]="true" [style]="{width: '400px', height:'480px' }">
        <p-header>
            Nouveau distributeur
        </p-header>
        <app-form-dynamic #formNewDis [formModel]="NewDistributeurModel" (OnCancel)="displayNewDistributeur=false"
            (OnValidate)="addNewDistributeur($event)">
        </app-form-dynamic>
    </p-dialog>
</div>
<div *ngIf="displayNewCanal">
    <p-dialog [(visible)]="displayNewCanal" [modal]="true" [style]="{width: '400px' }">
        <p-header>
            Nouveau canal pour le distributeur {{selectedDistributeur.libelle}}
        </p-header>
        <app-form-dynamic #formNewCan [formModel]="newCanalModel" (OnCancel)="displayNewCanal=false"
            (OnValidate)="addNewCanal($event)">
        </app-form-dynamic>
    </p-dialog>
</div>


<div *ngIf="displayAddRatePlans">
    <p-dialog [(visible)]="displayAddRatePlans" [modal]="true" [style]="{width: '400px' }">
        <p-header>
            Ajout de plans tarifaires pour le canal {{selectedCanal.libelle}}
        </p-header>
        <app-form-dynamic #formaddRatePlans [formModel]="addRatePlansModel" (OnCancel)="displayAddRatePlans=false"
            (OnValidate)="addRatePlans($event)">
        </app-form-dynamic>
    </p-dialog>
</div>

<div *ngIf="displayAddPrdvuExclusDialog">
    <p-dialog [(visible)]="displayAddPrdvuExclusDialog" [modal]="true" [style]="{width: '400px' }">
        <p-header>
            Exclure les produits de la diffusion
        </p-header>
        <app-form-dynamic [formModel]="AddPrdvuExclusModel" (OnCancel)="displayAddPrdvuExclusDialog=false"
            (OnValidate)="addProduitExclus($event)">
        </app-form-dynamic>
    </p-dialog>
</div>
