<div class="cell" [ngClass]="getClassJour()"  [ngStyle]="{'font-weight':getValue()?.hasYiledPrix==true?'bold':'' }"
[style.width.px]="cellwidth" [style.height.px]="cellHeight"
    [style.line-height.px]="cellHeight">
    <div *ngIf="!isClose()">
        {{getValue()?.prix }}
    </div>
    <div *ngIf="isClose()">
        <i style="color: black; font-weight: bold;" class="pi pi-times" style="font-size: 1rem"></i>
    </div>

</div>
